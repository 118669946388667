import React, { useState } from 'react';

// material ui
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Typography,
    useTheme
} from '@mui/material';

// project import
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

// assets
import { IconSearch, IconUser } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import UserList from './UserList';
import { getUser } from 'utils/services/users';
import useRootPermission from 'hooks/useRootPermission';
import RootPermission from '../permission';
import { hasPermission } from 'helper/general';

const User = () => {
    const [user, setUser] = useState(null);
    const theme = useTheme('oures');
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const [granted, allPermission] = useRootPermission(['Users@users_get']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchUsers = async () => {
        setLoading(true);
        getUser({ page, limit, search: filter })
            .then((res) => {
                setUser(res.data);
                setTotal(res.meta.total);
                handleClose();
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };
    React.useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page, filter]);

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconUser color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        User Management
                                    </Typography>
                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                        Manage and Control User Data
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                id="input-search-list-style1"
                                placeholder="Search"
                                onChange={(e) => setFilter(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                            {hasPermission(['Users@users_getputdel'], allPermission) && (
                                <Link to="/user/form?action=create">
                                    <IconButton>
                                        <AddCircleTwoToneIcon />
                                    </IconButton>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <UserList data={user} loading={loading} handleRefresh={() => fetchUsers()} />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="primary"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                id="menu-user-list-style1"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={() => setLimit(10)}> 10 Rows</MenuItem>
                                <MenuItem onClick={() => setLimit(25)}> 25 Rows</MenuItem>
                                <MenuItem onClick={() => setLimit(50)}> 50 Rows</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default User;
