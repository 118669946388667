import { Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';

const SubCardEdu = () => (
    <SubCard title="Education" sx={{ mt: 3 }}>
        <TableContainer>
            <Table sx={{ '& td': { borderBottom: 'none' } }} size="small">
                <TableBody>
                    <TableRow>
                        <TableCell variant="head" sx={{ width: '200px' }}>
                            <Stack direction="column">
                                <Typography sx={{ fontWeight: 600 }}>2020 - 2023</Typography>
                                <Typography sx={{ fontSize: '12px', color: '#364152' }}>Bachelor</Typography>
                            </Stack>
                        </TableCell>
                        <TableCell>
                            <Stack direction="column">
                                <Typography sx={{ fontWeight: 600, color: '#121926' }}>Bachelor degree in Computer Science</Typography>
                                <Typography sx={{ fontSize: '12px', color: '#364152' }}>Widyatama University, Indonesia</Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </SubCard>
);

export default SubCardEdu;
