function IconFramePerson(props) {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_2731_10499)" stroke="#8864EE" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
                <path d="M6.667 6a1.333 1.333 0 102.666 0 1.333 1.333 0 00-2.666 0zM2.667 5.333V4A1.333 1.333 0 014 2.667h1.333M2.667 10.667V12A1.333 1.333 0 004 13.333h1.333M10.667 2.667H12A1.333 1.333 0 0113.333 4v1.333M10.667 13.333H12A1.334 1.334 0 0013.333 12v-1.333M5.333 10.667a1.333 1.333 0 011.334-1.334h2.666a1.334 1.334 0 011.334 1.334" />
            </g>
            <defs>
                <clipPath id="clip0_2731_10499">
                    <path fill="#fff" d="M0 0H16V16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default IconFramePerson;
