import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const postAssignmentInterview = async ({ body }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/assignmentInterview`, { ...body }, config);
};

export const getAssignmentInterview = async () => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/assignmentInterview`, config);
};

export const getAssignmentInterviewList = async ({ page, limit, search }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/assignmentInterview/list/?page=${page}&limit=${limit}&search=${search}&sortBy=created_at,asc`,
        config
    );
};

export const updateCancelInterview = async ({ id, payload }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/assignment/cancel-interview/${id}`, { ...payload }, config);
};

export const uploadFile = async ({ id, payload }) => {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/resources/upload_cv/${id}`, payload, config);
};