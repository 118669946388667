import { Backdrop, Box, Card, CardContent, CardHeader, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IconBook2 } from '@tabler/icons';
import { CheckCircleOutlineTwoTone, QuestionAnswerTwoTone, RemoveCircleOutlineTwoTone } from '@mui/icons-material';
import { useState } from 'react';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import NoteModalQuotation from './NoteModalQuotation';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 635,
    boxShadow: 24,
    borderRadius: '12px'
};

export const boxStyle = {
    padding: 2,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    borderRadius: '12px',
    boxShadow: '0px 7px 19.3px 0px rgb(0 0 0 / 3%)',
    border: '1px solid #EEF2FF'
};

const NegotiationLetter = ({ row, open, handleClose, setQuot, loading, setLoading, quot, handleRefresh }) => {
    const [openModal, setOpenModal] = useState(false);

    const GridNameLetter = ({ link, name, letter }) => {
        return (
            <Grid sx={{ display: 'flex', flexDirection: 'row' }} alignItems="center" gap={1}>
                <IconBook2 width={18} height={18} />
                <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>
                    <Link href={link} target="_blank" underline="hover" rel="noopener noreferrer">
                        {name} - {letter}
                    </Link>
                </Typography>
            </Grid>
        );
    };

    const generateNegoQuot = (quotation, row) => {
        if (quotation.file_quotation) {
            return (
                <Box sx={boxStyle}>
                    <GridNameLetter link={quotation.file_quotation} name={row.resource_name} letter="Quotation Letter" />
                    <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip placement="top" title="Accept">
                            <IconButton
                                onClick={() => {
                                    setOpenModal('acceptQuotation');
                                    setQuot(quotation);
                                }}
                                color="success"
                            >
                                <CheckCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Reject">
                            <IconButton
                                onClick={() => {
                                    setOpenModal('rejectQuotation');
                                    setQuot(quotation);
                                }}
                                color="error"
                            >
                                <RemoveCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Box>
            );
        }
        return null;
    };
    const generateQuotBox = (quotation, row, status) => {
        if (quotation.file_quotation) {
            return (
                <Box sx={boxStyle}>
                    <GridNameLetter link={quotation.file_quotation} name={row.resource_name} letter="Quotation Letter" />
                    <Typography variant="h6">{status}</Typography>
                </Box>
            );
        }
        return null;
    };
    const generateKTP = (quotation, row) => {
        if (quotation.file_ktp) {
            return (
                <Box sx={boxStyle}>
                    <GridNameLetter link={quotation.file_ktp} name={row.resource_name} letter="KTP" />
                </Box>
            );
        }
        return null;
    };
    const generateNdaTaNego = (quotation, row) => {
        if (quotation.file_nda && quotation.file_ta) {
            return (
                <Box sx={boxStyle}>
                    <Stack direction="column" spacing={1}>
                        <GridNameLetter link={quotation.file_nda} name={row.resource_name} letter="Non-Disclosure Agreement (NDA)" />
                        <GridNameLetter link={quotation.file_ta} name={row.resource_name} letter="Talent Agreement (TA)" />
                    </Stack>
                    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip placement="top" title="Accept">
                            <IconButton
                                onClick={() => {
                                    setOpenModal('acceptNDA_TA');
                                    setQuot(quotation);
                                }}
                                color="success"
                            >
                                <CheckCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Reject">
                            <IconButton
                                onClick={() => {
                                    setOpenModal('rejectNDA_TA');
                                    setQuot(quotation);
                                }}
                                color="error"
                            >
                                <RemoveCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Box>
            );
        }
        return null;
    };
    const generateNdaTaBox = (quotation, row, status) => {
        if (quotation.file_nda && quotation.file_ta) {
            return (
                <Box sx={boxStyle}>
                    <Stack direction="column" spacing={1}>
                        <GridNameLetter link={quotation.file_nda} name={row.resource_name} letter="Non-Disclosure Agreement (NDA)" />
                        <GridNameLetter link={quotation.file_ta} name={row.resource_name} letter="Talent Agreement (TA)" />
                    </Stack>
                    <Typography variant="h6">{status}</Typography>
                </Box>
            );
        }
        return null;
    };
    const generateCaSpkNego = (quotation, row) => {
        if (quotation.file_contract && quotation.file_spk) {
            return (
                <Box sx={boxStyle}>
                    <Stack direction="column" spacing={1}>
                        <GridNameLetter link={quotation.file_contract} name={row.resource_name} letter="Contract Agreement (CA)" />
                        <GridNameLetter link={quotation.file_spk} name={row.resource_name} letter="Surat Perintah Kerja (SPK)" />
                    </Stack>
                    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                        <IconButton color="primary">
                            <QuestionAnswerTwoTone sx={{ fontSize: 'large', color: '#03a9f4' }} />
                        </IconButton>
                        <Tooltip placement="top" title="Accept">
                            <IconButton
                                onClick={() => {
                                    setOpenModal('acceptCA_SPK');
                                    setQuot(quotation);
                                }}
                                color="success"
                            >
                                <CheckCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Reject">
                            <IconButton
                                onClick={() => {
                                    setOpenModal('rejectCA_SPK');
                                    setQuot(quotation);
                                }}
                                color="error"
                            >
                                <RemoveCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Box>
            );
        }
        return null;
    };
    const generateCaSpkBox = (quotation, row, status) => {
        if (quotation.file_contract && quotation.file_spk) {
            return (
                <Box sx={boxStyle}>
                    <Stack direction="column" spacing={1}>
                        <GridNameLetter link={quotation.file_contract} name={row.resource_name} letter="Contract Agreement (CA)" />
                        <GridNameLetter link={quotation.file_spk} name={row.resource_name} letter="Surat Perintah Kerja (SPK)" />
                    </Stack>
                    <Typography variant="h6">{status}</Typography>
                </Box>
            );
        }
        return null;
    };

    return (
        <>
            {(openModal === 'acceptQuotation' ||
                openModal === 'acceptNDA_TA' ||
                openModal === 'acceptCA_SPK' ||
                openModal === 'rejectQuotation' ||
                openModal === 'rejectNDA_TA' ||
                openModal === 'rejectCA_SPK') && (
                <NoteModalQuotation
                    open={
                        openModal === 'acceptQuotation' ||
                        openModal === 'acceptNDA_TA' ||
                        openModal === 'acceptCA_SPK' ||
                        openModal === 'rejectQuotation' ||
                        openModal === 'rejectNDA_TA' ||
                        openModal === 'rejectCA_SPK'
                    }
                    action={openModal}
                    row={row}
                    quotation={quot}
                    loading={loading}
                    setLoading={setLoading}
                    handleClose={() => setOpenModal(false)}
                    handleRefresh={handleRefresh}
                    GridNameLetter={GridNameLetter}
                />
            )}
            <Modal
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <Card sx={modalStyle}>
                        <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Negotiation" />
                        <CardContent>
                            <Grid container xs={12} gap={1}>
                                {row.quotations.map((quotation) => (
                                    <>
                                        {quotation.status === 0 && quotation.file_quotation && generateNegoQuot(quotation, row)}{' '}
                                        {quotation.status === 1 && (
                                            <>
                                                {quotation.file_quotation && generateQuotBox(quotation, row, 'Accepted')}
                                                {quotation.file_ktp && generateKTP(quotation, row)}
                                                {quotation.file_nda && quotation.file_ta && generateNdaTaNego(quotation, row)}
                                            </>
                                        )}
                                        {quotation.status === 2 && (
                                            <>
                                                {quotation.file_quotation && generateQuotBox(quotation, row, 'Rejected')}
                                                {quotation.file_quotation && generateNegoQuot(quotation, row)}
                                            </>
                                        )}
                                        {quotation.status === 3 && (
                                            <>
                                                {quotation.file_ktp && generateQuotBox(quotation, row, 'Accepted')}
                                                {quotation.file_ktp && generateKTP(quotation, row)}
                                                {quotation.file_nda && quotation.file_ta && generateNdaTaBox(quotation, row, 'Accepted')}
                                                {quotation.file_contract && quotation.file_spk && generateCaSpkNego(quotation, row)}
                                            </>
                                        )}
                                        {quotation.status === 4 && (
                                            <>
                                                {quotation.file_quotation && generateQuotBox(quotation, row, 'Accepted')}
                                                {quotation.file_ktp && generateKTP(quotation, row)}
                                                {quotation.file_nda && quotation.file_ta && generateNdaTaBox(quotation, row, 'Rejected')}
                                                {quotation.file_nda && quotation.file_ta && generateNdaTaNego(quotation, row)}
                                            </>
                                        )}
                                        {quotation.status === 5 && (
                                            <>
                                                {quotation.file_quotation && generateQuotBox(quotation, row, 'Accepted')}
                                                {quotation.file_ktp && generateKTP(quotation, row)}
                                                {quotation.file_nda && quotation.file_ta && generateNdaTaBox(quotation, row, 'Accepted')}
                                                {quotation.file_contract &&
                                                    quotation.file_spk &&
                                                    generateCaSpkBox(quotation, row, 'Accepted')}
                                            </>
                                        )}
                                        {quotation.status === 6 && (
                                            <>
                                                {quotation.file_quotation && generateQuotBox(quotation, row, 'Accepted')}
                                                {quotation.file_ktp && generateKTP(quotation, row)}
                                                {quotation.file_nda && quotation.file_ta && generateNdaTaBox(quotation, row, 'Accepted')}
                                                {quotation.file_contract &&
                                                    quotation.file_spk &&
                                                    generateCaSpkBox(quotation, row, 'Rejected')}
                                                {quotation.file_contract && quotation.file_spk && generateCaSpkNego(quotation, row)}
                                            </>
                                        )}
                                    </>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>
                </Fade>
            </Modal>
        </>
    );
};

export default NegotiationLetter;
