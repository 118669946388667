// material-ui
import { Box, Grid, TextField, Button, Stack, Typography, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { createNewCategory, getDetailCategory, updateCategory } from 'utils/services/categories';

const CategoryForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        category_title: '',
        category_detail: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        createNewCategory({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    navigate('/category');
                } else {
                    console.log(res);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const form = {
            category_title: payload.category_title,
            category_detail: payload.category_detail
        };
        updateCategory({ identify, form }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                navigate('/category');
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate('/category');
            }
        });
    };
    const fetchDetail = async (identify) => {
        getDetailCategory({ identify }).then((res) => {
            setPayload(res.data);
        });
    };

    useEffect(() => {
        if (action === 'update' && identify) {
            fetchDetail(identify);
        }
    }, [identify, action]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Create new Category' : 'Update Category'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/category')}
            />
            <Box
                component="form"
                autoComplete="off"
                onSubmit={(ev) => (action && action === 'create' ? handleSubmit(ev) : handleUpdate(ev))}
            >
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sx={12} md={6} spacing={2}>
                        <TextField
                            required
                            name="category_title"
                            margin="normal"
                            value={payload.category_title}
                            onChange={(e) => setPayload({ ...payload, category_title: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Category Name"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="category_detail"
                            margin="normal"
                            value={payload.category_detail}
                            onChange={(e) => setPayload({ ...payload, category_detail: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Category Detail"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button disabled={loading} variant="contained" type="submit">
                                {loading ? 'Loading' : `${action === 'create' ? 'Create' : 'Update Category'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default CategoryForm;
