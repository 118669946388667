import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import ReplyIcon from '@mui/icons-material/Reply';
import BlockIcon from '@mui/icons-material/Block';
import ClearIcon from '@mui/icons-material/Clear';
import ReassignModal from '../Modal/ReassignModal';
import ReassignNoteModal from '../Modal/ReassignNoteModal';
import TableLoader from 'ui-component/loader/TableLoader';
import BlacklistModal from '../Modal/BlacklistModal';
import BlacklistedModal from '../Modal/BlacklistedModal';
import TableEmpty from 'ui-component/loader/TableEmpty';

function TableReassign({ startIndex, open, hasPermission, allPermission, loading, setLoading, setOpen, lists, handleRefresh }) {
    const [data, setData] = useState([]);
    const theme = useTheme('oures');

    return (
        <>
            {open === 'reassignModal' && (
                <ReassignModal
                    open={open === 'reassignModal'}
                    row={data}
                    action={open}
                    step={open === 'accept' ? 0 : 4}
                    handleClose={() => setOpen()}
                    handleRefresh={handleRefresh}
                />
            )}
            {open === 'reassignNoteModal' && (
                <ReassignNoteModal
                    open={open === 'reassignNoteModal'}
                    action={open}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    setOpen={setOpen}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}
            {open === 'blacklistModal' && (
                <BlacklistModal
                    open={open === 'blacklistModal'}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    setOpen={setOpen}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}
            {open === 'blacklistedModal' && (
                <BlacklistedModal
                    open={open === 'blacklistedModal'}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    setOpen={setOpen}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 3 }}>
                                No.
                            </TableCell>
                            <TableCell>Resource Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? (
                        lists && lists.length > 0 ? (
                            lists.map((row, index) => (
                                <TableBody>
                                    <TableRow key={index}>
                                        <TableCell align="center" sx={{ pl: 3 }}>
                                            <Typography variant="h6">{index + startIndex}.</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={row.is_blacklisted ? 'subtitle2' : 'h6'} noWrap>
                                                {row.resource_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2" noWrap>
                                                {row.date}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {row.is_blacklisted && row.blacklist_notes && (
                                                <Chip
                                                    row={row}
                                                    index={index}
                                                    label="View Notes"
                                                    size="small"
                                                    align="left"
                                                    onClick={() => {
                                                        setOpen('blacklistedModal');
                                                        setData(row);
                                                    }}
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.text.secondary
                                                    }}
                                                />
                                            )}
                                            {!row.is_blacklisted && row.cancel_notes && (
                                                <Chip
                                                    row={row}
                                                    index={index}
                                                    label="View Notes"
                                                    size="small"
                                                    align="left"
                                                    onClick={() => {
                                                        setOpen('reassignNoteModal');
                                                        setData(row);
                                                    }}
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            {row.is_blacklisted ? (
                                                <Tooltip placement="top" title="Blacklisted">
                                                    <ClearIcon sx={{ fontSize: '1.1rem' }} />
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    {hasPermission(['FormRequestAssignment@store_assignment'], allPermission) && (
                                                        <Tooltip placement="top" title="Reassign Resource">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setOpen('reassignModal');
                                                                    setData(row);
                                                                }}
                                                                color="primary"
                                                                aria-label="open"
                                                                size="large"
                                                            >
                                                                <ReplyIcon sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip placement="top" title="Blacklist Developer">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('blacklistModal');
                                                                setData(row);
                                                            }}
                                                            color="error"
                                                            aria-label="open"
                                                            size="large"
                                                        >
                                                            <BlockIcon sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))
                        ) : (
                            <TableEmpty colSpan={7} />
                        )
                    ) : (
                        <TableLoader colSpan={7} />
                    )}
                </Table>
            </TableContainer>
        </>
    );
}

export default TableReassign;
