import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, Chip, Grid, Typography } from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';

const PersonCard = ({ avatar, name, activated, email, phoneNumber }) => {
    const theme = useTheme('oures');

    return (
        <Card
            sx={{
                p: 2,
                width: '100%',
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.grey[100],
                '&:hover': {
                    border: `1px solid${theme.palette.primary.main}`
                }
            }}
        >
            <Grid container spacing={gridSpacing} alignItems="center">
                <Grid item xs={4}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs zeroMinWidth>
                            {avatar ? (
                                <Avatar alt={name} src={avatar} sx={{ width: 72, height: 72 }} />
                            ) : (
                                <div
                                    style={{
                                        width: 72,
                                        height: 72,
                                        background: '#f1f1f1',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {name.substr(0, 2)}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} md={4} alignItems="center">
                    <Grid container>
                        <Grid item xs={12}>
                            {activated ? (
                                <Chip
                                    label="Active"
                                    size="small"
                                    sx={{
                                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'success.light',
                                        color: 'success.dark',
                                        fontSize: 10
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Inactive"
                                    size="small"
                                    sx={{
                                        bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'error.light',
                                        color: 'error.dark',
                                        fontSize: 10
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} zeroMinWidth>
                            <Typography variant="h4" marginTop={1}>
                                {name}
                            </Typography>
                            <Typography variant="caption">{phoneNumber}</Typography>
                            <Typography variant="caption">{email}</Typography>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <BlockWrapper fullWidth>
                                        <BlockIcon fontSize="small" />
                                    </BlockWrapper>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

PersonCard.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    activated: PropTypes.string
};

export default PersonCard;
