import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getAssignmentDone = async () => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/assignmentContract/done`, config);
};

export const updateAssignmentDone = async ({ id, payload, notes = '' }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/assignment/status/${id}?process_step=${payload}&notes=${notes}`, {}, config);
};

export const updateExtends = async ({ id, payload }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` },
        'Content-Type': 'multipart/form-data'
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/assignmentContract/extends/${id}`, payload, config);
};
