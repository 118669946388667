import { Box, Chip, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';

const SubCardDetail = ({ loading, client }) => (
    <SubCard sx={{ mt: 3 }}>
        {!loading ? (
            <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table
                            sx={{
                                '& td': {
                                    borderBottom: 'none'
                                }
                            }}
                            size="small"
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell variant="head">Company Name</TableCell>
                                    <TableCell>:</TableCell>
                                    <TableCell>
                                        {client.company_name}{' '}
                                        <Chip
                                            size="small"
                                            style={{ color: 'white' }}
                                            label={client.is_active ? 'Active' : 'Inactive'}
                                            color={client.is_active ? 'success' : 'primary'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Address</TableCell>
                                    <TableCell>:</TableCell>
                                    <TableCell>{client.address}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">No. Telephone</TableCell>
                                    <TableCell>:</TableCell>
                                    <TableCell>{client.company_phone}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Email</TableCell>
                                    <TableCell>:</TableCell>
                                    <TableCell>{client.company_mail}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Created At</TableCell>
                                    <TableCell>:</TableCell>
                                    <TableCell>{client.created_at}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        ) : (
            <Box width="100%">
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Box>
        )}
    </SubCard>
);

export default SubCardDetail;
