import { FormattedMessage } from 'react-intl';

import {
    IconBuildingCommunity,
    IconUser,
    IconBuildingSkyscraper,
    IconLicense,
    IconLayoutDistributeHorizontal,
    Icon3dCubeSphere,
    IconListSearch,
    IconListNumbers,
    IconAccessible,
    IconCategory,
    IconUserSearch,
    IconGenderEpicene
} from '@tabler/icons';

const icons = {
    IconBuildingCommunity,
    IconUser,
    IconBuildingSkyscraper,
    IconLicense,
    IconLayoutDistributeHorizontal,
    Icon3dCubeSphere,
    IconListNumbers,
    IconListSearch,
    IconAccessible,
    IconCategory,
    IconUserSearch,
    IconGenderEpicene
};

const log = {
    id: 'log-tab',
    title: <FormattedMessage id="Log" />,
    permissions: ['LogActivity@get_list_log'],
    type: 'group',
    children: [
        {
            id: 'logActivity',
            title: <FormattedMessage id="Log Activity" />,
            type: 'item',
            url: '/log-activity',
            icon: icons.IconListNumbers,
            permissions: ['LogActivity@get_list_log'],
            breadcrumbs: false
        }
    ]
};

export default log;
