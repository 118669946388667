import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getSkills = async ({ page = 1, limit = 5, search = '' }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/skill?page=${page}&limit=${limit}&filter=${search}`, config);
};
export const updateResource = async ({ identify, form }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/resources/${identify}`, form, config);
};
export const updateAcademicalHistories = async ({ identify, form }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/resourceAcademicHistories/${identify}`, form, config);
};
export const getDetailSkill = async ({ identify }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/resourceSkills/resource?resource_id=${identify}&page=1&limit=10`, config);
};
export const getDetailWorkingExperience = async ({ identify }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/workingExperience/${identify}`, config);
};
export const createNewWorkingExperience = async ({ payload }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/workingExperience`, { ...payload }, config);
};
export const updateWorkingExperience = async ({ identify, form }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/workingExperience/${identify}`, form, config);
};
export const updateSkill = async ({ identify, form }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/resourceSkills/${identify}`, form, config);
};
export const deleteSkill = async ({ id }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/skill/${id}`, config);
};

export const blacklistResource = async ({ id, status, payload }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.put(`${process.env.REACT_APP_API_URL}/resources/${status}/${id}`, payload, config);
};

export const getResourceDetail = async (id) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/resources/${id}`, config);
};
