import {
    Avatar,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Select,
    Typography,
    useTheme
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import InvoiceList from './InvoiceList';
import AddInvoiceModal from './modal/AddInvoiceModal';
import { gridSpacing } from 'store/constant';
import { IconSearch, IconFileInvoice } from '@tabler/icons';
import { useState, useEffect } from 'react';
import { getInvoice } from 'utils/services/invoice';
import moment from 'moment';

const Invoice = () => {
    const theme = useTheme('oures');
    const [invoice, setInvoice] = useState([]);
    const [open, setOpen] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchInvoiceData = async () => {
        setLoading(true);
        await getInvoice({ search, date, status })
            .then((res) => {
                if (res) {
                    setInvoice(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        fetchInvoiceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, date, status]);

    return (
        <>
            {open === 'addInvoice' && (
                <AddInvoiceModal
                    open={open === 'addInvoice'}
                    handleClose={() => setOpen()}
                    handleRefresh={fetchInvoiceData}
                    loading={loading}
                    setLoading={(e) => setLoading(e)}
                />
            )}
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" alignItems="center" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconFileInvoice color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        Invoice
                                    </Typography>
                                    <Typography variant="caption">Lists of invoice</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControl size="small" sx={{ justifyContent: 'center' }}>
                                        <InputLabel id="status-client">Status</InputLabel>
                                        <Select
                                            label="Status"
                                            value={status}
                                            margin="normal"
                                            labelId="status-client"
                                            sx={{ width: '110px' }}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <MenuItem value="">Semua</MenuItem>
                                            <MenuItem value="true">Done</MenuItem>
                                            <MenuItem value="false">Not Done</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <OutlinedInput
                                        focused
                                        type="month"
                                        size="small"
                                        fullWidth
                                        color="grey"
                                        onChange={(e) => setDate(moment(e.target.value).format('MMM.YYYY'))}
                                    />
                                </Grid>
                                <Grid item>
                                    <OutlinedInput
                                        id="input-search-list-style1"
                                        placeholder="Search"
                                        onChange={(e) => setSearch(e.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconSearch stroke={1.5} size="1rem" />
                                            </InputAdornment>
                                        }
                                        size="small"
                                    />
                                    <IconButton onClick={() => setOpen('addInvoice')}>
                                        <AddCircleTwoToneIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <InvoiceList
                    open={open}
                    loading={loading}
                    setLoading={(e) => setLoading(e)}
                    invoice={invoice}
                    setOpen={(e) => setOpen(e)}
                    handleRefresh={fetchInvoiceData}
                    startIndex={(page - 1) * limit + 1}
                    // handleClose={() => setOpen()}
                />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="primary"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                id="menu-user-list-style1"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={() => setLimit(10)}> 10 Rows</MenuItem>
                                <MenuItem onClick={() => setLimit(25)}> 25 Rows</MenuItem>
                                <MenuItem onClick={() => setLimit(50)}> 50 Rows</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default Invoice;
