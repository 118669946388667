import { storageKey } from 'store/constant';
import ApiService from '../../service';

const getReporting = async ({ page, limit, search, filter }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/timesheets/?page=${page}&limit=${limit}&search=${search}&filter=${filter}`,
        config
    );
};
export const addReport = async ({ payload }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/timesheets/report`, payload, config);
};
export const updateStatusReporting = async ({ id, payload }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/timesheets/status/${id}?status=${payload}`, {} , config)
};
export const updateReport = async ({ id, payload }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/timesheets/${id}`, {...payload} , config)
};
export const deleteReport = async ({ id }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.delete(`${process.env.REACT_APP_API_URL}/timesheets/${id}`, config)
};
export const exportReport = async ({ startDate, endDate, nameProject, nameResource }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` },
        responseType: 'blob'
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/timesheets/export-pdf?start_date=${startDate}&end_date=${endDate}&name_project=${nameProject}&name_resource=${nameResource}`, 
    config)
};

export default getReporting