import { Card, CardContent, CardHeader, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { updateTerminateContract } from 'utils/services/assignment-running';
import { updateStatusAssignment } from 'utils/services/assignment-quotation';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import moment from 'moment';
import MarkdownEditor from 'ui-component/forms/MarkdownEditor';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    boxShadow: 24,
    borderRadius: '12px'
};

export default function TerminateModal({ open, handleClose, row, handleRefresh }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);

    const [payload, setPayload] = useState({
        end_date: moment().locale('en').format('dddd, DD MMMM YYYY'),
        termination_notes: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await updateTerminateContract({ id: row.id, payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    updateStatusAssignment({ id: row.form_request_assignment_id, payload: 3 });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                    handleClose();
                }
            })
            .catch((err) => {
                setLoading(false);
                handleClose();
                console.log(err);
            })
            .finally(() => handleRefresh());
    };

    const onCancel = () => {
        setPayload({
            expired_date: row.quotation.expired_date
        });
        handleClose();
    };

    useEffect(() => {
        if (payload.termination_notes === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Fade in={open}>
                <Card sx={modalStyle}>
                    <form onSubmit={(ev) => handleSubmit(ev)}>
                        <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Terminate Contract" />
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack spacing={2}>
                                    <Typography variant="h5">Are you sure you want terminate this contract?</Typography>
                                    <TextField
                                        focused
                                        label="End Date"
                                        value={moment().locale('en').format('dddd, DD MMMM YYYY')}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                    <Typography variant="h5">Termination Notes</Typography>
                                    <MarkdownEditor
                                        value={payload.termination_notes}
                                        handleChange={(e) => setPayload({ ...payload, termination_notes: e })}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="end">
                                    <Button id="button-assignment-cancel" onClick={onCancel} variant="outlined">
                                        Cancel
                                    </Button>
                                    <Button disableElevation disabled={disable} variant="contained" type="submit">
                                        {loading ? 'Loading' : 'Submit'}
                                    </Button>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </form>
                </Card>
            </Fade>
        </Modal>
    );
}
