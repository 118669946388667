import { Circle } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';

const SubCardOverview = ({ client }) => (
    <SubCard sx={{ mt: 3 }} title={<Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Company Overview</Typography>}>
        <Box>
            <Stack direction="column" spacing={1}>
                <Typography sx={{ color: '#121926' }}>
                    <strong>Pengantar:</strong> <br /> Selamat datang di {client.company_name}, tujuan utama Anda untuk solusi pengembangan
                    web mutakhir. Dengan fokus pada inovasi, kreativitas, dan kepuasan klien, kami berkomitmen untuk menyediakan layanan
                    unggulan di bidang Teknologi Informasi.
                </Typography>
                <Typography sx={{ color: '#121926' }}>
                    <strong>Tentang Kami:</strong> <br /> {client.company_name} adalah penyedia terkemuka dalam layanan pengembangan web,
                    khususnya dalam menciptakan solusi web yang dinamis dan interaktif untuk bisnis di berbagai industri. Dengan tim
                    pengembang, desainer, dan profesional TI yang sangat terampil, kami memberikan solusi yang disesuaikan untuk memenuhi
                    kebutuhan unik klien kami.
                </Typography>
                <Typography sx={{ color: '#121926' }}>
                    <strong>Misi Kami:</strong> <br /> Misi kami adalah memberdayakan bisnis dengan solusi digital yang mendorong
                    pertumbuhan, meningkatkan visibilitas merek, dan mengoptimalkan kehadiran online. Kami berusaha memberikan hasil luar
                    biasa dengan menggabungkan keahlian teknis dengan kreativitas dan inovasi.
                </Typography>
                <Stack direction="column">
                    <Typography sx={{ color: '#121926' }}>
                        <strong>Layanan Kami:</strong> <br /> Di {client.company_name}, kami menawarkan berbagai layanan pengembangan web,
                        termasuk:
                    </Typography>
                    <Stack direction="flex" alignItems="flex-start" gap={1}>
                        <Circle sx={{ fontSize: '6px', color: '#121926', mt: 1 }} />
                        <Typography sx={{ color: '#121926', p: 0, m: 0 }}>
                            <strong>Pengembangan Situs Web Kustom:</strong> Kami merancang dan mengembangkan situs web sepenuhnya
                            disesuaikan sesuai dengan kebutuhan spesifik Anda, memastikan kehadiran online yang unik
                        </Typography>
                    </Stack>
                    <Stack direction="flex" alignItems="flex-start" gap={1}>
                        <Circle sx={{ fontSize: '6px', color: '#121926', mt: 1 }} />
                        <Typography sx={{ color: '#121926', p: 0, m: 0 }}>
                            <strong>Solusi E-Commerce:</strong> Solusi e-commerce kami memungkinkan bisnis untuk mendirikan dan mengelola
                            toko online dengan efisien, meningkatkan penjualan dan pendapatan.
                        </Typography>
                    </Stack>
                    <Stack direction="flex" alignItems="flex-start" gap={1}>
                        <Circle sx={{ fontSize: '6px', color: '#121926', mt: 1 }} />
                        <Typography sx={{ color: '#121926', p: 0, m: 0 }}>
                            <strong>Sistem Manajemen Konten (CMS):</strong> Kami mengkhususkan diri dalam membangun platform CMS yang kokoh
                            yang memberdayakan bisnis untuk mengelola dan memperbarui konten situs web dengan mudah.
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    </SubCard>
);

export default SubCardOverview;
