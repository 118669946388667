/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { storageKey } from 'store/constant';

import PropTypes from 'prop-types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */

const AuthGuard = ({ children }) => {
    const [exp, setExp] = useState();
    const [formatExp, setFormatExp] = useState();
    const [currentTime, setCurrentTime] = useState(new Date());

    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem(storageKey.USER_INFO));
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const currentPath = window.location.pathname;

    useEffect(() => {
        if (userInfo && userInfo.exp) {
            setExp(userInfo.exp);
            const expDate = new Date(userInfo.exp * 1000);
            setFormatExp(expDate.toLocaleString());
        }
    }, [userInfo]);

    useEffect(() => {
        const currentTimestamp = Math.floor(currentTime / 1000);

        if (!isLoggedIn && currentPath !== '/login') {
            navigate('/', { replace: true });
        } else if (isLoggedIn && formatExp && currentTimestamp >= exp) {
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userToken');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userRoleName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('rms@data');
            navigate('/', { replace: true });
        }
    }, [currentTime, exp, formatExp, navigate, isLoggedIn, currentPath]);

    useEffect(() => {
        if (!isLoggedIn && currentPath === '/login') {
            navigate('/login', { replace: true });
        }
    }, [navigate, isLoggedIn, currentPath]);

    useEffect(() => {
        if (isLoggedIn && currentPath === '/') {
            navigate('/dashboard', { replace: true });
        }
    }, [navigate, isLoggedIn, currentPath]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
