import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import { TabStyle } from 'style/tab';
import { Grid, Tab, Tabs } from '@mui/material';

import Information from './Information';
import PersonInCharge from './PersonInCharge';
import MainCard from 'ui-component/cards/MainCard';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import TabPanelFunc from 'component/function/TabPanelFunc';

// tabs option
const tabsOption = [
    {
        label: 'Detail Information',
        icon: <LibraryBooksTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Person In Charge',
        icon: <AccountCircleTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    }
];

const ClientDetail = () => {
    const { value, handleChange, TabPanel, a11yProps } = TabPanelFunc();

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        id="tabs-client-detail"
                        textColor="primary"
                        onChange={handleChange}
                        sx={TabStyle()}
                    >
                        {tabsOption.map((tab, index) => (
                            <Tab
                                id="tab-client-detail"
                                key={index}
                                component={Link}
                                to="#"
                                icon={tab.icon}
                                label={tab.label}
                                {...a11yProps(index)}
                            />
                        ))}
                    </Tabs>
                    <TabPanel id="panel-client-detail0" value={value} index={0}>
                        <Information />
                    </TabPanel>
                    <TabPanel id="panel-client-detail1" value={value} index={1}>
                        <PersonInCharge />
                    </TabPanel>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ClientDetail;
