import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getInvoice = async ({ search, date, status }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/invoice/?search=${search}&date=${date}&is_done=${status}`, config);
};

export const getInvoiceCode = async (code) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/invoice/get/invoice_code?initial_company=${code}`, config);
};

export const getInvoiceDetail = async (id) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/invoice/get/${id}`, config);
};

export const getViewInvoice = async (id) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` },
        responseType: 'blob'
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/invoice/view/${id}`, config);
};

export const createNewInvoice = async (payload) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/invoice/addinvoice`, payload, config);
};

export const updateInvoice = async ({ id, payload }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/invoice/edit/${id}`, payload, config);
};
export const updateStatusInvoice = async ({ id, payload }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/invoice/status/${id}?is_done=${payload}`, {}, config);
};

export const deleteInvoice = async (id) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/invoice/delete/${id}`, config);
};
