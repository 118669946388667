import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Typography
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

// project imports
import UserList from './UserList';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import FilterDrawer from './filter.drawer';

import axios from 'axios';

// assets
import { IconListSearch, IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import RootPermission from 'views/pages/permission';
import useRootPermission from 'hooks/useRootPermission';
import { AddCircleTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// ==============================|| USER LIST STYLE 1 ||============================== //

const ResourcePage = () => {
    const theme = useTheme('oures');
    const [granted, allPermission] = useRootPermission(['Resources@resources_get']);
    const [resources, setResources] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [skill, setSkill] = useState('');
    const [experience, setExperience] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getData = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/resources?page=${page}&search=${filter}&limit=${limit}${skill ? `&skill=${skill}` : ''}${
                    experience ? `&experience=${experience}` : ''
                }`,
                config
            )
            .then((res) => {
                if (res) {
                    setLoading(false);
                    setResources(res.data.data);
                    setTotal(res.data.meta.total);
                }
            });
    };

    React.useEffect(() => {
        getData();
    }, [page, limit, filter, skill, experience]); //eslint-disable-line

    React.useEffect(() => {
        if (filter !== '') {
            setPage(1);
        }
    }, [filter]);

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconListSearch color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        Resources List
                                    </Typography>
                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                        List of resources in our company
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" gap={2}>
                                <IconButton
                                    color="primary"
                                    sx={{
                                        backgroundColor: '#FFFFFF'
                                    }}
                                    onClick={() => setDrawerOpen(!drawerOpen)}
                                    id="button-resource-filter"
                                >
                                    <FilterListIcon />
                                </IconButton>
                                <OutlinedInput
                                    id="button-resource-search"
                                    placeholder="Search"
                                    onChange={(e) => setFilter(e.target.value)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconSearch stroke={1.5} size="1rem" />
                                        </InputAdornment>
                                    }
                                    size="small"
                                />
                                <Link to="/resources/form">
                                    <IconButton onClick={() => setDrawerOpen(!drawerOpen)} id="button-resource-filter">
                                        <AddCircleTwoTone />
                                    </IconButton>
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <FilterDrawer open={drawerOpen} setterOpen={setDrawerOpen} setterSkill={setSkill} setterExperience={setExperience} />
                <UserList resources={resources} handleRefresh={getData} loading={loading} page={page} limit={limit} />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="primary"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                                id="button-resource-rows"
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                id="dropdown-resource-rows"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem id="menu-resource-limit5" onClick={() => setLimit(10)}>
                                    {' '}
                                    10 Rows
                                </MenuItem>
                                <MenuItem id="menu-resource-limit10" onClick={() => setLimit(20)}>
                                    {' '}
                                    20 Rows
                                </MenuItem>
                                <MenuItem id="menu-resource-limit20" onClick={() => setLimit(30)}>
                                    {' '}
                                    30 Rows
                                </MenuItem>
                                <MenuItem id="menu-resource-limit30" onClick={() => setLimit(50)}>
                                    {' '}
                                    50 Rows
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default ResourcePage;
