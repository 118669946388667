import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useRootPermission(permissions = []) {
    const [granted, setGranted] = useState(false);
    const [allPermission, setAllPermission] = useState([]);

    const temp = useSelector((state) => state.account);

    useEffect(() => {
        if (temp.user && temp.user.role && temp.user.role.role_permission) {
            setAllPermission(temp.user.role.role_permission);
        }
    }, [temp]);

    useEffect(() => {
        if (allPermission) {
            if (permissions.find((x) => allPermission.some((p) => p.permission_name === x))) {
                setGranted(true);
            } else {
                setGranted(false);
            }
        }
    }, [permissions, allPermission]);

    return [granted, allPermission];
}

export default useRootPermission;
