import {
    CardGiftcard,
    CreditCard,
    DirectionsBusFilledOutlined,
    MedicalServicesOutlined,
    MeetingRoomOutlined,
    PlaceOutlined
} from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';

const icons = [CreditCard, CardGiftcard, MeetingRoomOutlined, DirectionsBusFilledOutlined, MedicalServicesOutlined, PlaceOutlined];
const benefits = ['Competitive Salary', 'THR / Bonus System', 'Period Leave', 'Transportation', 'Medical Insurance', 'Vacancy Time'];

const SubCardBenefits = () => (
    <SubCard sx={{ mt: 3 }} title={<Typography sx={{ fontWeight: 600, fontSize: '14px' }}>Company Benefits</Typography>}>
        <Stack direction="column" spacing={1}>
            {icons.map((Icon, index) => (
                <Stack key={index} direction="row" spacing={1} sx={{ p: 1, alignItems: 'center', borderRadius: '8px', bgcolor: '#EDE7F6' }}>
                    <Icon style={{ color: '#5E35B1' }} />
                    <Typography sx={{ color: '#5E35B1' }}>{benefits[index]}</Typography>
                </Stack>
            ))}
        </Stack>
    </SubCard>
);

export default SubCardBenefits;
