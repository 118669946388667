// material-ui
import { Grid, Box } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

import UnderConstructionImage from 'assets/images/others/under-construction.png';

// ==============================|| PROFILE 1 - MY ACCOUNT ||============================== //

const MyAccount = () => (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
            <Box textAlign="center">
                <img src={UnderConstructionImage} style={{ width: '300px', margin: '50px 0' }} alt="under-construction" />
            </Box>
        </Grid>
    </Grid>
);

export default MyAccount;
