/* eslint-disable no-unused-vars */
import { Box, Grid, TextField, Button, Stack, FormHelperText, Modal, Typography, Backdrop, Autocomplete, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { getOneRegistration } from 'utils/services/registration-requests';
import { postRegistrationRequest } from 'utils/services/requests';
import { getListRegion } from 'utils/services/master';

import MainCard from 'ui-component/cards/MainCard';
import SwitchComponent from 'ui-component/forms/Switch';
import ConfirmationModal, { modalStyle } from 'ui-component/confirmation';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

const RegisterClientForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const identify = queryParams.get('identify');

    const [disable, setDisable] = useState(true);
    const [value, copy] = useCopyToClipboard();
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [successModal, setSuccessModal] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [payload, setPayload] = useState({
        company_name: '',
        address: '',
        province: '',
        city: '',
        district: '',
        sub_district: '',
        postal_code: '',
        company_phone: '',
        company_mail: '',
        pic_name: '',
        pic_phone: '',
        pic_email: '',
        pic_address: '',
        pic_domisili: '',
        is_active: true
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await postRegistrationRequest({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res?.data?.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    setLoading(false);
                    navigate('/clients');
                    setSuccessModal(true);
                }
            })
            .catch((err) => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: err.response?.data?.message,
                    variant: 'alert',
                    alertSeverity: 'warning'
                });
                setLoading(false);
            });
    };

    const fetchRegions = async (type = 'Province', id = '') => {
        let url = '';
        switch (type) {
            case 'City':
                url = `&province_id=${id}`;
                break;
            case 'District':
                url = `&city_id=${id}`;
                break;
            case 'Sub District':
                url = `&district_id=${id}`;
                break;
            default:
                break;
        }
        await getListRegion({ type, url }).then((res) => {
            if (res && res.success) {
                const lists = [];
                res.data.forEach((x) => {
                    if (type === 'City') {
                        lists.push({ value: x.id, label: x.city });
                    } else if (type === 'District') {
                        lists.push({ value: x.id, label: x.district });
                    } else if (type === 'Sub District') {
                        lists.push({ value: x.id, label: x.sub_district });
                    } else {
                        lists.push({ value: x.id, label: x.province });
                    }
                });
                if (type === 'Province') {
                    setProvinces(lists.sort((a, b) => a.label.localeCompare(b.label)));
                } else if (type === 'City') {
                    setCities(lists.sort((a, b) => a.label.localeCompare(b.label)));
                } else if (type === 'District') {
                    setDistricts(lists.sort((a, b) => a.label.localeCompare(b.label)));
                } else if (type === 'Sub District') {
                    setSubDistricts(lists.sort((a, b) => a.label.localeCompare(b.label)));
                }
            }
        });
    };

    const fetchDetail = async () => {
        await getOneRegistration(identify).then((res) => {
            if (res && res.success) {
                setPayload({
                    ...payload,
                    company_name: res.data.company_name,
                    address: res.data.company_address,
                    province: '',
                    city: '',
                    district: '',
                    sub_district: '',
                    postal_code: '',
                    company_phone: res.data.company_phone,
                    company_mail: res.data.company_email,
                    pic_name: res.data.name,
                    pic_phone: res.data.phone,
                    pic_email: res.data.email,
                    pic_address: '',
                    pic_domisili: '',
                    is_active: true
                });
            }
        });
    };

    useEffect(() => {
        fetchDetail();
        fetchRegions('Province');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            payload.company_name === '' ||
            payload.address === '' ||
            payload.province === '' ||
            payload.city === '' ||
            payload.district === '' ||
            payload.sub_district === '' ||
            payload.postal_code === '' ||
            payload.company_phone === '' ||
            payload.company_mail === '' ||
            payload.pic_name === '' ||
            payload.pic_phone === '' ||
            payload.pic_email === '' ||
            payload.pic_address === '' ||
            payload.pic_domisili === '' ||
            payload.is_active === ''
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                Create New Client from Client Registration Request
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/registration-request')}
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={successModal}
                onClose={() => null}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Box sx={{ ...modalStyle, width: '400px' }}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="h3">Register Success</Typography>
                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="caption">Client Login Information</Typography>
                            <Box
                                sx={{
                                    background: '#f1f1f1',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                <Typography variant="caption">Email</Typography>
                                <Typography variant="subtitle1">{credentials.email}</Typography>
                                <Typography variant="caption">Password</Typography>
                                <Typography variant="subtitle1">{credentials.password}</Typography>
                            </Box>
                        </Stack>
                        <Button
                            disableElevation
                            variant="contained"
                            onClick={() => {
                                copy(`Email: ${credentials.email}  Password: ${credentials.password}`);
                                navigate('/registration-request');
                            }}
                        >
                            Copy & Return
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            <Box component="form" autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} spacing={2}>
                        <TextField
                            required
                            id="input-registration-picName"
                            name="pic_name"
                            margin="normal"
                            value={payload.pic_name}
                            onChange={(e) => setPayload({ ...payload, pic_name: e.target.value })}
                            fullWidth
                            label="Name"
                        />
                        <TextField
                            required
                            id="input-registration-picEmail"
                            margin="normal"
                            value={payload.pic_email}
                            onChange={(e) => setPayload({ ...payload, pic_email: e.target.value })}
                            fullWidth
                            label="Email"
                            type="email"
                        />
                        <TextField
                            required
                            id="input-registration-picPhone"
                            margin="normal"
                            value={payload.pic_phone}
                            onChange={(e) => {
                                const inputValue = Math.abs(parseInt(e.target.value, 10));
                                setPayload({ ...payload, pic_phone: inputValue });
                            }}
                            fullWidth
                            label="Your Phone"
                            type="number"
                        />
                        <TextField
                            required
                            id="input-registration-picDomicile"
                            margin="normal"
                            value={payload.pic_domisili}
                            onChange={(e) => setPayload({ ...payload, pic_domisili: e.target.value })}
                            fullWidth
                            label="Domisili"
                        />
                        <Autocomplete
                            disablePortal
                            id="province"
                            value={provinces.find((option) => option.value === payload.province)}
                            options={provinces}
                            getOptionLabel={(option) => option.label}
                            fullWidth
                            onChange={(e, v) => {
                                setPayload({ ...payload, province: v.value });
                                fetchRegions('City', v.value);
                            }}
                            renderInput={(params) => <TextField {...params} required label="Province" margin="normal" />}
                        />
                        <Autocomplete
                            disablePortal
                            id="city"
                            value={provinces.find((option) => option.value === payload.city)}
                            options={cities}
                            fullWidth
                            onChange={(e, v) => {
                                setPayload({ ...payload, city: v.value });
                                fetchRegions('District', v.value);
                            }}
                            renderInput={(params) => <TextField {...params} required label="City" margin="normal" />}
                            disabled={payload.province === ''}
                        />
                        <Autocomplete
                            disablePortal
                            id="disctrict"
                            value={provinces.find((option) => option.value === payload.district)}
                            options={districts}
                            fullWidth
                            onChange={(e, v) => {
                                setPayload({ ...payload, district: v.value });
                                fetchRegions('Sub District', v.value);
                            }}
                            renderInput={(params) => <TextField {...params} required label="District" margin="normal" />}
                            disabled={payload.city === ''}
                        />
                        <Autocomplete
                            disablePortal
                            id="sub-district"
                            value={provinces.find((option) => option.value === payload.sub_district)}
                            options={subDistricts}
                            fullWidth
                            onChange={(e, v) => {
                                setPayload({ ...payload, sub_district: v.value });
                            }}
                            renderInput={(params) => <TextField {...params} label="Sub District" margin="normal" />}
                            disabled={payload.district === ''}
                        />
                        <TextField
                            required
                            type="text"
                            id="input-registration-picAddress"
                            margin="normal"
                            value={payload.pic_address}
                            onChange={(e) => setPayload({ ...payload, pic_address: e.target.value })}
                            fullWidth
                            label="Address"
                        />
                        <TextField
                            required
                            margin="normal"
                            id="input-registration-postCode"
                            value={payload.postal_code}
                            onChange={(e) => {
                                const inputValue = Math.abs(parseInt(e.target.value, 10));
                                setPayload({ ...payload, postal_code: inputValue });
                            }}
                            fullWidth
                            label="Postal Code"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2}>
                        <TextField
                            required
                            id="input-registration-companyName"
                            margin="normal"
                            value={payload.company_name}
                            onChange={(e) => setPayload({ ...payload, company_name: e.target.value })}
                            fullWidth
                            label="Company Name"
                        />
                        <TextField
                            required
                            id="input-registration-companyPhone"
                            margin="normal"
                            value={payload.company_phone}
                            onChange={(e) => setPayload({ ...payload, company_phone: e.target.value })}
                            fullWidth
                            label="Company Phone"
                            type="number"
                        />
                        <TextField
                            required
                            id="input-registration-companyMail"
                            margin="normal"
                            value={payload.company_mail}
                            onChange={(e) => setPayload({ ...payload, company_mail: e.target.value })}
                            fullWidth
                            label="Company Mail"
                            type="email"
                        />
                        <TextField
                            required
                            margin="normal"
                            id="input-registration-companyAddress"
                            value={payload.address}
                            onChange={(e) => setPayload({ ...payload, address: e.target.value })}
                            fullWidth
                            label="Company Address"
                        />
                        <FormHelperText sx={{ alignItems: 'center' }}>
                            Status
                            <SwitchComponent
                                label="Helper"
                                id="switch-registration-statusUpdate"
                                checked={payload.is_active}
                                onChange={() => setPayload({ ...payload, is_active: !payload.is_active })}
                            />
                        </FormHelperText>
                    </Grid>
                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button id="button-registration-cancel" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button
                                id="button-registration-submit"
                                variant="contained"
                                type={loading ? 'button' : 'submit'}
                                disabled={loading || disable}
                                disableElevation
                                onClick={(e) => handleSubmit(e)}
                            >
                                {loading ? 'Loading' : 'Register Now'}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default RegisterClientForm;
