/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch, IconCalendarEvent } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { getInterviewSchedule } from 'utils/services/interview-schedule';
import { AddCircleTwoTone } from '@mui/icons-material';
import { hasPermission } from 'helper/general';
import MainCard from 'ui-component/cards/MainCard';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import TableInterviewSchedule from './TableInterviewSchedule';
import useRootPermission from 'hooks/useRootPermission';
import RootPermission from '../permission';

const InterviewSchedulePage = () => {
    const theme = useTheme('oures');

    const [interviewSchedule, setInterviewSchedule] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [granted, allPermission] = useRootPermission([
        'InterviewSchedule@interview_schedule_get_by_interviewer' &&
            'InterviewSchedule@interview_schedule_get_by_resource' &&
            'InterviewSchedule@interview_schedule_get_by_resource'
    ]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchSkill = async () => {
        setLoading(true);
        try {
            const response = await getInterviewSchedule({ page, limit, filter });
            setInterviewSchedule(response.data);
            setTotal(response.meta.total);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchSkill();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, filter]);

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconCalendarEvent color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        Interview Schedule
                                    </Typography>
                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                        Please let us now your Interview Schedule
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                id="input-search-list-style1"
                                placeholder="Search"
                                onChange={(e) => setFilter(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                            {hasPermission(['InterviewSchedule@add_interview_schedule'], allPermission) && (
                                <Link to="/interview-schedule/form?action=create">
                                    <IconButton>
                                        <AddCircleTwoTone />
                                    </IconButton>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <TableInterviewSchedule
                    interviewSchedule={interviewSchedule}
                    refresh={() => fetchSkill()}
                    loading={loading}
                    startIndex={(page - 1) * limit + 1}
                />
                <Grid container xs={12} sx={{ p: 3 }} justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination
                            onChange={(e, value) => setPage(value)}
                            count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                            color="primary"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            {limit} Rows
                        </Button>
                        <Menu
                            id="menu-user-list-style1"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={() => setLimit(10)}> 10 Rows</MenuItem>
                            <MenuItem onClick={() => setLimit(25)}> 25 Rows</MenuItem>
                            <MenuItem onClick={() => setLimit(50)}> 50 Rows</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default InterviewSchedulePage;
