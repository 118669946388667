// material-ui
import { Box, Grid, TextField, Button, Stack, Typography, Avatar, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { createNewUser, getDetailUser, updateUser } from 'utils/services/users';
import { getRoleList } from 'utils/services/role';

const UserForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');
    const [loading, setLoading] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [roles, setRoles] = useState([]);

    const [payload, setPayload] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        password: '',
        gender: '',
        address: '',
        domisili_address: '',
        role: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        createNewUser({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    navigate('/user');
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const form = {
            name: payload.name,
            phoneNumber: payload.phoneNumber,
            email: payload.email,
            gender: payload.gender,
            address: payload.address,
            domisili_address: payload.domisili_address,
            role: payload.role
        };
        updateUser({ identify, form }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                navigate('/user');
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate('/user');
            }
        });
    };

    const fetchDetail = async (identify) => {
        getDetailUser({ identify }).then((res) => {
            setPayload(res.data);
        });
    };

    useEffect(() => {
        if (action === 'update' && identify) {
            fetchDetail(identify);
        }
        getRoleList().then((res) => {
            setRoles(res.data);
        });
    }, [identify, action]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Create new User' : 'Update user'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/clients')}
            />
            <Box
                component="form"
                autoComplete="off"
                onSubmit={(ev) => (action && action === 'create' ? handleSubmit(ev) : handleUpdate(ev))}
            >
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item md={7} xs={12}>
                        <TextField
                            required
                            name="name"
                            type="text"
                            value={payload.name}
                            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Name"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <TextField
                            required
                            name="phoneNumber"
                            type="text"
                            value={payload.phoneNumber}
                            onChange={(e) => setPayload({ ...payload, phoneNumber: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Phone Number"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <TextField
                            required
                            name="email"
                            type="email"
                            value={payload.email}
                            onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Email"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    {action !== 'update' && (
                        <Grid item md={7} xs={12}>
                            <TextField
                                required
                                name="password"
                                type="password"
                                value={payload.password}
                                onChange={(e) => setPayload({ ...payload, password: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Password"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                        </Grid>
                    )}
                    <Grid item md={7} xs={12}>
                        {/* <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Age">
                            <MenuItem value={payload.gender}>Male</MenuItem>
                            <MenuItem value={payload.gender}>Female</MenuItem>
                        </Select> */}
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={payload.gender}
                                label="Gender"
                                onChange={(e) => setPayload({ ...payload, gender: e.target.value })}
                            >
                                <MenuItem value="Men">Men</MenuItem>
                                <MenuItem value="Woman">Woman</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <TextField
                            required
                            name="address"
                            type="text"
                            value={payload.address}
                            onChange={(e) => setPayload({ ...payload, address: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Address"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <TextField
                            required
                            name="domisili_address"
                            type="text"
                            value={payload.domisili_address}
                            onChange={(e) => setPayload({ ...payload, domisili_address: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Domicili Address"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <FormControl size="large" sx={{ margin: 0, width: '100%' }}>
                            <InputLabel id="role">Role</InputLabel>
                            <Select
                                label="Role"
                                onChange={(e) => setPayload({ ...payload, role: e.target.value })}
                                margin="normal"
                                sx={{ width: '100%' }}
                                id="role"
                                value={payload.role}
                            >
                                {roles &&
                                    roles.map((role, i) => (
                                        <MenuItem key={`role=${i}`} value={role.name.toLowerCase()}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={age}
                                label="Age"
                                // onChange={handleChange}
                            >
                                <MenuItem value={10}>User</MenuItem>
                                <MenuItem value={20}>Admin</MenuItem>
                                <MenuItem value={30}>Super - Admin</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            {/* <Button variant="outlined" onClick={setOpenCancelModal(true)}> */}
                            <Button variant="outlined" onClick={() => window.history.back()}>
                                Cancel
                            </Button>
                            <Button variant="contained" type={loading ? 'button' : 'submit'} disabled={loading}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Create' : 'Update User'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default UserForm;
