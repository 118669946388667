import { Link } from 'react-router-dom';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';

import Profile from './Profile';
import Security from './Security';
import MainCard from 'ui-component/cards/MainCard';
import TabPanelFunc from 'component/function/TabPanelFunc';

const ProfilePage = () => {
    const { value, handleChange, TabPanel, a11yProps } = TabPanelFunc();

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3" sx={{ mb: 0 }}>
                            Your Profile
                        </Typography>
                    </Grid>
                </Grid>
            }
        >
            <Tabs
                value={value}
                centered
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                sx={{
                    mb: 3,
                    minHeight: 'auto',
                    '& button': {
                        minWidth: 100
                    },
                    '& a': {
                        minHeight: 'auto',
                        minWidth: 10,
                        py: 1.5,
                        px: 1,
                        mr: 2.25,
                        color: 'grey.600'
                    },
                    '& a.Mui-selected': {
                        color: 'primary.main'
                    }
                }}
                aria-label="simple tabs example"
                variant="scrollable"
            >
                <Tab component={Link} to="#" label="Profile" {...a11yProps(0)} />
                {/* <Tab component={Link} to="#" label="Billing" {...a11yProps(1)} /> */}
                <Tab component={Link} to="#" label="Security" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Profile />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                    <Billing />
                </TabPanel> */}
            <TabPanel value={value} index={1}>
                <Security />
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
                    <Notifications />
                </TabPanel> */}
        </MainCard>
    );
};

export default ProfilePage;
