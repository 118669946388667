import { Button } from '@mui/material';

import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const Buttons = ({ title, onClick, color, disable, type, startIcon, sx, outlined, size }) => (
    <Button
        variant={outlined ? 'outlined' : 'contained'}
        type={type ?? 'submit'}
        color={color ?? 'secondary'}
        size={size ?? 'small'}
        sx={{ borderRadius: '12px', boxShadow: 'none', '&:hover': { boxShadow: 'none' }, ...sx }}
        onClick={onClick}
        disabled={disable}
        startIcon={startIcon}
    >
        {title}
    </Button>
);

Buttons.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export default Buttons;
