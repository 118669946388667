import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';

// eslint-disable-next-line import/prefer-default-export
export const IconWrapper = styled('div')(({ sx }) => ({
    width: '32px',
    height: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '4px',
    overflow: 'hidden',
    justifyContent: 'center',
    '& span:first-child': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        opacity: '0.2',
        zIndex: '1'
    },
    '& span': {
        width: '20px',
        height: '20px'
    },
    '& svg': {
        width: '20px',
        height: '20px',
        position: 'relative',
        zIndex: '5'
    },
    ...sx
}));

export const BorderDashedStyle = () => {
    const theme = useTheme();

    return {
        borderRadius: 5,
        border: 'dashed',
        borderColor: 'darkgrey',
        ':hover': {
            borderColor: theme.palette.primary.main
        },
        padding: 2,
        borderWidth: 1
    };
};
