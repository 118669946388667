import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Modal,
    Stack,
    TextField,
    FormControlLabel,
    Switch,
    IconButton,
    Tooltip
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { addReport, updateReport } from 'utils/services/reporting';
import { Close } from '@mui/icons-material';
import { IconCirclePlus } from '@tabler/icons';
import moment from 'moment';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 735,
    boxShadow: 24,
    borderRadius: '12px'
};

const AddEditReportModal = ({ open, action, row, loading, setLoading, handleClose, handleRefresh }) => {
    const dispatch = useDispatch();

    const [disable, setDisable] = useState(false);
    const [payload, setPayload] = useState(
        action === 'addReport'
            ? {
                  date: '',
                  start_time: '',
                  end_time: '',
                  project: '',
                  title: '',
                  task_detail: [''],
                  status: 0
              }
            : action === 'editReport'
            ? {
                  date: moment(row.date).format('YYYY-MM-DD'),
                  start_time: moment.utc(row.start_time).format('HH:mm'),
                  end_time: moment.utc(row.end_time).format('HH:mm'),
                  project: row.project,
                  title: row.title,
                  task_detail: row.task_detail,
                  status: row.status
              }
            : {}
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (action === 'addReport') {
            await addReport({ payload })
                .then((res) => {
                    if (res && res.success) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'success',
                            variant: 'alert'
                        });
                        setLoading(false);
                        handleClose();
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'warning',
                            variant: 'alert'
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                })
                .finally(() => handleRefresh());
        } else if (action === 'editReport') {
            await updateReport({ id: row.id, payload })
                .then((res) => {
                    if (res && res.success) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'success',
                            variant: 'alert'
                        });
                        setLoading(false);
                        handleClose();
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'warning',
                            variant: 'alert'
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                })
                .finally(() => handleRefresh());
        }
    };

    const onCancel = () => {
        setPayload({
            date: '',
            start_time: '',
            end_time: '',
            project: '',
            title: '',
            task_detail: [''],
            status: 0
        });
        handleClose();
    };

    useEffect(() => {
        if (
            payload.date === '' ||
            payload.start_time === '' ||
            payload.end_time === '' ||
            payload.project === '' ||
            payload.title === '' ||
            (payload.task_detail.length === 1 && payload.task_detail[0] === '')
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Card sx={modalStyle}>
                <CardHeader
                    sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }}
                    title={action === 'addReport' ? 'Add Report' : 'Edit Report'}
                />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Stack direction="column" spacing={1.5} width="40%">
                                    <TextField
                                        required
                                        type="date"
                                        label="Date"
                                        color="grey"
                                        fullWidth
                                        value={moment(payload.date).format('YYYY-MM-DD')}
                                        onChange={(e) => setPayload({ ...payload, date: moment(e.target.value).format('YYYY-MM-DD') })}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            max: moment().format('YYYY-MM-DD')
                                        }}
                                    />
                                    <TextField
                                        required
                                        type="time"
                                        label="Start Time"
                                        color="grey"
                                        fullWidth
                                        value={payload.start_time}
                                        onChange={(e) => setPayload({ ...payload, start_time: e.target.value })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        required
                                        type="time"
                                        label="End Time"
                                        color="grey"
                                        fullWidth
                                        value={payload.end_time}
                                        onChange={(e) => setPayload({ ...payload, end_time: e.target.value })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Stack>
                                <Stack direction="column" justifyContent="end" spacing={1.5} width="100%">
                                    <TextField
                                        required
                                        type="text"
                                        label="Projek"
                                        fullWidth
                                        value={payload.project}
                                        onChange={(e) => setPayload({ ...payload, project: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        type="text"
                                        label="Title"
                                        fullWidth
                                        value={payload.title}
                                        onChange={(e) => setPayload({ ...payload, title: e.target.value })}
                                    />
                                </Stack>
                            </Stack>
                            <Stack sx={{ maxHeight: '150px', overflowY: 'auto' }} spacing={1.5}>
                                {payload.task_detail.map((item, index) => (
                                    <Stack direction="column" spacing={1} key={index}>
                                        <TextField
                                            required
                                            type="text"
                                            multiline
                                            minRows={1}
                                            maxRows={2}
                                            label={`Task Detail ${index + 1}`}
                                            value={item}
                                            onChange={(e) => {
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    task_detail: prev.task_detail.map((item, i) => (index === i ? e.target.value : item))
                                                }));
                                            }}
                                            sx={{ mt: 1 }}
                                            autoFocus={payload.task_detail.length > 1 && index === payload.task_detail.length - 1}
                                            InputProps={{
                                                endAdornment: payload.task_detail.length > 1 && (
                                                    <Tooltip placement="top" title="Delete Task Detail">
                                                        <IconButton
                                                            onClick={() => {
                                                                const indexToDelete = index;
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    task_detail: prev.task_detail.filter((_, i) => i !== indexToDelete)
                                                                }));
                                                            }}
                                                            color="inherit"
                                                            size="large"
                                                        >
                                                            <Close sx={{ fontSize: 'large' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }}
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                            {action === 'addReport' && (
                                <Stack>
                                    <Button
                                        color="primary"
                                        startIcon={<IconCirclePlus />}
                                        onClick={() => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                task_detail: [...prev.task_detail, '']
                                            }));
                                        }}
                                        disabled={payload.task_detail.length === 1 && payload.task_detail[0] === ''}
                                    >
                                        Add More Task
                                    </Button>
                                </Stack>
                            )}
                            <FormControlLabel
                                value="end"
                                control={
                                    <Switch
                                        color="success"
                                        checked={payload.status === 1}
                                        onChange={() => {
                                            const newStatus = payload.status === 1 ? 0 : 1;
                                            setPayload({ ...payload, status: newStatus });
                                        }}
                                    />
                                }
                                label={payload.status === 1 ? 'Done' : 'Not Done'}
                                labelPlacement="end"
                                sx={{ position: 'static', width: '120px' }}
                            />
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <Button variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                >
                                    {loading ? 'Loading' : action === 'addReport' ? 'Add Report' : 'Edit Report'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default AddEditReportModal;
