import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { hasPermission } from 'helper/general';
import { useState, useEffect } from 'react';
import { gridSpacing, storageKey } from 'store/constant';
import { useParams } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { getRequestById } from 'utils/services/requests';
import {
    deleteCandidate,
    getCandidatebyReqId,
    getSuggestCandidateId,
    postAssignCandidate,
    updateStatusCandidate
} from 'utils/services/candidates';
import CandidateCardItem from './CandidateItemCard';
import ConfirmationModal from 'ui-component/confirmation';
import CandidateDetailModal from '../Modal/CandidateDetailModal';
import AssignModal from '../Modal/AssignModal';

const RequestCandidate = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [removeId, setRemoveId] = useState('');
    const [candidateId, setCandidateId] = useState();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({});
    const [candidates, setCandidates] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [payload, setPayload] = useState({
        id: '',
        status: 0,
        name: ''
    });
    const permission = [
        {
            permission_name: localStorage.getItem(storageKey.USER_ROLE)
        }
    ];

    const fetchDetail = async () => {
        setLoading(true);
        await getRequestById(id)
            .then((res) => {
                if (res && res.success) {
                    setDetail(res.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchCandidates = async () => {
        setLoading(true);
        await getSuggestCandidateId(id)
            .then((res) => {
                if (res && res.success) {
                    setCandidates(res.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchAssigned = async () => {
        setLoading(true);
        await getCandidatebyReqId({ requestId: id })
            .then((res) => {
                if (res && res.success) {
                    setAssigned(res.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSuggest = async (value) => {
        setLoading(true);
        const form = {
            form_request_id: id,
            resource_id: value
        };
        await postAssignCandidate(form)
            .then((res) => {
                if (res && res.success) {
                    setOpen(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => fetchAssigned());
    };

    const handleConfirm = async () => {
        setLoading(true);
        const form = {
            form_request_id: id,
            resource_id: payload.resource_id,
            status: payload.status
        };

        await updateStatusCandidate({ form })
            .then((res) => {
                if (res && res.success) {
                    setOpen(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => fetchAssigned());
    };

    const handleRemove = async () => {
        setLoading(true);
        await deleteCandidate({ removeId })
            .then((res) => {
                if (res && res.success) {
                    setOpen(false);
                    fetchAssigned();
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        fetchAssigned();
        fetchCandidates();
        fetchDetail();
    }, [id]); //eslint-disable-line

    return (
        <>
            {open === 'confirmStatus' && (
                <ConfirmationModal
                    open={open === 'confirmStatus'}
                    title={payload.name}
                    description={`${payload.status === 1 ? 'Accept' : 'Decline'} this candidate?`}
                    handleContinue={() => handleConfirm()}
                    handleCancel={() => setOpen(false)}
                    loading={loading}
                />
            )}
            {open === 'confirmDelete' && (
                <ConfirmationModal
                    open={open === 'confirmDelete'}
                    title="Confirmation"
                    description="Are you sure want to delete this candidate?"
                    handleContinue={() => handleRemove()}
                    handleCancel={() => setOpen(false)}
                    loading={loading}
                />
            )}
            {open === 'detailCandidate' && (
                <CandidateDetailModal
                    open={open === 'detailCandidate'}
                    handleClose={() => setOpen(false)}
                    id={candidateId}
                    hasPermission={hasPermission}
                    permission={permission}
                />
            )}
            {open === 'assignCandidate' && (
                <AssignModal
                    open={open === 'assignCandidate'}
                    handleClose={() => setOpen(false)}
                    id={id}
                    handleRefresh={fetchAssigned}
                    assigned={assigned}
                />
            )}

            <Box>
                {!loading ? (
                    detail.status === 1 ? (
                        <>
                            {hasPermission(['super-admin'], permission) && (
                                <>
                                    <Typography variant="h3" my="10px">
                                        Candidates Suggestion
                                    </Typography>
                                    <Grid container spacing={gridSpacing}>
                                        {candidates && candidates.length > 0 ? (
                                            candidates.map((candidate) => (
                                                <Grid item xs={12} md={4}>
                                                    <CandidateCardItem
                                                        type="suggestion"
                                                        key={candidate.id_candidate}
                                                        status={candidate.status}
                                                        name={candidate.name}
                                                        cv={candidate.cv}
                                                        position={candidate.skill}
                                                        permission={permission}
                                                        onPreview={() => {
                                                            setCandidateId(candidate.id_candidate);
                                                            setOpen('detailCandidate');
                                                        }}
                                                        onConfirm={() => handleSuggest(candidate.id_candidate)}
                                                    />
                                                </Grid>
                                            ))
                                        ) : (
                                            <Box
                                                sx={{
                                                    height: '200px',
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Typography variant="h3" color="gray">
                                                    Suggestions Unavailable
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                </>
                            )}
                            {hasPermission(['client'], permission) && (
                                <Typography variant="h3" margin="10px 0">
                                    Review our suggestion candidates
                                </Typography>
                            )}
                            <Box mt={2}>
                                <Stack my="10px" direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h3">Assigned Candidates</Typography>
                                    <Button variant="text" size="small" onClick={() => setOpen('assignCandidate')}>
                                        {assigned && assigned.length > 0 ? 'Add More' : 'Add Candidate'}
                                    </Button>
                                </Stack>
                            </Box>
                            <Grid container spacing={gridSpacing}>
                                {assigned && assigned.length > 0 ? (
                                    assigned.map((candidate) => (
                                        <Grid item xs={12} md={4}>
                                            <CandidateCardItem
                                                key={candidate.id}
                                                status={candidate.status}
                                                name={candidate.name}
                                                cv={candidate.cv}
                                                position={candidate.skill}
                                                permission={permission}
                                                onPreview={() => {
                                                    setCandidateId(candidate.resource_id);
                                                    setOpen('detailCandidate');
                                                }}
                                                onAccept={() => {
                                                    setPayload({
                                                        id: candidate.resource_id,
                                                        status: 1,
                                                        name: candidate.name
                                                    });
                                                    setOpen('confirmStatus');
                                                }}
                                                onRevoke={() => {
                                                    setPayload({
                                                        id: candidate.resource_id,
                                                        status: 2,
                                                        name: candidate.name
                                                    });
                                                    setOpen('confirmStatus');
                                                }}
                                                onDelete={() => {
                                                    setRemoveId(candidate.id);
                                                    setOpen('confirmDelete');
                                                }}
                                            />
                                        </Grid>
                                    ))
                                ) : (
                                    <Box
                                        sx={{
                                            height: '300px',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography variant="h3" color="gray">
                                            Candidates are Unavailable
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Box sx={{ height: 'calc(100vh - 250px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {hasPermission(['super-admin'], permission) && (
                                <Stack direction="column" textAlign="center">
                                    <Typography variant="h3">Request status must be on process</Typography>
                                    <Typography variant="caption">
                                        Change this request status on request list & when its cancelled you can&apos;t add candidates
                                    </Typography>
                                </Stack>
                            )}
                            {hasPermission(['client'], permission) && (
                                <Stack direction="column" textAlign="center">
                                    <Typography variant="h3">
                                        {detail.status === 0
                                            ? 'Request still pending'
                                            : detail.status === 2
                                            ? 'Request is cancelled by you'
                                            : 'Request is Rejected'}
                                    </Typography>
                                    <Typography variant="caption">Tell our team for more information</Typography>
                                </Stack>
                            )}
                        </Box>
                    )
                ) : (
                    <Box sx={{ height: 'calc(100vh - 250px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress size="1.7rem" />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default RequestCandidate;
