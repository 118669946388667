function IconCreditCardHeart({ color = '#5E35B1', size = 16, ...props }) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="a"
                style={{
                    maskType: 'alpha'
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={size}
                height={size}
            >
                <path fill="#D9D9D9" d="M0 0H16V16H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M2.667 12V6.667v.216V4v8zm9.266 1.667L9.6 11.333a1.289 1.289 0 01-.308-.466 1.444 1.444 0 01-.092-.5c0-.356.128-.673.383-.95.256-.278.584-.417.984-.417.31 0 .555.072.733.217.178.144.389.339.633.583.223-.222.425-.411.609-.567.183-.155.436-.233.758-.233.411 0 .742.142.992.425.25.283.375.603.375.958 0 .167-.034.334-.1.5a1.332 1.332 0 01-.3.45l-2.334 2.334zM2.667 5.333h10.666V4H2.667v1.333zm5.05 8h-5.05c-.367 0-.68-.13-.942-.391A1.284 1.284 0 011.333 12V4c0-.367.13-.68.392-.942.261-.26.575-.391.942-.391h10.666c.367 0 .68.13.942.391.261.261.392.575.392.942v3.55a4.845 4.845 0 00-1.275-.65 4.531 4.531 0 00-1.459-.233c-.633 0-1.23.12-1.791.358A4.772 4.772 0 008.667 8h-6v4h4.65a4.675 4.675 0 00.4 1.333z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default IconCreditCardHeart;
