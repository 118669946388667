import { Button, Stack, TextField, Typography } from '@mui/material';
import DialogModal from 'ui-component/dialogModal';

const ModalCancelNote = ({ open, handleClose, reasonMessage, setReasonMessage }) => (
    <DialogModal open={open} handleClose={handleClose}>
        <Stack direction="column" spacing={2}>
            <Typography variant="h3">Cancellation Note</Typography>
            <TextField
                fullWidth
                type="text"
                size="small"
                label="Note"
                value={reasonMessage}
                onChange={(e) => setReasonMessage(e.target.value)}
                id="input-confirmation-request-reason"
            />
            <Stack direction="row" justifyContent="right" margin="10px 0 0 0">
                <Button
                    size="small"
                    onClick={() => {
                        handleClose();
                    }}
                    variant="outlined"
                    id="button-confirmation-request-cancel"
                >
                    Exit
                </Button>
            </Stack>
        </Stack>
    </DialogModal>
);

export default ModalCancelNote;
