// material-ui
import { Box, Grid, TextField, Button, Stack, Typography, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { createNewSkill, getDetailSkill, updateSkill } from 'utils/services/skills';

const SkillForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        name: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        createNewSkill({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    navigate('/skills');
                } else {
                    console.log(res);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const form = {
            name: payload.name
        };
        updateSkill({ identify, form }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                navigate('/skills');
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate('/skills');
            }
        });
    };
    const fetchDetail = async (identify) => {
        getDetailSkill({ identify }).then((res) => {
            setPayload(res.data);
        });
    };

    useEffect(() => {
        if (action === 'update' && identify) {
            fetchDetail(identify);
        }
    }, [identify, action]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Create new Skill' : 'Update Skill'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/skills')}
            />
            <Box
                component="form"
                autoComplete="off"
                onSubmit={(ev) => (action && action === 'create' ? handleSubmit(ev) : handleUpdate(ev))}
            >
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sx={12} md={6} spacing={2}>
                        <TextField
                            required
                            name="skill"
                            margin="normal"
                            value={payload.name}
                            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Skill"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit" disabled={loading}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Create' : 'Update Skill'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default SkillForm;
