const RootPermission = ({ children, granted }) => {
    return (
        <>
            {granted ? (
                children
            ) : (
                <>
                    <h1>You dont have any permission to this page!</h1>
                </>
            )}
        </>
    );
};

export default RootPermission;
