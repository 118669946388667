import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getCandidatebyReqId = async ({ requestId = '' }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/candidate?form_request_id=${requestId}`, config);
};
export const getSuggestCandidateId = async (id) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/candidate/suggest_candidate/${id}`, config);
};
export const postAssignCandidateBatch = async (form) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/candidate/batch`, { candidates: [...form] }, config);
};
export const postAssignCandidate = async (form) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/candidate/batch`, { candidates: [form] }, config);
};
export const updateStatusCandidate = async ({ form }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/candidate/status`, { ...form }, config);
};
export const deleteCandidate = async ({ removeId }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/candidate/${removeId}`, config);
};
