// material-ui
import { Box, Grid, TextField, Button, Stack, Typography, Avatar, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
// import features from './features';
import { getPermission } from 'utils/services/permission';
import { createNewRole, getDetailRole, updateRole } from 'utils/services/role';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { element } from 'prop-types';

const RoleForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');

    const identify = queryParams.get('identify');
    const [value, copy] = useCopyToClipboard(); //eslint-disable-line
    const [loading, setLoading] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [checkModules, setCheckModules] = useState([]);
    // const [featureList, setFeatureList] = useState([]);
    const [permission, setPermission] = useState(null);

    const fetchPermission = async () => {
        setLoading(true);
        getPermission({})
            .then((res) => {
                setPermission(res);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };

    const [payload, setPayload] = useState({
        name: '',
        permission: []
    });

    const handleSelectAll = (e, array = []) => {
        const { checked, id } = e.target;
        let temp = [...payload.permission];
        array?.permissions.map((element) => {
            return temp.push(element.id);
        });

        setCheckModules([...checkModules, id]);
        setPayload({
            ...payload,
            permission: temp
        });

        if (!checked) {
            temp = temp.filter((x) => !array.permissions.some((p) => p.id === x));
            setCheckModules(checkModules.filter((item) => item !== id));
            setPayload({
                ...payload,
                permission: [...temp]
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        createNewRole({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    navigate('/role');
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const form = {
            name: payload.name,
            permission: payload.permission
        };
        updateRole({ identify, form }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                navigate('/role');
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate('/role');
            }
        });
    };

    const fetchDetail = async (identify) => {
        getDetailRole({ identify }).then((res) => {
            const result = res.data.role_permission.map((item) => item.permission_id);

            setPayload({
                name: res.data.name,
                permission: [...result]
            });
        });
    };

    useEffect(() => {
        fetchPermission();
        if (action === 'update' && identify) {
            fetchDetail(identify);
            // setPayload({ ...payload, name: data[0].name, description: data[0].descriptions });
        }
        // const temp = [...features];
        // const results = temp.map((item) => {
        //     return {
        //         module: item.module,
        //         checked: false,
        //         permissions: item.permissions.map((x) => {
        //             return {
        //                 ...x,
        //                 checked: false
        //             };
        //         })
        //     };
        // });
        // setFeatureList(results);
    }, [action, identify]);

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Create new Role' : 'Update Role'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/clients')}
            />
            <Box component="form" autoComplete="off" onSubmit={identify && action === 'update' ? handleUpdate : handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Role</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="input-resource-name"
                            required
                            name="name"
                            type="text"
                            value={payload.name}
                            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Role Name"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Permission</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {permission &&
                                permission.map((element, index) => (
                                    <Grid key={index} item xs={12} md={12} spacing={2}>
                                        <Box
                                            key={`module-${index}`}
                                            sx={{
                                                background: '#f7f5f5',
                                                boxShadow: '2px 2px 5px #b3b3b3',
                                                padding: '20px',
                                                borderRadius: '10px',
                                                marginBottom: '10px'
                                            }}
                                        >
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={element.module}
                                                            checked={checkModules.includes(element.module)}
                                                            onChange={(e) => handleSelectAll(e, element)}
                                                        />
                                                    }
                                                    label={<Typography variant="h4">{element.module}</Typography>}
                                                />
                                                <Grid container xs={12}>
                                                    {element.permissions.map((permissions) => (
                                                        <Grid key={permissions.id} item xs={6}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        id={`checkbox-role-${permissions.id}`}
                                                                        checked={payload.permission.includes(permissions.id)}
                                                                        value={payload.permission}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setPayload({
                                                                                    ...payload,
                                                                                    permission: [...payload.permission, permissions.id]
                                                                                });
                                                                            } else {
                                                                                const result = payload.permission.filter((item) => {
                                                                                    return item !== permissions.id;
                                                                                });
                                                                                setPayload({
                                                                                    ...payload,
                                                                                    permission: result
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                label={permissions.name}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                ))}
                            {/* <Grid item sx={12} md={12} spacing={2}>
                                {featureList && featureList.map((feature, index) => (
                                    <Box key={`module-${index}`} sx={{ background: '#eaeaea', padding: '10px', borderRadius: '10px', marginBottom: '10px' }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feature.checked} onChange={(e) => {
                                                console.log(e)
                                                const prev = [...featureList]
                                                const childs = prev[index].permissions.map((x) => {
                                                    return {
                                                        ...x,
                                                        checked: !feature.checked
                                                    }
                                                })
                                                prev[index].checked = !feature.checked
                                                prev[index].permissions = [...childs]
                                                setFeatureList(prev)
                                            }} />}
                                                label={feature.module}
                                                sx={{ width: '100%' }}
                                            />

                                        </FormGroup>
                                        <hr />
                                        <Grid container spacing={2}>
                                            {feature.permissions && feature.permissions.length > 0 && feature.permissions.map((permission, idx) => (
                                                <Grid key={`child-${idx}`} item sx={12} md={4} spacing={2}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                checked={permission.checked} onChange={(e) => {
                                                                    console.log(e)
                                                                    const temp = [...featureList];
                                                                    temp[index].checked = false
                                                                    temp[index].permissions[idx].checked = !temp[index].permissions[idx].checked
                                                                    setFeatureList(temp)
                                                                }} />}
                                                            label={permission.name}
                                                            sx={{ width: '100%' }}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                ))}
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            {/* <Button variant="outlined" onClick={setOpenCancelModal(true)}> */}
                            <Button id="button-role-cancel" variant="outlined">
                                Cancel
                            </Button>
                            <Button id="button-role-submit" variant="contained" type={loading ? 'button' : 'submit'} disabled={loading}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Create' : 'Update Role'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default RoleForm;
