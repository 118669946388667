import { Stack, Typography, Button, Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { modalStyle } from 'ui-component/confirmation';
import DialogModal from 'ui-component/dialogModal';

const ModalChangeStatus = ({ open, handleClose, payload, setPayload, handleSubmit }) => {
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        if (payload.reason === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <DialogModal open={open}>
            <Box sx={{ ...modalStyle, width: '400px' }}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="column" spacing={0.5}>
                        <Typography variant="h3">Confirmation</Typography>
                        <Typography margin="0 0 10px 0">
                            Change request status to {payload.status === 1 ? 'process' : payload.status === 2 ? 'reject' : 'cancel'}?
                        </Typography>
                    </Stack>
                    <TextField
                        required
                        fullWidth
                        type="text"
                        size="small"
                        label="Reason"
                        value={payload.reason}
                        onChange={(e) => setPayload({ ...payload, reason: e.target.value })}
                        id="input-confirmation-request-reason"
                    />
                    <Stack direction="row" justifyContent="right" spacing={1} margin="10px 0 0 0">
                        <Button
                            size="small"
                            onClick={() => {
                                setPayload({ ...payload, reason: '' });
                                handleClose();
                            }}
                            id="button-confirmation-request-cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            disableElevation
                            variant="contained"
                            id="button-confirmation-request-continue"
                            onClick={(e) => handleSubmit(e)}
                            disabled={disable}
                        >
                            Continue
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </DialogModal>
    );
};

export default ModalChangeStatus;
