import Chart from 'react-apexcharts';

const SpiderChart = ({ categories, series }) => {
    const options = {
        chart: {
            type: 'radar',
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories,
            labels: {
                show: true,
                style: {
                    colors: Array(categories.length).fill('rgba(136, 100, 238, 1)'),
                    fontSize: '10px',
                    fontWeight: 700
                }
            }
        },
        yaxis: {
            show: false
        },
        fill: {
            opacity: 0.5,
            colors: 'rgba(152, 94, 255, 0.3)'
        },
        stroke: {
            width: 0
        },
        markers: {
            size: 2,
            colors: 'rgba(136, 100, 238, 1)',
            hover: {
                size: 4
            }
        },
        tooltip: {
            style: {
                fontSize: '11px',
                fontFamily: 'Roboto'
            },
            background: '#f3f3f3',
            color: 'orange'
        }
    };

    return <Chart options={options} series={series} type="radar" height={200} />;
};

export default SpiderChart;
