import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Typography
} from '@mui/material';

// project imports

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import { IconCategory, IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Link } from 'react-router-dom';
import CategoryList from './CategoryList';
import { getCategories } from 'utils/services/categories';
import { AddCircleTwoTone } from '@mui/icons-material';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';
import RootPermission from '../permission';

const CategoryPage = () => {
    const theme = useTheme('oures');
    const [categories, setCategories] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const [granted, allPermission] = useRootPermission(['Category@category_getpost']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const fetchCategory = async () => {
        setLoading(true);
        getCategories({ page, limit, search: '' })
            .then((res) => {
                setCategories(res.data);
                setTotal(res.meta.total);
                handleClose();
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };

    React.useEffect(() => {
        fetchCategory();
    }, [page, limit, filter]); //eslint-disable-line

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconCategory color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        Category
                                    </Typography>
                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                        Please let us now your Category
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                id="input-search-list-style1"
                                placeholder="Search"
                                onChange={(e) => setFilter(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                            {hasPermission(['Category@add_category'], allPermission) && (
                                <Link to="/category/form?action=create">
                                    <IconButton>
                                        <AddCircleTwoTone />
                                    </IconButton>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <CategoryList category={categories} loading={loading} refresh={() => fetchCategory()} />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="primary"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                id="menu-user-list-style1"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={() => setLimit(10)}> 10 Rows</MenuItem>
                                <MenuItem onClick={() => setLimit(25)}> 25 Rows</MenuItem>
                                <MenuItem onClick={() => setLimit(50)}> 50 Rows</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default CategoryPage;
