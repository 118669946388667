// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import moment from 'moment';
import 'moment/locale/id';

moment.locale('id');

const UserList = ({ resources = [], loading = true }) => {
    const theme = useTheme('oures');

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Last Login</TableCell>
                            <TableCell>Status</TableCell>
                            {/* <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {resources && resources.length > 0 ? (
                                    resources.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Avatar alt="User 1" src="" />
                                                    </Grid>
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography align="left" variant="subtitle1" component="div">
                                                            {row.pic_name}{' '}
                                                            {row.status === 'Idle' && (
                                                                <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} />
                                                            )}
                                                        </Typography>
                                                        <Typography align="left" variant="subtitle2" noWrap>
                                                            {row.name}
                                                        </Typography>
                                                        <Typography align="left" variant="subtitle2" noWrap>
                                                            {row.email}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>{moment(row.created_at).format('DD MMMM YYYY')}</TableCell>
                                            <TableCell>
                                                {row.last_login_at ? moment(row.last_login_at).format('DD MMMM YYYY') : ''}
                                            </TableCell>
                                            <TableCell>
                                                {row.activated && (
                                                    <Chip
                                                        label="Active"
                                                        size="small"
                                                        sx={{
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.success.light + 60,
                                                            color: theme.palette.success.dark
                                                        }}
                                                    />
                                                )}
                                                {!row.activated && (
                                                    <Chip
                                                        label="Inactive"
                                                        size="small"
                                                        sx={{
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.warning.light,
                                                            color: theme.palette.warning.dark
                                                        }}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                <Typography>-</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={6} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={6} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default UserList;
