import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { getSkills } from 'utils/services/skills';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { IconSearch } from '@tabler/icons';

export default function FilterDrawer({ open, setterOpen, setterSkill, setterExperience }) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    });
    const [skill, setSkill] = React.useState([]);
    const [checkSkill, setCheckSkill] = useState([]);
    const [checkExperience, setCheckExperience] = useState([]);
    const [filterSkill, setFilterSkill] = useState('');

    const experiences = ['1', '2', '3', '4', '5', '6', '7'];

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ maxWidth: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem>
                    <Stack>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>Skill</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <OutlinedInput
                                    sx={{ mb: 2 }}
                                    id="input-search-list-style1"
                                    placeholder="Search"
                                    onChange={(e) => setFilterSkill(e.target.value)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconSearch stroke={1.5} size="1rem" />
                                        </InputAdornment>
                                    }
                                    size="small"
                                />
                                <FormGroup>
                                    {skill.map((item, index) => (
                                        <FormControlLabel
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setCheckSkill([...checkSkill, item.id]);
                                                } else {
                                                    const cek = checkSkill.filter((e) => e !== item.id);
                                                    setCheckSkill(cek);
                                                }
                                            }}
                                            key={index}
                                            control={<Checkbox defaultChecked={checkSkill.includes(item.id) && true} />}
                                            label={item.name}
                                        />
                                    ))}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                <Typography>Experience</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormGroup>
                                    <Grid container spacing={2} justifyContent="center">
                                        {experiences.map((item, index) => (
                                            <Grid key={index} item md={6}>
                                                <FormControlLabel
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCheckExperience([...checkExperience, item]);
                                                        } else {
                                                            const cek = checkExperience.filter((e) => e !== item);
                                                            setCheckExperience(cek);
                                                        }
                                                    }}
                                                    control={<Checkbox defaultChecked={checkExperience.includes(item)}/>}
                                                    label={item}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                <Typography>Assigments Status</Typography>
                            </AccordionSummary>
                            {/* <AccordionDetails></AccordionDetails> */}
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                <Typography>Domicile</Typography>
                            </AccordionSummary>
                            {/* <AccordionDetails></AccordionDetails> */}
                        </Accordion>
                    </Stack>
                </ListItem>
            </List>
        </Box>
    );

    const fetchSkill = async () => {
        getSkills({search: filterSkill })
            .then((res) => {
                setSkill(res.data);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };
    React.useEffect(() => {
        fetchSkill();
        const resultSkill = checkSkill.map((item) => item).join(',');
        setterSkill(resultSkill);
        const resultExperience = checkExperience.map((item) => item).join(',');
        setterExperience(resultExperience)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkSkill, filterSkill, checkExperience]);

    return (
        <div>
            <Drawer
                anchor="right"
                open={open}
                onClose={() => {
                    setterOpen(!open);
                }}
            >
                {list('right')}
            </Drawer>
        </div>
    );
}
