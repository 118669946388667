import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getJobPosition = async ({ page = 1, limit = 5, search = '' }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/jobposition?search=${search}&limit=${limit}&page=${page}`, config);
};
export const createNewJobPosition = async ({ payload }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/jobposition`, { ...payload }, config);
};
export const updateJobPosition = async ({ identify, form }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/jobposition/${identify}`, form, config);
};
export const deleteJobPosition = async ({ id }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/jobposition/${id}`, config);
};
export const getDetailJobPosition = async ({ identify }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/jobposition/${identify}`, config);
};
export const getListJobPosition = async () => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/jobposition`, config);
};
