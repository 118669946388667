import { storageKey } from 'store/constant';
import ApiService from '../../service';

const getUserRunning= async ({ page, limit, search }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/assignmentContract/running?page=${page}&limit=${limit}&search=${search}`,
        config
    );
};

export const updateTerminateContract = async ({ id, payload }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/assignmentContract/terminate/${id}`, {...payload} , config)
};

export default getUserRunning
