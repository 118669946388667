import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Typography } from '@mui/material';

import axios from 'axios';

// project imports
import UserList from './AccountList';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing, storageKey } from 'store/constant';

// assets
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { getUserInfo, hasPermission } from 'helper/general';
import { Link } from 'react-router-dom';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

const AccountsPage = () => {
    const theme = useTheme('oures');
    const [resources, setResources] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [userInfo, setUserInfo] = useState({});

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getData = async () => {
        setLoading(true);
        if (userInfo && userInfo.client_id) {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
            };

            await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/client/pic/list?page=${page}&limit=${limit}&search=${search}&client_id=${userInfo.client_id}`,
                    config
                )
                .then((res) => {
                    if (res) {
                        setLoading(false);
                        setResources(res.data.data);
                        setTotal(res.data.meta.total);
                    }
                });
        }
    };

    useEffect(() => {
        setUserInfo(getUserInfo());
    }, []);

    useEffect(() => {
        getData();
    }, [page, limit, search, userInfo]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Company Accounts</Typography>
                        <Typography variant="subtitle2">Person in charge at your company that can create new requests.</Typography>
                    </Grid>
                    <Grid item spacing={1}>
                        <OutlinedInput
                            id="input-accounts-search"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" />
                                </InputAdornment>
                            }
                            size="small"
                        />
                        {localStorage.getItem(storageKey.USER_ROLE) === 'client' && (
                            <Link id="link-accounts-create" to="/account/pic/form?action=create">
                                <IconButton>
                                    <AddCircleTwoToneIcon />
                                </IconButton>
                            </Link>
                        )}
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <UserList loading={loading} resources={resources} onRefresh={getData} />
            <Grid item xs={12} sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination
                            onChange={(e, value) => setPage(value)}
                            count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                            color="primary"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                            id="button-accounts-rows"
                        >
                            {limit} Rows
                        </Button>
                        <Menu
                            id="menu-accounts-rows"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem id="menu-accounts-limit5" onClick={() => setLimit(5)}>
                                {' '}
                                5 Rows
                            </MenuItem>
                            <MenuItem id="menu-accounts-limit10" onClick={() => setLimit(10)}>
                                {' '}
                                10 Rows
                            </MenuItem>
                            <MenuItem id="menu-accounts-limit20" onClick={() => setLimit(20)}>
                                {' '}
                                20 Rows
                            </MenuItem>
                            <MenuItem id="menu-accounts-limit30" onClick={() => setLimit(30)}>
                                {' '}
                                30 Rows
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AccountsPage;
