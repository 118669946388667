import { Box, Chip, Grid, Typography } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';

const SubCardExpertise = ({ detail }) => {
    const interest = ['Graphic Design', 'Mobile Development', 'Visual Design', 'Technology'];

    return (
        <SubCard title="Area of Expertise" sx={{ mt: 3 }}>
            <Typography sx={{ fontWeight: 600, color: '#121926', mb: 1 }}>Skills</Typography>
            <Grid container spacing={1}>
                {detail.resources.skills.length ? (
                    detail.resources.skills.map((skill) => (
                        <Grid item>
                            <Chip
                                sx={{
                                    borderRadius: '4px',
                                    backgroundColor: '#EDE7F6',
                                    color: '#5E35B1',
                                    fontSize: '12px',
                                    fontWeight: 450
                                }}
                                label={skill.skill_name}
                            />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Box>
                            <Typography textAlign="center" variant="subtitle2">
                                Resource doesn&apos;t add any skill(s) yet
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Typography sx={{ fontWeight: 600, color: '#121926', my: 1 }}>Interest</Typography>
            <Grid container spacing={1}>
                {interest.map((item, index) => (
                    <Grid item key={index}>
                        <Chip
                            sx={{
                                borderRadius: '4px',
                                backgroundColor: '#DCE2EB',
                                color: '#364152',
                                fontSize: '12px',
                                fontWeight: 450
                            }}
                            label={item}
                        />
                    </Grid>
                ))}
            </Grid>
        </SubCard>
    );
};

export default SubCardExpertise;
