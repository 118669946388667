import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';

// assets
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ExpandMore, OpenInNewRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import ConfirmationModal from 'ui-component/confirmation';
import axios from 'axios';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';
import { makeStyles } from '@mui/styles';

// ==============================|| USER LIST 1 ||============================== //
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 400 // Set the desired height for the table container if you want vertical scrolling
    },
    stickyColumn: {
        position: 'sticky',
        minWidth: 300,
        left: 50,
        background: theme.palette.background.paper, // Set the desired background color for the sticky column
        zIndex: 2,
        boxShadow: 2
    },
    stickyRight: {
        position: 'sticky',
        right: 0,
        maxWidth: 350,
        background: theme.palette.background.paper, // Set the desired background color for the sticky column
        zIndex: 2,
        boxShadow: 2
    }
}));

const UserList = ({ resources = [], handleRefresh, loading = true, page = 1, limit = 10 }) => {
    const theme = useTheme('oures');
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const classes = useStyles();

    const [granted, allPermission] = useRootPermission(['Resources@resources_get']);

    const handleRemove = async () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios.delete(`${process.env.REACT_APP_API_URL}/resources/${deleteId}`, config).then((res) => {
            if (res) {
                setOpen(false);
                handleRefresh();
            }
        });
    };

    const generatePhone = (phone) => phone.replaceAll('-', '').replace(/^0/, '+62');

    return (
        <>
            <ConfirmationModal open={open} handleCancel={() => setOpen(false)} handleContinue={handleRemove} />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{ position: 'sticky', left: 0, width: '50px', zIndex: 2, background: theme.palette.background.paper }}
                            >
                                No
                            </TableCell>
                            <TableCell className={classes.stickyColumn}>User Profile</TableCell>
                            <TableCell>Domicile</TableCell>
                            <TableCell sx={{ pl: 4 }}>Skills</TableCell>
                            <TableCell sx={{ minWidth: 250 }}>Applied Position</TableCell>
                            {/* <TableCell>Status</TableCell> */}
                            <TableCell>CV</TableCell>
                            <TableCell sx={{ minWidth: 250 }}>Competences</TableCell>
                            <TableCell className={classes.stickyRight} align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {resources && resources.length > 0 ? (
                                    resources.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell
                                                sx={{
                                                    position: 'sticky',
                                                    left: 0,
                                                    width: '50px',
                                                    zIndex: 2,
                                                    background: theme.palette.background.paper
                                                }}
                                            >
                                                {(page - 1) * limit + (index + 1)}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    position: 'sticky',
                                                    left: '50px',
                                                    minWidth: 250,
                                                    zIndex: 999,
                                                    background: theme.palette.background.paper,
                                                    boxShadow: 2
                                                }}
                                            >
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        {row.users.avatarURL ? (
                                                            <Avatar alt="User 1" src={row.users.avatarURL} />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    background: '#f1f1f1',
                                                                    fontSize: '20px',
                                                                    padding: '10px',
                                                                    textAlign: 'center',
                                                                    borderRadius: '50%'
                                                                }}
                                                            >
                                                                {row.users.name.substr(0, 2)}
                                                            </div>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography align="left" variant="subtitle1" component="div">
                                                            {row.users.name}
                                                        </Typography>
                                                        <Typography align="left" noWrap variant="subtitle2" component="div">
                                                            {row.users.email}
                                                        </Typography>
                                                        <Typography align="left" noWrap variant="subtitle2" component="div">
                                                            {generatePhone(row.users.phoneNumber)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>{row.users.address}</TableCell>
                                            <TableCell display="flex" alignItems="center" justifyContent="center">
                                                {/* eslint-disable-next-line */}
                                                <>
                                                    {row.skills?.length > 1 ? (
                                                        <Stack direction="row" display="flex" justifyContent="start">
                                                            <>
                                                                <Accordion
                                                                    sx={{
                                                                        border: 'none',
                                                                        borderRadius: 8,
                                                                        background: 'none',
                                                                        padding: 0,
                                                                        width: '200px',
                                                                        margin: 0
                                                                    }}
                                                                    disableGutters
                                                                >
                                                                    <AccordionSummary
                                                                        color="success"
                                                                        expandIcon={<ExpandMore />}
                                                                        sx={{
                                                                            width: '200px',
                                                                            justifyContent: 'between'
                                                                        }}
                                                                    >
                                                                        <Typography>{row.skills[0].skill_name}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails
                                                                        sx={{
                                                                            background: 'none',
                                                                            justifyContent: 'start',
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}
                                                                    >
                                                                        {row.skills.slice(1, row.skills.length).map((skill) => (
                                                                            <Typography
                                                                                sx={{
                                                                                    marginY: 1,
                                                                                    justifyContent: 'start',
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column'
                                                                                }}
                                                                            >
                                                                                {skill.skill_name}
                                                                            </Typography>
                                                                        ))}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </>
                                                        </Stack>
                                                    ) : (
                                                        row.skills.map((skill) => (
                                                            <Stack
                                                                direction="row"
                                                                display="flex"
                                                                justifyContent="start"
                                                                sx={{ pl: 2 }}
                                                                spacing={1}
                                                            >
                                                                <Typography>{skill.skill_name}</Typography>
                                                            </Stack>
                                                        ))
                                                    )}
                                                </>
                                            </TableCell>
                                            <TableCell sx={{ minWidth: 250 }}>{row.competence_name}</TableCell>
                                            {/* <TableCell>
                                            {row.status === 'Idle' && (
                                                <Chip
                                                    label={row.status}
                                                    size="small"
                                                    sx={{
                                                        background:
                                                            theme.palette.mode === 'dark'
                                                                ? theme.palette.dark.main
                                                                : theme.palette.success.light + 60,
                                                        color: theme.palette.success.dark
                                                    }}
                                                />
                                            )}
                                            {row.status === 'On Assignment' && (
                                                <Chip
                                                    label={row.status}
                                                    size="small"
                                                    sx={{
                                                        background:
                                                            theme.palette.mode === 'dark'
                                                                ? theme.palette.dark.main
                                                                : theme.palette.orange.light + 80,
                                                        color: theme.palette.orange.dark
                                                    }}
                                                />
                                            )}
                                            {row.status === 'Assigned' && (
                                                <Chip
                                                    label={row.status}
                                                    size="small"
                                                    sx={{
                                                        background:
                                                            theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                                                        color: theme.palette.warning.dark
                                                    }}
                                                />
                                            )}
                                        </TableCell> */}
                                            <TableCell>{row.cv_url}</TableCell>
                                            <TableCell sx={{ minWidth: 250 }}>{row.competence_name}</TableCell>
                                            <TableCell className={classes.stickyRight} align="center" sx={{ pr: 3 }}>
                                                {hasPermission(['Resources@resources_getputdel'], allPermission) && (
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        <Tooltip placement="top" title="View full profile & histories">
                                                            <Link id="link-resource-detail" to={`view/${row.id}/detail`}>
                                                                <IconButton color="primary" aria-label="open" size="large">
                                                                    <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        <a
                                                            href={`https://wa.me/${generatePhone(row.users.phoneNumber)}`}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            id="link-resource-whatsapp"
                                                        >
                                                            <Tooltip placement="top" title="Message">
                                                                <IconButton color="success" aria-label="delete" size="large">
                                                                    <WhatsAppIcon sx={{ fontSize: '1.1rem' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </a>
                                                        <Tooltip placement="top" title="Block">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setDeleteId(row.id);
                                                                    setOpen(true);
                                                                }}
                                                                color="primary"
                                                                sx={{
                                                                    color: theme.palette.orange.dark,
                                                                    borderColor: theme.palette.orange.main,
                                                                    '&:hover ': { background: theme.palette.orange.light }
                                                                }}
                                                                size="large"
                                                                id="button-resource-delete"
                                                            >
                                                                <BlockTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={8} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={8} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default UserList;
