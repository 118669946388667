import { Backdrop, Card, CardContent, CardHeader, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    boxShadow: 24,
    borderRadius: '12px'
};

export default function ReassignNoteModal({ action, row, index, open, handleClose }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <Card sx={modalStyle}>
                    <CardHeader
                        sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }}
                        title={action === 'cancel' ? 'Cancel Note' : 'Reassign Note'}
                    />
                    <CardContent>
                        <TextField
                            type="text"
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={8}
                            value={
                                action === 'cancel'
                                    ? row.history_interview[index].cancel_note
                                    : action === 'reassign'
                                    ? row.history_interview[index].reassign_note
                                    : ''
                            }
                        />
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
}
