import { Avatar, Box, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/styles';

import AnimateButton from 'ui-component/extended/AnimateButton';
import backgroundImg from 'assets/svg/BGLandingBottom.svg';
import GroupsIcon from '@mui/icons-material/Groups';
import User1 from 'assets/images/users/user-round.svg';
import EngineeringIcon from '@mui/icons-material/Engineering';
import IconDatabase from 'assets/images/landing/IconDatabase.svg';
import ShapeIconCard from 'ui-component/cards/ShapeIconCard';

const HeaderTestimoniesPage = () => {
    const theme = useTheme();
    const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const midpointIndex = Math.floor(list.length * 0.5) + 1;

    return (
        <CardContent
            sx={{
                backgroundImage: `url(${backgroundImg})`,
                backgroundColor: 'rgba(102, 67, 201, 1)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: { xs: '40vh', md: '70vh' },
                display: 'flex',
                alignItems: 'center !important'
            }}
        >
            <Grid container xs={12} justifyContent="space-between">
                <Grid
                    container
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                        height: '300px'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            background: `
                                linear-gradient(to right, rgba(102, 67, 201, 1), rgba(102, 67, 201, 0) 40%) left,
                                linear-gradient(to left, rgba(102, 67, 201, 1), rgba(102, 67, 201, 0) 30%) right
                            `
                        }}
                    />
                    <Box
                        sx={{
                            p: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                            minWidth: `${window.innerWidth}px`,
                            [`& > :nth-child(${midpointIndex})`]: {
                                marginLeft: '125px'
                            },
                            maxHeight: '300px'
                        }}
                    >
                        {[
                            list.map((index) => (
                                <Grid
                                    item
                                    xs={3}
                                    key={index}
                                    sx={{
                                        paddingX: 1,
                                        minWidth: '250px',
                                        maxWidth: '250px',
                                        paddingY: 1
                                    }}
                                >
                                    <Card sx={{ borderRadius: '8px' }}>
                                        <Stack direction="column" padding="8px">
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Avatar
                                                    src={User1}
                                                    sx={{
                                                        ...theme.typography.mediumAvatar,
                                                        margin: '8px 0 8px 8px !important',
                                                        cursor: 'pointer',
                                                        width: '20.4px',
                                                        height: '20.4px'
                                                    }}
                                                    color="inherit"
                                                />
                                                <Stack direction="column">
                                                    <Typography
                                                        sx={{ fontFamily: ['Figtree'], fontWeight: 700, color: '#1E1E1E', fontSize: '9px' }}
                                                    >
                                                        Sarach Halimah
                                                    </Typography>
                                                    <Typography
                                                        sx={{ fontFamily: ['Figtree'], fontWeight: 400, color: '#7B7B7B', fontSize: '9px' }}
                                                    >
                                                        Frontend Developer
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Typography
                                                sx={{
                                                    fontFamily: ['Figtree'],
                                                    fontWeight: 400,
                                                    color: '#1E1E1E',
                                                    fontSize: '9px',
                                                    paddingX: '5px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                &quot;I landed my dream job through <strong>Oures</strong>! The tailored job matches were
                                                spot-on, and the application process was a breeze. The platform truly understands your
                                                unique needs and connects you with opportunities that align with your career goals.&quot;
                                            </Typography>
                                        </Stack>
                                    </Card>
                                </Grid>
                            ))
                        ]}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -5 }}>
                        <Box>
                            <Card sx={{ borderRadius: '8px' }}>
                                <Stack direction="row" spacing={1} sx={{ alignItems: 'center', padding: '10px' }}>
                                    <ShapeIconCard
                                        iconPrimary={<GroupsIcon />}
                                        color={theme.palette.success.dark}
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                            '& span': {
                                                width: '20px',
                                                height: '20px'
                                            },
                                            '& svg': {
                                                width: '20px',
                                                height: '20px',
                                                position: 'relative',
                                                zIndex: '5'
                                            }
                                        }}
                                    />
                                    <Stack direction="row" spacing={0.5}>
                                        <Typography sx={{ fontFamily: ['Figtree'], fontSize: '12px', fontWeight: 700, color: '#28A91D' }}>
                                            1200+
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: ['Figtree'],
                                                fontSize: '12px',
                                                fontWeight: 700,
                                                color: '#1E1E1E'
                                            }}
                                        >
                                            Applicants
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Box>
                        <Box sx={{ paddingRight: 5 }}>
                            <Card sx={{ borderRadius: '8px' }}>
                                <Stack direction="row" spacing={1} sx={{ alignItems: 'center', padding: '9px' }}>
                                    <ShapeIconCard
                                        iconPrimary={<EngineeringIcon />}
                                        color="#A86AF8"
                                        sx={{
                                            width: '25px',
                                            height: '25px',
                                            '& span': {
                                                width: '18px',
                                                height: '18px'
                                            },
                                            '& svg': {
                                                width: '18px',
                                                height: '18px',
                                                position: 'relative',
                                                zIndex: '5'
                                            }
                                        }}
                                    />
                                    <Stack direction="row" spacing={0.5}>
                                        <Typography sx={{ fontFamily: ['Figtree'], fontSize: '10px', fontWeight: 700, color: '#A86AF8' }}>
                                            1200+
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: ['Figtree'],
                                                fontSize: '10px',
                                                fontWeight: 700,
                                                color: '#1E1E1E'
                                            }}
                                        >
                                            Hired
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Box>
                    </Box>
                    <Stack direction="column" spacing={1} sx={{ textAlign: 'left', mt: 5, ml: 5 }}>
                        <motion.div
                            initial={{ opacity: 0, translateY: 550 }}
                            animate={{ opacity: 1, translateY: 0 }}
                            transition={{
                                type: 'spring',
                                stiffness: 150,
                                damping: 30
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 500,
                                    fontFamily: ['Figtree'],
                                    color: 'white',
                                    textAlign: { xs: 'center', md: 'left' }
                                }}
                            >
                                Customer Testimonials
                            </Typography>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, translateY: 550 }}
                            animate={{ opacity: 1, translateY: 0 }}
                            transition={{
                                type: 'spring',
                                stiffness: 150,
                                damping: 30
                            }}
                        >
                            <Typography
                                variant={{ xs: 'h1', md: 'h2' }}
                                sx={{
                                    fontSize: { xs: '2.25rem', sm: '2.5rem', md: '2rem' },
                                    fontWeight: 700,
                                    fontFamily: ['Figtree'],
                                    color: 'white',
                                    lineHeight: 0.8,
                                    textAlign: { xs: 'center', md: 'left' }
                                }}
                            >
                                What our users say
                            </Typography>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, translateY: 550 }}
                            animate={{ opacity: 1, translateY: 0 }}
                            transition={{
                                type: 'spring',
                                stiffness: 150,
                                damping: 30
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 400,
                                    fontFamily: ['Figtree'],
                                    color: 'white',
                                    lineHeight: 1.2,
                                    textAlign: { xs: 'center', md: 'justify' },
                                    [theme.breakpoints.only('lg')]: { width: '80%' }
                                }}
                            >
                                Discover how Oures has transformed the careers of individuals just like you. Register now to unlock a world
                                of opportunities and take the next step towards your professional goals!
                            </Typography>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, translateY: 550 }}
                            animate={{ opacity: 1, translateY: 0 }}
                            transition={{
                                type: 'spring',
                                stiffness: 150,
                                damping: 30,
                                delay: 0.4
                            }}
                        >
                            <AnimateButton>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        borderColor: 'white',
                                        color: 'white',
                                        alignSelf: 'flex-end',
                                        marginTop: '10px',
                                        borderRadius: '200px',
                                        textTransform: 'none',
                                        fontWeight: 600,
                                        fontFamily: ['Figtree'],
                                        fontSize: { xs: '10px', sm: '11px', md: '12px' },
                                        width: 'fit-content',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            borderColor: 'white',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    Register Now
                                </Button>
                            </AnimateButton>
                        </motion.div>
                    </Stack>
                    <Box sx={{ display: 'flex', marginLeft: 20, mt: 5 }}>
                        <Card sx={{ borderRadius: '8px' }}>
                            <Stack direction="row" spacing={1} sx={{ alignItems: 'center', padding: '7px' }}>
                                <ShapeIconCard
                                    iconPrimary={<img src={IconDatabase} alt="Resources" />}
                                    color="#1276D1"
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        paddingRight: '2px',
                                        '& span': {
                                            width: '15px',
                                            height: '15px'
                                        },
                                        '& svg': {
                                            width: '15px',
                                            height: '15px',
                                            position: 'relative',
                                            zIndex: '5'
                                        }
                                    }}
                                />
                                <Stack direction="row" spacing={0.5}>
                                    <Typography sx={{ fontFamily: ['Figtree'], fontSize: '8px', fontWeight: 700, color: '#1276D1' }}>
                                        1200+
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: ['Figtree'],
                                            fontSize: '8px',
                                            fontWeight: 700,
                                            color: '#1E1E1E'
                                        }}
                                    >
                                        Resources
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default HeaderTestimoniesPage;
