// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const storageKey = {
    USER_ROLE: 'userRole',
    USER_INFO: 'userInfo',
    USER_ROLE_NAME: 'userRoleName',
    USER_TOKEN: 'userToken',
    IS_LOGGED_ID: 'isLoggedIn',
    USER_PERMISSION: ""
};
