// material-ui
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { OpenInNewRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import { deleteCompetences } from 'utils/services/competences';
import { useDispatch } from 'react-redux';
import { hasPermission } from 'helper/general';
import useRootPermission from 'hooks/useRootPermission';
// ==============================|| USER LIST 1 ||============================== //

const CompetencesList = ({ competences = [], handleRefresh, loading = true }) => {
    // const [open, setOpen] = useState(false);
    // const [message, setMessage] = useState('');
    // const [floatingPayload, setFloatingPayload] = useState({ id: '', is_active: false });
    const dispatch = useDispatch();

    const [granted, allPermission] = useRootPermission(['Competences@competences_getpost']);

    const handleDelete = async (id) => {
        deleteCompetences({ id })
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    handleRefresh()
                }
                else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {competences && competences.length > 0 ? (
                                    competences.map((row, index) => (
                                        <TableRow hover key={index}>
                                             <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                {hasPermission(['Competences@competences_getputdel'], allPermission) && (
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    <Tooltip placement="top" title="Edit Competences">
                                                        <Link to={`form?action=update&identify=${row.id}`}>
                                                            <IconButton color="primary" aria-label="open" size="large">
                                                                <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete Competences">
                                                        <IconButton onClick={() => handleDelete(row.id)} color="primary" aria-label="delete" size="large">
                                                            <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={5} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={5} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default CompetencesList;
