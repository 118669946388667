import { Box, Grid, TextField, Button, Stack, Typography, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { IconUser } from '@tabler/icons';
import { changePassword } from 'utils/services/account-setting';
import ConfirmationModal from 'ui-component/confirmation';
import MainCard from 'ui-component/cards/MainCard';

const AccountSetting = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disable, setDisable] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        old_password: '',
        new_password: '',
        confirm_new_password: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await changePassword({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    navigate('/profile');
                    setLoading(false);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Failed Change Password',
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        if (payload.confirm_new_password === '' || payload.new_password === '' || payload.old_password === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <MainCard
            title={
                <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                    <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                        <IconUser color="#fff" />
                    </Avatar>
                    <Stack direction="column">
                        <Typography variant="h3">Change Password</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Please fill the form bellow
                        </Typography>
                    </Stack>
                </Stack>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/profile')}
            />

            <Box component="form" autoComplete="off" onSubmit={(ev) => handleSubmit(ev)}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item container xs={12} md={6} gap={2}>
                        <TextField
                            required
                            fullWidth
                            label="Old Password"
                            value={payload.old_password}
                            onChange={(e) => setPayload({ ...payload, old_password: e.target.value })}
                        />
                        <TextField
                            required
                            fullWidth
                            label="New Password"
                            value={payload.new_password}
                            onChange={(e) => setPayload({ ...payload, new_password: e.target.value })}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Confirm New Password"
                            value={payload.confirm_new_password}
                            onChange={(e) => setPayload({ ...payload, confirm_new_password: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="center">
                            <Button variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button disabled={loading || disable} variant="contained" type="submit" disableElevation>
                                {loading ? 'Loading' : 'Change Password'}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default AccountSetting;
