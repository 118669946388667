import { Button, CardContent, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { styled, useTheme } from '@mui/styles';

import AnimateButton from 'ui-component/extended/AnimateButton';
import dashboard from 'assets/svg/OuresLandingTop.svg';
import backgroundImg from 'assets/svg/BGforLandingTop.svg';

const HeaderImage = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    borderRadius: '20px',
    [theme.breakpoints.up('lg')]: {
        transform: 'scale(1.1)'
    }
}));

const HeaderPage = () => {
    const theme = useTheme();
    return (
        <CardContent
            sx={{
                mt: 5,
                backgroundImage: `url(${backgroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: { xs: '40vh', md: '60vh' },
                display: 'flex',
                alignItems: 'center',
                pr: 5,
                pb: 10
            }}
        >
            <Stack direction="row" justifyContent="space-between" spacing={1} sx={{ alignItems: 'center' }}>
                <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                        textAlign: 'left',
                        pr: 10,
                        width: '50%',
                        [theme.breakpoints.down('md')]: { p: 0, textAlign: 'center', width: '100%' },
                        [theme.breakpoints.only('md')]: { width: '40%' },
                        [theme.breakpoints.only('xl')]: { width: '40%' }
                    }}
                >
                    <motion.div
                        initial={{ opacity: 0, translateY: 550 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{
                            type: 'spring',
                            stiffness: 150,
                            damping: 30
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 500, fontFamily: ['Figtree'], color: 'white', textAlign: { xs: 'center', md: 'left' } }}
                        >
                            Connecting Talent to Opportunities
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, translateY: 550 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{
                            type: 'spring',
                            stiffness: 150,
                            damping: 30
                        }}
                    >
                        <Typography
                            variant={{ xs: 'h1', md: 'h2' }}
                            sx={{
                                fontSize: { xs: '2.25rem', sm: '2.5rem', md: '2rem' },
                                fontWeight: 700,
                                fontFamily: ['Figtree'],
                                color: 'white',
                                lineHeight: 0.8,
                                textAlign: { xs: 'center', md: 'left' }
                            }}
                        >
                            Unlock Your Career Potential
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, translateY: 550 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{
                            type: 'spring',
                            stiffness: 150,
                            damping: 30
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 400,
                                fontFamily: ['Figtree'],
                                color: 'white',
                                lineHeight: 1.2,
                                textAlign: { xs: 'center', md: 'justify' },
                                [theme.breakpoints.only('lg')]: { width: '80%' }
                            }}
                        >
                            Welcome to <strong>Oures</strong>, where career aspirations meet limitless possibilities! Are you ready to take
                            the next step in your professional journey? Look no further — we&apos;re here to bridge the gap between your
                            skills and the perfect job opportunity.
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, translateY: 550 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{
                            type: 'spring',
                            stiffness: 150,
                            damping: 30,
                            delay: 0.4
                        }}
                    >
                        <AnimateButton>
                            <Button
                                variant="outlined"
                                sx={{
                                    borderColor: 'white',
                                    color: 'white',
                                    alignSelf: 'flex-end',
                                    marginTop: '10px',
                                    borderRadius: '200px',
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    fontFamily: ['Figtree'],
                                    fontSize: { xs: '10px', sm: '11px', md: '12px' },
                                    width: 'fit-content',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        borderColor: 'white',
                                        color: 'white'
                                    }
                                }}
                            >
                                Explore
                            </Button>
                        </AnimateButton>
                    </motion.div>
                </Stack>
                <Stack sx={{ display: { md: 'flex', xs: 'none' }, width: { md: '60%', lg: '45%', xl: '40%' } }}>
                    <HeaderImage src={dashboard} alt="Oures" />
                </Stack>
            </Stack>
        </CardContent>
    );
};

export default HeaderPage;
