import { Box, Button, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { changeScheduleRegistration, getOneRegistration } from 'utils/services/registration-requests';
import moment from 'moment';
import DialogModal from 'ui-component/dialogModal';

const DateModal = ({ open, handleClose, item, handleRefresh }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [load, setLoad] = useState(false);
    const [list, setList] = useState({});
    const [payload, setPayload] = useState({
        status: 'pending',
        email: '',
        meeting_date: ''
    });

    const getItem = async () => {
        setLoad(true);
        await getOneRegistration(item.id)
            .then((res) => {
                if (res && res.success) {
                    setList(res.data);
                    setLoad(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        changeScheduleRegistration({ id: item.id, payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoad(false);
                    handleClose();
                    navigate('/registration-request');
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    handleClose();
                    navigate('/registration-request');
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => handleRefresh());
    };

    useEffect(() => {
        getItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (list) {
            setPayload({ ...payload, email: list.email, meeting_date: moment.utc(list.avaliable_at).format('YYYY-MM-DDTHH:mm') });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    return (
        <DialogModal open={open} handleClose={handleClose}>
            <Stack justifyContent="start" alignItems="start">
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Set Meeting Schedule
                </Typography>
                <Box component="form" onSubmit={(e) => handleSubmit(e)} justifyContent="center" sx={{ width: '100%', height: '100%' }}>
                    {load ? (
                        <Skeleton variant="rectangular" sx={{ borderRadius: '12px', width: '336px', height: '51.13px' }} />
                    ) : (
                        <TextField
                            focused
                            required
                            id="input-registration-date"
                            label="Assignment Schedule"
                            type="datetime-local"
                            fullWidth
                            value={payload.meeting_date}
                            onChange={(e) => {
                                setPayload({ ...payload, meeting_date: e.target.value });
                            }}
                            inputProps={{
                                min: moment.utc(list.avaliable_at).format('YYYY-MM-DDTHH:mm'),
                                max: moment.utc(list.avaliable_until).format('YYYY-MM-DDTHH:mm')
                            }}
                        />
                    )}
                    <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{ width: '100%', mt: 2 }}>
                        <Button size="small" id="button-registration-cancel" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            size="small"
                            disabled={load}
                            id="button-registration-submit"
                            variant="contained"
                            disableElevation
                        >
                            {load ? 'Loading' : 'Submit'}
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </DialogModal>
    );
};

export default DateModal;
