/* eslint-disable import/prefer-default-export */
import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getListTicket = async ({ page, limit }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/support?page=${page}&limit=${limit}`, config);
};
export const deleteTicketbyId = async ({ id }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/support/${id}}`, config);
};
export const postNewTicket = async ({ payload }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.post(`${process.env.REACT_APP_API_URL}/support`, { ...payload }, config);
};
export const getTicketDetailbyId = async (id) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/support/detail/${id}`, config);
};
export const postTicketReply = async ({ id, message }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.post(`${process.env.REACT_APP_API_URL}/support/reply/${id}`, { message }, config);
};
export const updateStatusTicket = async (id) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.put(`${process.env.REACT_APP_API_URL}/support/status/${id}`, { status: 1 }, config);
};
