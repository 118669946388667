import {
    Chip,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { QuestionAnswerTwoTone, CheckCircleOutlineTwoTone, RemoveCircleOutlineTwoTone } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import TableLoader from 'ui-component/loader/TableLoader';
import NegotiationContractModal from '../Modal/NegotiationContractModal';
import NegotiationLetter from '../Modal/NegotiationLetterModal';
import TableEmpty from 'ui-component/loader/TableEmpty';

function TableOnProcess({ startIndex, open, hasPermission, allPermission, setOpen, lists, loading, setLoading, handleRefresh }) {
    const theme = useTheme('oures');

    const [data, setData] = useState('');
    const [quot, setQuot] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <>
            {open === 'negotiateContract' && (
                <NegotiationContractModal
                    open={open === 'negotiateContract'}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={(e) => setSelectedIndex(e)}
                />
            )}
            {open === 'negotiateLetters' && (
                <NegotiationLetter
                    open={open === 'negotiateLetters'}
                    row={data}
                    setQuot={(e) => setQuot(e)}
                    handleClose={() => setOpen(false)}
                    loading={loading}
                    setLoading={setLoading}
                    handleRefresh={handleRefresh}
                    quot={quot}
                />
            )}

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 3 }}>
                                No.
                            </TableCell>
                            <TableCell>Resource Name</TableCell>
                            <TableCell>Request Title</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Quotation</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? (
                        <>
                            {lists && lists.length > 0 ? (
                                lists.map((row, index) => (
                                    <TableBody>
                                        <TableRow key={index}>
                                            <TableCell align="center" sx={{ pl: 3 }}>
                                                <Typography variant="h6">{startIndex + index}.</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" noWrap>
                                                    {row.resource_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2" noWrap>
                                                    {row.request_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Grid sx={{ display: 'flex' }} alignItems="center">
                                                    {row.quotations?.length === 0 ? (
                                                        <Typography variant="subtitle2">Start Negotiation</Typography>
                                                    ) : row.quotations[row.quotations.length - 1]?.status === 0 ? (
                                                        <Typography variant="subtitle2">On Negotiation of Quotation</Typography>
                                                    ) : row.quotations[row.quotations.length - 1]?.status === 1 ? (
                                                        <>
                                                            {row.quotations[row.quotations.length - 1]?.file_nda &&
                                                            row.quotations[row.quotations.length - 1]?.file_ta ? (
                                                                <Typography variant="subtitle2">On Negotiation of NDA and TA</Typography>
                                                            ) : row.quotations[row.quotations.length - 1]?.file_ktp ? (
                                                                <>
                                                                    <IconButton
                                                                        disabled
                                                                        size="large"
                                                                        sx={{
                                                                            padding: 0,
                                                                            paddingRight: '5px',
                                                                            hover: 'none'
                                                                        }}
                                                                    >
                                                                        <CheckCircleOutlineTwoTone
                                                                            sx={{ fontSize: 'small', color: '#64DD17' }}
                                                                        />
                                                                    </IconButton>
                                                                    <Typography variant="subtitle2">ID Card Uploaded</Typography>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <IconButton
                                                                        disabled
                                                                        size="large"
                                                                        sx={{
                                                                            padding: 0,
                                                                            paddingRight: '5px',
                                                                            hover: 'none'
                                                                        }}
                                                                    >
                                                                        <CheckCircleOutlineTwoTone
                                                                            sx={{ fontSize: 'small', color: '#64DD17' }}
                                                                        />
                                                                    </IconButton>
                                                                    <Typography variant="subtitle2">Quotation Accepted</Typography>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : row.quotations[row.quotations.length - 1]?.status === 2 ? (
                                                        <>
                                                            <IconButton
                                                                disabled
                                                                size="large"
                                                                sx={{
                                                                    padding: 0,
                                                                    paddingRight: '5px',
                                                                    hover: 'none'
                                                                }}
                                                            >
                                                                <RemoveCircleOutlineTwoTone sx={{ fontSize: 'small', color: '#d32f2f' }} />
                                                            </IconButton>
                                                            <Typography variant="subtitle2">Quotation Rejected</Typography>
                                                        </>
                                                    ) : row.quotations[row.quotations.length - 1]?.status === 3 ? (
                                                        <>
                                                            {row.quotations[row.quotations.length - 1]?.file_contract &&
                                                            row.quotations[row.quotations.length - 1]?.file_spk ? (
                                                                <Typography variant="subtitle2">
                                                                    On Negotiation of Contract and SPK
                                                                </Typography>
                                                            ) : (
                                                                <>
                                                                    <IconButton
                                                                        disabled
                                                                        size="large"
                                                                        sx={{
                                                                            padding: 0,
                                                                            paddingRight: '5px',
                                                                            hover: 'none'
                                                                        }}
                                                                    >
                                                                        <CheckCircleOutlineTwoTone
                                                                            sx={{ fontSize: 'small', color: '#64DD17' }}
                                                                        />
                                                                    </IconButton>
                                                                    <Typography variant="subtitle2">NDA and TA Accepted</Typography>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : row.quotations[row.quotations.length - 1]?.status === 4 ? (
                                                        <>
                                                            <IconButton
                                                                disabled
                                                                size="large"
                                                                sx={{
                                                                    padding: 0,
                                                                    paddingRight: '5px',
                                                                    hover: 'none'
                                                                }}
                                                            >
                                                                <RemoveCircleOutlineTwoTone sx={{ fontSize: 'small', color: '#d32f2f' }} />
                                                            </IconButton>
                                                            <Typography variant="subtitle2">NDA and TA Rejected</Typography>
                                                        </>
                                                    ) : row.quotations[row.quotations.length - 1]?.status === 5 ? (
                                                        <>
                                                            <IconButton
                                                                disabled
                                                                size="large"
                                                                sx={{
                                                                    padding: 0,
                                                                    paddingRight: '5px',
                                                                    hover: 'none'
                                                                }}
                                                            >
                                                                <CheckCircleOutlineTwoTone sx={{ fontSize: 'small', color: '#64DD17' }} />
                                                            </IconButton>
                                                            <Typography variant="subtitle2">CA and SPK Accepted</Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconButton
                                                                disabled
                                                                size="large"
                                                                sx={{
                                                                    padding: 0,
                                                                    paddingRight: '5px'
                                                                }}
                                                            >
                                                                <RemoveCircleOutlineTwoTone sx={{ fontSize: 'small', color: '#d32f2f' }} />
                                                            </IconButton>
                                                            <Typography variant="subtitle2">CA and SPK Declined</Typography>
                                                        </>
                                                    )}
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                {row.quotations.length > 0 ? (
                                                    <Chip
                                                        row={row}
                                                        index={index}
                                                        label="View Negotiation"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpen('negotiateLetters');
                                                            setData(row);
                                                        }}
                                                        sx={{
                                                            background: theme.palette.primary.light + 60,
                                                            color: theme.palette.primary.dark
                                                        }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                {row.quotations[row.quotations.length - 1]?.status === 0 ? (
                                                    <IconButton disabled color="primary" size="large" sx={{ padding: 0 }}>
                                                        <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                ) : row.quotations[row.quotations.length - 1]?.status === 1 &&
                                                  row.quotations[row.quotations.length - 1]?.file_nda &&
                                                  row.quotations[row.quotations.length - 1]?.file_ta ? (
                                                    <IconButton disabled color="primary" size="large" sx={{ padding: 0 }}>
                                                        <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                ) : !row.quotations.length ? (
                                                    <>
                                                        {hasPermission(['FormRequestAssignment@store_assignment'], allPermission) && (
                                                            <Tooltip placement="top" title="Start Negotiation">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setOpen('negotiateContract');
                                                                        setData(row);
                                                                        setSelectedIndex(0);
                                                                    }}
                                                                    color="primary"
                                                                    size="large"
                                                                    sx={{ padding: 0 }}
                                                                >
                                                                    <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                ) : !row.quotations[row.quotations.length - 1]?.file_ktp ? (
                                                    <Tooltip placement="top" title="Upload ID Card">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('negotiateContract');
                                                                setData(row);
                                                                setSelectedIndex(1);
                                                            }}
                                                            color="primary"
                                                            size="large"
                                                            sx={{ padding: 0 }}
                                                        >
                                                            <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : !row.quotations[row.quotations.length - 1]?.file_nda ? (
                                                    <Tooltip placement="top" title="Negotiate on NDA">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('negotiateContract');
                                                                setData(row);
                                                                setSelectedIndex(2);
                                                            }}
                                                            color="primary"
                                                            size="large"
                                                            sx={{ padding: 0 }}
                                                        >
                                                            <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : !row.quotations[row.quotations.length - 1]?.file_ta ? (
                                                    <Tooltip placement="top" title="Negotiate on TA">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('negotiateContract');
                                                                setData(row);
                                                                setSelectedIndex(3);
                                                            }}
                                                            color="primary"
                                                            size="large"
                                                            sx={{ padding: 0 }}
                                                        >
                                                            <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : !row.quotations[row.quotations.length - 1]?.file_contract ? (
                                                    <Tooltip placement="top" title="Negotiate on Contract">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('negotiateContract');
                                                                setData(row);
                                                                setSelectedIndex(4);
                                                            }}
                                                            color="primary"
                                                            size="large"
                                                            sx={{ padding: 0 }}
                                                        >
                                                            <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : !row.quotations[row.quotations.length - 1]?.file_spk ? (
                                                    <Tooltip placement="top" title="Negotiate on SPK">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('negotiateContract');
                                                                setData(row);
                                                                setSelectedIndex(5);
                                                            }}
                                                            color="primary"
                                                            size="large"
                                                            sx={{ padding: 0 }}
                                                        >
                                                            <QuestionAnswerTwoTone sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))
                            ) : (
                                <TableEmpty colSpan={7} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={7} />
                    )}
                </Table>
            </TableContainer>
        </>
    );
}

export default TableOnProcess;
