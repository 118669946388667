import { Box, Chip, Grid, Link, Stack, Typography } from '@mui/material';
import { GppGood, GroupsOutlined, HomeWorkOutlined, LaunchOutlined, PinDropOutlined } from '@mui/icons-material';

import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';

const SubCardClient = ({ client }) => (
    <SubCard
        title={
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Avatar sx={{ width: 60, height: 60, fontSize: '26px' }}>{client.company_name?.slice(0, 3)}</Avatar>
                </Grid>
                <Grid item>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={0.5}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={0.5}>
                            <Typography align="left" variant="subtitle1">
                                {client.company_name}
                            </Typography>
                            <GppGood sx={{ fontSize: '1.35rem', color: '#4C86DD' }} />
                            <Chip
                                size="small"
                                label={client.is_active === true ? 'Available' : 'Not Available'}
                                sx={{
                                    color: 'white',
                                    width: 'fit-content',
                                    fontWeight: 400,
                                    backgroundColor: client.is_active === true ? '#11A797' : '#BEBEBE'
                                }}
                            />
                        </Box>
                        <Link href="https://oures.id/" target="_blank" rel="noopener noreferrer" underline="hover">
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={0.5}>
                                <LaunchOutlined style={{ fontSize: 10, color: '#4C86DD' }} />
                                <Typography align="left" variant="subtitle2" sx={{ color: '#4C86DD' }}>
                                    https://oures.id/
                                </Typography>
                            </Box>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        }
    >
        <Box
            sx={{
                backgroundColor: '#EEF2F6',
                borderRadius: '8px',
                justifyContent: 'space-between',
                p: 2,
                display: 'flex',
                flexDirection: 'row',
                width: 'fit-content'
            }}
            gap={3}
        >
            <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                    <PinDropOutlined sx={{ fontSize: '1rem', color: '#212121' }} />
                    <Typography variant="subtitle1">Location</Typography>
                </Stack>
                <Typography variant="subtitle2">{`${client.address}, ${client.city?.capital_city}, ${client.city?.city}`}</Typography>
            </Stack>
            <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                    <HomeWorkOutlined sx={{ fontSize: '1.1rem', color: '#212121' }} />
                    <Typography variant="subtitle1">Industry</Typography>
                </Stack>
                <Typography variant="subtitle2">Informasi dan Teknologi</Typography>
            </Stack>
            <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                    <GroupsOutlined sx={{ fontSize: '1.1rem', color: '#212121' }} />
                    <Typography variant="subtitle1">Company Size</Typography>
                </Stack>
                <Typography variant="subtitle2">100 - 250 Employees</Typography>
            </Stack>
        </Box>
    </SubCard>
);

export default SubCardClient;
