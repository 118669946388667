// material-ui
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { OpenInNewRounded } from '@mui/icons-material';
import { SNACKBAR_OPEN } from 'store/actions';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCategory } from 'utils/services/categories';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';

const CategoryList = ({ category = [], loading = true, refresh }) => {
    const dispatch = useDispatch();

    const [granted, allPermission] = useRootPermission(['Category@category_getpost']);

    const handleDelete = async (id) => {
        deleteCategory({ id }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
            }
            refresh();
        });
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Category Name</TableCell>
                        <TableCell>Category Detail</TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading ? (
                        <>
                            {category && category.length > 0 ? (
                                category.map((row, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.category_title}</TableCell>
                                        <TableCell>{row.category_detail}</TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            {hasPermission(['Category@category_getputdel'], allPermission) && (
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    <>
                                                        <Tooltip placement="top" title="Edit Skill">
                                                            <Link to={`form?action=update&identify=${row.id}`}>
                                                                <IconButton color="primary" aria-label="open" size="large">
                                                                    <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Delete Skill">
                                                            <IconButton
                                                                onClick={() => handleDelete(row.id)}
                                                                color="primary"
                                                                aria-label="delete"
                                                                size="large"
                                                            >
                                                                <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableEmpty colSpan={6} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={6} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CategoryList;
