import dashboard from './dashboard';
import master from './master';
import other from './other';
import log from './log';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, other, master, log]
};

export default menuItems;
