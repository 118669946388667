function IconFileSave(props) {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="a"
                style={{
                    maskType: 'alpha'
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={16}
                height={16}
            >
                <path fill="#D9D9D9" d="M0 0H16V16H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M12 14l2.667-2.667-.934-.933-1.066 1.067V8.683h-1.334v2.784L10.267 10.4l-.934.933L12 14zm-2.667 2v-1.333h5.334V16H9.333zM4 13.333c-.367 0-.68-.13-.942-.391A1.284 1.284 0 012.667 12V2.667c0-.367.13-.68.391-.942.261-.261.575-.392.942-.392h4.667l4 4V7.35h-1.334V6H8V2.667H4V12h4v1.333H4z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default IconFileSave;
