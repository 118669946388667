import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, Grid, IconButton, Menu, MenuItem, Pagination, Stack, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconLifebuoy } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { AddCircleTwoTone } from '@mui/icons-material';
import { hasPermission } from 'helper/general';
import { getListTicket } from 'utils/services/helpdesk';

import MainCard from 'ui-component/cards/MainCard';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import useRootPermission from 'hooks/useRootPermission';
import RootPermission from '../permission';
import HelpdeskList from './HelpdeskList';

const HelpdeskPage = () => {
    const theme = useTheme('oures');

    const [helpdesks, setHelpdesks] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [granted, allPermission] = useRootPermission(['HelpDesk@index']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchListTicket = async () => {
        setLoading(true);
        await getListTicket({ page, limit })
            .then((res) => {
                if (res && res.success) {
                    setHelpdesks(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchListTicket();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit]);

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconLifebuoy color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3">Help Desk</Typography>
                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                        Please let us know your problem or issue
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            {hasPermission(['HelpDesk@create'], allPermission) && (
                                <Link to="/helpdesk/form">
                                    <IconButton>
                                        <AddCircleTwoTone />
                                    </IconButton>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <HelpdeskList
                    helpdesks={helpdesks}
                    handleRefresh={() => fetchListTicket()}
                    loading={loading}
                    setLoading={setLoading}
                    startIndex={(page - 1) * limit + 1}
                />
                <Grid container justifyContent="space-between" spacing={gridSpacing} sx={{ p: 3 }}>
                    <Grid item>
                        <Pagination
                            onChange={(e, value) => setPage(value)}
                            count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                            color="primary"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            {limit} Rows
                        </Button>
                        <Menu
                            id="menu-user-list-style1"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={() => setLimit(10)}> 10 Rows</MenuItem>
                            <MenuItem onClick={() => setLimit(25)}> 25 Rows</MenuItem>
                            <MenuItem onClick={() => setLimit(50)}> 50 Rows</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default HelpdeskPage;
