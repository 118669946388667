import { useState, useEffect } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import PersonCard from './Card/PersonCard';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';

// ==============================|| USER CARD STYLE 2 ||============================== //

const PersonInCharge = () => {
    const [users, setUsers] = useState([]);
    const { id } = useParams();

    const [granted, allPermission] = useRootPermission(['Client@client_getputdel']);

    const getUsers = async () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/client/pic/list?page=1&limit=100&client_id=${id}`, config).then((res) => {
            if (res) {
                console.log(res);
                setUsers(res.data.data);
            }
        });
    };

    useEffect(() => {
        getUsers();
    }, []); //eslint-disable-line

    return (
        <MainCard>
            <Grid container direction="row" spacing={gridSpacing}>
                {users &&
                    users.map((user, index) => (
                        <Grid key={index} item xs={12} sm={12} md={4} lg={4}>
                            {hasPermission(['Client@list_pic'], allPermission) && <PersonCard {...user} />}
                        </Grid>
                    ))}
                {/* <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination count={5} color="primary" />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                10 Rows
                            </Button>
                            <Menu
                                id="menu-user-card-style2"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={handleClose}> 10 Rows</MenuItem>
                                <MenuItem onClick={handleClose}> 20 Rows</MenuItem>
                                <MenuItem onClick={handleClose}> 30 Rows </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </MainCard>
    );
};

export default PersonInCharge;
