/* eslint-disable no-unused-vars */
import {
    Avatar,
    Chip,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Box,
    Skeleton,
    Card
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getRequestById } from 'utils/services/requests';
import SubCard from 'ui-component/cards/SubCard';
import moment from 'moment';

const RequestDetail = () => {
    const { id } = useParams();
    const theme = useTheme('oures');
    const [detail, setDetail] = useState({ client: {} });
    const [loading, setLoading] = useState(false);

    const fetchDetail = async () => {
        setLoading(true);
        await getRequestById(id)
            .then((res) => {
                if (res && res.success) {
                    setDetail(res.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        fetchDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container direction="column" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <SubCard>
                            <Box>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    {!loading ? <Avatar /> : <Skeleton variant="circular" width={40} height={40} />}
                                    <Stack direction="column">
                                        {!loading ? (
                                            <>
                                                <Typography variant="h2">{detail.client.company_name}</Typography>
                                                <Typography>{detail.client.address}</Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Skeleton
                                                    variant="rectangular"
                                                    width="401.03px"
                                                    height="28px"
                                                    sx={{ borderRadius: '12px', mb: 0.5 }}
                                                />
                                                <Skeleton
                                                    variant="rectangular"
                                                    width="401.03px"
                                                    height="18px"
                                                    sx={{ borderRadius: '12px' }}
                                                />
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Box>
                        </SubCard>
                    </Grid>
                    <Grid item xs={12}>
                        <SubCard
                            title={
                                !loading ? (
                                    detail.request_name
                                ) : (
                                    <Skeleton variant="rectangular" width="401.03px" height="18px" sx={{ borderRadius: '12px' }} />
                                )
                            }
                        >
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Table sx={{ '& td': { borderBottom: 'none' } }} size="small">
                                            {!loading ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell variant="head" width="120px">
                                                            Needs
                                                        </TableCell>
                                                        <TableCell width="2px">:</TableCell>
                                                        <TableCell>{detail.needs}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" width="120px">
                                                            Created At
                                                        </TableCell>
                                                        <TableCell width="2px">:</TableCell>
                                                        <TableCell>{`${moment(detail.created_at).format(
                                                            'D MMMM YYYY, h:mm'
                                                        )} WIB`}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" width="120px">
                                                            Overdue
                                                        </TableCell>
                                                        <TableCell width="2px">:</TableCell>
                                                        <TableCell>
                                                            {`${moment(detail.order_end_date).format('D MMMM YYYY, h:mm')} WIB`}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" width="120px">
                                                            Status
                                                        </TableCell>
                                                        <TableCell width="2px">:</TableCell>
                                                        <TableCell>
                                                            {detail.status === 0 && (
                                                                <Chip
                                                                    label="Pending"
                                                                    size="small"
                                                                    sx={{
                                                                        background:
                                                                            theme.palette.mode === 'dark'
                                                                                ? theme.palette.dark.main
                                                                                : theme.palette.info.light + 60,
                                                                        color: theme.palette.info.dark
                                                                    }}
                                                                />
                                                            )}
                                                            {detail.status === 1 && (
                                                                <Chip
                                                                    label="On Progress"
                                                                    size="small"
                                                                    sx={{
                                                                        background:
                                                                            theme.palette.mode === 'dark'
                                                                                ? theme.palette.dark.main
                                                                                : theme.palette.success.light + 60,
                                                                        color: theme.palette.success.dark
                                                                    }}
                                                                />
                                                            )}
                                                            {detail.status === 2 && (
                                                                <Chip
                                                                    label="Rejected"
                                                                    size="small"
                                                                    sx={{
                                                                        background:
                                                                            theme.palette.mode === 'dark'
                                                                                ? theme.palette.dark.main
                                                                                : theme.palette.orange.light + 80,
                                                                        color: theme.palette.orange.dark
                                                                    }}
                                                                />
                                                            )}
                                                            {detail.status === 3 && (
                                                                <Chip
                                                                    label="Cancelled By Client"
                                                                    size="small"
                                                                    sx={{
                                                                        background:
                                                                            theme.palette.mode === 'dark'
                                                                                ? theme.palette.dark.main
                                                                                : theme.palette.warning.light,
                                                                        color: theme.palette.warning.dark
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {[1, 2, 3, 4].map((index) => (
                                                        <TableRow key={index}>
                                                            <TableCell width="120px">
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height="14px"
                                                                    sx={{ borderRadius: '12px', my: '5px' }}
                                                                />
                                                            </TableCell>
                                                            <TableCell width="2px">:</TableCell>
                                                            <TableCell>
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    width="200px"
                                                                    height="14px"
                                                                    sx={{ borderRadius: '12px' }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                    <Grid item xs={12}>
                        <SubCard
                            title={
                                !loading ? (
                                    'Request Criteria'
                                ) : (
                                    <Skeleton variant="rectangular" width="401.03px" height="18px" sx={{ borderRadius: '12px' }} />
                                )
                            }
                        >
                            <Grid container xs={12} gap={1.5} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {!loading
                                    ? detail.criteria &&
                                      detail.criteria.length > 0 &&
                                      detail.criteria.map((item, index) => (
                                          <Grid item key={index}>
                                              <Card
                                                  sx={{
                                                      p: '25px',
                                                      borderRadius: '12px',
                                                      boxShadow: '0px 7px 19.3px 0px rgb(0 0 0 / 3%)',
                                                      border: '1px solid #EEF2F6'
                                                  }}
                                              >
                                                  <Stack direction="column">
                                                      <Typography variant="h4">
                                                          {item.needs} {item.skill.name} Developer
                                                      </Typography>
                                                      <Typography variant="subtitle">
                                                          {item.min_experience}-{item.max_experience} years experience
                                                      </Typography>
                                                      <Typography variant="subtitle" sx={{ mt: 1 }}>
                                                          {item.notes}
                                                      </Typography>
                                                  </Stack>
                                              </Card>
                                          </Grid>
                                      ))
                                    : [1, 2, 3, 4].map((index) => (
                                          <Grid item key={index}>
                                              <Skeleton variant="rectangular" width="193px" height="98px" sx={{ borderRadius: '12px' }} />
                                          </Grid>
                                      ))}
                            </Grid>
                        </SubCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RequestDetail;
