function IconPinDrop({ color = '#454348', width = 16, height = 17, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="a"
                style={{
                    maskType: 'alpha'
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={width}
                height={height}
            >
                <path fill="#D9D9D9" d="M0 0.5H16V16.5H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M8 11.483c1.1-.889 1.93-1.744 2.492-2.566.56-.823.841-1.595.841-2.317 0-.622-.114-1.153-.341-1.592a3.338 3.338 0 00-.842-1.066 3.238 3.238 0 00-1.083-.592A3.585 3.585 0 008 3.167c-.322 0-.678.06-1.067.183-.389.122-.75.32-1.083.592a3.337 3.337 0 00-.842 1.066c-.227.44-.341.97-.341 1.592 0 .722.28 1.494.841 2.317C6.07 9.739 6.9 10.594 8 11.483zm0 1.684C6.433 12.01 5.264 10.889 4.492 9.8c-.773-1.089-1.159-2.156-1.159-3.2 0-.789.142-1.48.425-2.075A4.695 4.695 0 014.85 3.033c.444-.4.944-.7 1.5-.9.556-.2 1.106-.3 1.65-.3.544 0 1.094.1 1.65.3.556.2 1.056.5 1.5.9.444.4.808.898 1.092 1.492.283.594.425 1.286.425 2.075 0 1.044-.386 2.111-1.159 3.2-.772 1.089-1.941 2.211-3.508 3.367zm0-5.334c.367 0 .68-.13.942-.391.26-.261.391-.575.391-.942s-.13-.68-.391-.942A1.284 1.284 0 008 5.167c-.367 0-.68.13-.942.391a1.284 1.284 0 00-.391.942c0 .367.13.68.391.942.261.26.575.391.942.391zm-4.667 7.334v-1.334h9.334v1.334H3.333z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default IconPinDrop;
