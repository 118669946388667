import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RequestListPage from 'views/pages/request';
import ProfilePage from 'views/pages/profile';
import Dashboard from 'views/pages/dashboard';
import ClientPage from 'views/pages/client';
import ClientDetail from 'views/pages/client/Detailed';
import ClientForm from 'views/pages/client/Form';
import FormRequest from 'views/pages/request/FormRequest';
import AccountsPage from 'views/pages/accounts';
import AccountPICForm from 'views/pages/accounts/Form';
import HelpdeskPage from 'views/pages/helpdesk';
import HelpdeskForm from 'views/pages/helpdesk/Form/index';
import AssignmentPage from 'views/pages/assignments';
import SkillPage from 'views/pages/skills';
import SkillForm from 'views/pages/skills/Form';
import CompetencesPage from 'views/pages/competences';
import CompetencesForm from 'views/pages/competences/Form';
import Role from 'views/pages/role-management';
import RoleForm from 'views/pages/role-management/form';
import User from 'views/pages/user-management';
import UserForm from 'views/pages/user-management/form';
import Progress from 'views/pages/dashboard/Progress';
import CategoryPage from 'views/pages/category';
import CategoryForm from 'views/pages/category/Form';
import AccountSetting from 'views/pages/account-setting';
import DivisiPage from 'views/pages/division';
import DivisiForm from 'views/pages/division/form';
import InterviewSchedulePage from 'views/pages/interview-schedule';
import InterviewScheduleForm from 'views/pages/interview-schedule/form';
import JobPositionPage from 'views/pages/jobPosition';
import JobPositionForm from 'views/pages/jobPosition/form';
import EmployeePage from 'views/pages/employee';
import EmployeeForm from 'views/pages/employee/form';
import EmployeeDetail from 'views/pages/employee/EmployeeDetail';
import RegistrationRequestPage from 'views/pages/registration-requests';
import RegisterClientForm from 'views/pages/registration-requests/RegisterClientForm';
import ResourcePage from 'views/pages/resource';
import ResourceDetail from 'views/pages/resource/Detailed';
import ResourceForm from 'views/pages/resource/Form';
import Reporting from 'views/pages/reporting';
import LogActivityPage from 'views/pages/log-activity';
import Invoice from 'views/pages/Invoice';
import RequestData from 'views/pages/request/Detailed';
import AddNewAssignment from 'views/pages/assignments/AddNewAssignment';
import ChatMainPage from 'views/pages/helpdesk/detail';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/reporting',
            element: <Reporting />
        },
        {
            path: '/resources',
            element: <ResourcePage />
        },
        {
            path: '/resources/form',
            element: <ResourceForm />
        },
        {
            path: '/resources/view/:resourceId/detail',
            element: <ResourceDetail />
        },
        {
            path: '/requests',
            element: <RequestListPage />
        },
        {
            path: '/requests/form',
            element: <FormRequest />
        },
        {
            path: '/requests/view/:id/detail',
            element: <RequestData />
        },
        {
            path: '/clients',
            element: <ClientPage />
        },
        {
            path: '/registration-request',
            element: <RegistrationRequestPage />
        },
        {
            path: '/registration-request/form',
            element: <RegisterClientForm />
        },
        {
            path: '/clients/form',
            element: <ClientForm />
        },
        {
            path: '/clients/view/:id/detail',
            element: <ClientDetail />
        },
        {
            path: '/profile',
            element: <ProfilePage />
        },
        {
            path: '/assignments',
            element: <AssignmentPage />
        },
        {
            path: '/assignments/add',
            element: <AddNewAssignment />
        },
        {
            path: '/accounts',
            element: <AccountsPage />
        },
        {
            path: '/account-setting',
            element: <AccountSetting />
        },
        {
            path: '/account/pic/form',
            element: <AccountPICForm />
        },
        {
            path: '/helpdesk',
            element: <HelpdeskPage />
        },
        {
            path: '/helpdesk/form',
            element: <HelpdeskForm />
        },
        {
            path: '/helpdesk/:id/detail',
            element: <ChatMainPage />
        },
        {
            path: '/skills',
            element: <SkillPage />
        },
        {
            path: '/skills/form',
            element: <SkillForm />
        },
        {
            path: '/competences',
            element: <CompetencesPage />
        },
        {
            path: '/competences/form',
            element: <CompetencesForm />
        },
        {
            path: '/role',
            element: <Role />
        },
        {
            path: '/role/form',
            element: <RoleForm />
        },
        {
            path: '/user',
            element: <User />
        },
        {
            path: '/user/form',
            element: <UserForm />
        },
        {
            path: '/invoice',
            element: <Invoice />
        },
        {
            path: '/category',
            element: <CategoryPage />
        },
        {
            path: '/category/form',
            element: <CategoryForm />
        },
        {
            path: '/progress',
            element: <Progress />
        },
        {
            path: '/jobPosition',
            element: <JobPositionPage />
        },
        {
            path: '/jobPosition/form',
            element: <JobPositionForm />
        },
        {
            path: '/divisi',
            element: <DivisiPage />
        },
        {
            path: '/divisi/form',
            element: <DivisiForm />
        },
        {
            path: '/interview-schedule',
            element: <InterviewSchedulePage />
        },
        {
            path: '/interview-schedule/form',
            element: <InterviewScheduleForm />
        },
        {
            path: '/employee',
            element: <EmployeePage />
        },
        {
            path: '/employee/form',
            element: <EmployeeForm />
        },
        {
            path: '/employee/detail',
            element: <EmployeeDetail />
        },
        {
            path: '/log-activity',
            element: <LogActivityPage />
        }
    ]
};

export default MainRoutes;
