// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconFolder,
    IconCalendarEvent,
    IconBrandChrome,
    IconCheckbox,
    IconHelp,
    IconSitemap,
    IconInbox,
    IconContainer,
    IconUsers,
    IconLifebuoy,
    IconClipboardList,
    IconBriefcase,
    IconFileInvoice
} from '@tabler/icons';

// constant
export const icons = {
    IconFolder,
    IconBrandChrome,
    IconHelp,
    IconCheckbox,
    IconSitemap,
    IconInbox,
    IconContainer,
    IconUsers,
    IconLifebuoy,
    IconClipboardList,
    IconBriefcase,
    IconCalendarEvent,
    IconFileInvoice
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    title: <FormattedMessage id="Operation" />,
    permissions: ['Assignment@index'],
    type: 'group',
    children: [
        {
            id: 'Registration',
            title: <FormattedMessage id="Registration" />,
            type: 'collapse',
            icon: icons.IconContainer,
            permissions: ['Client@client_getpost' && 'Authority@assignPermission'],
            breadcrumbs: false,
            children: [
                {
                    id: 'clientRegistration',
                    title: <FormattedMessage id="Client Registration" />,
                    type: 'item',
                    url: '/registration-request',
                    icon: icons.IconBriefcase,
                    permissions: ['Authority@assignPermission'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'requests',
            title: <FormattedMessage id="requests" />,
            type: 'item',
            url: '/requests',
            icon: icons.IconInbox,
            permissions: ['FormRequest@get_all'],
            breadcrumbs: false
        },
        {
            id: 'interview-schedule',
            title: <FormattedMessage id="Interview Schedule" />,
            type: 'item',
            url: '/interview-schedule',
            icon: icons.IconCalendarEvent,
            permissions: [
                'InterviewSchedule@interview_schedule_get_by_interviewer' &&
                    'InterviewSchedule@interview_schedule_get_by_resource' &&
                    'InterviewSchedule@interview_schedule_get_by_resource'
            ],
            breadcrumbs: false
        },
        {
            id: 'assignments',
            title: <FormattedMessage id="assignments" />,
            type: 'item',
            url: '/assignments',
            icon: icons.IconClipboardList,
            permissions: ['Assignment@index'],
            breadcrumbs: false
        },
        {
            id: 'hepldesk',
            title: <FormattedMessage id="hepldesk" />,
            type: 'item',
            url: '/helpdesk',
            icon: icons.IconLifebuoy,
            permissions: ['HelpDesk@index'],
            breadcrumbs: false
        },
        {
            id: 'reporting',
            title: <FormattedMessage id="Reporting" />,
            type: 'item',
            url: '/reporting',
            icon: icons.IconCheckbox,
            permissions: ['HelpDesk@index'],
            breadcrumbs: false
        },
        {
            id: 'invoice',
            title: <FormattedMessage id="Invoice" />,
            type: 'item',
            url: '/invoice',
            icon: icons.IconFileInvoice,
            permissions: ['HelpDesk@index'],
            breadcrumbs: false
        },
        {
            id: 'progress',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/progress',
            icon: icons.IconBrandChrome,
            // permissions: ['resource'],
            breadcrumbs: false
        }
    ]
};

export default other;
