import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Popper,
    TextField,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { getTicketDetailbyId, postTicketReply, updateStatusTicket } from 'utils/services/helpdesk';
import { IconChevronDown, IconChevronUp, IconLifebuoy } from '@tabler/icons';
import { EmojiEmotionsOutlined, ErrorOutlineOutlined, SendOutlined } from '@mui/icons-material';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import UserDetails from './UserDetails';
import ChartHistory from './ChartHistory';
import AvatarStatus from './AvatarStatus';
import MainCard from 'ui-component/cards/MainCard';
import Avatar from 'ui-component/extended/Avatar';
import ConfirmationModal from 'ui-component/confirmation';

const ChatMainPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const avatarImage = require.context('assets/images/users', true);

    const [reply, setReply] = useState('');
    const [confess, setConfess] = useState([]);
    const [ticket, setTicket] = useState({});
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [emailDetails, setEmailDetails] = useState(false);
    const [anchorElEmoji, setAnchorElEmoji] = useState(); /** No single type can cater for all elements */

    const user = {
        avatar: 'avatar-1.png',
        birthdayText: 'happy Birthday Alene',
        company: 'ABC Pvt Ltd',
        id: 1,
        lastMessage: '2h ago',
        location: 'Port Narcos',
        name: 'Alene',
        online_status: 'available',
        personal_email: 'alene@company.com',
        personal_phone: '380-293-0177',
        role: 'Sr. Customer Manager',
        status: 'Technical Department',
        unReadChatCount: 2,
        work_email: 'alene_work@company.com',
        work_phone: '380-293-0177'
    };

    const handleToggle = () => {
        setOpen(!open);
    };
    const handleUserChange = () => {
        setEmailDetails((prev) => !prev);
    };

    const onEmojiClick = (e, emojiObject) => {
        setReply(reply + emojiObject.emoji);
    };
    const handleOnEmojiButtonClick = (event) => {
        setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
    };
    const emojiOpen = Boolean(anchorElEmoji);
    const emojiId = emojiOpen ? 'simple-popper' : undefined;
    const handleCloseEmoji = () => {
        setAnchorElEmoji(null);
    };

    const fetchDetail = async () => {
        setLoading(true);
        await getTicketDetailbyId(id)
            .then((res) => {
                if (res && res.success) {
                    if (res.data && res.data.length > 0) {
                        setTicket(res.data[0]);
                        setConfess(res.data[0].messages);
                        setLoading(false);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleReply = async (event) => {
        event.preventDefault();
        setLoading(true);
        await postTicketReply({ id, message: reply })
            .then((res) => {
                if (res.success) {
                    setReply('');
                    fetchDetail();
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleEnter = (event) => {
        if (event?.key !== 'Enter') {
            return;
        }
        handleReply();
    };

    const handleStatus = async (id) => {
        setLoading(true);
        if (ticket.status === 0) {
            await updateStatusTicket(id)
                .then((res) => {
                    if (res && res.success) {
                        fetchDetail();
                        setOpenModal(false);
                        setLoading(false);
                        navigate('/helpdesk');
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    useEffect(() => {
        fetchDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (reply === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [reply]);

    return (
        <>
            <ConfirmationModal handleContinue={() => handleStatus(id)} open={openModal} handleCancel={() => setOpenModal(false)} />
            <Grid container spacing={gridSpacing}>
                <Grid item xs zeroMinWidth sx={{ display: emailDetails ? { xs: 'none', sm: 'flex' } : 'flex' }}>
                    <MainCard
                        contentSX={{ bgcolor: 'grey.50' }}
                        title={
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar
                                                variant="rounded"
                                                color="inherit"
                                                sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}
                                            >
                                                <IconLifebuoy color="#fff" />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                                        {ticket.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                                        {ticket.category}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm zeroMinWidth />
                                        <Grid item>
                                            <Chip
                                                label="Topic"
                                                onClick={handleToggle}
                                                onDelete={handleToggle}
                                                sx={{
                                                    bgcolor: theme.palette.primary.light,
                                                    color: theme.palette.primary.main,
                                                    '&:hover': {
                                                        bgcolor: theme.palette.primary.light,
                                                        color: theme.palette.primary.main
                                                    }
                                                }}
                                                deleteIcon={
                                                    open ? (
                                                        <IconChevronUp
                                                            stroke={2.5}
                                                            size="1.2rem"
                                                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                                        />
                                                    ) : (
                                                        <IconChevronDown
                                                            stroke={2.5}
                                                            size="1.2rem"
                                                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                                        />
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm zeroMinWidth />
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Grid item>
                                        <Card sx={{ bgcolor: theme.palette.primary.light, px: 2 }}>
                                            <Typography dangerouslySetInnerHTML={{ __html: ticket.message }} />
                                        </Card>
                                    </Grid>
                                </Collapse>
                            </Grid>
                        }
                    >
                        <Grid container spacing={gridSpacing} justifyContent="center">
                            <Grid item xs={12}>
                                <Grid container alignItems="center" spacing={0.5}>
                                    <Grid item>
                                        <Grid container spacing={2} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                            <Grid item>
                                                <Avatar alt={user.name} src={user.avatar && avatarImage(`./${user.avatar}`).default} />
                                            </Grid>
                                            <Grid item sm zeroMinWidth>
                                                <Grid container spacing={0} alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Typography variant="h4" component="div">
                                                            {user.name} {user.online_status && <AvatarStatus status={user.online_status} />}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle2">Last seen {user.lastMessage}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm zeroMinWidth />
                                    <Grid item>
                                        <IconButton onClick={handleUserChange} size="large">
                                            <ErrorOutlineOutlined />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: theme.spacing(2) }} />
                            </Grid>
                            <PerfectScrollbar style={{ width: '100%', height: 'calc(100vh - 440px)', overflowX: 'hidden', minHeight: 525 }}>
                                <CardContent>
                                    <ChartHistory theme={theme} confess={confess} />
                                </CardContent>
                            </PerfectScrollbar>
                            {ticket.status === 0 ? (
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <IconButton
                                                ref={anchorElEmoji}
                                                aria-describedby={emojiId}
                                                onClick={handleOnEmojiButtonClick}
                                                size="large"
                                            >
                                                <EmojiEmotionsOutlined />
                                            </IconButton>
                                            <Popper
                                                id={emojiId}
                                                open={emojiOpen}
                                                anchorEl={anchorElEmoji}
                                                disablePortal
                                                popperOptions={{
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [-20, 20]
                                                            }
                                                        }
                                                    ]
                                                }}
                                            >
                                                <ClickAwayListener onClickAway={handleCloseEmoji}>
                                                    <MainCard elevation={8} content={false}>
                                                        <Picker
                                                            onEmojiClick={onEmojiClick}
                                                            skinTone={SKIN_TONE_MEDIUM_DARK}
                                                            disableAutoFocus
                                                        />
                                                    </MainCard>
                                                </ClickAwayListener>
                                            </Popper>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                            <TextField
                                                fullWidth
                                                label={loading ? 'Loading' : 'Type a Message'}
                                                value={reply}
                                                onChange={(e) => setReply(e.target.value)}
                                                onKeyPress={handleEnter}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton color="primary" onClick={handleReply} size="large" disabled={loading || disable}>
                                                <SendOutlined />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: theme.spacing(2) }} />
                                </Grid>
                            )}
                            {ticket.status === 0 && (
                                <Grid item>
                                    <Button onClick={() => setOpenModal(true)}>Mark As Closed</Button>
                                </Grid>
                            )}
                        </Grid>
                    </MainCard>
                </Grid>
                {emailDetails && (
                    <Grid item>
                        <UserDetails user={user} />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ChatMainPage;
