import { storageKey } from 'store/constant';
import ApiService from '../../../service';

export const getAllRequestOnProcess = async () => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/formRequest/list/status?filter=1`, config);
};
// export const createNewSkill = async ({ payload }) => { // eslint-disable-line
//     const config = {
//         headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
//     };

//     return ApiService.post(`${process.env.REACT_APP_API_URL}/skill`, { ...payload }, config);
// };
// export const updateSkill = async ({ identify,form }) => { // eslint-disable-line
//     const config = {
//         headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
//     };
    
//     return ApiService.put(`${process.env.REACT_APP_API_URL}/skill/${identify}`,  form , config)
    
// };
// export const deleteSkill = async ({ id }) => { // eslint-disable-line
//     const config = {
//         headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
//     };
//     return ApiService.delete(`${process.env.REACT_APP_API_URL}/skill/${id}`, config)
// };
// export const getDetailSkill = async ({ identify }) => { // eslint-disable-line
//     const config = {
//         headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
//     };
//     return ApiService.get(`${process.env.REACT_APP_API_URL}/skill/${identify}`, config)
    
// };
