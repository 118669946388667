import { Backdrop, Box, Button, Fade, Link, Modal, Stack, Typography } from '@mui/material';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 4
};

export default function ConfirmationModal({ open, title, description, handleContinue, handleCancel, link, loading, sx }) {
    return (
        <Modal
            open={open}
            onClose={handleCancel}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <Box sx={{ ...modalStyle, ...sx, textAlign: 'center' }}>
                    <Typography variant="h2">{title ?? 'Are you sure?'}</Typography>
                    <Typography
                        sx={{ my: 1 }}
                        dangerouslySetInnerHTML={{ __html: description ?? 'This action will remove data permanently!' }}
                    />

                    <Box sx={{ mt: 3 }}>
                        <Stack direction="row" spacing={2} justifyContent="center">
                            <Button id="button-confirmation-cancel" size="small" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button
                                id="button-confirmation-continue"
                                variant="contained"
                                onClick={handleContinue}
                                disableElevation
                                size="small"
                                disabled={loading || false}
                            >
                                {link ? (
                                    <Link href={link} target="_blank" color="inherit" underline="none" rel="noopener noreferrer">
                                        Continue
                                    </Link>
                                ) : loading ? (
                                    'Loading'
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
