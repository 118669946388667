import { FormattedMessage } from 'react-intl';

import {
    IconBuildingCommunity,
    IconUser,
    IconBuildingSkyscraper,
    IconLicense,
    IconLayoutDistributeHorizontal,
    Icon3dCubeSphere,
    IconListSearch,
    IconListNumbers,
    IconAccessible,
    IconCategory,
    IconUserSearch,
    IconGenderEpicene
} from '@tabler/icons';

const icons = {
    IconBuildingCommunity,
    IconUser,
    IconBuildingSkyscraper,
    IconLicense,
    IconLayoutDistributeHorizontal,
    Icon3dCubeSphere,
    IconListNumbers,
    IconListSearch,
    IconAccessible,
    IconCategory,
    IconUserSearch,
    IconGenderEpicene
};

const master = {
    id: 'master-tab',
    title: <FormattedMessage id="master Data" />,
    permissions: ['Users@users_get'],
    type: 'group',
    children: [
        {
            id: 'internal',
            title: <FormattedMessage id="Internal" />,
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            permissions: ['Division@division_getpost' && 'JobPosition@job_position_getpost'],
            breadcrumbs: false,
            children: [
                {
                    id: 'user',
                    title: <FormattedMessage id="User" />,
                    type: 'item',
                    url: '/user',
                    icon: icons.IconUser,
                    permissions: ['Users@users_get'],
                    breadcrumbs: false
                },
                {
                    id: 'role',
                    title: <FormattedMessage id="Role" />,
                    type: 'item',
                    url: '/role',
                    icon: icons.IconLicense,
                    permissions: ['Role@role_get'],
                    breadcrumbs: false
                },
                {
                    id: 'divisi',
                    title: <FormattedMessage id="Divisi" />,
                    type: 'item',
                    url: '/divisi',
                    icon: icons.IconLayoutDistributeHorizontal,
                    permissions: ['Division@division_getpost'],
                    breadcrumbs: false
                },
                {
                    id: 'employee',
                    title: <FormattedMessage id="Employee" />,
                    type: 'item',
                    url: '/employee',
                    icon: icons.IconGenderEpicene,
                    permissions: ['Authority@assignPermission'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'clientLists',
            title: <FormattedMessage id="Clients" />,
            type: 'item',
            url: '/clients',
            icon: icons.IconListNumbers,
            permissions: ['Client@client_getpost'],
            breadcrumbs: false
        },
        {
            id: 'internals',
            title: <FormattedMessage id="Resource" />,
            type: 'collapse',
            icon: icons.IconUserSearch,
            permissions: ['Division@division_getpost' && 'JobPosition@job_position_getpost'],
            breadcrumbs: false,
            children: [
                {
                    id: 'resources',
                    title: <FormattedMessage id="resources" />,
                    type: 'item',
                    url: '/resources',
                    icon: icons.IconListSearch,
                    permissions: ['Resources@resources_get'],
                    breadcrumbs: false
                },
                {
                    id: 'skill',
                    title: <FormattedMessage id="skill" />,
                    type: 'item',
                    url: '/skills',
                    icon: icons.Icon3dCubeSphere,
                    permissions: ['Skill@skill_list_all'],
                    breadcrumbs: false
                },
                {
                    id: 'competence',
                    title: <FormattedMessage id="competence" />,
                    type: 'item',
                    url: '/competences',
                    icon: icons.IconAccessible,
                    permissions: ['Competences@list_competences'],
                    breadcrumbs: false
                },
                {
                    id: 'category',
                    title: <FormattedMessage id="category" />,
                    type: 'item',
                    url: '/category',
                    icon: icons.IconCategory,
                    permissions: ['Category@category_getpost'],
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default master;
