import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Typography } from '@mui/material';

// project imports
import ActivityList from './ActivityList';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

import axios from 'axios';

// assets
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import RootPermission from '../permission';
import useRootPermission from 'hooks/useRootPermission';

// ==============================|| USER LIST STYLE 1 ||============================== //

const LogActivityPage = () => {
    const theme = useTheme('oures');
    const [logs, setLogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const [granted] = useRootPermission(['LogActivity@get_list_log']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getData = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios
            .get(`${process.env.REACT_APP_API_URL}/log_activity?page=${page}&limit=${limit}&search=${filter}`, config)
            .then((res) => {
                if (res) {
                    setLogs(res.data.data);
                    setTotal(res.data.meta.total);
                    setLoading(false);
                }
            });
    };

    React.useEffect(() => {
        getData();
    }, [page, limit, filter]); //eslint-disable-line

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Activity</Typography>
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                id="input-activity-search"
                                placeholder="Search"
                                onChange={(e) => setFilter(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <ActivityList logs={logs} handleRefresh={getData} loading={loading} />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="primary"
                                id="pagination-activity"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                                id="button-client-rows"
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                id="dropdown-client-row"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem id="menu-client-limit5" onClick={() => setLimit(5)}>
                                    {' '}
                                    5 Rows
                                </MenuItem>
                                <MenuItem id="menu-client-limit10" onClick={() => setLimit(10)}>
                                    {' '}
                                    10 Rows
                                </MenuItem>
                                <MenuItem id="menu-client-limit20" onClick={() => setLimit(20)}>
                                    {' '}
                                    20 Rows
                                </MenuItem>
                                <MenuItem id="menu-client-limit30" onClick={() => setLimit(30)}>
                                    {' '}
                                    30 Rows
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default LogActivityPage;
