import { Card, CardContent, CardHeader, Modal, Stack, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateStatusAssignment } from 'utils/services/assignment-quotation';
import { SNACKBAR_OPEN } from 'store/actions';
import { updateCancelInterview } from 'utils/services/assignmentInterview';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    boxShadow: 24,
    borderRadius: '12px'
};

const NoteModal = ({ open, handleClose, action, row, category, handleRefresh }) => {
    const dispatch = useDispatch();

    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ cancel_notes: '' });
    const [payload, setPayload] = useState(0);
    const [notes, setNotes] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (action === 'reject') {
            await updateCancelInterview({ id: row.form_request_assignment_id, payload: message })
                .then((res) => {
                    if (res && res.success) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'success',
                            variant: 'alert'
                        });
                        setLoading(false);
                        handleClose();
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'warning',
                            variant: 'alert'
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
        await updateStatusAssignment({ id: action === 'noteModal' ? row.id : row.form_request_assignment_id, payload, notes })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })
            .finally(() => handleRefresh());
    };

    useEffect(() => {
        if (category === 'interview') {
            if (action === 'accept' && payload === 0) {
                setPayload(1);
            } else if (action === 'reject') {
                setPayload(4);
            }
        } else if (category === 'onProcess') {
            if (action === 'accept' && payload === 1) {
                setPayload(2);
            } else if (action === 'reject') {
                setPayload(4);
            }
        } else if (category === 'running') {
            if (action === 'accept' && payload === 2) {
                setPayload(3);
            } else if (action === 'reject') {
                setPayload(4);
            }
        } else if (category === 'reassign') {
            if (action === 'noteModal' && payload === 4) {
                setPayload(0);
            }
        }
    }, [category, payload, action]);

    useEffect(() => {
        if (action !== 'accept') {
            if (message.cancel_notes === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else {
            setDisable(false);
        }
    }, [action, message]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Card sx={modalStyle}>
                <CardHeader
                    sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }}
                    title={action === 'reject' ? 'Rejection Note' : 'Acceptance Note'}
                />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <Typography>
                                You are about to {action} this user, please fill out the reason below
                                {action === 'reject' ? ':' : ' (optional):'}
                            </Typography>
                            {/* <MarkdownEditor
                                value={message.cancel_notes}
                                onChange={(e) => {
                                    setMessage({ ...message, cancel_notes: e.target.value });
                                    setNotes({ ...notes, notes: e.target.value });
                                }}
                            /> */}
                            <TextField
                                type="text"
                                required={action !== 'accept'}
                                multiline
                                minRows={4}
                                maxRows={8}
                                label={action === 'reject' ? 'Rejection Note' : 'Acceptance Note'}
                                value={message.cancel_notes}
                                onChange={(e) => {
                                    setMessage({ ...message, cancel_notes: e.target.value });
                                    setNotes(e.target.value);
                                }}
                            />
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <Button id="button-client-cancel" variant="outlined" onClick={() => handleClose()}>
                                    Cancel
                                </Button>
                                <Button
                                    disableElevation
                                    id="button-client-submit"
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {loading ? 'Loading' : 'Proceed'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default NoteModal;
