import { Button, Card, CardContent, CardHeader, Modal, Stack, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import moment from 'moment';
import { exportReport } from 'utils/services/reporting';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 735,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1
};

const ExportReportModal = ({ open, loading, setLoading, handleClose, handleRefresh }) => {
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);

    const [payload, setPayload] = useState({
        start_date: '',
        end_date: '',
        name_project: '',
        name_resource: '',
        name_client: ''
    });
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        await exportReport({ 
            startDate: payload.start_date, 
            endDate: payload.end_date, 
            nameProject: payload.name_project, 
            nameResource: payload.name_resource 
        })
            .then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = window.document.createElement('a');
                link.setAttribute('download', `TIMESHEETS ${moment(payload.start_date).format('DD-MM-YYYY')} sd ${moment(payload.end_date).format('DD-MM-YYYY')}`);
                link.href = url;
                link.click();
                setTimeout(URL.revokeObjectURL, 5000, url);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: "Successfully Generated to PDF",
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                setLoading(false);
                handleClose();
            })
            .catch((err) => {
                setLoading(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: "Failed to Generate to PDF",
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                setLoading(false);
                console.log(err);
            })
            .finally(() => (handleRefresh()));
    };

    useEffect(() => {
        if (
            payload.start_date === "" ||
            payload.end_date === "" 
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={modalStyle}>
                <CardHeader 
                    sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} 
                    title="Export Report to PDF"
                />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Stack direction="column" spacing={1.5} width='40%'>
                                    <TextField
                                        required
                                        type="date"
                                        label="Start Date"
                                        color="grey"
                                        onChange={(e) => 
                                            setPayload({ 
                                                ...payload, 
                                                start_date: moment(e.target.value).format('YYYY-MM-DD')
                                            })
                                        }
                                        sx={{ width: "100%" }}
                                        InputLabelProps={{ shrink: true }}
                                    /> 
                                    <TextField
                                        required
                                        type="date"
                                        label="End Date"
                                        color="grey"
                                        onChange={(e) => 
                                            setPayload({ 
                                                ...payload, 
                                                end_date: moment(e.target.value).format('YYYY-MM-DD')
                                            })
                                        }
                                        sx={{ width: "100%" }}
                                        InputLabelProps={{ shrink: true }}
                                    /> 
                                </Stack>
                                <Stack direction="column" justifyContent="end" spacing={1.5} width="100%">
                                    <TextField
                                        // required
                                        type="text"
                                        label="Nama Projek"
                                        color="grey"
                                        onChange={(e) => setPayload({ ...payload, name_project: e.target.value })}
                                        sx={{ width: "100%" }}
                                    /> 
                                    <TextField
                                        // required
                                        type="text"
                                        label="Nama Resource"
                                        color="grey"
                                        onChange={(e) => setPayload({ ...payload, name_resource: e.target.value })}
                                        sx={{ width: "100%" }}
                                    /> 
                                    <TextField
                                        // required
                                        type="text"
                                        label="Nama Client"
                                        color="grey"
                                        onChange={(e) => setPayload({ ...payload, name_client: e.target.value })}
                                        sx={{ width: "100%" }}
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <Button 
                                    id="button-negotiate-cancel" 
                                    variant="outlined" 
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id="button-client-submit"
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                >
                                    {loading ? 'Loading' : 'Export Report'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default ExportReportModal;