import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getInterviewSchedule = async ({ page, limit }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/interviewSchedule/?page=${page}&limit=${limit}`, config);
};
export const createNewInterviewSchedule = async ({ payload }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/interviewSchedule`, { ...payload }, config);
};
export const updateInterviewSchedule = async ({ identify, form }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/interviewSchedule/${identify}`, form, config);
};
export const updateStatusInterviewSchedule = async ({ identify, form }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/interviewSchedule/status/${identify}`, form, config);
};
export const deleteInterviewSchedule = async ({ id }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/interviewSchedule/${id}`, config);
};
export const getDetailInterviewSchedule = async ({ identify }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/interviewSchedule/list/${identify}`, config);
};
export const getRequestInterviewSchedule = async ({ page, limit, search }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/interviewSchedule/getListRequest?page=${page}&limit=${limit}&search=${search}`,
        config
    );
};
