import { AssignmentLateTwoTone, AssignmentTurnedInTwoTone } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Chip,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getResourceDetail } from 'utils/services/resource';
import DialogModal from 'ui-component/dialogModal';

const CandidateDetailModal = ({ open, handleClose, hasPermission, permission, id }) => {
    const [loading, setLoading] = useState(false);
    const [resourceDetail, setResourceDetail] = useState({
        resources: {},
        users: {}
    });
    const fetchResourceDetail = async () => {
        setLoading(true);
        await getResourceDetail(id)
            .then((res) => {
                if (res && res.success) {
                    if (res && res.data && res.data.length > 0) {
                        setResourceDetail(res.data[0]);
                        setLoading(false);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (open) {
            fetchResourceDetail(id);
        } else {
            setResourceDetail({ resources: {}, users: {} });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <DialogModal open={open} handleClose={handleClose} overrideStyle={{ width: '40%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" display="flex">
                <Stack direction="row" spacing={2} alignItems="center" display="flex">
                    {!loading ? <Avatar /> : <Skeleton variant="circular" width={40} height={40} />}
                    <Stack direction="column">
                        {!loading ? (
                            <>
                                <Typography variant="h4">
                                    {/* {resourceDetail && resourceDetail?.users && resourceDetail?.users?.name ? resourceDetail?.users?.name : '—'} */}
                                    {resourceDetail.users.name || '—'}
                                </Typography>
                                <Typography variant="caption">
                                    {/* {resourceDetail && resourceDetail?.resources && resourceDetail?.resources?.competence_name
                                    ? resourceDetail?.resources?.competence_name
                                    : '—'} */}
                                    {resourceDetail.resources.competence_name || '—'}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Skeleton variant="rectangular" sx={{ borderRadius: '12px', width: '150px', height: '16px', mb: 1 }} />
                                <Skeleton variant="rectangular" sx={{ borderRadius: '12px', width: '150px', height: '13px' }} />
                            </>
                        )}
                    </Stack>
                </Stack>
                {hasPermission(['client'], permission) && (
                    <Stack direction="row" spacing={1}>
                        {!loading ? (
                            <>
                                <IconButton color="success" sx={{ p: 0, m: 0 }}>
                                    <AssignmentTurnedInTwoTone />
                                </IconButton>
                                <IconButton color="warning" sx={{ p: 0, m: 0 }}>
                                    <AssignmentLateTwoTone />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <Skeleton variant="rectangular" sx={{ borderRadius: '4px', width: 22, height: 22 }} />
                                <Skeleton variant="rectangular" sx={{ borderRadius: '4px', width: 22, height: 22 }} />
                            </>
                        )}
                    </Stack>
                )}
            </Stack>
            <Box mt="10px">
                <TableContainer>
                    <Table sx={{ '& td': { borderBottom: 'none' } }} size="small">
                        {!loading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        Degree
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>
                                        {resourceDetail?.resources?.academic_histories?.length > 0
                                            ? resourceDetail?.resources?.academic_histories[0]?.degree
                                            : '—'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        Major
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>
                                        {resourceDetail?.resources?.academic_histories?.length > 0
                                            ? resourceDetail?.resources?.academic_histories[0]?.major
                                            : '—'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        Institute
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>
                                        {resourceDetail?.resources?.academic_histories?.length > 0
                                            ? resourceDetail?.resources?.academic_histories[0]?.institute_name
                                            : '—'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        English Level
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>{resourceDetail.resources.english_level}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        Skill
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>
                                        {resourceDetail?.resources?.skills?.length > 0 ? (
                                            resourceDetail.resources.skills.map((skill, index) => (
                                                <Chip key={`skill-${index}`} label={skill.skill_name} sx={{ mr: 0.5 }} />
                                            ))
                                        ) : (
                                            <Typography>—</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        Available Work
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>{resourceDetail.resources.available_work || '—'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        Office Hour
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>{resourceDetail.resources.office_hour === 'false' ? 'No' : 'Yes'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head" sx={{ pl: 0, width: '120px' }}>
                                        Salary Expect
                                    </TableCell>
                                    <TableCell sx={{ px: 0 }}>:</TableCell>
                                    <TableCell>
                                        {resourceDetail.resources.salary_expect === '-' ? '—' : resourceDetail.resources.salary_expect}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ pl: 0, width: '120px' }}>
                                            <Skeleton variant="rectangular" sx={{ borderRadius: '12px', height: '14px', my: '5px' }} />
                                        </TableCell>
                                        <TableCell sx={{ px: 0 }}>:</TableCell>
                                        <TableCell>
                                            <Skeleton variant="rectangular" sx={{ borderRadius: '12px', width: '220px', height: '14px' }} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>
        </DialogModal>
    );
};

export default CandidateDetailModal;
