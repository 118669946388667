import { Button } from '@mui/material';
import { styled } from '@mui/styles';

const ButtonStyled = styled(Button)(({ ...props }) => ({
    borderRadius: '200px',
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: ['Figtree'],
    width: 'fit-content',
    boxShadow: 'none',
    fontSize: '12px',
    ...props
}));

export default ButtonStyled;
