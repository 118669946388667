// third party
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './markdown.css';

// ==============================|| QUILL EDITOR ||============================== //

const MarkdownEditor = ({ value, handleChange }) => (
    <ReactQuill className="toolbarClassName editorClassName wrapperClassName" value={value} onChange={handleChange} />
);
export default MarkdownEditor;
