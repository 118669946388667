/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Chip,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { OpenInNewRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { hasPermission } from 'helper/general';
import { SNACKBAR_OPEN } from 'store/actions';
import { updateStatusRequest } from 'utils/services/requests';
import { useDispatch } from 'react-redux';

import Avatar from 'ui-component/extended/Avatar';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone';
import moment from 'moment';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import useRootPermission from 'hooks/useRootPermission';
import ModalChangeStatus from './Modal/ModalChangeStatus';
import ModalCancelNote from './Modal/ModalCancelNote';
import ListCriteriaCell from './ListCriteriaCell';

const TableRequest = ({ resources, onRefresh, loading, setLoading, startIndex }) => {
    const theme = useTheme('oures');
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [id, setId] = useState('');
    const [reasonMessage, setReasonMessage] = useState('');
    const [granted, allPermission] = useRootPermission(['FormRequest@get_all']);
    const [payload, setPayload] = useState({
        status: 0,
        reason: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await updateStatusRequest({ id, payload })
            .then((res) => {
                if (res && res.success) {
                    onRefresh();
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    setLoading(false);
                    setOpen(false);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            {open === 'changeStatus' && (
                <ModalChangeStatus
                    open={open === 'changeStatus'}
                    handleClose={() => setOpen(false)}
                    payload={payload}
                    setPayload={setPayload}
                    handleSubmit={handleSubmit}
                />
            )}
            {open === 'cancelNote' && (
                <ModalCancelNote
                    open={open === 'cancelNote'}
                    handleClose={() => setOpen(false)}
                    reasonMessage={reasonMessage}
                    setReasonMessage={setReasonMessage}
                />
            )}
            <TableContainer>
                <Table sx={{ minWidth: '1250px', overflow: 'auto' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 3 }}>
                                No
                            </TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Needs</TableCell>
                            <TableCell align="center">Criteria</TableCell>
                            <TableCell align="center">Request Date</TableCell>
                            <TableCell align="center">Due Date</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {resources && resources.length > 0 ? (
                                    resources.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell align="center" sx={{ pl: 3 }}>
                                                {index + startIndex}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Avatar alt="User 1" src="" />
                                                    </Grid>
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography align="left" variant="subtitle1" component="div">
                                                            {row.request_name}
                                                        </Typography>
                                                        <Typography align="left" variant="subtitle2" noWrap>
                                                            {row.pic_name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">{row.needs}</TableCell>
                                            <TableCell>
                                                <ListCriteriaCell criterias={row.criteria ?? []} />
                                            </TableCell>
                                            <TableCell align="center">{moment(row.created_at).format('D MMMM YYYY')}</TableCell>
                                            <TableCell align="center">{moment(row.order_end_date).format('D MMMM YYYY')}</TableCell>
                                            <TableCell align="center">
                                                {row.status === 0 && (
                                                    <Chip
                                                        label="Pending"
                                                        size="small"
                                                        sx={{
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.info.light + 60,
                                                            color: theme.palette.info.dark
                                                        }}
                                                    />
                                                )}
                                                {row.status === 1 && (
                                                    <Chip
                                                        label="On Progress"
                                                        size="small"
                                                        sx={{
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.success.light + 60,
                                                            color: theme.palette.success.dark
                                                        }}
                                                    />
                                                )}
                                                {row.status === 2 && (
                                                    <Chip
                                                        label="Rejected"
                                                        size="small"
                                                        sx={{
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.orange.light + 80,
                                                            color: theme.palette.orange.dark
                                                        }}
                                                    />
                                                )}
                                                {row.status === 3 && (
                                                    <Chip
                                                        label="Cancelled"
                                                        size="small"
                                                        sx={{
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.warning.light,
                                                            color: theme.palette.warning.dark
                                                        }}
                                                    />
                                                )}
                                                {row.status === 3 && (
                                                    <Chip
                                                        label="Note"
                                                        size="small"
                                                        onClick={() => {
                                                            setReasonMessage(row.reason_cancel);
                                                            setOpen('cancelNote');
                                                        }}
                                                        id="button-request-note"
                                                        sx={{
                                                            cursor: 'pointer',
                                                            border: '1px solid #eaeaea',
                                                            background:
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.grey[100],
                                                            color: theme.palette.warning.contrastText
                                                        }}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    <Tooltip placement="top" title="View Detail">
                                                        <Link id="link-request-detail" to={`view/${row.id}/detail`}>
                                                            <IconButton color="primary" aria-label="open" size="large">
                                                                <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                    {hasPermission(['FormRequest@update_status'], allPermission) && (
                                                        <>
                                                            {row.status === 0 && (
                                                                <>
                                                                    <Tooltip placement="top" title="Make On Process">
                                                                        <IconButton
                                                                            color="success"
                                                                            aria-label="open"
                                                                            size="large"
                                                                            onClick={() => {
                                                                                setId(row.id);
                                                                                setPayload({ ...payload, status: 1 });
                                                                                setOpen('changeStatus');
                                                                            }}
                                                                            id="button-request-onProcess"
                                                                        >
                                                                            <CheckCircleOutlineTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip placement="top" title="Cancel Request">
                                                                        <IconButton
                                                                            color="error"
                                                                            aria-label="open"
                                                                            size="large"
                                                                            onClick={() => {
                                                                                setId(row.id);
                                                                                setPayload({ ...payload, status: 3 });
                                                                                setOpen('changeStatus');
                                                                            }}
                                                                            id="button-request-cancel"
                                                                        >
                                                                            <RemoveCircleOutlineTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={8} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={8} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default TableRequest;
