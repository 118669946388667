import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Grid,
    Link,
    Modal,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { IconFileBroken } from '@tabler/icons';
import { SNACKBAR_OPEN } from 'store/actions';
import { getInvoiceCode, getInvoiceDetail, getViewInvoice, updateInvoice } from 'utils/services/invoice';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ConfirmationModal from 'ui-component/confirmation';
import axios from 'axios';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 780,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1
};

const EditInvoiceModal = ({ open, action, invoiceId, loading, setLoading, handleClose, handleRefresh }) => {
    const dispatch = useDispatch();
    const uploadInputRef = useRef(null);
    const [disable, setDisable] = useState(false);
    const [disableInit, setDisableInit] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [initial, setInitial] = useState('');
    const [search, setSearch] = useState('');
    const [clients, setClients] = useState('');
    const [payload, setPayload] = useState({
        client_id: '',
        client_name: '',
        accountant_email: '',
        invoice_code: '',
        date: '',
        file: '',
        title: ''
    });

    const handleButtonClick = () => {
        uploadInputRef.current.click();
    };

    const fetchFile = async (id) => {
        setLoading(true);
        await getViewInvoice(id)
            .then((res) => {
                if (res) {
                    setLoading(false);
                    const file = new Blob([res], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    const link = window.document.createElement('a');
                    link.setAttribute('open', `Invoice`);
                    // link.setAttribute('download', `${payload.file}`);
                    link.href = fileURL;
                    link.click();
                    setPayload({ ...payload, file });
                    setTimeout(URL.revokeObjectURL, 5000, fileURL);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('client_id', payload.client_id);
        formData.append('invoice_code', payload.invoice_code);
        formData.append('accountant_email', payload.accountant_email);
        formData.append('file', payload.file);

        await updateInvoice({ id: invoiceId, payload: formData })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
            .finally(() => handleRefresh());
    };

    const fetchDetail = async () => {
        getInvoiceDetail(invoiceId).then((res) => {
            // getViewInvoice(invoiceId).then((res) => {
            //     const pdfBlob = new Blob([res], { type: 'application/pdf' });
            //     const url = URL.createObjectURL(pdfBlob);
            //     setPayload({ ...payload, file: url });
            //     const updateState = { ...payload };
            //     const blobFile = new File([res], { type: 'application/pdf' });
            //     updateState.file.push(blobFile);
            //     setPayload(updateState)
            //     const file = new Blob([res], { type: 'application/pdf' });
            //     setPayload({ ...payload, file.push() });
            // });
            // const file = new Blob([fetchFile], { type: 'application/pdf' });
            // const fileURL = URL.createObjectURL(file);
            // const link = window.document.createElement('a');
            // link.setAttribute('open', `Invoice`);
            // link.setAttribute('download', `${payload.file}`);
            // link.href = fileURL;
            // link.click();
            // // setPayload({ ...payload, file });
            // setTimeout(URL.revokeObjectURL, 5000, fileURL);
            setPayload({
                ...payload,
                client_id: res.data.client_id,
                client_name: res.data.client_name,
                accountant_email: res.data.accountant_email,
                invoice_code: res.data.invoice_code,
                file: res.data.file_link,
                title: res.data.client_name
            });
        });
    };

    useEffect(() => {
        if (action === 'editInvoice' && invoiceId) {
            fetchDetail(invoiceId);
        }
    }, [invoiceId, action]);

    const getData = async (search) => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/client?page=1&limit=9999&search=${search}`, config).then((res) => {
            if (res) {
                setClients(res.data.data);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        getData(search);
    }, [search]); //eslint-disable-line

    const fetchInvoiceCode = async () => {
        setLoading(true);
        await getInvoiceCode(initial)
            .then((res) => {
                if (res) {
                    setPayload({ ...payload, invoice_code: res.data });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        if (initial === '') {
            setDisableInit(true);
        } else {
            setDisableInit(false);
        }
    }, [initial]);

    useEffect(() => {
        if (payload.client_id === '' || payload.invoice_code === '' || payload.accountant_email === '' || payload.file === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    // const onCancel = () => handleClose();
    const theme = useTheme('oures');

    return (
        <>
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => handleClose()}
            />
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Card sx={modalStyle}>
                    <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Edit Invoice" />
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            value={payload}
                                            onChange={(event, newValue) => {
                                                if (newValue && newValue?.id) {
                                                    setPayload({ ...payload, client_id: newValue?.id, title: newValue?.company_name });
                                                    getData(newValue?.company_name);
                                                }
                                            }}
                                            inputValue={search}
                                            onInputChange={(event, newInput) => {
                                                setSearch(newInput);
                                            }}
                                            getOptionLabel={(option) => option.company_name ?? option.title}
                                            options={clients}
                                            id="autocomplete-invoice"
                                            renderInput={(params) => <TextField {...params} placeholder="Client Name" />}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="email"
                                            onChange={(e) => {
                                                setPayload({ ...payload, accountant_email: e.target.value });
                                            }}
                                            value={payload.accountant_email}
                                            label="Client Project Accountant Email"
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid direction="row">
                                    <Grid spacing={2} item display="flex" alignItems="center">
                                        <TextField
                                            value={initial}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\d/g, '').substring(0, 3).toUpperCase();
                                                setInitial(value);
                                            }}
                                            label="Initial Client"
                                        />
                                        <Button sx={{ ml: 2 }} variant="outlined" onClick={() => fetchInvoiceCode()} disabled={disableInit}>
                                            Get Code
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: 'primary',
                                                    position: 'absolute',
                                                    top: '45%',
                                                    left: '47%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px'
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Stack container direction="row" spacing={1}>
                                    <Typography variant="h5">Letter Number : </Typography>
                                    <Typography> {payload.invoice_code} </Typography>
                                </Stack>
                                <Grid item xs={12} md={12} spacing={2} direction="row">
                                    <Typography variant="h5" paddingBottom={0.5}>
                                        Upload Invoice File *
                                    </Typography>
                                    <input
                                        ref={uploadInputRef}
                                        accept="application/pdf"
                                        id="icon-button-file"
                                        required
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => setPayload({ ...payload, file: e.target.files[0] })}
                                    />
                                    <Button
                                        sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleButtonClick}
                                    >
                                        <div>
                                            <IconFileBroken />
                                            <Typography>Click to Add File</Typography>
                                        </div>
                                    </Button>
                                </Grid>

                                {payload.file.name ? (
                                    <>
                                        <Stack>
                                            <Link href={URL.createObjectURL(payload.file)} target="_blank" rel="noopener noreferrer">
                                                <Chip
                                                    label={!payload.file.name ? 'View Contract Extension Files' : payload.file.name}
                                                    size="medium"
                                                    variant="outlined"
                                                    onClick={() => {}}
                                                    sx={{
                                                        background:
                                                            theme.palette.mode === 'dark'
                                                                ? theme.palette.dark.main
                                                                : theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark,
                                                        width: '100%'
                                                    }}
                                                />
                                            </Link>
                                        </Stack>
                                    </>
                                ) : (
                                    <Stack>
                                        {/* <Link
                                            href={payload.file}
                                            onClick={() => fetchFile(invoiceId)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        > */}
                                        <Chip
                                            label={!payload.file ? 'View Contract Extension Files' : payload.file}
                                            size="medium"
                                            variant="outlined"
                                            onClick={() => fetchFile(invoiceId)}
                                            // onClick={() => {}}
                                            // onClick={() => {
                                            //     fetchFile(payload.file);
                                            //     URL.createObjectURL();
                                            // }}
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.dark.main
                                                        : theme.palette.primary.light + 60,
                                                color: theme.palette.primary.dark,
                                                width: '100%'
                                            }}
                                        />
                                        {/* </Link> */}
                                    </Stack>
                                )}
                                <Stack direction="row" spacing={2} justifyContent="end">
                                    <Button variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                        Cancel
                                    </Button>
                                    <Button
                                        id="button-client-submit"
                                        variant="contained"
                                        type={loading ? 'button' : 'submit'}
                                        disabled={loading || disable}
                                    >
                                        {loading ? 'Loading' : 'Update Invoice'}
                                    </Button>
                                </Stack>
                            </Stack>
                        </form>
                    </CardContent>
                </Card>
            </Modal>
        </>
    );
};

export default EditInvoiceModal;
