import { Grid, Typography } from '@mui/material';

const GridTextValue = ({ v1, v2, icon }) => (
    <Grid container alignItems="center" paddingY={1}>
        <Grid item xs={5} paddingRight={1}>
            <Typography variant="h5" sx={{ color: '#697586' }}>
                {v1}
            </Typography>
        </Grid>
        <Grid item alignItems="center" paddingRight={1}>
            {icon || (
                <Typography variant="h5" fontWeight={400}>
                    :
                </Typography>
            )}
        </Grid>
        <Grid item xs={5}>
            <Typography variant="h5" sx={{ fontWeight: 400, color: '#5E35B1' }}>
                {v2}
            </Typography>
        </Grid>
    </Grid>
);

export default GridTextValue;
