import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';

import config from 'config';
import Logo from 'assets/svg/OuresLogoTextDark.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <img style={{ width: 150 }} src={Logo} alt="oures-logo" />
    </ButtonBase>
);

export default LogoSection;
