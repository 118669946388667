import { storageKey } from 'store/constant';

// eslint-disable-next-line
export const hasPermission = (permissions = [], myPermissions = []) => {
    return permissions.find((x) => myPermissions.some((p) => p.permission_name === x));
};

export const getUserInfo = () => {
    const info = localStorage.getItem(storageKey.USER_INFO);
    const parsed = JSON.parse(info);
    if (parsed && parsed.sub) {
        return parsed.sub;
    }
    return null;
};
