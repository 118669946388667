import { Fragment, useCallback, useEffect, useRef } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { getUserInfo } from 'helper/general';
import moment from 'moment';

const ChartHistory = ({ theme, confess }) => {
    // scroll to bottom when new message is sent or received
    const wrapper = useRef(document.createElement('div'));
    const el = wrapper.current;
    const scrollToBottom = useCallback(() => {
        el.scrollIntoView(false);
    }, [el]);

    useEffect(() => {
        scrollToBottom();
    }, [confess.length, scrollToBottom]);

    return (
        <Grid container spacing={gridSpacing} ref={wrapper}>
            {confess &&
                confess.map((con, index) => (
                    <Fragment key={index}>
                        {getUserInfo().id === con.created_id ? (
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        display: 'inline-block',
                                        float: 'right',
                                        bgcolor: theme.palette.primary.light,
                                        width: 'fit-content',
                                        ml: 'auto',
                                        px: 2,
                                        py: 1
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ color: theme.palette.primary.dark }}>
                                                {con.created_by}
                                            </Typography>
                                            <Typography variant="body2" mt={0.5}>
                                                {con.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align="right" variant="subtitle2">
                                                {moment(con.created_at).format('HH:mm')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={7}>
                                <Card
                                    sx={{
                                        display: 'inline-block',
                                        float: 'left',
                                        background: theme.palette.secondary.light,
                                        px: 2,
                                        py: 1
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ color: theme.palette.secondary.dark }}>
                                                {con.created_by}
                                            </Typography>
                                            <Typography variant="body2" mt={0.5}>
                                                {con.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align="right" variant="subtitle2">
                                                12.23 AM
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}
                    </Fragment>
                ))}
        </Grid>
    );
};

export default ChartHistory;
