function IconBagOffice({ color = '#454348', width = 16, height = 17, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="a"
                style={{
                    maskType: 'alpha'
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={width}
                height={height}
            >
                <path fill="#D9D9D9" d="M0 0.5H16V16.5H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M2.667 14.5c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.392-.941V5.833c0-.366.13-.68.392-.941.261-.261.575-.392.942-.392h2.666V3.167c0-.367.13-.68.392-.942.261-.261.575-.392.942-.392h2.666c.367 0 .68.13.942.392.261.261.392.575.392.942V4.5h2.666c.367 0 .68.13.942.392.261.26.392.575.392.941v7.334c0 .366-.13.68-.392.941a1.284 1.284 0 01-.942.392H2.667zm4-10h2.666V3.167H6.667V4.5zm6.666 6H10v1.333H6V10.5H2.667v2.667h10.666V10.5zm-6 0h1.334V9.167H7.333V10.5zM2.667 9.167H6V7.833h4v1.334h3.333V5.833H2.667v3.334z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default IconBagOffice;
