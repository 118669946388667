// material-ui
import {
    Box,
    Chip,
    TextField,
    Button,
    Stack,
    Autocomplete,
    FormGroup,
    FormControlLabel,
    Radio,
    Divider,
    Grid,
    LinearProgress,
    IconButton,
    Typography
} from '@mui/material';

import PropTypes from 'prop-types';
import { gridSpacing } from 'store/constant';

import React, { useEffect, useRef, useState } from 'react';

import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Delete } from '@mui/icons-material';
import moment from 'moment';

// progress
function LinearProgressWithLabel({ value, ...other }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress {...other} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

const EditProfileTab = () => {
    const { resourceId } = useParams();
    const [detail, setDetail] = React.useState({ resources: {}, users: {} });

    // for patch edit
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [skills, setSkills] = useState([]);
    const [skillSearch, setSkillSearch] = useState('');
    const textArea = true;

    const [payload, setPayload] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        gender: '',
        nik: '',
        kk: '',
        address: '',
        domisili_address: '',
        npwp: '',
        skills: [
            {
                skill_id: '',
                skill_name: '',
                experience: 0
            }
        ].filter((x) => x.skill_id !== '')
    });

    const getResourceData = async () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };
        axios
            .get(`${process.env.REACT_APP_API_URL}/resources/${resourceId}`, config)
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.length > 0) {
                    setDetail(res.data.data[0]);

                    setPayload({
                        name: res.data.data[0].users.name,
                        email: res.data.data[0].users.email,
                        phoneNumber: res.data.data[0].users.phoneNumber,
                        gender: res.data.data[0].users.gender,
                        nik: res.data.data[0].resources.nik,
                        kk: res.data.data[0].resources.kk,
                        address: res.data.data[0].users.address,
                        domisili_address: res.data.data[0].users.domisili_address,
                        npwp: res.data.data[0].resources.npwp,
                        skills: res.data.data[0].resources.skills.map((x) => {
                            return {
                                skill_id: x.skill_id,
                                skill_name: x.skill_name,
                                experience: x.experience / 12
                            };
                        })
                    });
                }
            })
            .catch();
    };

    const handleUpdateData = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };
        const formPayload = {
            ...payload,
            skills: payload.skills.map((x) => {
                return {
                    skill_id: x.skill_id,
                    skill_name: x.skill_name,
                    experience: x.experience
                };
            }),
            birth_date: moment(payload.birth_date).format('YYYY-MM-DD'),
            competence_id: payload.competence_id
        };

        await axios
            .put(`${process.env.REACT_APP_API_URL}/resources/user/${detail.users.id}`, formPayload, config)
            .then((res) => {
                if (res) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'Update Resources Data Success',
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    setSuccess(true);
                    navigate(`/resources/view/${resourceId}/detail`);
                }
            })
            .catch((e) => {
                if (e && e.response) {
                    setSuccess(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: e.response.data.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: e,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setSuccess(false);
                }
            })
            .finally(() => {
                setLoading(false);
                getResourceData();
                // setPayload({
                //     name: '',
                //     email: '',
                //     phoneNumber: '',
                //     gender: '',
                //     nik: '',
                //     kk: '',
                //     address: '',
                //     domisili_address: '',
                //     npwp: '',
                //     skills: [
                //         {
                //             skill_id: '',
                //             skill_name: '',
                //             experience: 0
                //         }
                //     ].filter((x) => x.skill_id !== '')
                // });
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            payload.gender === '' &&
            payload.name === '' &&
            payload.email === '' &&
            payload.phoneNumber === '' &&
            payload.skills.filter((x) => x.skill_id === '')
        ) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Please Fill Out All Fields!',
                alertSeverity: 'warning',
                variant: 'alert'
            });
        } else {
            handleUpdateData();
        }
    };

    const handleOptionClick = (option) => {
        const isSelected = payload.skills.some((skill) => skill.skill_id === option.id);
        let newSelectedOptions = [];

        if (isSelected) {
            newSelectedOptions = payload.skills.filter((skill) => skill.skill_id !== option.id);
        } else {
            newSelectedOptions = [...payload.skills, { skill_id: option.id, skill_name: option.name, experience: 0 }];
        }

        setPayload({
            ...payload,
            skills: newSelectedOptions
        });
    };

    const fetchSkills = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/skill?page=1&limit=20${skillSearch ? `&filter=${skillSearch}` : ''}`).then((res) => {
            if (res) {
                setSkills(res.data.data);
            }
        });
    };

    useEffect(() => {
        fetchSkills();
    }, [skillSearch]);

    React.useEffect(() => {
        // const config = {
        //     headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        // };
        // axios
        //     .get(`${process.env.REACT_APP_API_URL}/resources/${resourceId}`, config)
        //     .then((res) => {
        //         if (res && res.data && res.data.data && res.data.data.length > 0) {
        //             setDetail(res.data.data[0]);

        //             setPayload({
        //                 name: res.data.data[0].users.name,
        //                 email: res.data.data[0].users.email,
        //                 phoneNumber: res.data.data[0].users.phoneNumber,
        //                 gender: res.data.data[0].users.gender,
        //                 nik: res.data.data[0].resources.nik,
        //                 kk: res.data.data[0].resources.kk,
        //                 address: res.data.data[0].users.address,
        //                 domisili_address: res.data.data[0].users.domisili_address,
        //                 npwp: res.data.data[0].resources.npwp,
        //                 skills: res.data.data[0].resources.skills.map((x) => {
        //                         return {
        //                             skill_id: x.skill_id,
        //                             skill_name: x.skill_name,
        //                             experience: x.experience/12
        //                         };
        //                     })
        //             });
        //         }
        //     })
        //     .catch();
        getResourceData();
    }, [resourceId]);
    return detail && detail.resources.id ? (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={12} xs={12}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} spacing={2} direction="row">
                            <Typography variant="h3">1. Personal Identity</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} spacing={2} direction="row">
                            <TextField
                                required
                                name="pic_name"
                                id="input-client-picName"
                                margin="normal"
                                value={payload.name}
                                onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Name"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} spacing={2} direction="row">
                            <TextField
                                required
                                margin="normal"
                                id="input-client-picEmail"
                                value={payload.email}
                                onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Email"
                                type="email"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} spacing={2} direction="row">
                            <TextField
                                required
                                margin="normal"
                                id="input-client-picPhone"
                                value={payload.phoneNumber}
                                onChange={(e) => setPayload({ ...payload, phoneNumber: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Phone Number"
                                type="text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} direction="row">
                            <Typography>Gender</Typography>
                            <FormGroup sx={{ display: 'flex' }}>
                                <Grid item direction="row">
                                    <FormControlLabel
                                        required
                                        control={
                                            <Radio
                                                checked={payload.gender === 'male'}
                                                onChange={(e) => setPayload({ ...payload, gender: e.target.value })}
                                                value="male"
                                            />
                                        }
                                        label="Male"
                                    />
                                    <FormControlLabel
                                        required
                                        control={
                                            <Radio
                                                checked={payload.gender === 'female'}
                                                onChange={(e) => setPayload({ ...payload, gender: e.target.value })}
                                                value="female"
                                            />
                                        }
                                        label="Female"
                                    />
                                </Grid>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6} spacing={2} direction="row">
                            <TextField
                                required
                                multiline={textArea}
                                minRows={3}
                                maxRows={10}
                                margin="normal"
                                id="input-client-picDomicile"
                                value={payload.address}
                                onChange={(e) => setPayload({ ...payload, address: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Address"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} spacing={2} direction="row">
                            <TextField
                                required
                                multiline={textArea}
                                minRows={3}
                                maxRows={10}
                                margin="normal"
                                id="input-client-domicile"
                                value={payload.domisili_address}
                                onChange={(e) => setPayload({ ...payload, domisili_address: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Domicile"
                                type="text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={12} spacing={2} marginY={2} direction="column">
                            <Grid item md={12} spacing={2} marginY={2} direction="column">
                                <Typography variant="h5">Personal Identity</Typography>
                                <Typography variant="p">You can fill these forms later when you are ready for assignment</Typography>
                            </Grid>
                            <Grid item md={12} spacing={2} direction="row" sx={{ border: 'dashed', borderRadius: '10px', padding: 2 }}>
                                <TextField
                                    margin="normal"
                                    name="Family Card Number"
                                    id="input-client-family-card-number"
                                    value={payload.kk}
                                    onChange={(e) => setPayload({ ...payload, kk: e.target.value })}
                                    sx={{ width: '100%' }}
                                    label="Family Card Number"
                                    type="text"
                                    variant="outlined"
                                />
                                <TextField
                                    margin="normal"
                                    name="Identity Number"
                                    id="input-client-identity-number"
                                    value={payload.nik}
                                    onChange={(e) => setPayload({ ...payload, nik: e.target.value })}
                                    sx={{ width: '100%' }}
                                    label="Identity Number"
                                    type="text"
                                    variant="outlined"
                                />
                                <TextField
                                    margin="normal"
                                    id="input-client-npwp"
                                    value={payload.npwp}
                                    onChange={(e) => setPayload({ ...payload, npwp: e.target.value })}
                                    sx={{ width: '100%' }}
                                    label="NPWP"
                                    type="text"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={12} spacing={2} direction="row" marginY={2}>
                            <Grid item md={12} spacing={2} direction="row" marginY={2}>
                                <Typography variant="h3">2. Skill & Experiences</Typography>
                                <Typography variant="h5">Please Choose One or More Skills Below</Typography>
                            </Grid>
                            <Grid item md={12} spacing={2} direction="row" sx={{ border: 'solid', borderRadius: '10px', padding: 2 }}>
                                <TextField
                                    name="Identity Number"
                                    id="input-skills"
                                    margin="normal"
                                    value={skillSearch}
                                    placeholder="Search Skill..."
                                    onChange={(e) => setSkillSearch(e.target.value)}
                                    sx={{ width: '100%' }}
                                    type="search"
                                    onBlur={(e) => console.log(e)}
                                    variant="outlined"
                                />
                                <Divider />
                                <Grid item md={12} spacing={2} direction="row" sx={{ padding: 2 }}>
                                    {skills.map((item) => (
                                        <Chip key={item.id} label={item.name} variant="outlined" onClick={() => handleOptionClick(item)} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        {payload.skills.length > 0 && (
                            <Grid item md={12} direction="row">
                                <Grid item md={12} spacing={2} marginY={2} direction="row">
                                    <Typography variant="h5">Add your experience on each selected skill</Typography>
                                </Grid>
                                {payload.skills.map((item, index) => (
                                    <Grid
                                        key={item.skill_id}
                                        container
                                        md={12}
                                        spacing={2}
                                        marginY={1}
                                        direction="row"
                                        justifyContent="between"
                                        alignItems="center"
                                        display="flex"
                                    >
                                        <Grid item xs={12} md={3} direction="row" alignItems="center" display="flex">
                                            <TextField
                                                required
                                                id="input-client-skill-name"
                                                value={item.skill_name}
                                                disabled
                                                sx={{ width: '100%' }}
                                                label="Skill Name"
                                                type="text"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3} direction="row" alignItems="center" display="flex">
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                id="work-status"
                                                value={item.experience}
                                                options={['1', '2', '3', '4', '5', '6', '7']}
                                                sx={{ width: '100%' }}
                                                onChange={(e, v) => {
                                                    const newSkills = [...payload.skills];
                                                    newSkills[index].experience = v;
                                                    setPayload({
                                                        ...payload,
                                                        skills: newSkills
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} required label="Work Status" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3} direction="row" alignItems="center" display="flex">
                                            <IconButton
                                                onClick={() => {
                                                    const newSkills = [...payload.skills];
                                                    newSkills.splice(index, 1);
                                                    setPayload({
                                                        ...payload,
                                                        skills: newSkills
                                                    });
                                                }}
                                                aria-label="delete"
                                                size="small"
                                            >
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        <Grid item xs={12} md={12} textAlign="right" alignItems="right">
                            <Stack direction="row" spacing={2} justifyContent="end">
                                <Button
                                    id="button-client-submit"
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading}
                                >
                                    {loading ? 'Loading' : `Update Data`}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    ) : (
        <p>Tidak ditemukan data.</p>
    );
};

export default EditProfileTab;
