// material-ui
import { Box, Grid, TextField, Button, Stack, Typography, Avatar, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconBuildingSkyscraper } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { createNewJobPosition, getDetailJobPosition, updateJobPosition } from 'utils/services/job-position';
import { getDivisi } from 'utils/services/divisi';

const JobPositionForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');
    const [divisi, setDivisi] = useState(null);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        name: '',
        division_id: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        createNewJobPosition({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    navigate('/jobPosition');
                } else {
                    console.log(res);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const form = {
            name: payload.name,
            division_id: payload.division_id
        };
        updateJobPosition({ identify, form }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                navigate('/jobPosition');
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate('/jobPosition');
            }
        });
    };

    const fetchDivisi = async () => {
        getDivisi({ page: 1, limit: 10, search: '' })
            .then((res) => {
                setDivisi(res.data);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };

    const fetchDetail = async (identify) => {
        getDetailJobPosition({ identify }).then((res) => {
            setPayload(res.data);
        });
    };

    useEffect(() => {
        fetchDivisi();
        if (action === 'update' && identify) {
            fetchDetail(identify);
        }
    }, [identify, action]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconBuildingSkyscraper color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Create new Job Position' : 'Update Job Position'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/jobPosition')}
            />
            <Box
                component="form"
                autoComplete="off"
                onSubmit={(ev) => (action && action === 'create' ? handleSubmit(ev) : handleUpdate(ev))}
            >
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sx={12} md={6} spacing={2}>
                        <TextField
                            required
                            name="jobPosition"
                            margin="normal"
                            value={payload.name}
                            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Job Position"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Division</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={payload.division_id}
                                label="Division"
                                onChange={(e) => setPayload({ ...payload, division_id: e.target.value })}
                            >
                                {divisi &&
                                    divisi.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit" disabled={loading}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Create' : 'Update Job Position'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default JobPositionForm;
