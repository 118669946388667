import { IconButton, Stack, Typography } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useState } from 'react';

const ListCriteriaCell = ({ criterias = [] }) => {
    const [open, setOpen] = useState(false);
    return criterias && criterias.length > 0 ? (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {!open ? (
                    <Typography>
                        <strong>{criterias[0]?.skill?.name ?? ''}</strong> with {criterias[0].min_experience} -{' '}
                        {criterias[0].max_experience} year experiences
                    </Typography>
                ) : (
                    <Stack direction="column" spacing={1}>
                        {criterias.map((item, index) => (
                            <Typography key={index}>
                                <strong>{item?.skill?.name ?? ''}</strong> with {item.min_experience} - {item.max_experience} year
                                experiences
                            </Typography>
                        ))}
                    </Stack>
                )}
                {criterias && criterias.length > 1 && (
                    <IconButton
                        title={open ? 'Show Less' : 'Show More'}
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{ borderRadius: '50%', width: '40px', height: '40px' }}
                    >
                        {open ? (
                            <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                        ) : (
                            <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                        )}
                    </IconButton>
                )}
            </Stack>
        </>
    ) : (
        <p>There are no criteria</p>
    );
};

export default ListCriteriaCell;
