import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getUserQuotation = async ({ page, limit, search }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    
    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/assignmentQuotation/list?page=${page}&limit=${limit}&search=${search}`,
        config
    );
};

export const getRequestQuotation = async ({ formId }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/assignmentQuotation/list/assignment?form_request_assignment_id=${formId}`,
        config
    );
};

export const getRequestDone = async ({ page, limit, search }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/assignmentContract/done?page=${page}&limit=${limit}&search=${search}`,
        config
    );
};

export const postQuotation = async ({ payload }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.post(`${process.env.REACT_APP_API_URL}/assignmentQuotation/`, { ...payload }, config);
};

export const updateStatusAssignment = async ({ id, payload, notes = '' }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.put(`${process.env.REACT_APP_API_URL}/assignment/status/${id}?process_step=${payload}&notes=${notes}`, {}, config);
};

export const updateStatusAssignmentQuot = async ({ id, payload }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.put(`${process.env.REACT_APP_API_URL}/quotation/status/${id}?status=${payload}`, {}, config);
};

export const addLetterFiles = async ({ id, payload }) => {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}`,
            'Content-Type': 'multipart/form-data'
        }
    };
    return ApiService.post(`${process.env.REACT_APP_API_URL}/quotation/${id}`, payload, config);
};
