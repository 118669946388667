/* eslint-disable no-unused-vars */
import { Autocomplete, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { getListResource } from 'utils/services/dashboard/resource';
import { postAssignCandidateBatch } from 'utils/services/candidates';
import DialogModal from 'ui-component/dialogModal';

const AssignModal = ({ open, handleClose, assigned, id, handleRefresh }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState([]);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);

    const fetchListResource = async () => {
        setLoading(true);
        await getListResource({ page, search, limit: 20 })
            .then((res) => {
                if (res && res.success) {
                    setPage(page + 1);
                    const filteredData = res.data.filter(
                        (item) =>
                            !payload.some((payloadItem) => payloadItem.id === item.id) &&
                            !assigned.some((assignedItem) => assignedItem.name === item.users.name)
                    );
                    setList((prevList) => [...prevList, ...filteredData]);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        if (listboxNode.scrollHeight - position <= 1) {
            const currentScroll = listboxNode.scrollTop + 30;
            fetchListResource().then(() => {
                listboxNode.scrollTop = currentScroll;
            });
        }
    };

    const handleSubmit = async () => {
        const form = [];
        payload.forEach((x) => {
            form.push({
                form_request_id: id,
                resource_id: x.id
            });
        });
        payload.forEach((x) => {
            if (x.resources) {
                form.push({
                    form_request_id: id,
                    resource_id: x.resources.id
                });
            }
        });

        if (form.length > 0) {
            await postAssignCandidateBatch(form)
                .then((res) => {
                    if (res && res.data.success) {
                        handleClose();
                        setPayload([]);
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.data.message,
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => handleRefresh());
        }
    };

    useEffect(() => {
        fetchListResource();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <DialogModal open={open} handleClose={handleClose} overrideStyle={{ width: '45%' }}>
            <Typography variant="h2" mb="20px">
                Assign Candidates
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" mb={1.5}>
                <Autocomplete
                    multiple
                    fullWidth
                    options={list}
                    getOptionLabel={(option) => option?.users?.name ?? ''}
                    value={payload}
                    onChange={(e, value) => {
                        setPayload(value);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setSearch(newInputValue);
                    }}
                    ListboxProps={{
                        onScroll: handleScroll
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            focused
                            label="Developer"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                />
            </Stack>
            <Button
                disableElevation
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
                onClick={() => handleSubmit()}
                disabled={payload.length === 0}
            >
                Submit Candidates
            </Button>
        </DialogModal>
    );
};
export default AssignModal;
