import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getRegistration = async ({ page, limit, filter }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/registrationRequest?page=${page}&limit=${limit}&search=${filter}`, config);
};

export const getOneRegistration = async (id) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/registrationRequest/${id}`, config);
};

export const changeScheduleRegistration = async ({ id, payload })  => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/registrationRequest/meeting/${id}`, { ...payload }, config);
};

export const changeRegistrationStatus = async ({ id, payload })  => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/registrationRequest/status/${id}`, { ...payload }, config);
};

// export default {getRegistration, getOneRegistration};
