// material-ui
import { Box, Grid, TextField, Button, Stack, Typography, Avatar, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { createNewSkill, getDetailSkill, updateSkill } from 'utils/services/skills';
import { createNewEmployee, getDetailEmployee, updateEmployee } from 'utils/services/employee';
import { getJobPosition } from 'utils/services/job-position';

const EmployeeForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');
    const [jobPosition, setJobPosition] = useState(null);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        job_position_id: '',
        no_kk: '',
        nik: '',
        no_npwp: '',
        name_user: '',
        status_user: '',
        in_date: '',
        out_date: '',
        start_date: '',
        end_date: '',
        email: '',
        phone: '',
        educational: '',
        marital: '',
        dependents: '',
        place_of_birth: '',
        date_of_birth: '',
        address: '',
        domisili_address: '',
        bank_account: '',
        bank_number: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const form = {
            ...payload,
            is_active: true
        };
        createNewEmployee({ payload: form })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    navigate('/employee');
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
            .catch((err) => {
                const error = JSON.stringify(err.response.data.message.replace(`"`, ``));
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: error.replaceAll(`"`, ``),
                    alertSeverity: 'error',
                    variant: 'alert'
                });
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const form = {
            name: payload.name
        };
        updateEmployee({ identify, form }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                navigate('/skills');
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate('/skills');
            }
        });
    };
    const fetchJobPosition = async () => {
        getJobPosition({ page: 1, limit: 10, search: '' })
            .then((res) => {
                setJobPosition(res.data);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };

    const fetchDetail = async (identify) => {
        getDetailEmployee({ identify }).then((res) => {
            setPayload(res.data);
        });
    };

    useEffect(() => {
        fetchJobPosition();
        if (action === 'update' && identify) {
            fetchDetail(identify);
        }
    }, [identify, action]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Create new Employee' : 'Update Employee'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/skills')}
            />
            <Box
                component="form"
                autoComplete="off"
                onSubmit={(ev) => (action && action === 'create' ? handleSubmit(ev) : handleUpdate(ev))}
            >
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sx={12} md={6} spacing={2}>
                        <TextField
                            required
                            name="name_user"
                            margin="dense"
                            value={payload.name_user}
                            onChange={(e) => setPayload({ ...payload, name_user: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Name Employee"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Job Position</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={payload.job_position_id}
                                label="Job Position"
                                onChange={(e) => setPayload({ ...payload, job_position_id: e.target.value })}
                            >
                                {jobPosition &&
                                    jobPosition.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <TextField
                            required
                            name="no_kk"
                            margin="dense"
                            type="number"
                            value={payload.no_kk}
                            onChange={(e) => setPayload({ ...payload, no_kk: e.target.value })}
                            sx={{
                                width: '100%',
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                            label="Family Card (No KK)"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="nik"
                            margin="dense"
                            type="number"
                            value={payload.nik}
                            onChange={(e) => setPayload({ ...payload, nik: e.target.value })}
                            sx={{
                                width: '100%',
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                            label="NIK"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="no_npwp"
                            margin="dense"
                            type="number"
                            value={payload.no_npwp}
                            onChange={(e) => setPayload({ ...payload, no_npwp: e.target.value })}
                            sx={{
                                width: '100%',
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                            label="No NPWP"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Status User</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={payload.status_user}
                                label="Status User"
                                onChange={(e) => setPayload({ ...payload, status_user: e.target.value })}
                            >
                                <MenuItem value="Magang">Magang</MenuItem>
                                <MenuItem value="Probation">Probation</MenuItem>
                                <MenuItem value="Kontrak">Kontrak</MenuItem>
                                <MenuItem value="Tetap">Tetap</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            type="date"
                            required
                            name="in_date"
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            value={payload.in_date}
                            onChange={(e) => setPayload({ ...payload, in_date: e.target.value })}
                            sx={{ width: '100%' }}
                            label="In Date"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            type="date"
                            required
                            name="out_date"
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            value={payload.out_date}
                            onChange={(e) => setPayload({ ...payload, out_date: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Out Date"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            type="date"
                            required
                            name="start_date"
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            value={payload.start_date}
                            onChange={(e) => setPayload({ ...payload, start_date: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Start Date"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            type="date"
                            required
                            name="end_date"
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            value={payload.end_date}
                            onChange={(e) => setPayload({ ...payload, end_date: e.target.value })}
                            sx={{ width: '100%' }}
                            label="End Date"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="email"
                            margin="dense"
                            type="email"
                            value={payload.email}
                            onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Email"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="phone"
                            margin="dense"
                            type="number"
                            value={payload.phone}
                            onChange={(e) => setPayload({ ...payload, phone: e.target.value })}
                            sx={{
                                width: '100%',
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                            label="Phone"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Education</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={payload.educational}
                                label="Educational"
                                onChange={(e) => setPayload({ ...payload, educational: e.target.value })}
                            >
                                <MenuItem value="SMA">SMA</MenuItem>
                                <MenuItem value="S1">S1</MenuItem>
                                <MenuItem value="S2">S2</MenuItem>
                                <MenuItem value="S3">S3</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">Marital Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={payload.marital}
                                label="Marital Status"
                                onChange={(e) => setPayload({ ...payload, marital: e.target.value })}
                            >
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Not Married">Not Married</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            type="number"
                            required
                            name="dependents"
                            margin="dense"
                            value={payload.dependents}
                            onChange={(e) => setPayload({ ...payload, dependents: e.target.value })}
                            sx={{
                                width: '100%',
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                            label="Dependents"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="place_of_birth"
                            margin="dense"
                            value={payload.place_of_birth}
                            onChange={(e) => setPayload({ ...payload, place_of_birth: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Place of Birth"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="date_of_birth"
                            margin="dense"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={payload.date_of_birth}
                            onChange={(e) => setPayload({ ...payload, date_of_birth: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Date Of Birth"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="address"
                            margin="dense"
                            value={payload.address}
                            onChange={(e) => setPayload({ ...payload, address: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Address"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="domisili_address"
                            margin="dense"
                            value={payload.domisili_address}
                            onChange={(e) => setPayload({ ...payload, domisili_address: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Domicile Address"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="bank_account"
                            margin="dense"
                            value={payload.bank_account}
                            onChange={(e) => setPayload({ ...payload, bank_account: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Bank Account"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                        <TextField
                            required
                            name="bank_number"
                            margin="dense"
                            type="number"
                            value={payload.bank_number}
                            onChange={(e) => setPayload({ ...payload, bank_number: e.target.value })}
                            sx={{
                                width: '100%',
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                            label="Bank Number"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit" disabled={loading}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Create' : 'Update Employee'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default EmployeeForm;
