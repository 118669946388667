import { Link } from 'react-router-dom';
import { Grid, Tab, Tabs } from '@mui/material';
import { TabStyle } from 'style/tab';
import { gridSpacing } from 'store/constant';

import MainCard from 'ui-component/cards/MainCard';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import TabPanelFunc from 'component/function/TabPanelFunc';
import RequestDetail from './RequestDetail';
import RequestCandidate from './RequestCandidates';

const tabsOption = [
    {
        label: 'Detail',
        icon: <AccountCircleTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Candidates',
        icon: <DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    }
];

const RequestData = () => {
    const { value, handleChange, TabPanel, a11yProps } = TabPanelFunc();

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        id="tabs-request-detail"
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={TabStyle()}
                    >
                        {tabsOption.map((tab, index) => (
                            <Tab
                                id="tab-request-detail"
                                key={index}
                                component={Link}
                                to="#"
                                icon={tab.icon}
                                label={tab.label}
                                {...a11yProps(index)}
                            />
                        ))}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <RequestDetail />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <RequestCandidate />
                    </TabPanel>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default RequestData;
