import { Box } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';
import GridTextValue from 'component/grid/GridTextValue';
import IconCreditCardHeart from 'assets/images/icons/IconCreditCardHeart';
import IconBagOffice from 'assets/images/icons/IconBagOffice';
import IconPinDrop from 'assets/images/icons/IconPinDrop';

const SubcardExpectancy = () => (
    <SubCard sx={{ mt: 3 }} title="Expectancy">
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <GridTextValue v1="Salary Expectation" v2="Rp10.000.000,00" icon={<IconCreditCardHeart size={16} />} />
            <GridTextValue v1="Job Type Expectation" v2="Fulltime" icon={<IconBagOffice width={16} height={17} color="#5E35B1" />} />
            <GridTextValue v1="Work Type Expectation" v2="Remote" icon={<IconPinDrop width={16} height={17} color="#5E35B1" />} />
        </Box>
    </SubCard>
);

export default SubcardExpectancy;
