// material-ui
import {
    Chip,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import { OpenInNewRounded } from '@mui/icons-material';
import TableLoader from 'ui-component/loader/TableLoader';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { useTheme } from '@mui/material/styles';
import { getViewInvoice, updateStatusInvoice } from 'utils/services/invoice';
import EditInvoiceModal from './modal/EditInvoiceModal';
import TableEmpty from 'ui-component/loader/TableEmpty';
import { useState } from 'react';

function InvoiceList({ startIndex, invoice = [], loading, setLoading, handleRefresh }) {
    const dispatch = useDispatch();
    const [invoiceId, setInvoiceId] = useState('');
    const [open, setOpen] = useState('');
    const theme = useTheme('oures');

    const fetchFile = async (id) => {
        setLoading(true);
        await getViewInvoice(id)
            .then((res) => {
                if (res) {
                    setLoading(false);
                    const file = new Blob([res], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    const link = window.document.createElement('a');
                    link.setAttribute('download', `Invoice`);
                    link.href = fileURL;
                    link.click();
                    setTimeout(URL.revokeObjectURL, 5000, fileURL);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const handleSubmit = async ({ id, value }) => {
        setLoading(true);
        await updateStatusInvoice({ id, payload: value })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
            .finally(() => handleRefresh());
    };

    return (
        <>
            {open === 'editInvoice' && (
                <EditInvoiceModal
                    action={open}
                    open={open === 'editInvoice'}
                    invoiceId={invoiceId}
                    handleClose={() => setOpen()}
                    handleRefresh={handleRefresh}
                    loading={loading}
                    setLoading={(e) => setLoading(e)}
                />
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell align="left">Invoice Code</TableCell>
                            <TableCell>Client Name</TableCell>
                            <TableCell>File Preview</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {invoice && invoice.length > 0 ? (
                                    invoice.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{index + startIndex}</TableCell>
                                            <TableCell>{row.invoice_code}</TableCell>
                                            <TableCell>{row.client_name}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    row={row}
                                                    index={index}
                                                    label="View Invoice"
                                                    size="small"
                                                    clickable
                                                    onClick={() => fetchFile(row.id)}
                                                    sx={{
                                                        background:
                                                            theme.palette.mode === 'dark'
                                                                ? theme.palette.dark.main
                                                                : theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    value="end"
                                                    control={
                                                        <Switch
                                                            onChange={() => {
                                                                handleSubmit({ id: row.id, value: row.is_done ? 0 : 1 });
                                                            }}
                                                            color="success"
                                                            checked={row.is_done}
                                                        />
                                                    }
                                                    label={row.is_done ? 'Done' : 'Not Done'}
                                                    labelPlacement="end"
                                                    sx={{ position: 'static', width: '120px' }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    <Tooltip placement="top" title="Edit Invoice">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('editInvoice');
                                                                setInvoiceId(row.id);
                                                            }}
                                                            color="primary"
                                                            aria-label="open"
                                                            size="large"
                                                        >
                                                            <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={7} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={7} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default InvoiceList;
