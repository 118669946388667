import React, { useState } from 'react';
import { getSkills } from 'utils/services/skills';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Typography
} from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
// project imports

// assets
import { IconSearch, Icon3dCubeSphere, IconGenderEpicene } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Link } from 'react-router-dom';
import { getEmployees } from 'utils/services/employee';
import EmployeeList from './EmployeList';
import { AddCircleTwoTone } from '@mui/icons-material';

const EmployeePage = () => {
    const [employee, setEmployee] = useState(null);
    const theme = useTheme('oures');
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const fetchEmployee = async () => {
        setLoading(true);
        getEmployees({ page, limit, search: filter })
            .then((res) => {
                setEmployee(res.data);
                setTotal(res.meta.total);
                handleClose();
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };
    React.useEffect(() => {
        fetchEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page, filter]);
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                            <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                <IconGenderEpicene color="#fff" />
                            </Avatar>
                            <Stack direction="column">
                                <Typography variant="h3" sx={{ mb: 0 }}>
                                    Employee
                                </Typography>
                                <Typography variant="caption" sx={{ mb: 2 }}>
                                    Please let us now your Employee
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <OutlinedInput
                            id="input-search-list-style1"
                            placeholder="Search"
                            onChange={(e) => setFilter(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" />
                                </InputAdornment>
                            }
                            size="small"
                        />
                        <Link to="/employee/form?action=create">
                            <IconButton>
                                <AddCircleTwoTone />
                            </IconButton>
                        </Link>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <EmployeeList employee={employee} refresh={() => fetchEmployee()} loading={loading} />
            <Grid item xs={12} sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination
                            onChange={(e, value) => setPage(value)}
                            count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                            color="primary"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            {limit} Rows
                        </Button>
                        <Menu
                            id="menu-user-list-style1"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={() => setLimit(10)}> 10 Rows</MenuItem>
                            <MenuItem onClick={() => setLimit(25)}> 25 Rows</MenuItem>
                            <MenuItem onClick={() => setLimit(50)}> 50 Rows</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default EmployeePage;
