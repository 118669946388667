import { Card, CardContent, CardHeader, Modal, Stack, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { blacklistResource } from 'utils/services/resource';
import MarkdownEditor from 'ui-component/forms/MarkdownEditor';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 735,
    boxShadow: 24,
    borderRadius: '12px'
};

const BlacklistModal = ({ open, handleClose, row, handleRefresh }) => {
    const dispatch = useDispatch();

    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        blacklist_notes: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await blacklistResource({ id: row.resource_id, status: 'blacklist', payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'error',
                        variant: 'alert'
                    });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
            .finally(() => handleRefresh());
    };

    const onCancel = () => {
        handleClose();
    };

    useEffect(() => {
        if (payload.blacklist_notes === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Card sx={modalStyle}>
                <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Blacklist Resource" />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <Typography>Are you sure you want to blacklist this resource?</Typography>
                            <MarkdownEditor
                                value={payload.blacklist_notes}
                                handleChange={(e) => setPayload({ ...payload, blacklist_notes: e })}
                            />
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <Button id="button-client-cancel" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {loading ? 'Loading' : 'Proceed'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default BlacklistModal;
