import { Card, CardContent, CardHeader, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { updateInterviewSchedule } from 'utils/services/interview-schedule';
import { BackupSharp } from '@mui/icons-material';
import { uploadFile } from 'utils/services/assignmentInterview';

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import moment from 'moment';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 535,
    boxShadow: 24,
    borderRadius: '12px'
};

export default function ScheduleModal({ open, handleClose, row, loading, setLoading, handleRefresh, action }) {
    const dispatch = useDispatch();
    const uploadCVRef = useRef(null);
    const uploadMoURef = useRef(null);

    const [disable, setDisable] = useState(false);
    const [payload, setPayload] = useState({
        resource_id: row.interview.resource_id,
        interviewer_id: row.interview.interviewer_id,
        start_date: row.interview.start_date,
        end_date: row.interview.end_date,
        description: '',
        meeting_link: row.interview.meeting_link,
        file_cv: null,
        file_mou: null
    });

    const handleButtonClick = (e) => {
        if (e === 'cv') {
            uploadCVRef.current.click();
        } else {
            uploadMoURef.current.click();
        }
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append('file_cv', payload.file_cv);
        formData.append('file_mou', payload.file_mou);

        if (action === 'updateSchedule') {
            await uploadFile({ id: row.interview.resource_id, payload: formData })
                .then((res) => {
                    if (res && res.success) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'success',
                            variant: 'alert'
                        });
                        updateInterviewSchedule({ identify: row.interview.id, form: payload }).then((res) => {
                            if (res && res.success) {
                                dispatch({
                                    type: SNACKBAR_OPEN,
                                    open: true,
                                    message: res.message,
                                    alertSeverity: 'success',
                                    variant: 'alert'
                                });
                            }
                        });
                        setLoading(false);
                        handleClose();
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'warning',
                            variant: 'alert'
                        });
                        setLoading(false);
                        handleClose();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    handleClose();
                    console.log(err);
                })
                .finally(() => handleRefresh());
        } else if (action === 'schedule') {
            await updateInterviewSchedule({ identify: row.interview.id, form: payload })
                .then((res) => {
                    if (res && res.success) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'success',
                            variant: 'alert'
                        });
                        setLoading(false);
                        handleClose();
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'warning',
                            variant: 'alert'
                        });
                        setLoading(false);
                        handleClose();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    handleClose();
                    console.log(err);
                })
                .finally(() => handleRefresh());
        }
    };

    const onCancel = () => {
        setPayload({
            ...payload,
            start_date: row.interview.start_date,
            end_date: row.interview.end_date
        });
        handleClose();
    };

    useEffect(() => {
        if (action === 'schedule') {
            if (payload.start_date === '' || payload.end_date === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else if (action === 'updateSchedule') {
            if (payload.start_date === '' || payload.end_date === '' || payload.file_cv === null || payload.file_mou === null) {
                setDisable(true);
            } else {
                setDisable(false);
            }
        }
    }, [action, payload]);

    useEffect(() => {
        if (action === 'schedule') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                description: row.interview.description
            }));
        } else if (action === 'updateSchedule') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                description: 'User Interview'
            }));
        }
    }, [action, row.interview.description]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Fade in={open}>
                <Card sx={modalStyle}>
                    <form onSubmit={(ev) => handleSubmit(ev)}>
                        <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6' }} title="Update Interview Schedule" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack spacing={2} direction="column">
                                        {action === 'updateSchedule' && (
                                            <>
                                                <TextField required fullWidth value={payload.description} label="Description" />
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label="Meeting Link"
                                                    value={payload.meeting_link}
                                                    onChange={(e) => setPayload({ ...payload, meeting_link: e.target.value })}
                                                />
                                                <Stack xs={12} spacing={1} direction="column" alignItems="center" justifyContent="center">
                                                    <Typography variant="h5">Upload CV Template *</Typography>
                                                    <input
                                                        required
                                                        type="file"
                                                        ref={uploadCVRef}
                                                        accept=".doc, .docx"
                                                        id="icon-button-file"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => setPayload({ ...payload, file_cv: e.target.files[0] })}
                                                    />
                                                    <Button
                                                        component="label"
                                                        variant="outlined"
                                                        startIcon={<BackupSharp />}
                                                        onClick={() => handleButtonClick('cv')}
                                                        sx={{ width: '100%', textTransform: 'none' }}
                                                    >
                                                        {!payload.file_cv ? (
                                                            <Typography>Click to Add File</Typography>
                                                        ) : (
                                                            <Typography>Click to Choose Different File</Typography>
                                                        )}
                                                    </Button>
                                                    {payload.file_cv && (
                                                        <Link
                                                            href={URL.createObjectURL(payload.file_cv)}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            underline="hover"
                                                            align="center"
                                                            width="50%"
                                                        >
                                                            {payload.file_cv.name}
                                                        </Link>
                                                    )}
                                                    <Typography variant="h5">Upload MoU Letter *</Typography>
                                                    <input
                                                        ref={uploadMoURef}
                                                        accept="application/pdf"
                                                        id="icon-button-file"
                                                        required
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => setPayload({ ...payload, file_mou: e.target.files[0] })}
                                                    />
                                                    <Button
                                                        component="label"
                                                        variant="outlined"
                                                        startIcon={<BackupSharp />}
                                                        onClick={() => handleButtonClick()}
                                                        sx={{ width: '100%', textTransform: 'none' }}
                                                    >
                                                        {!payload.file_mou ? (
                                                            <Typography>Click to Add File</Typography>
                                                        ) : (
                                                            <Typography>Click to Choose Different File</Typography>
                                                        )}
                                                    </Button>
                                                    {payload.file_mou && (
                                                        <Link
                                                            href={URL.createObjectURL(payload.file_mou)}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            underline="hover"
                                                            align="center"
                                                            width="50%"
                                                        >
                                                            {payload.file_mou.name}
                                                        </Link>
                                                    )}
                                                </Stack>
                                            </>
                                        )}
                                        <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="input-assignment-startDate"
                                                type="datetime-local"
                                                label="Start Date"
                                                value={moment.utc(payload.start_date).format('YYYY-MM-DD HH:mm')}
                                                onChange={(e) => setPayload({ ...payload, start_date: e.target.value })}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <TextField
                                                required
                                                fullWidth
                                                id="input-assignment-endDate"
                                                type="datetime-local"
                                                label="End Date"
                                                inputProps={{
                                                    min: moment.utc(payload.start_date).format('YYYY-MM-DD HH:mm')
                                                }}
                                                value={moment.utc(payload.end_date).format('YYYY-MM-DD HH:mm')}
                                                onChange={(e) => setPayload({ ...payload, end_date: e.target.value })}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item container gap={1}>
                                    <Button id="button-assignment-cancel" onClick={onCancel} variant="outlined" sx={{ marginLeft: 'auto' }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        disableElevation
                                        id="button-assignment-save"
                                        disabled={disable}
                                        variant="contained"
                                        type="submit"
                                    >
                                        {loading ? 'Loading' : 'Submit'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </form>
                </Card>
            </Fade>
        </Modal>
    );
}
