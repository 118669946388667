import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';

const SubCardProfile = ({ detail }) => (
    <SubCard sx={{ mt: 3 }} title="Profile">
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{ color: '#121926', fontSize: '13px' }}>
                    Hello, I&apos;m Ahnaf Ramadhan Creative Graphic Designer & User Experience Designer based in Website, I create digital
                    Products a more Beautiful and usable place.
                </Typography>
                <Typography sx={{ color: '#121926', fontWeight: 600, mt: 1 }}>Personal Details</Typography>
                <TableContainer>
                    <Table sx={{ '& td': { borderBottom: 'none' } }} size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">Full Name</TableCell>
                                <TableCell>:</TableCell>
                                <TableCell>{detail.users.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Gender</TableCell>
                                <TableCell>:</TableCell>
                                <TableCell>
                                    {/* eslint-disable-next-line */}
                                    {detail.users.gender === 'm' ? 'Man' : detail.users.gender === 'w' ? 'Woman' : 'Unset'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Address</TableCell>
                                <TableCell>:</TableCell>
                                {/* <TableCell>{detail.users.address}</TableCell> */}
                                <TableCell>Jl. Talaga Bodas, Bandung, Indonesia</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Last Education</TableCell>
                                <TableCell>:</TableCell>
                                <TableCell>Bachelor of Computer Science</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </SubCard>
);

export default SubCardProfile;
