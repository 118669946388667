/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Grid, TextField, Typography, Stack, Box, Autocomplete, InputAdornment } from '@mui/material';
import { IconClipboardList, IconTrash } from '@tabler/icons';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { useNavigate } from 'react-router-dom';
import { BorderDashedStyle } from 'style/wrapper';
import { getAllSkills } from 'utils/services/skills';
import { getListClient } from 'utils/services/dashboard/client';
import { postFormRequest } from 'utils/services/requests';
import { getJobType, getListCity, getWorkType } from 'utils/services/master';
import { getListJobPosition } from 'utils/services/job-position';

import MainCard from 'ui-component/cards/MainCard';
import Buttons from 'component/button/Buttons';
import moment from 'moment';

function FormRequest() {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const experiences = [0, 1, 2, 3, 4, 5, 6, 7, '7+'];

    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true);
    const [skills, setSkills] = useState([]);
    const [works, setWorks] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [city, setCity] = useState([]);
    const [jobPosition, setJobPosition] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [clients, setClients] = useState([]);
    const [payload, setPayload] = useState({
        client_id: '',
        request_name: '',
        needs: null,
        order_end_date: '',
        pic_name: '',
        order_form_number: '',
        criteria: [
            {
                skill_id: '',
                min_experience: null,
                max_experience: null,
                needs: null,
                notes: ''
            }
        ],
        job_position_id: '',
        work_type_id: '',
        job_type_id: '',
        experience_min: null,
        experience_max: null,
        salary: '',
        city_id: ''
    });

    const fetchWorks = async () => {
        setLoading(true);
        await getWorkType()
            .then((res) => {
                if (res && res.success) {
                    setWorks(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchJobs = async () => {
        setLoading(true);
        await getJobType()
            .then((res) => {
                if (res && res.success) {
                    setJobs(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchSkills = async () => {
        setLoading(true);
        await getAllSkills()
            .then((res) => {
                if (res && res.success) {
                    setSkills(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchClient = async () => {
        setLoading(true);
        await getListClient({ page, limit })
            .then((res) => {
                if (res && res.success) {
                    setLimit(res.meta.total);
                    setClients(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchCity = async () => {
        setLoading(true);
        await getListCity()
            .then((res) => {
                if (res && res.success) {
                    setCity(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchJobPosition = async () => {
        setLoading(true);
        await getListJobPosition()
            .then((res) => {
                if (res && res.success) {
                    setJobPosition(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await postFormRequest({ payload })
            .then((res) => {
                if (res && res.success) {
                    navigate('/requests');
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err && err.response) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: err.response.data.message,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                    setLoading(false);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: err.message,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        fetchSkills();
        fetchWorks();
        fetchJobs();
        fetchClient();
        fetchCity();
        fetchJobPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit]);

    useEffect(() => {
        if (
            payload.client_id === '' ||
            payload.request_name === '' ||
            payload.needs === null ||
            payload.order_end_date === '' ||
            payload.pic_name === '' ||
            // payload.order_form_number === '' ||
            payload.criteria[0].skill_id === '' ||
            payload.criteria[0].min_experience === null ||
            payload.criteria[0].max_experience === null ||
            payload.criteria[0].needs === null ||
            payload.job_position_id === '' ||
            payload.work_type_id === '' ||
            payload.job_type_id === '' ||
            payload.experience_min === null ||
            payload.experience_max === null ||
            payload.salary === '' ||
            payload.city_id === ''
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    useEffect(() => {
        if (payload.criteria.length > 0) {
            const minExperience = Math.min(...payload.criteria.map((item) => item.min_experience));
            const maxExperience = Math.max(...payload.criteria.map((item) => item.max_experience));

            setPayload((prevPayload) => ({
                ...prevPayload,
                experience_min: minExperience,
                experience_max: maxExperience
            }));
        }
    }, [payload.criteria]);

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3">Form Request</Typography>
                            <Typography variant="body2">Please fill the form bellow</Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <Grid container spacing={2} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            Request Title
                        </Typography>
                        <Autocomplete
                            disablePortal
                            id="job-position-dropdown"
                            options={jobPosition}
                            size="medium"
                            getOptionLabel={(option) => option.name}
                            sx={{ width: '100%', height: '100%' }}
                            onChange={(e, v) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    job_position_id: v ? v.id : '',
                                    request_name: v ? v.name : ''
                                }));
                            }}
                            renderInput={(params) => <TextField {...params} required placeholder="Enter Request Title" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            PIC Name
                        </Typography>
                        <TextField
                            onChange={(e) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    pic_name: e.target.value
                                }));
                            }}
                            value={payload.pic_name}
                            fullWidth
                            placeholder="Enter PIC Name"
                            id="input-request-pic"
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            Client Name
                        </Typography>
                        <Autocomplete
                            disablePortal
                            id="client-dropdown-listbox"
                            options={clients}
                            size="medium"
                            getOptionLabel={(option) => option.company_name}
                            sx={{ width: '100%', height: '100%' }}
                            onChange={(e, v) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    client_id: v ? v.id : ''
                                }));
                            }}
                            renderInput={(params) => <TextField {...params} required placeholder="Enter Client Name" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            Need(s)
                        </Typography>
                        <TextField
                            required
                            value={payload.needs}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const newValue = inputValue === '' ? '' : Math.abs(inputValue);
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    needs: newValue === 0 || Number.isNaN(newValue) ? 1 : newValue
                                }));
                            }}
                            type="number"
                            fullWidth
                            placeholder="Enter Needs"
                            id="input-needs"
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            Work Type
                        </Typography>
                        <Autocomplete
                            disablePortal
                            id="work-dropdown"
                            sx={{ width: '100%' }}
                            options={works}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} placeholder="Enter Work Type" />}
                            onChange={(e, v) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    work_type_id: v ? v.id : ''
                                }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            Job Type
                        </Typography>
                        <Autocomplete
                            disablePortal
                            id="job-dropdown"
                            sx={{ width: '100%' }}
                            options={jobs}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} placeholder="Enter Job Type" />}
                            onChange={(e, v) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    job_type_id: v ? v.id : ''
                                }));
                            }}
                        />
                    </Grid>
                </Grid>
                {/* <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1.5}>
                            Min Experience
                        </Typography>
                        <Autocomplete
                            disablePortal
                            options={experiences.filter((option) => option !== '7+')}
                            getOptionLabel={(option) => `${option} Year`}
                            onChange={(e, v) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    experience_min: v === 0 ? 0 : v || null,
                                    experience_max:
                                        v && prevPayload.experience_max && v > prevPayload.experience_max
                                            ? null
                                            : prevPayload.experience_max
                                }));
                            }}
                            fullWidth
                            renderInput={(params) => <TextField {...params} placeholder="Min Experience" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1.5}>
                            Max Experience
                        </Typography>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="max-experience-dropdown"
                            value={experiences.find((option) => option === payload.experience_max) || null}
                            options={experiences.filter((option) => {
                                if (typeof option === 'number') {
                                    return option > (payload.experience_min || -1);
                                }
                                return true;
                            })}
                            getOptionLabel={(option) => `${option} Year`}
                            onChange={(e, v) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    experience_max: v || null
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={payload.experience_min === null ? 'Add Min. Experience First' : 'Max Experience'}
                                />
                            )}
                        />
                    </Grid>
                </Grid> */}
                <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            Due Date
                        </Typography>
                        <TextField
                            required
                            value={payload.order_end_date}
                            onChange={(e) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    order_end_date: e.target.value
                                }));
                            }}
                            type="date"
                            fullWidth
                            placeholder="Enter Date"
                            id="input-request-date"
                            inputProps={{
                                min: moment().format('YYYY-MM-DD')
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" mb={1}>
                            Location
                        </Typography>
                        <Autocomplete
                            disablePortal
                            id="city-dropdown"
                            sx={{ width: '100%' }}
                            options={city}
                            getOptionLabel={(option) => option.city}
                            renderInput={(params) => <TextField {...params} placeholder="Enter Location" />}
                            onChange={(e, v) => {
                                setPayload((prevPayload) => ({
                                    ...prevPayload,
                                    city_id: v ? v.id : ''
                                }));
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" mb={1}>
                        Salary
                    </Typography>
                    <TextField
                        required
                        value={payload.salary}
                        onChange={(e) => {
                            setPayload((prevPayload) => ({
                                ...prevPayload,
                                salary: e.target.value.replace(/\D|^0+/g, '')
                            }));
                        }}
                        type="text"
                        fullWidth
                        placeholder="Enter Salary"
                        id="input-salary"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>
                        }}
                        helperText="Ex. Rp7000000"
                    />
                </Grid>
                <Grid item justifyContent="center">
                    <Typography variant="h5" mb={2}>
                        Resources Criteria
                    </Typography>
                    {payload.criteria &&
                        payload.criteria.map((candidate, index) => (
                            <Stack direction="column" spacing={2} sx={BorderDashedStyle()} mt={1}>
                                <Autocomplete
                                    disablePortal
                                    id="skill-dropdown"
                                    options={skills}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, v) => {
                                        const temporary = [...payload.criteria];
                                        temporary[index].skill_id = v ? v.id : '';
                                        setPayload((prevState) => ({
                                            ...prevState,
                                            criteria: temporary
                                        }));
                                    }}
                                    renderInput={(params) => <TextField {...params} required label="Pick the Required Skill" />}
                                />
                                <Stack direction="row" spacing={1}>
                                    <Autocomplete
                                        disablePortal
                                        id="min-experience-dropdown"
                                        options={experiences.filter((option) => option !== '7+')}
                                        getOptionLabel={(option) => `${option} Year`}
                                        onChange={(e, v) => {
                                            const temporary = [...payload.criteria];
                                            if (Number(v) >= Number(candidate.max_experience)) {
                                                temporary[index].max_experience = null;
                                            }
                                            temporary[index].min_experience = v || null;
                                            setPayload((prevState) => ({
                                                ...prevState,
                                                criteria: temporary
                                            }));
                                        }}
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} required label="Min Experience" />}
                                    />
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        id="max-experience-dropdown"
                                        options={experiences.filter((option) => {
                                            if (typeof option === 'number') {
                                                return option > (candidate.min_experience || -1);
                                            }
                                            return true;
                                        })}
                                        getOptionLabel={(option) => `${option} Year`}
                                        onChange={(e, v) => {
                                            const temporary = [...payload.criteria];
                                            temporary[index].max_experience = v || null;
                                            setPayload((prevState) => ({
                                                ...prevState,
                                                criteria: temporary
                                            }));
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label={candidate.min_experience === null ? 'Add Min. Experience First' : 'Max Experience'}
                                            />
                                        )}
                                    />
                                </Stack>
                                <TextField
                                    required
                                    type="number"
                                    value={candidate.needs}
                                    onChange={(e) => {
                                        const temporary = [...payload.criteria];
                                        if (e.target.value === '' || Number(e.target.value) <= 0) {
                                            temporary[index].needs = '';
                                        } else {
                                            temporary[index].needs = e.target.value;
                                        }
                                        setPayload((prevState) => ({
                                            ...prevState,
                                            criteria: temporary
                                        }));
                                    }}
                                    label="Total Needs"
                                    id="input-request-needs"
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    value={candidate.notes}
                                    placeholder="e.g: Familiar with Typescript, Fluently in English, etc"
                                    onChange={(e) => {
                                        const temporary = [...payload.criteria];
                                        temporary[index].notes = e.target.value;
                                        setPayload((prevState) => ({
                                            ...prevState,
                                            criteria: temporary
                                        }));
                                    }}
                                    label="Notes"
                                    id="input-request-notes"
                                />
                                <Buttons
                                    id="button-request-deleteCriteria"
                                    title="Delete Criteria"
                                    startIcon={<IconTrash size="16px" />}
                                    onClick={() => {
                                        if (payload.criteria.length > 1) {
                                            const temporary = [...payload.criteria];
                                            temporary.splice(index, 1);
                                            setPayload((prevState) => ({
                                                ...prevState,
                                                criteria: temporary
                                            }));
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: '#FADCDC',
                                        color: '#E44F4F',
                                        '&:hover': {
                                            backgroundColor: '#E44F4F',
                                            color: 'white',
                                            boxShadow: 'none'
                                        }
                                    }}
                                    disable={payload.criteria.length === 1}
                                />
                            </Stack>
                        ))}
                    <Stack justifyContent="center" mt={1}>
                        <Buttons
                            outlined
                            id="button-request-addCriteria"
                            title="Add More Criteria"
                            color="primary"
                            onClick={() => {
                                setPayload((prevState) => ({
                                    ...prevState,
                                    criteria: [
                                        ...prevState.criteria,
                                        {
                                            skill_id: '',
                                            min_experience: null,
                                            max_experience: null,
                                            needs: null,
                                            notes: ''
                                        }
                                    ]
                                }));
                            }}
                            disable={
                                payload.criteria.some(
                                    (item) =>
                                        item.skill_id === '' ||
                                        item.min_experience === '' ||
                                        item.max_experience === '' ||
                                        item.needs === null
                                ) || loading
                            }
                        />
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="end" mt={2}>
                        <Buttons size="medium" title="Cancel" onClick={() => navigate('/requests')} color="primary" />
                        <Buttons size="medium" title="Submit" type="submit" disable={disable} onClick={handleSubmit} />
                    </Stack>
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default FormRequest;
