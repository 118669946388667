// eslint-disable-next-line import/prefer-default-export
export const TypographyLanding = () => ({
    p: 0,
    m: 0,
    fontWeight: 500,
    color: '#454348',
    fontSize: '13.5px',
    fontFamily: ['Figtree'],
    textAlign: 'left'
});
