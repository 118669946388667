import { Box, Skeleton, TableRow, TableCell } from '@mui/material';

const TableLoader = ({ colSpan = 1 }) => (
    <TableRow>
        <TableCell colSpan={colSpan}>
            <Box width="100%">
                <Skeleton height={40} />
                <Skeleton height={40} />
            </Box>
        </TableCell>
    </TableRow>
);

export default TableLoader;
