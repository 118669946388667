import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getRequests = async ({ page = 1, limit = 5, search = '', filter }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/formRequest?page=${page}&limit=${limit}&search=${search}${filter ? `&client_id=${filter}` : ''}`,
        config
    );
};
export const getRequestByStatus = async ({ page = 1, limit = 5, search = '', filter = 0 }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/formRequest?page=${page}&limit=${limit}&search=${search}&filter=${filter}`,
        config
    );
};
export const getRequestById = async (id) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/formRequest/${id}`, config);
};
export const postFormRequest = async ({ payload }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/formRequest`, { ...payload }, config);
};
export const postRegistrationRequest = async ({ payload }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/client`, { ...payload }, config);
};
export const updateStatusRequest = async ({ id, payload }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/formRequest/status/${id}`, { ...payload }, config);
};
