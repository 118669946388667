// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

import { hasPermission } from 'helper/general';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const roles = useSelector((state) => state.account);
    // menu list collapse & items
    const [items, setItems] = useState(null);

    useEffect(() => {
        // if (roles.user && roles.user.role && roles.user.role.role_permission) {
            const myPermissions = roles.user.role.role_permission;
            const navItems = menuItem.items.map((item) => {
                if (hasPermission(item.permissions, myPermissions)) {
                    switch (item.type) {
                        case 'group':
                            return <NavGroup key={item.id} item={item} />;
                        default:
                            return (
                                <Typography key={item.id} variant="h6" color="error" align="center">
                                    Menu Items Error
                                </Typography>
                            );
                    }
                } else {
                    return null
                }
            });
            setItems(navItems)
        // }
    }, []); 

    return <>{items}</>;
};

export default MenuList;
