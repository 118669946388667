// material-ui
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';


import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import Moment from 'moment';

// ==============================|| LOG LIST ||============================== //

const ActivityList = ({ logs = [], loading = true }) => {

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {logs && logs.length > 0 ? (
                                    logs.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.user.user_name}
                                                <div style={{backgroundColor: '#A010F5', padding: '2px 5px', color: '#FFFFFF', borderRadius: '10px', fontSize: '10pt'}}>
                                                    {row.user.user_role}
                                                </div>
                                            </TableCell>
                                            <TableCell>{Moment(row.date).format('DD MMMM YYYY')}</TableCell>
                                            <TableCell>{row.time}</TableCell>
                                            <TableCell>{row.action}</TableCell>
                                            <TableCell>{row.description}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={5} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={5} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ActivityList;
