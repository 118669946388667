import {
    Chip,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { updateStatusReporting } from 'utils/services/reporting';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { EditOutlined } from '@mui/icons-material';

import TableEmpty from 'ui-component/loader/TableEmpty';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '../modal/ConfirmDeleteModal';
import AddReportModal from '../modal/AddEditReportModal';
import TaskDetailModal from '../modal/TaskDetailModal';
import TableLoader from 'ui-component/loader/TableLoader';

function TableReporting({ startIndex, lists, loading, setLoading, handleRefresh, open, setOpen }) {
    const theme = useTheme('oures');
    const dispatch = useDispatch();

    const [data, setData] = useState('');

    const handleSubmit = async ({ id, value }) => {
        setLoading(true);
        await updateStatusReporting({ id, payload: value })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })
            .finally(() => handleRefresh());
    };

    return (
        <>
            {open === 'taskDetail' && <TaskDetailModal open={open === 'taskDetail'} handleClose={() => setOpen(false)} row={data} />}
            {open === 'editReport' && (
                <AddReportModal
                    open={open === 'editReport'}
                    action={open}
                    row={data}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
            {open === 'deleteReport' && (
                <ConfirmDelete
                    open={open === 'deleteReport'}
                    handleCancel={() => setOpen(false)}
                    setLoading={setLoading}
                    handleRefresh={handleRefresh}
                    row={data}
                    handleClose={() => setOpen(false)}
                />
            )}
            <TableContainer>
                <Table sx={{ minWidth: '1250px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                style={{ width: '55px', position: 'sticky', left: 0, zIndex: 1, background: 'white', pl: 3 }}
                            >
                                No.
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ width: '155px', position: 'sticky', left: '55px', zIndex: 1, background: 'white' }}
                            >
                                Date
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ width: '200px', position: 'sticky', left: '210px', zIndex: 1, background: 'white' }}
                            >
                                Nama
                            </TableCell>
                            <TableCell>Jabatan</TableCell>
                            <TableCell>Divisi</TableCell>
                            <TableCell>Projek</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell align="center">Task Detail</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell
                                align="center"
                                style={{ width: '120px', position: 'sticky', right: 0, zIndex: 1, pr: 8, background: 'white' }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? (
                        lists && lists.length > 0 ? (
                            lists.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            style={{ width: '55px', position: 'sticky', left: 0, zIndex: 1, background: 'white', pl: 3 }}
                                        >
                                            <Typography>{index + startIndex}.</Typography>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ width: '155px', position: 'sticky', left: '55px', zIndex: 1, background: 'white' }}
                                        >
                                            <Typography noWrap>{moment(row.date).format('D MMMM YYYY')}</Typography>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ width: '200px', position: 'sticky', left: '210px', zIndex: 1, background: 'white' }}
                                        >
                                            <Typography noWrap>{row.username}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{row.jabatan}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{row.divisi}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{row.project}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{row.title}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label="View Task Detail"
                                                size="small"
                                                align="left"
                                                onClick={() => {
                                                    setOpen('taskDetail');
                                                    setData(row);
                                                }}
                                                sx={{
                                                    background: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Switch
                                                        onChange={() => handleSubmit({ id: row.id, value: row.status === 1 ? 0 : 1 })}
                                                        color="success"
                                                        checked={row.status === 1}
                                                    />
                                                }
                                                label={row.status === 1 ? 'Done' : 'Not Done'}
                                                labelPlacement="end"
                                                sx={{ position: 'static', width: '120px' }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ width: '120px', position: 'sticky', right: 0, zIndex: 1, pr: 8, background: 'white' }}
                                        >
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <Tooltip placement="top" title="Edit Report">
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpen('editReport');
                                                            setData(row);
                                                        }}
                                                        color="primary"
                                                        size="large"
                                                    >
                                                        <EditOutlined sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Delete Report">
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpen('deleteReport');
                                                            setData(row);
                                                        }}
                                                        color="primary"
                                                        size="large"
                                                    >
                                                        <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))
                        ) : (
                            <TableEmpty colSpan={10} />
                        )
                    ) : (
                        <TableLoader colSpan={10} />
                    )}
                </Table>
            </TableContainer>
        </>
    );
}

export default TableReporting;
