const posisiPekerjaanIT = [
    'Web Developer',
    'Frontend Developer',
    'Backend Developer',
    'Full Stack Developer',
    'Mobile App Developer',
    'Software Engineer',
    'UI/UX Designer',
    'DevOps Engineer',
    'Quality Assurance Engineer',
    'Systems Analyst',
    'Database Administrator',
    'Network Engineer',
    'Cloud Architect',
    'Security Analyst',
    'Data Scientist',
    'Machine Learning Engineer',
    'Artificial Intelligence Specialist',
    'Blockchain Developer',
    'Game Developer',
    'IT Project Manager',
    'Technical Support Specialist',
    'IT Consultant',
    'Cybersecurity Specialist',
    'IT Trainer/Educator',
    'Business Intelligence Analyst',
    'IT Auditor',
    'Digital Marketing Specialist (with IT focus)',
    'IT Sales Representative',
    'IT Procurement Specialist',
    'IT Compliance Officer',
    'IT Risk Analyst',
    'IT Service Manager',
    'IT Operations Manager',
    'IT Business Analyst',
    'IT Architect',
    'IT Researcher',
    'IT Administrator',
    'IT Technician',
    'IT Coordinator',
    'IT Director',
    'Chief Technology Officer (CTO)'
];

const namaPerusahaanIT = {
    'Web Developer': [
        'WebWorks Inc.',
        'CodeCraft Solutions',
        'PixelPioneers',
        'Digital Dreamers',
        'WebWizards Co.',
        'TechTide Solutions',
        'ByteBuilders',
        'Webitects Corporation',
        'WebGenius Innovations',
        'NexTech Developers'
    ],
    'Frontend Developer': [
        'FrontEdge Technologies',
        'WebFront Innovations',
        'Frontier Systems',
        'FrontFocus Solutions',
        'Frontline Developers',
        'FrontPulse Inc.',
        'FrontRank Technologies',
        'FrontView Solutions',
        'FrontBlend Systems',
        'FrontLoop Innovations'
    ],
    'Backend Developer': [
        'BackBeam Solutions',
        'BackCore Technologies',
        'BackLink Innovations',
        'BackEndeavor Systems',
        'BackPulse Tech',
        'BackForge Solutions',
        'BackLoop Innovations',
        'BackGenius Technologies',
        'BackWave Systems',
        'BackTide Innovations'
    ],
    'Full Stack Developer': [
        'FullSpectrum Solutions',
        'StackWave Technologies',
        'FullLink Innovations',
        'FullGenius Solutions',
        'FullCore Systems',
        'FullPulse Tech',
        'FullForce Innovations',
        'FullCycle Systems',
        'FullBlend Solutions',
        'FullScope Technologies'
    ],
    'Mobile App Developer': [
        'MobileWorks Inc.',
        'AppCraft Solutions',
        'PixelPulse Apps',
        'Digital Dream Apps',
        'AppWizards Co.',
        'AppTide Solutions',
        'ByteBuilders Apps',
        'AppGenius Innovations',
        'NexTech Mobile',
        'AppNest Innovations'
    ],
    'Software Engineer': [
        'CodeCraft Solutions',
        'SoftEdge Technologies',
        'SoftwareWorks Inc.',
        'DigitalSoft Innovations',
        'SoftPulse Tech',
        'SoftForge Solutions',
        'SoftLoop Innovations',
        'SoftGenius Technologies',
        'SoftWave Systems',
        'SoftTide Innovations'
    ],
    'UI/UX Designer': [
        'DesignWorks Inc.',
        'UXCraft Solutions',
        'PixelPerfect Designs',
        'DigitalDesign Innovations',
        'DesignWizards Co.',
        'DesignTide Solutions',
        'ByteBuilders Design',
        'DesignGenius Innovations',
        'NexTech Designs',
        'DesignNest Innovations'
    ],
    'DevOps Engineer': [
        'DevOpsWorks Inc.',
        'DevOpsCraft Solutions',
        'PixelOps Innovations',
        'Digital DevOps Dreamers',
        'DevOpsWizards Co.',
        'DevOpsTide Solutions',
        'ByteBuilders DevOps',
        'DevOpsGenius Innovations',
        'NexTech DevOps',
        'OpsNest Innovations'
    ],
    'Quality Assurance Engineer': [
        'QualityWorks Inc.',
        'QA Craft Solutions',
        'PixelPerfect QA',
        'Digital Quality Innovations',
        'QAWizards Co.',
        'QATide Solutions',
        'ByteBuilders QA',
        'QAGenius Innovations',
        'NexTech QA',
        'QANest Innovations'
    ],
    'Systems Analyst': [
        'SystemWorks Inc.',
        'SystemCraft Solutions',
        'PixelSystems Innovations',
        'Digital Systems Analysis',
        'SystemWizards Co.',
        'SystemTide Solutions',
        'ByteBuilders Systems',
        'SystemGenius Innovations',
        'NexTech Systems',
        'SystemNest Innovations'
    ],
    'Database Administrator': [
        'DataWorks Inc.',
        'DB Craft Solutions',
        'PixelDB Innovations',
        'Digital Data Dreamers',
        'DBWizards Co.',
        'DBTide Solutions',
        'ByteBuilders DB',
        'DBGenius Innovations',
        'NexTech DB',
        'DBNest Innovations'
    ],
    'Network Engineer': [
        'NetWorks Inc.',
        'NetworkCraft Solutions',
        'PixelNetwork Innovations',
        'Digital Network Dreamers',
        'NetWizards Co.',
        'NetTide Solutions',
        'ByteBuilders Networks',
        'NetGenius Innovations',
        'NexTech Networks',
        'NetNest Innovations'
    ],
    'Cloud Architect': [
        'CloudWorks Inc.',
        'CloudCraft Solutions',
        'PixelCloud Innovations',
        'Digital Cloud Dreamers',
        'CloudWizards Co.',
        'CloudTide Solutions',
        'ByteBuilders Cloud',
        'CloudGenius Innovations',
        'NexTech Cloud',
        'CloudNest Innovations'
    ],
    'Security Analyst': [
        'SecurityWorks Inc.',
        'SecurityCraft Solutions',
        'PixelSecurity Innovations',
        'Digital Security Dreamers',
        'SecurityWizards Co.',
        'SecurityTide Solutions',
        'ByteBuilders Security',
        'SecurityGenius Innovations',
        'NexTech Security',
        'SecurityNest Innovations'
    ],
    'Data Scientist': [
        'DataWorks Inc.',
        'DataCraft Solutions',
        'PixelData Innovations',
        'Digital Data Dreamers',
        'DataWizards Co.',
        'DataTide Solutions',
        'ByteBuilders Data',
        'DataGenius Innovations',
        'NexTech Data',
        'DataNest Innovations'
    ],
    'Machine Learning Engineer': [
        'MLWorks Inc.',
        'ML Craft Solutions',
        'PixelML Innovations',
        'Digital ML Dreamers',
        'MLWizards Co.',
        'MLTide Solutions',
        'ByteBuilders ML',
        'MLGenius Innovations',
        'NexTech ML',
        'MLNest Innovations'
    ],
    'Artificial Intelligence Specialist': [
        'AIWorks Inc.',
        'AICraft Solutions',
        'PixelAI Innovations',
        'Digital AI Dreamers',
        'AIWizards Co.',
        'AITide Solutions',
        'ByteBuilders AI',
        'AIGenius Innovations',
        'NexTech AI',
        'AINest Innovations'
    ],
    'Blockchain Developer': [
        'ChainWorks Inc.',
        'ChainCraft Solutions',
        'PixelChain Innovations',
        'Digital Chain Dreamers',
        'ChainWizards Co.',
        'ChainTide Solutions',
        'ByteBuilders Chain',
        'ChainGenius Innovations',
        'NexTech Chain',
        'ChainNest Innovations'
    ],
    'Game Developer': [
        'GameWorks Inc.',
        'GameCraft Solutions',
        'PixelGame Innovations',
        'Digital Game Dreamers',
        'GameWizards Co.',
        'GameTide Solutions',
        'ByteBuilders Games',
        'GameGenius Innovations',
        'NexTech Games',
        'GameNest Innovations'
    ],
    'IT Project Manager': [
        'ProjectWorks Inc.',
        'ProjectCraft Solutions',
        'PixelProject Innovations',
        'Digital Project Dreamers',
        'ProjectWizards Co.',
        'ProjectTide Solutions',
        'ByteBuilders Projects',
        'ProjectGenius Innovations',
        'NexTech Projects',
        'ProjectNest Innovations'
    ],
    'Technical Support Specialist': [
        'SupportWorks Inc.',
        'SupportCraft Solutions',
        'PixelSupport Innovations',
        'Digital Support Dreamers',
        'SupportWizards Co.',
        'SupportTide Solutions',
        'ByteBuilders Support',
        'SupportGenius Innovations',
        'NexTech Support',
        'SupportNest Innovations'
    ],
    'IT Consultant': [
        'ConsultWorks Inc.',
        'ConsultCraft Solutions',
        'PixelConsult Innovations',
        'Digital Consult Dreamers',
        'ConsultWizards Co.',
        'ConsultTide Solutions',
        'ByteBuilders Consult',
        'ConsultGenius Innovations',
        'NexTech Consult',
        'ConsultNest Innovations'
    ],
    'Cybersecurity Specialist': [
        'CyberWorks Inc.',
        'CyberCraft Solutions',
        'PixelCyber Innovations',
        'Digital Cyber Dreamers',
        'CyberWizards Co.',
        'CyberTide Solutions',
        'ByteBuilders Cyber',
        'CyberGenius Innovations',
        'NexTech Cyber',
        'CyberNest Innovations'
    ],
    'IT Trainer/Educator': [
        'EduWorks Inc.',
        'EduCraft Solutions',
        'PixelEdu Innovations',
        'Digital Edu Dreamers',
        'EduWizards Co.',
        'EduTide Solutions',
        'ByteBuilders Edu',
        'EduGenius Innovations',
        'NexTech Edu',
        'EduNest Innovations'
    ],
    'Business Intelligence Analyst': [
        'BIWorks Inc.',
        'BICraft Solutions',
        'PixelBI Innovations',
        'Digital BI Dreamers',
        'BIWizards Co.',
        'BITide Solutions',
        'ByteBuilders BI',
        'BIGenius Innovations',
        'NexTech BI',
        'BINest Innovations'
    ],
    'IT Auditor': [
        'AuditWorks Inc.',
        'AuditCraft Solutions',
        'PixelAudit Innovations',
        'Digital Audit Dreamers',
        'AuditWizards Co.',
        'AuditTide Solutions',
        'ByteBuilders Audit',
        'AuditGenius Innovations',
        'NexTech Audit',
        'AuditNest Innovations'
    ],
    'Digital Marketing Specialist (with IT focus)': [
        'MarketingWorks Inc.',
        'MarketingCraft Solutions',
        'PixelMarketing Innovations',
        'Digital Marketing Dreamers',
        'MarketingWizards Co.',
        'MarketingTide Solutions',
        'ByteBuilders Marketing',
        'MarketingGenius Innovations',
        'NexTech Marketing',
        'MarketingNest Innovations'
    ],
    'IT Sales Representative': [
        'SalesWorks Inc.',
        'SalesCraft Solutions',
        'PixelSales Innovations',
        'Digital Sales Dreamers',
        'SalesWizards Co.',
        'SalesTide Solutions',
        'ByteBuilders Sales',
        'SalesGenius Innovations',
        'NexTech Sales',
        'SalesNest Innovations'
    ],
    'IT Procurement Specialist': [
        'ProcureWorks Inc.',
        'ProcureCraft Solutions',
        'PixelProcure Innovations',
        'Digital Procurement Dreamers',
        'ProcureWizards Co.',
        'ProcureTide Solutions',
        'ByteBuilders Procure',
        'ProcureGenius Innovations',
        'NexTech Procure',
        'ProcureNest Innovations'
    ],
    'IT Compliance Officer': [
        'ComplianceWorks Inc.',
        'ComplianceCraft Solutions',
        'PixelCompliance Innovations',
        'Digital Compliance Dreamers',
        'ComplianceWizards Co.',
        'ComplianceTide Solutions',
        'ByteBuilders Compliance',
        'ComplianceGenius Innovations',
        'NexTech Compliance',
        'ComplianceNest Innovations'
    ],
    'IT Risk Analyst': [
        'RiskWorks Inc.',
        'RiskCraft Solutions',
        'PixelRisk Innovations',
        'Digital Risk Dreamers',
        'RiskWizards Co.',
        'RiskTide Solutions',
        'ByteBuilders Risk',
        'RiskGenius Innovations',
        'NexTech Risk',
        'RiskNest Innovations'
    ],
    'IT Service Manager': [
        'ServiceWorks Inc.',
        'ServiceCraft Solutions',
        'PixelService Innovations',
        'Digital Service Dreamers',
        'ServiceWizards Co.',
        'ServiceTide Solutions',
        'ByteBuilders Service',
        'ServiceGenius Innovations',
        'NexTech Service',
        'ServiceNest Innovations'
    ],
    'IT Operations Manager': [
        'OperationsWorks Inc.',
        'OperationsCraft Solutions',
        'PixelOperations Innovations',
        'Digital Operations Dreamers',
        'OperationsWizards Co.',
        'OperationsTide Solutions',
        'ByteBuilders Operations',
        'OperationsGenius Innovations',
        'NexTech Operations',
        'OperationsNest Innovations'
    ],
    'IT Business Analyst': [
        'BizWorks Inc.',
        'BizCraft Solutions',
        'PixelBiz Innovations',
        'Digital Biz Dreamers',
        'BizWizards Co.',
        'BizTide Solutions',
        'ByteBuilders Biz',
        'BizGenius Innovations',
        'NexTech Biz',
        'BizNest Innovations'
    ],
    'IT Architect': [
        'ArchWorks Inc.',
        'ArchCraft Solutions',
        'PixelArch Innovations',
        'Digital Arch Dreamers',
        'ArchWizards Co.',
        'ArchTide Solutions',
        'ByteBuilders Arch',
        'ArchGenius Innovations',
        'NexTech Arch',
        'ArchNest Innovations'
    ],
    'IT Researcher': [
        'ResearchWorks Inc.',
        'ResearchCraft Solutions',
        'PixelResearch Innovations',
        'Digital Research Dreamers',
        'ResearchWizards Co.',
        'ResearchTide Solutions',
        'ByteBuilders Research',
        'ResearchGenius Innovations',
        'NexTech Research',
        'ResearchNest Innovations'
    ],
    'IT Administrator': [
        'AdminWorks Inc.',
        'AdminCraft Solutions',
        'PixelAdmin Innovations',
        'Digital Admin Dreamers',
        'AdminWizards Co.',
        'AdminTide Solutions',
        'ByteBuilders Admin',
        'AdminGenius Innovations',
        'NexTech Admin',
        'AdminNest Innovations'
    ],
    'IT Technician': [
        'TechWorks Inc.',
        'TechCraft Solutions',
        'PixelTech Innovations',
        'Digital Tech Dreamers',
        'TechWizards Co.',
        'TechTide Solutions',
        'ByteBuilders Tech',
        'TechGenius Innovations',
        'NexTech Tech',
        'TechNest Innovations'
    ],
    'IT Coordinator': [
        'CoordWorks Inc.',
        'CoordCraft Solutions',
        'PixelCoord Innovations',
        'Digital Coord Dreamers',
        'CoordWizards Co.',
        'CoordTide Solutions',
        'ByteBuilders Coord',
        'CoordGenius Innovations',
        'NexTech Coord',
        'CoordNest Innovations'
    ],
    'IT Director': [
        'DirectorWorks Inc.',
        'DirectorCraft Solutions',
        'PixelDirector Innovations',
        'Digital Director Dreamers',
        'DirectorWizards Co.',
        'DirectorTide Solutions',
        'ByteBuilders Director',
        'DirectorGenius Innovations',
        'NexTech Director',
        'DirectorNest Innovations'
    ],
    'Chief Technology Officer (CTO)': [
        'CTOWorks Inc.',
        'CTOCraft Solutions',
        'PixelCTO Innovations',
        'Digital CTO Dreamers',
        'CTOWizards Co.',
        'CTOTide Solutions',
        'ByteBuilders CTO',
        'CTOGenius Innovations',
        'NexTech CTO',
        'CTONest Innovations'
    ]
};

const namaProvinsi = [
    'Aceh',
    'Sumatera Utara',
    'Sumatera Barat',
    'Riau',
    'Kepulauan Riau',
    'Jambi',
    'Bengkulu',
    'Sumatera Selatan',
    'Kepulauan Bangka Belitung',
    'Lampung',
    'Banten',
    'DKI Jakarta',
    'Jawa Barat',
    'Jawa Tengah',
    'DI Yogyakarta',
    'Jawa Timur',
    'Bali',
    'Nusa Tenggara Barat',
    'Nusa Tenggara Timur',
    'Kalimantan Barat',
    'Kalimantan Tengah',
    'Kalimantan Selatan',
    'Kalimantan Timur',
    'Kalimantan Utara',
    'Sulawesi Utara',
    'Gorontalo',
    'Sulawesi Barat',
    'Sulawesi Tengah',
    'Sulawesi Selatan',
    'Sulawesi Tenggara',
    'Maluku',
    'Maluku Utara',
    'Papua Barat',
    'Papua'
];

const jenisKerja = ['Full Time', 'Part Time', 'Freelance', 'Internship'];

const jenisPekerjaan = ['Onsite', 'Remote', 'Hybrid'];

// ======================================================================

function generateRandomPosisiPekerjaan() {
    const maxIndex = 20;
    const randomIndex = Math.floor(Math.random() * maxIndex);
    return posisiPekerjaanIT[randomIndex];
}

function generateRandomNamaPerusahaan(posisi) {
    const perusahaan = namaPerusahaanIT[posisi];
    if (!perusahaan) {
        return 'Posisi pekerjaan tidak valid';
    }
    const randomIndex = Math.floor(Math.random() * perusahaan.length);
    return perusahaan[randomIndex];
}

function generateRandomNamaProvinsi() {
    const maxIndex = 20;
    const randomIndex = Math.floor(Math.random() * maxIndex);
    return namaProvinsi[randomIndex];
}

function generateRandomJenisKerja() {
    const randomIndex = Math.floor(Math.random() * jenisKerja.length);
    return jenisKerja[randomIndex];
}

function generateRandomJenisPekerjaan() {
    const randomIndex = Math.floor(Math.random() * jenisPekerjaan.length);
    return jenisPekerjaan[randomIndex];
}

function generateRandomSalary() {
    return Math.floor(Math.random() * 50 + 1);
}

function generateRandomHour() {
    return Math.floor(Math.random() * 25 + 1);
}

function generateRandomYoE(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomDate() {
    const currentDate = new Date();
    const randomDays = Math.floor(Math.random() * 365);
    currentDate.setDate(currentDate.getDate() + randomDays);

    return currentDate;
}

function formatDate(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = 2024;

    let suffix = '';
    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';
    } else if (day === 2 || day === 22) {
        suffix = 'nd';
    } else if (day === 3 || day === 23) {
        suffix = 'rd';
    } else {
        suffix = 'th';
    }

    return `${month} ${day}${suffix}, ${year}`;
}

function generateJobDescription(jenisPekerjaan) {
    switch (jenisPekerjaan) {
        case 'Web Developer':
            return `Sebagai seorang Web Developer, Saya bertanggung jawab untuk mengembangkan aplikasi web menggunakan berbagai bahasa pemrograman seperti HTML, CSS, dan JavaScript. Saya bekerja sama dengan tim untuk merancang dan mengimplementasikan fitur-fitur baru serta memastikan kinerja dan keamanan aplikasi.`;
        case 'Frontend Developer':
            return `Sebagai seorang Frontend Developer, Saya fokus pada pengembangan antarmuka pengguna (UI) dari aplikasi web. Saya menggunakan teknologi seperti HTML, CSS, dan JavaScript untuk membuat tampilan yang menarik dan responsif bagi pengguna.`;
        case 'Backend Developer':
            return `Sebagai seorang Backend Developer, Saya berfokus pada pengembangan bagian backend dari aplikasi web. Saya bertanggung jawab untuk mengelola data, logika bisnis, dan interaksi antara server dan basis data, menggunakan bahasa pemrograman seperti Python, Node.js, dan Java.`;
        case 'Full Stack Developer':
            return `Sebagai seorang Full Stack Developer, Saya memiliki pengetahuan dan keterampilan dalam pengembangan baik frontend maupun backend dari aplikasi web. Saya mampu mengelola seluruh siklus pengembangan, mulai dari desain UI hingga implementasi logika bisnis dan pengujian.`;
        case 'Mobile App Developer':
            return `Sebagai seorang Mobile App Developer, Saya bertanggung jawab untuk mengembangkan aplikasi mobile untuk berbagai platform seperti iOS dan Android. Saya menggunakan teknologi seperti React Native dan Flutter untuk membuat aplikasi yang responsif dan mudah digunakan.`;
        default:
            return `Sebagai seorang profesional di bidang Teknologi Informasi (IT), Saya memiliki tanggung jawab untuk merancang, mengembangkan, dan memelihara solusi teknologi informasi yang mendukung operasi bisnis dan organisasi. Saya bekerja dengan berbagai sistem, aplikasi, dan infrastruktur teknologi untuk memastikan keberlanjutan dan efisiensi operasional.`;
    }
}
// ======================================================================

function createData(
    posisiPekerjaan,
    namaPerusahaan,
    jenisKerja,
    jenisPekerjaan,
    provinsi,
    jam,
    pengalaman,
    pengalaman2,
    salary,
    deskripsi,
    date
) {
    return {
        posisiPekerjaan,
        namaPerusahaan,
        jenisKerja,
        jenisPekerjaan,
        provinsi,
        jam,
        pengalaman,
        pengalaman2,
        salary,
        deskripsi,
        date
    };
}

const createRealisticData = (size) => {
    const data = Array.from({ length: size }, () =>
        createData(
            generateRandomPosisiPekerjaan(),
            generateRandomNamaPerusahaan(generateRandomPosisiPekerjaan()),
            generateRandomJenisKerja(),
            generateRandomJenisPekerjaan(),
            generateRandomNamaProvinsi(),
            generateRandomHour(),
            generateRandomYoE(7, 20),
            generateRandomYoE(1, 6),
            generateRandomSalary(),
            generateJobDescription(generateRandomPosisiPekerjaan()),
            formatDate(generateRandomDate())
        )
    );
    return data;
};

// ======================================================================

const dataLoker = createRealisticData(20);

const dummyData = {
    dataLoker
};
export default dummyData;
