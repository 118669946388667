import { getAllResource } from 'utils/services/dashboard/resource';
import { getAllClient } from 'utils/services/dashboard/client';
import { getAllPendingRequest } from 'utils/services/dashboard/pendingRequest';
import { getAllRequestOnProcess } from 'utils/services/dashboard/requestOnProcess';
import { getAllCancelledRequest } from 'utils/services/dashboard/canceledRequest';
import { getAllAssignedRequest } from 'utils/services/dashboard/assignedDeveloper';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { gridSpacing, storageKey } from 'store/constant';
import { hasPermission } from 'helper/general';

import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import IconNumberCard from 'ui-component/cards/IconNumberCard';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import ModelTrainingTwoToneIcon from '@mui/icons-material/ModelTrainingTwoTone';
import RootPermission from '../permission';
import useRootPermission from 'hooks/useRootPermission';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const [totalResource, setTotalResource] = useState(0);
    const [totalClient, setTotalClient] = useState(0);
    const [pendingRequest, setTotalPendingRequest] = useState(0);
    const [requestOnProcess, setRequestOnProcess] = useState(0);
    const [cancelledRequest, setCancelledRequest] = useState(0);
    const [assignedDeveloper, setAssignedDeveloper] = useState(0);
    const [granted, allPermission] = useRootPermission(['Assignment@index']);

    const fetchResource = async () => {
        setLoading(true);
        await getAllResource()
            .then((res) => {
                if (res && res.success) {
                    setTotalResource(res.meta.total);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };
    const fetchClient = async () => {
        setLoading(true);
        await getAllClient()
            .then((res) => {
                if (res && res.success) {
                    setTotalClient(res.meta.total);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };
    const fetchPendingRequst = async () => {
        setLoading(true);
        await getAllPendingRequest()
            .then((res) => {
                if (res && res.success) {
                    setTotalPendingRequest(res.meta.total);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };
    const fetchRequestOnProcess = async () => {
        setLoading(true);
        await getAllRequestOnProcess()
            .then((res) => {
                if (res && res.success) {
                    setRequestOnProcess(res.meta.total);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };
    const fetchRequestAssigned = async () => {
        setLoading(true);
        await getAllAssignedRequest()
            .then((res) => {
                if (res && res.success) {
                    setAssignedDeveloper(res.meta.total);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };
    const fetchGetAllCancelledRequest = async () => {
        setLoading(true);
        await getAllCancelledRequest()
            .then((res) => {
                if (res && res.success) {
                    setCancelledRequest(res.meta.total);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchResource();
        fetchClient();
        fetchPendingRequst();
        fetchRequestOnProcess();
        fetchRequestAssigned();
        fetchGetAllCancelledRequest();
    }, []);

    return (
        <RootPermission granted={granted}>
            <Grid container spacing={gridSpacing}>
                {hasPermission(['FormRequest@requests_count_all'], allPermission) &&
                    localStorage.getItem(storageKey.USER_ROLE) === 'super-admin' && (
                        <>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    {hasPermission(['Resources@resources_count_all'], allPermission) && (
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <EarningCard total={totalResource} isLoading={isLoading} />
                                        </Grid>
                                    )}
                                    {hasPermission(['Client@clients_count_all'], allPermission) && (
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <TotalOrderLineChartCard total={totalClient} isLoading={isLoading} />
                                        </Grid>
                                    )}
                                    <Grid item lg={4} md={12} sm={12} xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                                <TotalIncomeDarkCard total={pendingRequest} isLoading={isLoading} />
                                            </Grid>
                                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                                <TotalIncomeLightCard total={requestOnProcess} isLoading={isLoading} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} md={8}>
                                        <TotalGrowthBarChart isLoading={isLoading} data={assignedDeveloper} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <PopularCard isLoading={isLoading} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}

                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        {hasPermission(['FormRequestAssignment@on_assign_requests_count_all'], allPermission) &&
                            localStorage.getItem(storageKey.USER_ROLE) !== 'super-admin' && (
                                <>
                                    <Grid item xs={12} lg={12} sm={12}>
                                        <IconNumberCard
                                            title="Request Created"
                                            primary="536"
                                            color="#2FDD92"
                                            iconPrimary={AssignmentTwoToneIcon}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={3} sm={6}>
                                        <IconNumberCard
                                            title="On Assignment Request"
                                            primary="161"
                                            color="#ABD9FF"
                                            iconPrimary={ModelTrainingTwoToneIcon}
                                        />
                                    </Grid>
                                </>
                            )}
                        {hasPermission(['FormRequest@requests_count_all_by_status'], allPermission) &&
                            localStorage.getItem(storageKey.USER_ROLE) !== 'super-admin' && (
                                <>
                                    <Grid item xs={12} lg={3} sm={6}>
                                        <IconNumberCard
                                            title="Pending Request"
                                            primary="75"
                                            color="#FFE400"
                                            iconPrimary={PendingActionsTwoToneIcon}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={3} sm={6}>
                                        <IconNumberCard
                                            title="Completed Request"
                                            primary="296"
                                            color="#06FF00"
                                            iconPrimary={FactCheckTwoToneIcon}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={3} sm={6}>
                                        <IconNumberCard
                                            title="Cancelled Request"
                                            primary={cancelledRequest}
                                            color="#EB5353"
                                            iconPrimary={AssignmentLateIcon}
                                        />
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </Grid>
            </Grid>
        </RootPermission>
    );
};

export default Dashboard;
