import { Box, Grid, TextField, Button, Stack, Typography, Avatar } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { storageKey } from 'store/constant';
import { getUserInfo } from 'helper/general';
import axios from 'axios';
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';

const AccountPICForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');

    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        pic_name: '',
        pic_phone: '',
        pic_email: '',
        pic_address: '',
        pic_domisili: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
        };

        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/client/pic/create?client_id=${getUserInfo() ? getUserInfo().client_id : ''}`,
                payload,
                config
            )
            .then((res) => {
                if (res && res.status === 200 && res.data && res.data.data && res.data.data.length > 0) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    navigate('/accounts');
                } else if (res) {
                    setLoading(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'warning'
                    });
                }
            });
    };

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Register Person In Charge Data' : 'Update Person In Charge Data'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form below
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/clients')}
            />
            <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {!identify && (
                        <Grid item sx={12} md={6} spacing={2}>
                            <TextField
                                required
                                id="input-accounts-picName"
                                name="pic_name"
                                margin="normal"
                                value={payload.pic_name}
                                onChange={(e) => setPayload({ ...payload, pic_name: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Name"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                            <TextField
                                required
                                id="input-accounts-picEmail"
                                margin="normal"
                                value={payload.pic_email}
                                onChange={(e) => setPayload({ ...payload, pic_email: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Email"
                                type="email"
                                variant="outlined"
                            />
                            <TextField
                                required
                                id="input-accounts-picPhone"
                                margin="normal"
                                value={payload.pic_phone}
                                onChange={(e) => setPayload({ ...payload, pic_phone: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Your Phone"
                                type="number"
                                variant="outlined"
                            />
                            <TextField
                                required
                                margin="normal"
                                id="input-accounts-picDomicile"
                                value={payload.pic_domisili}
                                onChange={(e) => setPayload({ ...payload, pic_domisili: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Domisili"
                                variant="outlined"
                            />
                            <TextField
                                required
                                type="text"
                                margin="normal"
                                id="input-accounts-picAddress"
                                value={payload.pic_address}
                                onChange={(e) => setPayload({ ...payload, pic_address: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Address"
                                variant="outlined"
                            />
                        </Grid>
                    )}
                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button id="button-accounts-cancel" variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button id="button-accounts-submit" variant="contained" type={loading ? 'button' : 'submit'} disabled={loading}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Register Now' : 'Update Client'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default AccountPICForm;
