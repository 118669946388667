import { Card, CardContent, CardHeader, Grid, Modal, Stack, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { updateAssignmentCancelled, updateReassign } from 'utils/services/assignment-reassign';
import moment from 'moment';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 735,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1
};

const ReassignModal = ({ open, handleClose, action, row, category, handleRefresh }) => {
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ reassign_notes: '' });
    const [payload, setPayload] = useState({
        step: 0,
        user: '',
        start_date: moment().locale('en').format('dddd, DD MMMM YYYY'),
        message: ''
    });

    const handleChange = (event) => {
        setPayload({ ...payload, user: event.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await updateAssignmentCancelled({
            id: action === 'reassignModal' ? row.id : row.form_request_assignment_id,
            payload: payload.step,
            notes: message.reassign_notes
        })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    updateReassign({ id: row.id, payload: message });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
            .finally(() => handleRefresh());
    };

    const onCancel = () => {
        handleClose();
    };

    useEffect(() => {
        if (category === 'reassign') {
            if (action === 'reassignModal' && payload === 4) {
                setPayload(0);
            }
        }
    }, [category, payload, action]);

    useEffect(() => {
        if (action !== 'accept') {
            if (message.reassign_notes === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else {
            setDisable(false);
        }
    }, [action, message]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={modalStyle}>
                <CardHeader
                    sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }}
                    title={action === 'reassignModal' ? 'Reassign Resource' : ''}
                />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <Typography>
                                You are about to {action === 'reassignModal' ? 'reassign' : ''} this user, please fill out the reason below
                                {action === 'reject' ? ':' : ' (optional):'}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="input-assignment-startDate"
                                            variant="outlined"
                                            label="Sign Date"
                                            sx={{ width: '100%' }}
                                            value={moment().locale('en').format('dddd, DD MMMM YYYY')}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack direction="column" spacing={2}>
                                <TextField
                                    required
                                    type="text"
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    label={action === 'reassignModal' ? 'Reassign Note' : ''}
                                    value={message.reassign_notes}
                                    onChange={(e) => setMessage({ ...message, reassign_notes: e.target.value })}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2} justifyContent="end">
                                <Button id="button-client-cancel" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    id="button-client-submit"
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {loading ? 'Loading' : 'Proceed'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default ReassignModal;
