/* eslint-disable no-unused-vars */
import { Box, Grid, TextField, Button, Stack, Typography, Avatar, Autocomplete, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import {
    createNewInterviewSchedule,
    getDetailInterviewSchedule,
    getRequestInterviewSchedule,
    updateInterviewSchedule
} from 'utils/services/interview-schedule';
import { currentUser } from 'utils/services/users';
import { getCandidatebyReqId } from 'utils/services/candidates';

import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import moment from 'moment';
import 'moment/locale/id';

moment.locale('id');

const InterviewScheduleForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');
    const description = ['HR Interview', 'User Interview'];

    const [page, setPage] = useState(1);
    const [disable, setDisable] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [resources, setResources] = useState();
    const [request, setRequest] = useState([]);
    const [data, setData] = useState({});
    const [payload, setPayload] = useState({
        step: 0,
        status: '',
        title: '',
        request_id: '',
        resource_id: '',
        interviewer_id: '',
        meeting_link: '',
        description: '',
        start_date: '',
        end_date: '',
        cancel_note: '',
        is_active: false
    });

    const fetchUser = async () => {
        setLoading(true);
        await currentUser()
            .then((res) => {
                if (res && res.success) {
                    setPayload({ ...payload, interviewer_id: res.data.user_info.id });
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchCandidate = async (value) => {
        setLoading(true);
        await getCandidatebyReqId({ requestId: value })
            .then((res) => {
                if (res && res.success) {
                    const result = res.data.map((item) => {
                        return {
                            resource: item.name,
                            id: item.resource_id
                        };
                    });
                    setResources(result);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchRequestList = async () => {
        setLoading(true);
        await getRequestInterviewSchedule({ page, limit: 30, search })
            .then((res) => {
                if (res && res.success) {
                    setRequest(res.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchDetail = async (identify) => {
        setLoading(true);
        await getDetailInterviewSchedule({ identify })
            .then((res) => {
                if (res && res.success) {
                    setPayload(res.data);
                    setData(res.data);
                    fetchCandidate(res.data.request_id);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await createNewInterviewSchedule({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    navigate('/interview-schedule');
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        setLoading(true);
        const form = {
            request_id: payload.request_id,
            resource_id: payload.resource_id,
            interviewer_id: payload.interviewer_id,
            title: payload.title,
            meeting_link: payload.meeting_link,
            description: payload.description,
            start_date: payload.start_date,
            end_date: payload.end_date,
            status: payload.status,
            cancel_note: payload.cancel_note
        };
        await updateInterviewSchedule({ identify, form })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    navigate('/interview-schedule');
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                    navigate('/interview-schedule');
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchRequestList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search]);

    useEffect(() => {
        if (action === 'update' && identify) {
            fetchDetail(identify);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identify, action]);

    useEffect(() => {
        if (
            payload.title === '' ||
            payload.request_id === '' ||
            payload.interviewer_id === '' ||
            payload.description === '' ||
            payload.resource_id === '' ||
            payload.meeting_link === '' ||
            payload.start_date === '' ||
            payload.end_date === ''
        ) {
            setDisable(true);
        } else if (
            payload.title === data.title &&
            payload.request_id === data.request_id &&
            payload.interviewer_id === data.interviewer_id &&
            payload.description === data.description &&
            payload.resource_id === data.resource_id &&
            payload.meeting_link === data.meeting_link &&
            payload.start_date === data.start_date &&
            payload.end_date === data.end_date
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload, data]);

    return (
        <>
            {open === 'cancel' && (
                <ConfirmationModal
                    open={open === 'cancel'}
                    description="Your changes will be lost"
                    handleCancel={() => setOpen(false)}
                    handleContinue={() => navigate('/interview-schedule')}
                />
            )}

            <MainCard
                title={
                    <Box width="100%">
                        <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                            <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                <IconClipboardList color="#fff" />
                            </Avatar>
                            <Stack direction="column">
                                <Typography variant="h3" sx={{ mb: 0 }}>
                                    {action === 'create' ? 'Create New Interview Schedule' : 'Update Interview Schedule'}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    Please fill the form below
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                }
            >
                <form onSubmit={(ev) => (action && action === 'create' ? handleSubmit(ev) : handleUpdate(ev))}>
                    <Grid container spacing={3} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item container spacing={1}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={request}
                                    getOptionLabel={(option) => (option.request_name ? option.request_name : option.title)}
                                    value={payload}
                                    onChange={(event, newValue) => {
                                        if (newValue && newValue?.request_id) {
                                            setPayload({ ...payload, request_id: newValue?.request_id, title: newValue?.request_name });
                                            fetchCandidate(newValue.request_id);
                                        } else {
                                            setPayload({ ...payload, request_id: '', title: '', resource_id: '' });
                                            setResources([]);
                                        }
                                    }}
                                    inputValue={search}
                                    onInputChange={(event, newInput) => {
                                        setSearch(newInput);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label="Request Title"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    fullWidth
                                    options={resources}
                                    getOptionLabel={(option) => option.resource}
                                    value={resources ? resources.find((item) => item.id === payload.resource_id) : null}
                                    onChange={(event, newValue) => {
                                        if (newValue && newValue?.id) {
                                            setPayload({ ...payload, resource_id: newValue?.id });
                                        } else {
                                            setPayload({ ...payload, resource_id: '' });
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label="Resource"
                                            helperText={
                                                payload.title === '' && payload.request_id === '' && 'Please select Request Title first'
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    fullWidth
                                    options={description}
                                    value={payload.description}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setPayload({ ...payload, description: newValue });
                                        } else {
                                            setPayload({ ...payload, description: '' });
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} required label="Description" />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Meeting Link"
                                    required
                                    fullWidth
                                    value={payload.meeting_link}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        setPayload({ ...payload, meeting_link: inputValue });
                                        const urlPattern = /^(?:(ftp|http|https):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)+)(?:\/\S*)?$/;
                                        if (!urlPattern.test(inputValue)) {
                                            setInputError(true);
                                        } else {
                                            setInputError(false);
                                        }
                                    }}
                                    error={inputError}
                                    helperText={inputError ? 'Please enter a valid URL' : ''}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Start Date"
                                    required
                                    fullWidth
                                    type="datetime-local"
                                    value={moment.utc(payload.start_date).format('YYYY-MM-DD HH:mm')}
                                    onChange={(e) => setPayload({ ...payload, start_date: e.target.value })}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        min: moment().format('YYYY-MM-DDTHH:mm')
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="End Time"
                                    required
                                    fullWidth
                                    type="datetime-local"
                                    value={moment.utc(payload.end_date).format('YYYY-MM-DD HH:mm')}
                                    onChange={(e) => setPayload({ ...payload, end_date: e.target.value })}
                                    inputProps={{
                                        min: moment.utc(payload.start_date).format('YYYY-MM-DD HH:mm')
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} gap={1}>
                            <Button variant="outlined" onClick={() => setOpen('cancel')}>
                                Cancel
                            </Button>
                            <Button disableElevation variant="contained" type="submit" disabled={loading || disable}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Create' : 'Update'}`}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
        </>
    );
};

export default InterviewScheduleForm;
