import { Box, Card, CardContent, CardHeader, Modal, Typography } from '@mui/material';
import { boxStyle } from 'views/pages/assignments/Modal/NegotiationLetterModal';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    boxShadow: 24,
    borderRadius: '12px'
};

const TaskDetailModal = ({ open, row, handleClose }) => {
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={modalStyle}>
                <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Task Detail" />
                <CardContent>
                    <Box sx={boxStyle}>
                        <Typography>{row.task_detail}</Typography>
                    </Box>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default TaskDetailModal;
