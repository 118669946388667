import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getDivisi = async ({ page = 1, limit = 5, search = '' }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/division?search=${search}&limit=${limit}&page=${page}`, config);
};
export const createNewDivisi = async ({ payload }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/division`, { ...payload }, config);
};
export const updateDivisi = async ({ identify,form }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    
    return ApiService.put(`${process.env.REACT_APP_API_URL}/division/${identify}`,  form , config)
    
};
export const deleteDivisi = async ({ id }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/division/${id}`, config)
};
export const getDetailDivisi = async ({ identify }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/division/${identify}`, config)
    
};
