import { useTheme } from '@mui/styles';

// eslint-disable-next-line import/prefer-default-export
export const TabStyle = () => {
    const theme = useTheme();

    return {
        mb: 3,
        '& a': {
            minHeight: 'auto',
            minWidth: 10,
            py: 1.5,
            px: 1,
            mr: 2.25,
            color: theme.palette.grey[600],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& a.Mui-selected': {
            color: theme.palette.primary.main
        },
        '& .MuiTabs-indicator': {
            bottom: 2
        },
        '& a > svg': {
            marginBottom: '0px !important',
            mr: 1.25
        }
    };
};
