/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import SubCardDetail from './Information/SubCardDetail';
import SubCardClient from './Information/SubCardClient';
import SubCardOverview from './Information/SubCardOverview';
import SubCardBenefits from './Information/SubCardBenefits';
import SubCardJobs from './Information/SubCardJobs';
import { getRequests } from 'utils/services/requests';

const Information = () => {
    const { id } = useParams();

    const [client, setClient] = useState({});
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [lists, setLists] = useState([]);

    const fetchDetail = async () => {
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/client/${id}`, config).then((res) => {
            if (res && res.status === 200) {
                setClient(res.data.data);
                setLoading(false);
            }
        });
    };

    const fetchOpeningJobs = async () => {
        setLoading(true);
        await getRequests({ page, limit, search })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        fetchDetail();
    }, []); //eslint-disable-line

    useEffect(() => {
        fetchOpeningJobs();
    }, [page, limit, search]); //eslint-disable-line

    return (
        <Grid container>
            <Grid item lg={12} xs={12}>
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <SubCardClient client={client} />
                        <SubCardDetail loading={loading} client={client} />
                        <Grid container xs={12} justifyContent="space-between">
                            <Grid item xs={8}>
                                <SubCardOverview client={client} />
                            </Grid>
                            <Grid item xs={3.7}>
                                <SubCardBenefits />
                            </Grid>
                        </Grid>
                        <SubCardJobs lists={lists} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Information;
