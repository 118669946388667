import { useState } from 'react';
import { AppBar, Box, Button, Drawer, IconButton, List, Stack, Tab, Tabs, Typography } from '@mui/material';
import { IconX } from '@tabler/icons';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { landingTheme } from 'component/theme';

import Logo from 'assets/svg/OuresLogoText.svg';
import MenuIcon from '@mui/icons-material/Menu';
import ButtonStyled from 'style/button';

const AppBarCustom = () => {
    const navigate = useNavigate();

    const [drawerToggle, setDrawerToggle] = useState(false);
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };

    const navBar = ['Jobs', 'Companies', 'Courses'];

    return (
        <ThemeProvider theme={landingTheme}>
            <AppBar sx={{ bgcolor: 'black' }}>
                <Box sx={{ height: '80px', alignItems: 'center', display: 'flex', mx: { md: 10, sm: 5, xs: 3 }, width: 'auto' }}>
                    <IconButton
                        color="inherit"
                        onClick={drawerToggler(true)}
                        size="large"
                        sx={{ p: 0, display: { xs: 'flex', sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor="left"
                        open={drawerToggle}
                        onClose={drawerToggler(false)}
                        PaperProps={{
                            sx: {
                                width: '40%',
                                minWidth: '200px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: 'auto'
                            }}
                            role="presentation"
                            onClick={drawerToggler(false)}
                            onKeyDown={drawerToggler(false)}
                        >
                            <IconButton color="inherit" onClick={drawerToggler(true)} size="large" sx={{ padding: '16px 16px 24px' }}>
                                <IconX size="20px" />
                            </IconButton>
                            <List>
                                <Tabs
                                    orientation="vertical"
                                    value={value}
                                    onChange={handleChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                >
                                    {navBar.map((item, index) => (
                                        <Tab
                                            key={index}
                                            value={index + 1}
                                            label={item}
                                            sx={{ textTransform: 'none', fontFamily: ['Figtree'], alignItems: 'start' }}
                                        />
                                    ))}
                                </Tabs>
                            </List>
                            <Stack direction="column" spacing={2} sx={{ marginLeft: '16px', marginTop: '10px' }}>
                                <ButtonStyled
                                    variant="outlined"
                                    sx={{ color: 'white', borderColor: 'white' }}
                                    onClick={() => navigate('/login')}
                                >
                                    Login
                                </ButtonStyled>
                                <ButtonStyled variant="contained" color="primary">
                                    Register
                                </ButtonStyled>
                            </Stack>
                        </Box>
                    </Drawer>
                    <Typography
                        component="div"
                        sx={{
                            flexGrow: { xs: 1, sm: 0 },
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: { xs: 'right', sm: 'left' },
                            justifyContent: { xs: 'flex-end', sm: 'flex-start' }
                        }}
                    >
                        <img src={Logo} alt="logo" width={119} />
                    </Typography>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        centered
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        {navBar.map((item, index) => (
                            <Tab value={index + 1} label={item} sx={{ textTransform: 'none', fontFamily: ['Figtree'], fontWeight: 600 }} />
                        ))}
                    </Tabs>
                    <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Button
                            variant="text"
                            sx={{ textTransform: 'none', fontWeight: 600, fontFamily: ['Figtree'] }}
                            onClick={() => navigate('/login')}
                        >
                            Login
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ borderRadius: '200px', textTransform: 'none', fontWeight: 600, fontFamily: ['Figtree'] }}
                        >
                            Register
                        </Button>
                    </Stack>
                </Box>
            </AppBar>
        </ThemeProvider>
    );
};

export default AppBarCustom;
