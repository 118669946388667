import { Autocomplete, Avatar, Box, Button, Card, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { IconTrashX } from '@tabler/icons';
import { SNACKBAR_OPEN } from 'store/actions';
import { useEffect, useState } from 'react';
import { createBatchAssignment } from 'utils/services/assignments';
import { useDispatch } from 'react-redux';
import { getCandidatebyReqId } from 'utils/services/candidates';
import { getRequests } from 'utils/services/requests';
import { Assignment } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import MainCard from 'ui-component/cards/MainCard';
import moment from 'moment';

const AddNewAssignment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme('oures');

    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [developers, setDevelopers] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [requestOptions, setRequestOptions] = useState([]);
    const [payload, setPayload] = useState({
        request: undefined,
        start_date: '',
        end_date: ''
    });

    const fetchCandidate = async (id) => {
        setLoading(true);
        await getCandidatebyReqId({ requestId: id })
            .then((res) => {
                if (res && res.success) {
                    if (res.data && res.data.length > 0) {
                        const filteredData = res.data.filter((item) => item.status === 1);
                        if (filteredData.length > 0) {
                            const buckets = filteredData.map((item) => ({ value: item.resource_id, label: item.name }));
                            setDevelopers([{ value: '', label: '' }]);
                            setCandidates(buckets);
                        }
                    }
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const fetchRequest = async () => {
        setLoading(true);
        await getRequests({ page: 1, limit: 100 })
            .then((res) => {
                if (res && res.success) {
                    setRequestOptions(res.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        if (developers && developers.length > 0) {
            const formData = developers.map((item) => ({
                form_request_id: payload.request.id,
                resource_id: item.value,
                start_date: payload.start_date,
                end_date: payload.end_date
            }));

            await createBatchAssignment({ body: { assignments: [...formData] } })
                .then((res) => {
                    if (res && res.success) {
                        setPayload({ request: undefined, start_date: '', end_date: '' });
                        setDevelopers([]);
                        setCandidates([]);
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                        setLoading(false);
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: `Error: ${res.message || 'Something went wrong!'}`,
                            variant: 'alert',
                            alertSeverity: 'error'
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: "You haven't add any developer!",
                variant: 'alert',
                alertSeverity: 'warning'
            });
        }
    };

    useEffect(() => {
        fetchRequest();
    }, []);

    useEffect(() => {
        if (payload.request === undefined || payload.start_date === '' || payload.end_date === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <MainCard
            sx={{ marginBottom: '10px' }}
            title={
                <Stack direction="row" spacing={2} justifyContent="left" alignItems="center" sx={{ float: 'left' }}>
                    <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                        <Assignment sx={{ color: '#ffffff' }} />
                    </Avatar>
                    <Stack direction="column">
                        <Typography variant="h3" sx={{ mb: 0 }}>
                            New Assignments
                        </Typography>
                        <Typography variant="caption">Fill all form bellow to submit new assignment.</Typography>
                    </Stack>
                </Stack>
            }
            content={false}
        >
            <Box component="form" onSubmit={handleSubmit} padding="20px" width="100%">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" mb={2}>
                            Assignment Information
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            <Autocomplete
                                disablePortal
                                options={
                                    requestOptions && requestOptions.length > 0
                                        ? requestOptions.map((item) => ({ id: item.id, label: item.request_name }))
                                        : []
                                }
                                getOptionLabel={(option) => option.label}
                                value={payload.request}
                                onChange={(e, newValue) => {
                                    setDevelopers([]);
                                    setPayload((prev) => {
                                        return { ...prev, request: newValue };
                                    });
                                    if (newValue && newValue.value) {
                                        fetchCandidate(newValue.value);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Request Name" required />}
                            />
                            <TextField
                                required
                                fullWidth
                                type="date"
                                label="Start Date"
                                value={payload.start_date}
                                onChange={(e) => {
                                    setPayload((prev) => {
                                        return { ...prev, start_date: e.target.value };
                                    });
                                }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: moment().format('YYYY-MM-DD')
                                }}
                            />
                            <TextField
                                required
                                fullWidth
                                type="date"
                                label="End Date"
                                value={payload.end_date}
                                onChange={(e) => {
                                    setPayload((prev) => {
                                        return { ...prev, end_date: e.target.value };
                                    });
                                }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: moment(payload.start_date).format('YYYY-MM-DD')
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" mb={2}>
                            Add Developer Candidates
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            {developers && developers.length > 0 ? (
                                developers.map((item, index) => (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            value={item}
                                            options={candidates}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(e, newValue) => {
                                                const temp = [...developers];
                                                const finder = temp.find((item) => item.value === newValue.value);
                                                if (!finder) {
                                                    temp[index] = newValue;
                                                    setDevelopers(temp);
                                                } else {
                                                    dispatch({
                                                        type: SNACKBAR_OPEN,
                                                        open: true,
                                                        message: 'Developer has been added before, select another developer!',
                                                        variant: 'alert',
                                                        alertSeverity: 'warning'
                                                    });
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} required label="Developer Name" />}
                                        />
                                        <IconButton
                                            sx={{ height: '40px' }}
                                            onClick={() => {
                                                const temp = [...developers];
                                                temp.splice(index, 1);
                                                setDevelopers(temp);
                                            }}
                                        >
                                            <IconTrashX color="red" />
                                        </IconButton>
                                    </Stack>
                                ))
                            ) : (
                                <Card sx={{ bgcolor: '#f3f3f3', p: 2 }}>
                                    <Typography sx={{ fontSize: '12px' }}>
                                        Before you can add developer, select request first. <br />
                                        <strong>Developer that listed here is developer that you&lsquo;ve been approved!</strong>
                                    </Typography>
                                </Card>
                            )}
                        </Stack>
                        {candidates && candidates.length > 0 && (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    disableElevation
                                    variant="text"
                                    size="small"
                                    sx={{ marginTop: '5px' }}
                                    onClick={() => {
                                        setDevelopers([...developers, { label: '', value: '' }]);
                                    }}
                                >
                                    Add More
                                </Button>
                            </Box>
                        )}
                    </Grid>
                    <Grid item container gap={1}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            sx={{ marginLeft: 'auto' }}
                            onClick={() => navigate('/assignments')}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            disableElevation
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={loading || disable}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default AddNewAssignment;
