import {
    Chip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Link
} from '@mui/material';
import { useState } from 'react';
import { IconArticleOff } from '@tabler/icons';

import TableLoader from 'ui-component/loader/TableLoader';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import LoopIcon from '@mui/icons-material/Loop';
import TerminateModal from '../Modal/TerminateModal';
import moment from 'moment';
import ExtendModal from '../Modal/ExtendModal';
import TableEmpty from 'ui-component/loader/TableEmpty';

function TableRunning({ startIndex, open, setOpen, lists, hasPermission, allPermission, loading, setLoading, handleRefresh }) {
    const [data, setData] = useState('');

    return (
        <>
            {open === 'extendModal' && (
                <ExtendModal
                    open={open === 'extendModal'}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    setOpen={setOpen}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}
            {open === 'terminate' && (
                <TerminateModal open={open === 'terminate'} row={data} handleClose={() => setOpen()} handleRefresh={handleRefresh} />
            )}

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 3 }}>
                                No.
                            </TableCell>
                            <TableCell>Resource Name</TableCell>
                            <TableCell>Request Title</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">End Date</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? (
                        <>
                            {lists && lists.length > 0 ? (
                                lists.map((row, index) => (
                                    <TableBody>
                                        <TableRow key={index}>
                                            <TableCell align="center" sx={{ pl: 3 }}>
                                                <Typography variant="h6">{index + startIndex}.</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" noWrap>
                                                    {row.resource_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2" noWrap>
                                                    {row.request_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Chip
                                                    label={
                                                        moment(row.quotation.start_date).format('D MMMM YYYY') <
                                                        moment(row.quotation.expired_date).format('D MMMM YYYY')
                                                            ? 'Running'
                                                            : 'Extended'
                                                    }
                                                    sx={{ fontSize: '12px' }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="subtitle2" noWrap>
                                                    {moment(row.quotation.start_date).format('D MMMM YYYY')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="subtitle2" noWrap>
                                                    {moment(row.quotation.expired_date).format('D MMMM YYYY')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                <Tooltip placement="top" title="View Letters">
                                                    <Link href={row.quotation.file_link} target="_blank" rel="noopener noreferrer">
                                                        <IconButton size="small">
                                                            <DescriptionTwoToneIcon sx={{ fontSize: 'large' }} />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                                {hasPermission(['FormRequestAssignment@store_assignment'], allPermission) && (
                                                    <Tooltip placement="top" title="Extend Contact">
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpen('extendModal');
                                                                setData(row);
                                                            }}
                                                            color="success"
                                                            size="large"
                                                        >
                                                            <LoopIcon sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip placement="top" title="Terminate Contract">
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpen('terminate');
                                                            setData(row);
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <IconArticleOff width={17} color="darkRed" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))
                            ) : (
                                <TableEmpty colSpan={7} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={7} />
                    )}
                </Table>
            </TableContainer>
        </>
    );
}

export default TableRunning;
