/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import {
    Typography,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    Stack,
    TextField,
    Link,
    Chip,
    Menu,
    MenuItem
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addLetterFiles } from 'utils/services/assignment-quotation';
import { SNACKBAR_OPEN } from 'store/actions';
import { useTheme } from '@mui/styles';
import { IconFileBroken } from '@tabler/icons';

import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 735,
    boxShadow: 24,
    borderRadius: '12px'
};

const NegotiationContractModal = ({ open, row, loading, setLoading, handleClose, handleRefresh, selectedIndex, setSelectedIndex }) => {
    const theme = useTheme('oures');
    const dispatch = useDispatch();
    const uploadInputRef = useRef();

    const [disable, setDisable] = useState(false);
    const [letterCode, setLetterCode] = useState('');
    const [letterNumber, setLetterNumber] = useState('');
    const [letterType, setLetterType] = useState('');
    const [clientName, setClientName] = useState('');
    const [type, setType] = useState('');
    const [date, setDate] = useState('');
    const [anchorEl, setAnchorEl] = useState();
    const [formData, setformData] = useState({
        name: row.resource_name,
        type: '',
        number: '',
        file_quotation: '',
        file_ktp: '',
        file_nda: '',
        file_ta: '',
        file_contract: '',
        file_spk: '',
        start_date: '',
        end_date: '',
        expired_date: ''
    });

    const onCancel = () => {
        setformData({
            name: row.resource_name,
            type: '',
            number: '',
            file_quotation: '',
            file_ktp: '',
            file_nda: '',
            file_ta: '',
            file_contract: '',
            file_spk: '',
            start_date: '',
            end_date: '',
            expired_date: ''
        });
        if (row.quotations.length > 0) {
            handleClose('negotiateLetters');
        } else {
            handleClose();
        }
    };

    const options = [
        'Quotation Letter',
        'Kartu Tanda Penduduk (KTP)',
        'Non-Disclosure Agreement (NDA)',
        'Talent Agreement (TA)',
        'Contract Agreement (CA)',
        'Surat Perintah Kerja (SPK)'
    ];

    const handleNextStep = () => {
        if (selectedIndex === 5) {
            setSelectedIndex(0);
        } else {
            setSelectedIndex(selectedIndex + 1);
        }
    };

    const handlePreviousStep = () => {
        if (selectedIndex === 0) {
            setSelectedIndex(5);
        } else {
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleButtonClick = () => {
        uploadInputRef.current.click();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const selectedFile = (() => {
        switch (options[selectedIndex]) {
            case 'Quotation Letter':
                return formData.file_quotation;
            case 'Kartu Tanda Penduduk (KTP)':
                return formData.file_ktp;
            case 'Non-Disclosure Agreement (NDA)':
                return formData.file_nda;
            case 'Talent Agreement (TA)':
                return formData.file_ta;
            case 'Contract Agreement (CA)':
                return formData.file_contract;
            case 'Surat Perintah Kerja (SPK)':
                return formData.file_spk;
            default:
                return null;
        }
    })();

    let fileText = 'Click to Add File';
    if (selectedFile) {
        fileText = 'Click to Choose Different File';
    }

    const selectedFileNames = [];
    const [fileStatusList, setFileStatusList] = useState(options.map(() => false));
    const handleFileInputChange = (selectedIndex, file) => {
        const updatedFileStatusList = [...fileStatusList];
        const newFileName = file ? file.name : '';
        const selectedFileName = selectedFileNames[selectedIndex];

        if (selectedFileName === newFileName) {
            alert('File with the same name already selected.');
            return;
        }

        updatedFileStatusList[selectedIndex] = !!file;
        setFileStatusList(updatedFileStatusList);

        if (selectedIndex === 0) {
            setformData({
                ...formData,
                file_quotation: file
            });
        } else if (selectedIndex === 1) {
            setformData({
                ...formData,
                file_ktp: file
            });
        } else if (selectedIndex === 2) {
            setformData({
                ...formData,
                file_nda: file
            });
        } else if (selectedIndex === 3) {
            setformData({
                ...formData,
                file_ta: file
            });
        } else if (selectedIndex === 4) {
            setformData({
                ...formData,
                file_contract: file
            });
        } else if (selectedIndex === 5) {
            setformData({
                ...formData,
                file_spk: file
            });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        let finalNumber = `${letterCode}.${letterNumber}/${letterType}${clientName}/${date}`;
        const updatedFormData = new FormData();
        updatedFormData.append('name', row.resource_name);
        updatedFormData.append('type', type);
        updatedFormData.append('number', finalNumber);
        updatedFormData.append('file_quotation', formData.file_quotation);
        updatedFormData.append('file_ktp', formData.file_ktp);
        updatedFormData.append('file_nda', formData.file_nda);
        updatedFormData.append('file_ta', formData.file_ta);
        updatedFormData.append('file_contract', formData.file_contract);
        updatedFormData.append('file_spk', formData.file_spk);
        updatedFormData.append('start_date', formData.start_date);
        updatedFormData.append('end_date', formData.end_date);
        updatedFormData.append('expired_date', formData.expired_date);

        await addLetterFiles({ id: row.id, payload: updatedFormData })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    finalNumber = '';
                    setformData({
                        ...formData,
                        number: '',
                        expired_date: ''
                    });
                    setLetterCode('');
                    setLetterType('');
                    setLetterNumber('');
                    setClientName('');
                    setDate('');
                    if (selectedIndex === 2 || selectedIndex === 4) {
                        setSelectedIndex(selectedIndex + 1);
                    }
                    if (selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 3 || selectedIndex === 5) {
                        handleClose();
                    }
                    setLoading(false);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })
            .finally(() => handleRefresh());
    };

    useEffect(() => {
        if (selectedIndex === 0) {
            setLetterCode('08');
            setLetterType('DIF-QUOT-');
            setType('quotation');
        } else if (selectedIndex === 1) {
            setType('ktp');
        } else if (selectedIndex === 2) {
            setType('nda');
        } else if (selectedIndex === 3) {
            setType('ta');
        } else if (selectedIndex === 4) {
            setLetterCode('06');
            setLetterType('DIF-RES');
            setType('contract');
        } else if (selectedIndex === 5) {
            setLetterCode('07');
            setLetterType('DIF-SPK');
            setType('spk');
        }
    }, [selectedIndex]);

    useEffect(() => {
        if (!options[selectedIndex]) {
            setDisable(true);
        } else if (selectedIndex === 0) {
            if (letterNumber === '' || clientName === '' || date === '' || formData.file_quotation === '' || formData.expired_date === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else if (selectedIndex === 1) {
            if (
                // formData.number === '' ||
                formData.file_ktp === ''
            ) {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else if (selectedIndex === 2) {
            if (formData.file_nda === '' || formData.expired_date === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else if (selectedIndex === 3) {
            if (formData.file_ta === '' || formData.expired_date === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else if (selectedIndex === 4) {
            if (
                letterNumber === '' ||
                date === '' ||
                formData.file_contract === '' ||
                formData.start_date === '' ||
                formData.end_date === ''
            ) {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else if (selectedIndex === 5) {
            if (letterNumber === '' || date === '' || formData.file_spk === '' || formData.expired_date === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        }
    }, [selectedIndex, formData, letterNumber, clientName, date, options]);

    console.log(selectedIndex);
    return (
        <Modal open={open} onClose={handleClose}>
            <Card sx={modalStyle}>
                <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Negotiate Contract" />
                <CardContent>
                    <form onSubmit={handleFormSubmit}>
                        <Stack spacing={2}>
                            <TextField
                                label="Name"
                                value={formData.name}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Button sx={{ paddingLeft: 0 }} color="primary" endIcon={<ExpandMoreIcon />} onClick={handleClick}>
                                    {options[selectedIndex] ? options[selectedIndex] : 'Letter'}
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    variant="selectedMenu"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                >
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <Stack direction="row" spacing={2}>
                                    <Button onClick={handlePreviousStep} disabled={selectedIndex === 0}>
                                        Previous
                                    </Button>
                                    <Button onClick={handleNextStep} disabled={selectedIndex === 5}>
                                        Next
                                    </Button>
                                </Stack>
                            </Stack>
                            {selectedIndex === 0 && (
                                <>
                                    <Typography variant="h5">Letter Number *</Typography>
                                    <Stack spacing={2} direction="row">
                                        <TextField disabled label="Code" value={letterCode} sx={{ width: '55px' }} />
                                        <TextField
                                            required
                                            label="Number"
                                            value={letterNumber}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, '').substring(0, 3);
                                                setLetterNumber(value);
                                            }}
                                            sx={{ width: '87px' }}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*'
                                            }}
                                        />
                                        <TextField disabled label="Letter Type" value="DIF - QUOT - " sx={{ width: '110px' }} />
                                        <TextField
                                            required
                                            label="Client Name in Short"
                                            value={clientName}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\d/g, '').substring(0, 3).toUpperCase();
                                                setClientName(value);
                                            }}
                                        />
                                        <TextField
                                            required
                                            type="month"
                                            label="Date"
                                            onChange={(e) => setDate(moment(e.target.value).format('MMM.YYYY'))}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Stack>
                                    <Grid xs={12} md={12} spacing={2} direction="row">
                                        <Typography variant="h5" paddingBottom={0.5}>
                                            Upload File *
                                        </Typography>
                                        <input
                                            required
                                            ref={uploadInputRef}
                                            accept="application/pdf"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileInputChange(selectedIndex, e.target.files[0]);
                                            }}
                                        />
                                        <Button
                                            sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleButtonClick}
                                        >
                                            <>
                                                <IconFileBroken />
                                                <Typography>{fileText}</Typography>
                                            </>
                                        </Button>
                                    </Grid>
                                    {formData.file_quotation && (
                                        <Stack>
                                            <Link
                                                href={URL.createObjectURL(formData.file_quotation)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Chip
                                                    label={formData.file_quotation.name}
                                                    size="medium"
                                                    variant="outlined"
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark,
                                                        width: '100%'
                                                    }}
                                                />
                                            </Link>
                                        </Stack>
                                    )}
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            type="date"
                                            label="Expired Date"
                                            value={moment(formData.expired_date).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                setformData({
                                                    ...formData,
                                                    expired_date: moment(e.target.value).format('YYYY-MM-DD')
                                                })
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {selectedIndex === 1 && (
                                <>
                                    <TextField
                                        label="Nomor Induk Kependudukan (NIK)"
                                        value="NIK"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                    <Grid xs={12} md={12} spacing={2} direction="row">
                                        <Typography variant="h5" paddingBottom={0.5}>
                                            Upload File *
                                        </Typography>
                                        <input
                                            required
                                            ref={uploadInputRef}
                                            accept="image/*"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileInputChange(selectedIndex, e.target.files[0]);
                                            }}
                                        />
                                        <Button
                                            sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleButtonClick}
                                        >
                                            <>
                                                <IconFileBroken />
                                                <Typography>{fileText}</Typography>
                                            </>
                                        </Button>
                                    </Grid>
                                    {formData.file_ktp && (
                                        <Stack>
                                            <Link href={URL.createObjectURL(formData.file_ktp)} target="_blank" rel="noopener noreferrer">
                                                <Chip
                                                    label={formData.file_ktp.name}
                                                    size="medium"
                                                    variant="outlined"
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark,
                                                        width: '100%'
                                                    }}
                                                />
                                            </Link>
                                        </Stack>
                                    )}
                                </>
                            )}
                            {selectedIndex === 2 && (
                                <>
                                    <Grid xs={12} md={12} spacing={2} direction="row">
                                        <Typography variant="h5" paddingBottom={0.5}>
                                            Upload File *
                                        </Typography>
                                        <input
                                            ref={uploadInputRef}
                                            accept="application/pdf"
                                            required
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileInputChange(selectedIndex, e.target.files[0]);
                                            }}
                                        />
                                        <Button
                                            sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleButtonClick}
                                        >
                                            <>
                                                <IconFileBroken />
                                                <Typography>{fileText}</Typography>
                                            </>
                                        </Button>
                                    </Grid>
                                    {formData.file_nda && (
                                        <Stack>
                                            <Link href={URL.createObjectURL(formData.file_nda)} target="_blank" rel="noopener noreferrer">
                                                <Chip
                                                    label={formData.file_nda.name}
                                                    size="medium"
                                                    variant="outlined"
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark,
                                                        width: '100%'
                                                    }}
                                                />
                                            </Link>
                                        </Stack>
                                    )}
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type="date"
                                            label="Expired Date"
                                            fullWidth
                                            value={moment(formData.expired_date).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                setformData({
                                                    ...formData,
                                                    expired_date: moment(e.target.value).format('YYYY-MM-DD')
                                                })
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {selectedIndex === 3 && (
                                <>
                                    <Grid xs={12} md={12} spacing={2} direction="row">
                                        <Typography variant="h5" paddingBottom={0.5}>
                                            Upload File *
                                        </Typography>
                                        <input
                                            ref={uploadInputRef}
                                            accept="application/pdf"
                                            required
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileInputChange(selectedIndex, e.target.files[0]);
                                            }}
                                        />
                                        <Button
                                            sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleButtonClick}
                                        >
                                            <>
                                                <IconFileBroken />
                                                <Typography>{fileText}</Typography>
                                            </>
                                        </Button>
                                    </Grid>
                                    {formData.file_ta && (
                                        <Stack>
                                            <Link href={URL.createObjectURL(formData.file_ta)} target="_blank" rel="noopener noreferrer">
                                                <Chip
                                                    label={formData.file_ta.name}
                                                    size="medium"
                                                    variant="outlined"
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark,
                                                        width: '100%'
                                                    }}
                                                />
                                            </Link>
                                        </Stack>
                                    )}
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type="date"
                                            label="Expired Date"
                                            fullWidth
                                            value={moment(formData.expired_date).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                setformData({
                                                    ...formData,
                                                    expired_date: moment(e.target.value).format('YYYY-MM-DD')
                                                })
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </>
                            )}
                            {selectedIndex === 4 && (
                                <>
                                    <Typography variant="h5">Letter Number *</Typography>
                                    <Stack spacing={2} direction="row">
                                        <TextField disabled label="Code" value={letterCode} sx={{ width: '55px' }} />
                                        <TextField
                                            required
                                            label="Number"
                                            value={letterNumber}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, '').substring(0, 3);
                                                setLetterNumber(value);
                                            }}
                                            sx={{ width: '87px' }}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*'
                                            }}
                                        />
                                        <TextField disabled label="Letter Type" value="DIF - RES" sx={{ width: '110px' }} />
                                        <TextField
                                            required
                                            type="month"
                                            label="Date"
                                            onChange={(e) => setDate(moment(e.target.value).format('MMM.YYYY'))}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Stack>
                                    <Grid xs={12} md={12} spacing={2} direction="row">
                                        <Typography variant="h5" paddingBottom={0.5}>
                                            Upload File *
                                        </Typography>
                                        <input
                                            ref={uploadInputRef}
                                            accept="application/pdf"
                                            required
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileInputChange(selectedIndex, e.target.files[0]);
                                            }}
                                        />
                                        <Button
                                            sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleButtonClick}
                                        >
                                            <>
                                                <IconFileBroken />
                                                <Typography>{fileText}</Typography>
                                            </>
                                        </Button>
                                    </Grid>
                                    {formData.file_contract && (
                                        <Stack>
                                            <Link
                                                href={URL.createObjectURL(formData.file_contract)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Chip
                                                    label={formData.file_contract.name}
                                                    size="medium"
                                                    variant="outlined"
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark,
                                                        width: '100%'
                                                    }}
                                                />
                                            </Link>
                                        </Stack>
                                    )}
                                    <Stack spacing={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    type="date"
                                                    label="Start Date"
                                                    fullWidth
                                                    value={moment(formData.start_date).format('YYYY-MM-DD')}
                                                    onChange={(e) =>
                                                        setformData({
                                                            ...formData,
                                                            start_date: moment(e.target.value).format('YYYY-MM-DD')
                                                        })
                                                    }
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    type="date"
                                                    label="End Date"
                                                    fullWidth
                                                    value={moment(formData.end_date).format('YYYY-MM-DD')}
                                                    onChange={(e) =>
                                                        setformData({
                                                            ...formData,
                                                            end_date: moment(e.target.value).format('YYYY-MM-DD')
                                                        })
                                                    }
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </>
                            )}
                            {selectedIndex === 5 && (
                                <>
                                    <Typography variant="h5">Letter Number *</Typography>
                                    <Stack spacing={2} direction="row">
                                        <TextField disabled label="Code" value={letterCode} sx={{ width: '55px' }} />
                                        <TextField
                                            required
                                            label="Number"
                                            value={letterNumber}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, '').substring(0, 3);
                                                setLetterNumber(value);
                                            }}
                                            sx={{ width: '87px' }}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*'
                                            }}
                                        />
                                        <TextField disabled label="Letter Type" value="DIF - SPK" sx={{ width: '110px' }} />
                                        <TextField
                                            required
                                            type="month"
                                            label="Date"
                                            onChange={(e) => setDate(moment(e.target.value).format('MMM.YYYY'))}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Stack>
                                    <Grid xs={12} md={12} spacing={2} direction="row">
                                        <Typography variant="h5" paddingBottom={0.5}>
                                            Upload File *
                                        </Typography>
                                        <input
                                            ref={uploadInputRef}
                                            accept="application/pdf"
                                            required
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                handleFileInputChange(selectedIndex, e.target.files[0]);
                                            }}
                                        />
                                        <Button
                                            sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleButtonClick}
                                        >
                                            <>
                                                <IconFileBroken />
                                                <Typography>{fileText}</Typography>
                                            </>
                                        </Button>
                                    </Grid>
                                    {formData.file_spk && (
                                        <Stack>
                                            <Link href={URL.createObjectURL(formData.file_spk)} target="_blank" rel="noopener noreferrer">
                                                <Chip
                                                    label={formData.file_spk.name}
                                                    size="medium"
                                                    variant="outlined"
                                                    onClick={() => {}}
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark,
                                                        width: '100%'
                                                    }}
                                                />
                                            </Link>
                                        </Stack>
                                    )}
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type="date"
                                            label="Expired Date"
                                            fullWidth
                                            value={moment(formData.expired_date).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                setformData({
                                                    ...formData,
                                                    expired_date: moment(e.target.value).format('YYYY-MM-DD')
                                                })
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <Button id="button-negotiate-cancel" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    disableElevation
                                    id="button-client-submit"
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                >
                                    {loading ? 'Loading' : 'Negotiate'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default NegotiationContractModal;
