/* eslint-disable no-unused-vars */
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { SNACKBAR_OPEN } from 'store/actions';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteInterviewSchedule } from 'utils/services/interview-schedule';
import { hasPermission } from 'helper/general';
import { IconEdit } from '@tabler/icons';
import useRootPermission from 'hooks/useRootPermission';
import DeleteIcon from '@mui/icons-material/Delete';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import moment from 'moment';
import 'moment/locale/id';

moment.locale('id');

const TableInterviewSchedule = ({ interviewSchedule = [], loading = true, refresh, startIndex }) => {
    const dispatch = useDispatch();

    const [granted, allPermission] = useRootPermission([
        'InterviewSchedule@interview_schedule_get_by_interviewer' &&
            'InterviewSchedule@interview_schedule_get_by_resource' &&
            'InterviewSchedule@interview_schedule_get_by_resource'
    ]);

    const handleDelete = async (id) => {
        deleteInterviewSchedule({ id }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
            }
            refresh();
        });
    };

    return (
        <TableContainer>
            <Table sx={{ minWidth: '850px', overflow: 'auto' }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ pl: 3 }}>
                            No.
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Interviewer</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading ? (
                        <>
                            {interviewSchedule && interviewSchedule.length > 0 ? (
                                interviewSchedule.map((row, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell align="center" sx={{ pl: 3 }}>
                                            {index + startIndex}.
                                        </TableCell>
                                        <TableCell>
                                            <Typography noWrap>{row.resource_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography noWrap>{row.interviewer_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography noWrap>{row.description}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography noWrap>{`${moment.utc(row.start_date).format('HH:mm')} WIB`}</Typography>
                                            <Typography noWrap>{moment.utc(row.start_date).format('D MMMM YYYY ')}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography noWrap>{`${moment.utc(row.end_date).format('HH:mm')} WIB`}</Typography>
                                            <Typography noWrap>{moment.utc(row.end_date).format('D MMMM YYYY ')}</Typography>
                                        </TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                {hasPermission(['JobPosition@edit_job_position'], allPermission) && (
                                                    <Tooltip placement="top" title="Edit">
                                                        <Link to={`form?action=update&identify=${row.id}`}>
                                                            <IconButton color="primary" aria-label="open" size="large">
                                                                <IconEdit size="19px" />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                )}
                                                {hasPermission(['JobPosition@delete_job_position'], allPermission) && (
                                                    <Tooltip placement="top" title="Delete">
                                                        <IconButton
                                                            onClick={() => handleDelete(row.id)}
                                                            color="primary"
                                                            aria-label="delete"
                                                            size="large"
                                                        >
                                                            <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableEmpty colSpan={7} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={7} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableInterviewSchedule;
