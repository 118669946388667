import { Card, CardContent, CardHeader, Modal, Stack, Button, TextField, Typography, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateStatusAssignment, updateStatusAssignmentQuot } from 'utils/services/assignment-quotation';
import { SNACKBAR_OPEN } from 'store/actions';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 635,
    boxShadow: 24,
    borderRadius: '12px'
};

const NoteModalQuotation = ({ open, handleClose, action, row, quotation, loading, setLoading, handleRefresh, GridNameLetter }) => {
    const dispatch = useDispatch();

    const [disable, setDisable] = useState(false);
    const [payload, setPayload] = useState({
        status: quotation[quotation.length - 1]?.status,
        process_step: 1,
        notes: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await updateStatusAssignmentQuot({
            id: quotation.id,
            payload: payload.status
        })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        if (payload.status === 5) {
            await updateStatusAssignment({
                id: row.form_request_assignment_id,
                payload: payload.process_step,
                notes: payload.notes
            })
                .then((res) => {
                    if (res && res.success) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'success',
                            variant: 'alert'
                        });
                        handleClose();
                        setLoading(false);
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: res.message,
                            alertSeverity: 'warning',
                            variant: 'alert'
                        });
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
        handleRefresh();
    };

    const onCancel = () => {
        handleClose('negotiateLetters');
    };

    useEffect(() => {
        if (action === 'acceptQuotation') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                status: 1,
                process_step: 1
            }));
        } else if (action === 'rejectQuotation') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                status: 2,
                process_step: 1
            }));
        } else if (action === 'acceptNDA_TA') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                status: 3,
                process_step: 1
            }));
        } else if (action === 'rejectNDA_TA') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                status: 4,
                process_step: 1
            }));
        } else if (action === 'acceptCA_SPK') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                status: 5,
                process_step: 2
            }));
        } else if (action === 'rejectCA_SPK') {
            setPayload((prevPayload) => ({
                ...prevPayload,
                status: 6,
                process_step: 1
            }));
        }
    }, [action]);

    useEffect(() => {
        if (action === 'rejectQuotation' || action === 'rejectNDA_TA' || action === 'rejectCA_SPK') {
            if (payload.notes === '') {
                setDisable(true);
            } else {
                setDisable(false);
            }
        } else {
            setDisable(false);
        }
    }, [action, payload]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Card sx={modalStyle}>
                <CardHeader
                    sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }}
                    title={
                        action === 'rejectQuotation' || action === 'rejectNDA_TA' || action === 'rejectCA_SPK'
                            ? 'Rejection Note'
                            : 'Acceptance Note'
                    }
                />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <Grid sx={{ display: 'flex' }}>
                                <Typography variant="h6" sx={{ paddingLeft: '2px' }}>
                                    {(action === 'acceptQuotation' || action === 'rejectQuotation') && (
                                        <GridNameLetter
                                            link={quotation.file_quotation}
                                            name={row.resource_name}
                                            letter="Quotation Letter"
                                        />
                                    )}
                                    {(action === 'acceptNDA_TA' || action === 'rejectNDA_TA') && (
                                        <Stack direction="column" spacing={1}>
                                            <GridNameLetter
                                                link={quotation.file_nda}
                                                name={row.resource_name}
                                                letter="Non-Disclosure Agreement (NDA)"
                                            />
                                            <GridNameLetter
                                                link={quotation.file_ta}
                                                name={row.resource_name}
                                                letter="Talent Agreement (TA)"
                                            />
                                        </Stack>
                                    )}
                                    {(action === 'acceptCA_SPK' || action === 'rejectCA_SPK') && (
                                        <Stack direction="column" spacing={1}>
                                            <GridNameLetter
                                                link={quotation.file_contract}
                                                name={row.resource_name}
                                                letter="Contract Agreement (CA)"
                                            />
                                            <GridNameLetter
                                                link={quotation.file_spk}
                                                name={row.resource_name}
                                                letter="Surat Perintah Kerja (SPK)"
                                            />
                                        </Stack>
                                    )}
                                </Typography>
                            </Grid>
                            <Typography>
                                You are about to{' '}
                                {action === 'acceptQuotation' || action === 'acceptNDA_TA' || action === 'acceptCA_SPK'
                                    ? 'accept '
                                    : 'reject '}
                                this{' '}
                                {action === 'acceptQuotation' || action === 'rejectQuotation'
                                    ? 'Quotation. '
                                    : action === 'acceptNDA_TA' || action === 'rejectNDA_TA'
                                    ? 'NDA and TA. '
                                    : action === 'acceptCA_SPK' || action === 'rejectCA_SPK'
                                    ? 'CA and SPK. '
                                    : ''}
                                Please review it again and fill out the reason below
                                {action === 'rejectQuotation' || action === 'rejectNDA_TA' || action === 'rejectCA_SPK'
                                    ? ':'
                                    : ' (optional):'}
                            </Typography>
                            <TextField
                                required={action === 'rejectQuotation' || action === 'rejectNDA_TA' || action === 'rejectCA_SPK'}
                                type="text"
                                multiline
                                minRows={4}
                                maxRows={8}
                                label={
                                    action === 'rejectQuotation' || action === 'rejectNDA_TA' || action === 'rejectCA_SPK'
                                        ? 'Rejection Note'
                                        : 'Acceptance Note'
                                }
                                value={payload.notes}
                                onChange={(e) => setPayload({ ...payload, notes: e.target.value })}
                            />
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <Button id="button-client-cancel" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {loading ? 'Loading' : 'Proceed'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default NoteModalQuotation;
