import { Box, Grid, TextField, Button, Stack, Typography, Avatar, FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { icons } from 'menu-items/other';
import { getUserInfo } from 'helper/general';
import { SNACKBAR_OPEN } from 'store/actions';
import { getCategories } from 'utils/services/categories';
import { postNewTicket } from 'utils/services/helpdesk';

import styled from 'styled-components';
import ConfirmationModal from 'ui-component/confirmation';
import MainCard from 'ui-component/cards/MainCard';
import MarkdownEditor from 'ui-component/forms/MarkdownEditor';

const HelpdeskForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const userInfo = getUserInfo();
    const dispatch = useDispatch();

    const [disable, setDisable] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [categories, setCategories] = useState([]);
    const [change, setChange] = useState('');
    const [payload, setPayload] = useState({
        title: '',
        support_category_id: '',
        message: '',
        PIC_id: userInfo.id
    });

    const fetchCategory = async () => {
        setLoading(true);
        await getCategories({ search: '' })
            .then((res) => {
                setCategories(res.data);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSubmit(true);

        await postNewTicket({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    navigate('/helpdesk');
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    useEffect(() => {
        const isMessageEmpty = payload.message === '' || !payload.message.replace(/<[^>]*>?/gm, '').trim();

        if (payload.PIC_id === '' || isMessageEmpty || payload.support_category_id === '' || payload.title === '') {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload]);

    return (
        <MainCard
            title={
                <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                    <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                        <IconClipboardList color="#fff" />
                    </Avatar>
                    <Stack direction="column">
                        <Typography variant="h3">Submit New Ticket</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Please fill the form below
                        </Typography>
                    </Stack>
                </Stack>
            }
        >
            <ConfirmationModal
                open={open}
                description="Your changes will be lost"
                handleCancel={() => setOpen(false)}
                handleContinue={() => navigate('/helpdesk')}
            />

            <Box component="form" autoComplete="off" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Title</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    placeholder="Insert Ticket Title"
                                    value={payload.title}
                                    onChange={(e) => setPayload({ ...payload, title: e.target.value })}
                                />
                                {submit && !payload.title && <FormHelperText sx={{ color: 'red' }}>Title is required</FormHelperText>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Ticket Category</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    {categories.map((category, index) => (
                                        <Grid item xs={12} md={3} key={`cat-${index}`}>
                                            <CategoryBox
                                                selected={category.category_title === change}
                                                onClick={() => {
                                                    setPayload({ ...payload, support_category_id: category.id });
                                                    setChange(category.category_title);
                                                }}
                                            >
                                                <div>
                                                    <icons.IconContainer />
                                                </div>
                                                <p>{category.category_title}</p>
                                            </CategoryBox>
                                        </Grid>
                                    ))}
                                </Grid>
                                {submit && !payload.support_category_id && (
                                    <FormHelperText sx={{ color: 'red' }}>Category is required</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12}>
                                <Typography variant="h5">What question or issue?</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <MarkdownEditor value={payload.message} handleChange={(e) => setPayload({ ...payload, message: e })} />
                                {submit && !payload.message && <FormHelperText sx={{ color: 'red' }}>Message is required</FormHelperText>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} gap={1} justifyContent="right">
                        <Button variant="outlined" onClick={() => setOpen(true)}>
                            Cancel
                        </Button>
                        <Button disableElevation variant="contained" type={loading ? 'button' : 'submit'} disabled={loading || disable}>
                            {loading ? 'Loading' : `Submit Ticket`}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

const CategoryBox = styled.div`
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    border: 1px solid #cecece;
    border-radius: 12px;
    padding: 0 5px;
    cursor: pointer;

    ${({ selected }) =>
        selected
            ? `
        color: white;
        background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
        
        `
            : `
        background: white;
        color: black;
    `}

    p {
        margin-left: 5px;
        font-weight: 500;
        font-size: 13px;
    }

    div {
        display: flex;
        background-color: #4158d0;
        background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
        border-radius: 12px;
        padding: 6px;
        align-items: center;

        ${({ selected }) =>
            selected
                ? `
            background: white;
        `
                : `
            background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
        `}
        svg {
            ${({ selected }) =>
                selected
                    ? `
                stroke: black;
            `
                    : `
                stroke: white;
            `}
        }
    }
`;

export default HelpdeskForm;
