import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import BlockIcon from '@mui/icons-material/Block';
import ClearIcon from '@mui/icons-material/Clear';
import TableLoader from 'ui-component/loader/TableLoader';
import BlacklistModal from '../Modal/BlacklistModal';
import BlacklistedModal from '../Modal/BlacklistedModal';
import TableEmpty from 'ui-component/loader/TableEmpty';
import TerminateNoteModal from '../Modal/TerminateNoteModal';

function TableDone({ startIndex, open, loading, setLoading, setOpen, lists, handleRefresh }) {
    const theme = useTheme('oures');
    const [data, setData] = useState({});

    return (
        <>
            {open === 'terminateNoteModal' && (
                <TerminateNoteModal
                    open={open === 'terminateNoteModal'}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    setOpen={setOpen}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}
            {open === 'blacklistModal' && (
                <BlacklistModal
                    open={open === 'blacklistModal'}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    setOpen={setOpen}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}
            {open === 'blacklistedModal' && (
                <BlacklistedModal
                    open={open === 'blacklistedModal'}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    setOpen={setOpen}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 3 }}>
                                No
                            </TableCell>
                            <TableCell>Resource Name</TableCell>
                            <TableCell>Request Title</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? (
                        lists && lists.length > 0 ? (
                            lists.map((row, index) => (
                                <TableBody>
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography variant="h6">{index + startIndex}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={row.is_blacklisted ? 'subtitle2' : 'h6'} noWrap>
                                                {row.resource_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2" noWrap>
                                                {row.request_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2" noWrap>
                                                {row.end_date}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {row.blacklist_notes && (
                                                <Chip
                                                    row={row}
                                                    index={index}
                                                    label="View Notes"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpen('blacklistedModal');
                                                        setData(row);
                                                    }}
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.text.secondary
                                                    }}
                                                />
                                            )}
                                            {row.termination_note && !row.is_blacklisted && (
                                                <Chip
                                                    row={row}
                                                    index={index}
                                                    label="View Notes"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpen('terminateNoteModal');
                                                        setData(row);
                                                    }}
                                                    sx={{
                                                        background: theme.palette.primary.light + 60,
                                                        color: theme.palette.primary.dark
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            {row.is_blacklisted ? (
                                                <Tooltip placement="top" title="Blacklisted">
                                                    <ClearIcon sx={{ fontSize: '1.1rem' }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip placement="top" title="Blacklist Resource">
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpen('blacklistModal');
                                                            setData(row);
                                                        }}
                                                        color="error"
                                                        size="large"
                                                    >
                                                        <BlockIcon sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))
                        ) : (
                            <TableEmpty colSpan={7} />
                        )
                    ) : (
                        <TableLoader colSpan={7} />
                    )}
                </Table>
            </TableContainer>
        </>
    );
}

export default TableDone;
