import { useTheme } from '@mui/material/styles';
import { Card, Grid, Typography, IconButton, Stack, Link } from '@mui/material';
import { hasPermission } from 'helper/general';
import { AddTwoTone, HistoryEdu } from '@mui/icons-material';

import PropTypes from 'prop-types';
import Avatar from 'ui-component/extended/Avatar';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import AssignmentLateTwoToneIcon from '@mui/icons-material/AssignmentLateTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

const CandidateCardItem = ({
    avatar,
    name,
    status,
    position,
    onAccept,
    onRevoke,
    onPreview,
    onDelete,
    permission,
    cv,
    onConfirm,
    type
}) => {
    const theme = useTheme('oures');
    return (
        <Card
            sx={{
                py: 1,
                width: '100%',
                border: '1px solid grey',
                borderColor: theme.palette.grey[100],
                background:
                    status === 0 ? theme.palette.grey[100] : status === 1 ? 'linear-gradient(62deg, pink 0%, #0B90FD 100%)' : '#cecece'
            }}
        >
            <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 1 }}>
                    {avatar ? (
                        <Avatar alt={name} src={avatar} sx={{ width: 40, height: 40 }} />
                    ) : (
                        <div
                            style={{
                                width: 40,
                                height: 40,
                                background: theme.palette.grey[300],
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textTransform: 'uppercase',
                                fontWeight: 'bold'
                            }}
                        >
                            {name ? name.substr(0, 2) : '??'}
                        </div>
                    )}
                </Grid>
                <Grid item xs={9} zeroMinWidth>
                    <Typography variant="h4" marginTop={1} color={status !== 0 ? 'white' : 'black'}>
                        {name}
                    </Typography>
                    <Typography variant="caption" color={status !== 0 ? 'white' : 'black'}>
                        {position}
                    </Typography>
                    <Stack direction="row" spacing={1} mt={1}>
                        <IconButton color="info" title="View Detail Candidate" onClick={onPreview} sx={{ p: 0, m: 0 }}>
                            <AssignmentIndTwoToneIcon fontSize="20px" />
                        </IconButton>
                        <Link href={cv} rel="noopener noreferrer" target="_blank">
                            <IconButton color="info" title="View Candidate's CV" sx={{ p: 0, m: 0 }}>
                                <HistoryEdu fontSize="20px" />
                            </IconButton>
                        </Link>
                        {hasPermission(['super-admin'], permission) && (
                            <>
                                {type === 'suggestion' ? (
                                    <IconButton color="info" title="Add this suggestion" onClick={onConfirm} sx={{ p: 0, m: 0 }}>
                                        <AddTwoTone fontSize="20px" />
                                    </IconButton>
                                ) : (
                                    <IconButton color="error" title="Delete this candidate" onClick={onDelete} sx={{ p: 0, m: 0 }}>
                                        <DeleteTwoToneIcon fontSize="20px" />
                                    </IconButton>
                                )}
                            </>
                        )}

                        {hasPermission(['client'], permission) && (
                            <>
                                {status === 0 && (
                                    <>
                                        <IconButton color="success" title="Accept this candidate" onClick={onAccept} sx={{ p: 0, m: 0 }}>
                                            <AssignmentTurnedInTwoToneIcon fontSize="20px" />
                                        </IconButton>
                                        <IconButton color="warning" title="Decline this candidate" onClick={onRevoke} sx={{ p: 0, m: 0 }}>
                                            <AssignmentLateTwoToneIcon fontSize="20px" />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
};

CandidateCardItem.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string
};

export default CandidateCardItem;
