import { useState } from 'react';

// material-ui
import {
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { OpenInNewRounded, EditTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { hasPermission } from 'helper/general';

import ConfirmationModal from 'ui-component/confirmation';
import SwitchComponent from 'ui-component/forms/Switch';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import axios from 'axios';
import useRootPermission from 'hooks/useRootPermission';

// ==============================|| USER LIST 1 ||============================== //

const ClientList = ({ clients = [], handleRefresh, loading = true, startIndex }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [floatingPayload, setFloatingPayload] = useState({ id: '', is_active: false });
    const [granted, allPermission] = useRootPermission(['Client@client_getpost']);

    const handleDeactivate = async () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios
            .put(`${process.env.REACT_APP_API_URL}/client/status/${floatingPayload.id}`, { is_active: floatingPayload.is_active }, config)
            .then((res) => {
                if (res) {
                    setOpen(false);
                    handleRefresh();
                }
            });
    };

    return (
        <>
            <ConfirmationModal
                open={open}
                title="Confirmation"
                description={message}
                handleCancel={() => setOpen(false)}
                handleContinue={() => handleDeactivate()}
                sx={{ width: '350px' }}
            />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 3 }}>
                                No
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>PIC</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {clients && clients.length > 0 ? (
                                    clients.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell align="center" sx={{ pl: 3 }}>
                                                {startIndex + index}
                                            </TableCell>
                                            <TableCell>{row.company_name}</TableCell>
                                            <TableCell>
                                                <Grid item xs zeroMinWidth>
                                                    <Typography align="left" variant="subtitle1" component="div">
                                                        {row.company_pic.pic_name}
                                                    </Typography>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        {row.company_pic.pic_email}
                                                    </Typography>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        {row.company_pic.pic_number}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>{row.city && row.city.city ? row.city.city : '-'}</TableCell>
                                            <TableCell>
                                                {hasPermission(['Client@status_update'], allPermission) && (
                                                    <SwitchComponent
                                                        id="switch-client-status"
                                                        checked={row.is_active}
                                                        onChange={() => {
                                                            setFloatingPayload({ id: row.id, is_active: !row.is_active });
                                                            setOpen(true);
                                                            setMessage(
                                                                row.is_active
                                                                    ? `Are you sure you want to <strong>DEACTIVATE</strong> this client?`
                                                                    : `Are you sure you want to <strong>ACTIVATE</strong> this client?`
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                {hasPermission(['Client@client_getputdel'], allPermission) && (
                                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                                        <Tooltip placement="top" title="View full information">
                                                            <Link id="link-client-detail" to={`view/${row.id}/detail`}>
                                                                <IconButton color="primary" aria-label="open" size="large">
                                                                    <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Edit information">
                                                            <Link id="link-client-edit" to={`form?action=update&identify=${row.id}`}>
                                                                <IconButton color="primary" aria-label="open" size="large">
                                                                    <EditTwoTone sx={{ fontSize: '1.1rem' }} />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                    </Stack>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={6} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={6} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ClientList;
