import {
    Box,
    Grid,
    TextField,
    Button,
    Stack,
    FormHelperText,
    Typography,
    Autocomplete,
    Avatar,
    FormGroup,
    FormControlLabel,
    Radio,
    InputAdornment,
    InputLabel,
    Divider,
    Chip,
    IconButton
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MainCard from 'ui-component/cards/MainCard';
import SwitchComponent from 'ui-component/forms/Switch';
import ConfirmationModal from 'ui-component/confirmation';

import axios from 'axios';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList, IconFileBroken } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { Delete, LinkedIn } from '@mui/icons-material';

const ResourceForm = () => {
    const theme = useTheme('oures');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [skills, setSkills] = useState([]);
    const [file, setFile] = useState(null);
    const [competences, setCompetences] = useState([]);
    const [skillSearch, setSkillSearch] = useState('');
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const uploadInputRef = useRef(null);
    const textArea = true;

    const englishLevels = [
        {
            id: '1',
            name: 'Beginner',
            description: ''
        },
        {
            id: '2',
            name: 'Elementary',
            description: ''
        },
        {
            id: '3',
            name: 'Intermediate',
            description: ''
        },
        {
            id: '4',
            name: 'Advanced',
            description: ''
        },
        {
            id: '5',
            name: 'Proficient',
            description: ''
        }
    ];

    const availabilities = [
        {
            id: 'Available',
            name: 'Im Ready For Assignment',
            description: ''
        },
        {
            id: 'Not Available',
            name: 'Not ready for now',
            description: ''
        },
        {
            id: 'Will Update Status',
            name: 'I will change my status later',
            description: ''
        }
    ];

    const handleButtonClick = () => {
        uploadInputRef.current.click();
    };

    const [payload, setPayload] = useState({
        name: '',
        email: '',
        password: '',
        password_confirm: '',
        phoneNumber: '',
        gender: '',
        nik: '',
        birth_place: '',
        birth_date: '',
        address: '',
        domisili_address: '',
        npwp: '',
        academic_histories: [
            {
                institute_name: '',
                major: '',
                degree: '',
                start_date: '',
                end_date: '',
                is_active: false
            }
        ],
        marital_status: '',
        dependents: '',
        kk: '',
        work_status: '',
        salary_expect: 0,
        avatarURL: null,
        office_hour: '',
        competence_id: '',
        cv_url: '',
        linkedin_url: '',
        available_work: '',
        english_level: '',
        skills: [
            {
                skill_id: '',
                skill_name: '',
                experience: 0
            }
        ].filter((x) => x.skill_id !== '')
    });

    const handleOptionClick = (option) => {
        const isSelected = selectedSkill.some((skill) => skill.skill_id === option.id);
        let newSelectedOptions = [];

        if (isSelected) {
            newSelectedOptions = selectedSkill.filter((skill) => skill.skill_id !== option.id);
        } else {
            newSelectedOptions = [...selectedSkill, { skill_id: option.id, skill_name: option.name, experience: 0 }];
        }

        setSelectedSkill(newSelectedOptions);
        setPayload({
            ...payload,
            skills: newSelectedOptions
        });
    };

    const fetchSkills = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/skill?page=1&limit=20${skillSearch ? `&filter=${skillSearch}` : ''}`).then((res) => {
            if (res) {
                setSkills(res.data.data);
            }
        });
    };

    const handleRegister = async () => {
        setLoading(true);
        const formPayload = {
            ...payload,
            skills: payload.skills.map((x) => {
                return {
                    skill_id: x.skill_id,
                    skill_name: x.skill_name,
                    experience: x.experience
                };
            }),
            birth_date: moment(payload.birth_date).format('YYYY-MM-DD'),
            competence_id: payload.competence_id
        };

        if (file) {
            const bags = new FormData();
            bags.append('file', file);
            await axios.post(`${process.env.REACT_APP_API_URL}/media/upload_file?type=cv`, bags).then((res) => {
                if (res && res.status === 200) {
                    formPayload.cv_url = res.data.data;
                }
            });
        }

        await axios
            .post(`${process.env.REACT_APP_API_URL}/register/resources`, formPayload)
            .then((res) => {
                if (res) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    setSuccess(true);
                    navigate('/resources');
                }
            })
            .catch((e) => {
                if (e && e.response) {
                    setSuccess(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'Registration Failed Please Try Again!',
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: e,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setSuccess(false);
                }
            })
            .finally(() => {
                setLoading(false);
                setPayload({
                    name: '',
                    email: '',
                    password: '',
                    password_confirm: '',
                    phoneNumber: '',
                    gender: '',
                    nik: '',
                    birth_place: '',
                    birth_date: '',
                    address: '',
                    domisili_address: '',
                    npwp: '',
                    academic_histories: [
                        {
                            institute_name: '',
                            major: '',
                            degree: '',
                            start_date: '',
                            end_date: '',
                            is_active: false
                        }
                    ],
                    marital_status: '',
                    dependents: '',
                    kk: '',
                    work_status: '',
                    salary_expect: 0,
                    avatarURL: null,
                    office_hour: '',
                    competence_id: '',
                    cv_url: '',
                    linkedin_url: '',
                    available_work: '',
                    english_level: '',
                    skills: [
                        {
                            skill_id: '',
                            skill_name: '',
                            experience: 0
                        }
                    ].filter((x) => x.skill_id !== '')
                });
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            payload.marital_status === '' &&
            payload.gender === '' &&
            payload.birth_date === '' &&
            payload.office_hour === '' &&
            payload.work_status === '' &&
            payload.competence_id === '' &&
            payload.salary_expect === 0 &&
            file === null &&
            payload.name === '' &&
            payload.email === '' &&
            payload.phoneNumber === '' &&
            payload.skills.filter((x) => x.skill_id === '') &&
            payload.english_level === '' &&
            payload.available_work === ''
        ) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Please Fill Out All Fields!',
                alertSeverity: 'warning',
                variant: 'alert'
            });
        } else if (payload.password === payload.password_confirm) {
            handleRegister();
        } else {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Password Confirmation Missmatch!',
                alertSeverity: 'warning',
                variant: 'alert'
            });
        }
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/competences/dropdown/list`)
            .then((res) => {
                if (res) {
                    setCompetences(res.data.data);
                }
            })
            .catch();
    }, []);
    useEffect(() => {
        fetchSkills();
    }, [skillSearch]);

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                Add Resource
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => {
                    setPayload({
                        name: '',
                        email: '',
                        password: '',
                        password_confirm: '',
                        phoneNumber: '',
                        gender: '',
                        nik: '',
                        birth_place: '',
                        birth_date: '',
                        address: '',
                        domisili_address: '',
                        npwp: '',
                        academic_histories: [
                            {
                                institute_name: '',
                                major: '',
                                degree: '',
                                start_date: '',
                                end_date: '',
                                is_active: false
                            }
                        ],
                        marital_status: '',
                        dependents: '',
                        kk: '',
                        work_status: '',
                        salary_expect: 0,
                        avatarURL: null,
                        office_hour: '',
                        competence_id: '',
                        cv_url: '',
                        linkedin_url: '',
                        available_work: '',
                        english_level: '',
                        skills: [
                            {
                                skill_id: '',
                                skill_name: '',
                                experience: 0
                            }
                        ].filter((x) => x.skill_id !== '')
                    });
                    navigate('/resources');
                }}
            />
            <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} spacing={2} direction="row">
                        <Typography variant="h3">1. Personal Identity</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            name="pic_name"
                            id="input-client-picName"
                            margin="normal"
                            value={payload.name}
                            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Name"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            margin="normal"
                            id="input-client-picEmail"
                            value={payload.email}
                            onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Email"
                            type="email"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            margin="normal"
                            id="input-client-picPhone"
                            value={payload.phoneNumber}
                            onChange={(e) => setPayload({ ...payload, phoneNumber: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Phone Number"
                            type="number"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            margin="normal"
                            id="input-client-birthPlace"
                            value={payload.birth_place}
                            onChange={(e) => setPayload({ ...payload, birth_place: e.target.value })}
                            sx={{ width: '100%', marginY: '14px' }}
                            label="Birth Place"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            multiline={textArea}
                            rows={1}
                            maxRows={5}
                            margin="normal"
                            id="input-client-picDomicile"
                            value={payload.address}
                            onChange={(e) => setPayload({ ...payload, address: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Address"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            margin="normal"
                            id="input-client-domicile"
                            value={payload.domisili_address}
                            onChange={(e) => setPayload({ ...payload, domisili_address: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Domicile"
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} direction="row">
                        <Typography>Gender</Typography>
                        <FormGroup sx={{ display: 'flex' }}>
                            <Grid item direction="row">
                                <FormControlLabel
                                    required
                                    control={
                                        <Radio
                                            checked={payload.gender === 'male'}
                                            onChange={(e) => setPayload({ ...payload, gender: e.target.value })}
                                            value="male"
                                        />
                                    }
                                    label="Male"
                                />
                                <FormControlLabel
                                    required
                                    control={
                                        <Radio
                                            checked={payload.gender === 'female'}
                                            onChange={(e) => setPayload({ ...payload, gender: e.target.value })}
                                            value="female"
                                        />
                                    }
                                    label="Female"
                                />
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <Autocomplete
                            disablePortal
                            id="marital-status"
                            value={payload.marital_status}
                            options={['Married', 'Not Married']}
                            sx={{ width: '100%', marginY: '13px' }}
                            onChange={(e, v) => {
                                setPayload({ ...payload, marital_status: v });
                            }}
                            renderInput={(params) => <TextField {...params} required label="Marital Status" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            margin="normal"
                            id="input-client-dependents"
                            value={payload.dependents}
                            onChange={(e) => setPayload({ ...payload, dependents: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Dependents"
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            type="date"
                            required
                            margin="normal"
                            id="input-client-birthDate"
                            value={payload.birth_date}
                            onChange={(e) => setPayload({ ...payload, birth_date: e.target.value })}
                            sx={{ width: '100%', marginY: '14px' }}
                            label="Birth Date"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <Typography>Available In Working Hour</Typography>
                        <FormGroup sx={{ display: 'flex' }}>
                            <Grid item direction="row">
                                <FormControlLabel
                                    required
                                    control={
                                        <Radio
                                            checked={payload.office_hour === 'yes'}
                                            onChange={(e) => setPayload({ ...payload, office_hour: e.target.value })}
                                            value="yes"
                                        />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    required
                                    control={
                                        <Radio
                                            checked={payload.office_hour === 'no'}
                                            onChange={(e) => setPayload({ ...payload, office_hour: e.target.value })}
                                            value="no"
                                        />
                                    }
                                    label="No"
                                />
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <Autocomplete
                            disablePortal
                            id="work-status"
                            value={payload.work_status}
                            options={['Freelance', 'Fulltime', 'Looking for job']}
                            sx={{ width: '100%', marginY: '13px' }}
                            onChange={(e, v) => {
                                setPayload({ ...payload, work_status: v });
                            }}
                            renderInput={(params) => <TextField {...params} required label="Work Status" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <Autocomplete
                            disablePortal
                            id="position-role"
                            options={competences}
                            getOptionLabel={(option) => option.name ?? ''}
                            sx={{ width: '100%', marginY: '13px' }}
                            disableClearable
                            onChange={(e, v) => {
                                setPayload({ ...payload, competence_id: v.id });
                            }}
                            renderInput={(params) => <TextField {...params} required label="Position/Role" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            type="text"
                            required
                            margin="normal"
                            id="input-client-salary"
                            value={payload.salary_expect}
                            onChange={(e) => setPayload({ ...payload, salary_expect: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Expected Salary"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            type="text"
                            required
                            margin="normal"
                            placeholder="e. g. http://linkedin.com/in/john-doe"
                            id="input-client-salary"
                            value={payload.linkedin_url}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LinkedIn />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => setPayload({ ...payload, linkedin_url: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Linkedin Account"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} spacing={2} direction="row">
                        <InputLabel htmlFor="icon-button-file">Upload Your CV</InputLabel>
                        <input
                            ref={uploadInputRef}
                            accept="application/pdf"
                            id="icon-button-file"
                            required
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                        <Button
                            sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                            variant="outlined"
                            color="secondary"
                            onClick={handleButtonClick}
                        >
                            <div>
                                <IconFileBroken />
                                {!file ? <Typography>Click to Add File</Typography> : <Typography>{file.name}</Typography>}
                            </div>
                        </Button>
                    </Grid>
                    <Grid item md={12} spacing={2} marginY={2} direction="column">
                        <Grid item md={12} spacing={2} marginY={2} direction="column">
                            <Typography variant="h5">Personal Identity</Typography>
                            <Typography variant="p">You can fill these forms later when you are ready for assignment</Typography>
                        </Grid>
                        <Grid item md={12} spacing={2} direction="row" sx={{ border: 'dashed', borderRadius: '10px', padding: 2 }}>
                            <TextField
                                name="Identity Number"
                                id="input-client-kk"
                                margin="normal"
                                value={payload.kk}
                                onChange={(e) => setPayload({ ...payload, kk: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Kartu Keluarga"
                                type="number"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                            <TextField
                                margin="normal"
                                name="Identity Number"
                                id="input-client-identity-number"
                                value={payload.nik}
                                onChange={(e) => setPayload({ ...payload, nik: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Identity Number"
                                type="number"
                                variant="outlined"
                            />
                            <TextField
                                margin="normal"
                                id="input-client-npwp"
                                value={payload.npwp}
                                onChange={(e) => setPayload({ ...payload, npwp: e.target.value })}
                                sx={{ width: '100%' }}
                                label="NPWP"
                                type="number"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12} spacing={2} direction="row">
                        <Typography variant="h3">2. Academical Histories</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            name="institute"
                            id="input-client-institute_name"
                            margin="normal"
                            value={payload.academic_histories[0].institute_name}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    academic_histories: [
                                        {
                                            ...payload.academic_histories[0],
                                            institute_name: e.target.value
                                        }
                                    ]
                                })
                            }
                            sx={{ width: '100%' }}
                            label="Institute Name"
                            onBlur={(e) => console.log(e)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            margin="normal"
                            id="input-client-major"
                            value={payload.academic_histories[0].major}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    academic_histories: [
                                        {
                                            ...payload.academic_histories[0],
                                            major: e.target.value
                                        }
                                    ]
                                })
                            }
                            sx={{ width: '100%' }}
                            label="Major"
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            required
                            margin="normal"
                            id="input-client-degree"
                            value={payload.academic_histories[0].degree}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    academic_histories: [
                                        {
                                            ...payload.academic_histories[0],
                                            degree: e.target.value
                                        }
                                    ]
                                })
                            }
                            sx={{ width: '100%' }}
                            label="Degree"
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            type="date"
                            required
                            margin="normal"
                            id="input-client-start-date"
                            value={payload.academic_histories[0].start_date}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    academic_histories: [
                                        {
                                            ...payload.academic_histories[0],
                                            start_date: e.target.value
                                        }
                                    ]
                                })
                            }
                            sx={{ width: '100%', marginY: '14px' }}
                            label="Start Date"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row">
                        <TextField
                            type="date"
                            required
                            margin="normal"
                            id="input-client-end-date"
                            disabled={payload.academic_histories[0].is_active}
                            value={payload.academic_histories[0].end_date}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    academic_histories: [
                                        {
                                            ...payload.academic_histories[0],
                                            end_date: e.target.value
                                        }
                                    ]
                                })
                            }
                            sx={{ width: '100%', marginY: '14px' }}
                            label="End Date"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} spacing={2} direction="row" display="flex" alignItems="center">
                        <FormHelperText>
                            Active
                            <SwitchComponent
                                label="Helper"
                                id="switch-client-statusUpdate"
                                checked={payload.academic_histories[0].is_active}
                                onChange={(e) => {
                                    if (e.target.checked === true) {
                                        setPayload({
                                            ...payload,
                                            academic_histories: [
                                                {
                                                    ...payload.academic_histories[0],
                                                    end_date: null,
                                                    is_active: e.target.checked
                                                }
                                            ]
                                        });
                                    } else {
                                        setPayload({
                                            ...payload,
                                            academic_histories: [
                                                {
                                                    ...payload.academic_histories[0],
                                                    is_active: e.target.checked
                                                }
                                            ]
                                        });
                                    }
                                }}
                            />
                        </FormHelperText>
                    </Grid>
                    <Grid item md={12} spacing={2} direction="row" marginY={2}>
                        <Grid item md={12} spacing={2} direction="row" marginY={2}>
                            <Typography variant="h3">3. Skill & Experiences</Typography>
                            <Typography variant="h5">Please Choose One or More Skills Below</Typography>
                        </Grid>
                        <Grid item md={12} spacing={2} direction="row" sx={{ border: 'solid', borderRadius: '10px', padding: 2 }}>
                            <TextField
                                name="Identity Number"
                                id="input-skills"
                                margin="normal"
                                value={skillSearch}
                                placeholder="Search Skill..."
                                onChange={(e) => setSkillSearch(e.target.value)}
                                sx={{ width: '100%' }}
                                type="search"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                            <Divider />
                            <Grid item md={12} spacing={2} direction="row" sx={{ padding: 2 }}>
                                {skills.map((item) => (
                                    <Chip key={item.id} label={item.name} variant="outlined" onClick={() => handleOptionClick(item)} />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    {payload.skills.length > 0 && (
                        <Grid item md={12} direction="row">
                            <Grid item md={12} spacing={2} marginY={2} direction="row">
                                <Typography variant="h5">Add your experience on each selected skill</Typography>
                            </Grid>
                            {payload.skills.map((item, index) => (
                                <Grid
                                    key={item.skill_id}
                                    container
                                    md={12}
                                    spacing={2}
                                    marginY={1}
                                    direction="row"
                                    justifyContent="between"
                                    alignItems="center"
                                    display="flex"
                                >
                                    <Grid item xs={12} md={3} direction="row" alignItems="center" display="flex">
                                        <TextField
                                            required
                                            id="input-client-skill-name"
                                            value={item.skill_name}
                                            disabled
                                            sx={{ width: '100%' }}
                                            label="Skill Name"
                                            type="text"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} direction="row" alignItems="center" display="flex">
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="work-status"
                                            value={item.experience}
                                            options={['1', '2', '3', '4', '5', '6', '7']}
                                            sx={{ width: '100%' }}
                                            onChange={(e, v) => {
                                                const newSkills = [...payload.skills];
                                                newSkills[index].experience = v;
                                                setPayload({
                                                    ...payload,
                                                    skills: newSkills
                                                });
                                            }}
                                            renderInput={(params) => <TextField {...params} required label="Work Status" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} direction="row" alignItems="center" display="flex">
                                        <IconButton
                                            onClick={() => {
                                                const newSkills = [...payload.skills];
                                                newSkills.splice(index, 1);
                                                setPayload({
                                                    ...payload,
                                                    skills: newSkills
                                                });
                                            }}
                                            aria-label="delete"
                                            size="small"
                                        >
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    )}

                    <Grid item xs={12} md={12} direction="row">
                        <Grid item md={12} spacing={2} marginY={2} direction="row">
                            <Typography variant="h5">Select your english level</Typography>
                        </Grid>
                        <Grid container md={12} spacing={2}>
                            {englishLevels.map((item) => (
                                <Grid item md={8} spacing={2} direction="columns">
                                    <Button
                                        key={item.id}
                                        sx={{
                                            justifyContent: 'start',
                                            textAlign: 'start',
                                            width: '100%',
                                            height: '80px',
                                            display: 'flex',
                                            direction: 'column'
                                        }}
                                        variant={payload.english_level === item.name ? 'contained' : 'outlined'}
                                        color="secondary"
                                        onClick={() => setPayload({ ...payload, english_level: item.name })}
                                    >
                                        {item.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} direction="row">
                        <Grid item md={12} spacing={2} marginY={2} direction="row">
                            <Typography variant="h3">4. Availability</Typography>
                        </Grid>
                        <Grid container md={12} spacing={2}>
                            {availabilities.map((item) => (
                                <Grid item md={8} spacing={2} direction="columns">
                                    <Button
                                        key={item.id}
                                        sx={{
                                            justifyContent: 'start',
                                            textAlign: 'start',
                                            width: '100%',
                                            height: '80px',
                                            display: 'flex',
                                            direction: 'column'
                                        }}
                                        variant={payload.available_work === item.id ? 'contained' : 'outlined'}
                                        color="secondary"
                                        onClick={() => setPayload({ ...payload, available_work: item.id })}
                                    >
                                        {item.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} direction="row">
                        <Grid item md={12} spacing={2} marginY={2} direction="row">
                            <Typography variant="h3">5. Others</Typography>
                            <Typography variant="h5">Password to sign in to our Resource App</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} spacing={2} direction="row">
                            <TextField
                                required
                                name="resource-password"
                                id="input-resource-password"
                                margin="normal"
                                value={payload.password}
                                onChange={(e) => setPayload({ ...payload, password: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Password"
                                type="password"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                            <TextField
                                required
                                name="resource-passwordConfirm"
                                id="input-resource-passwordConfirm"
                                margin="normal"
                                value={payload.password_confirm}
                                onChange={(e) => setPayload({ ...payload, password_confirm: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Confirm Your Password"
                                type="password"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button id="button-client-cancel" variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button id="button-client-submit" variant="contained" type={loading ? 'button' : 'submit'} disabled={loading}>
                                {loading ? 'Loading' : `Register Now`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default ResourceForm;
