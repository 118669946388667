// material-ui
import {
    Box,
    Grid,
    TextField,
    Button,
    Stack,
    Typography,
    Avatar,
    TableContainer,
    TableCell,
    TableRow,
    Skeleton,
    Table,
    TableBody,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ConfirmationModal from 'ui-component/confirmation';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { createNewEmployee, getDetailEmployee, updateEmployee } from 'utils/services/employee';
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';
import { createNewEmployeePosition, getDetailEmployeePosition, updateEmployeePosition } from 'utils/services/employee-position';
import { getJobPosition } from 'utils/services/job-position';
import moment from 'moment';
import 'moment/locale/id';

moment.locale('id');

const EmployeeDetail = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const identify = queryParams.get('identify');
    const [currentIndex, setCurrentIndex] = useState(null);
    const [jobPosition, setJobPosition] = useState(null);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editEmployee, setEditEmployee] = useState(false);
    const [editEmployeePosition, setEditEmployeePosition] = useState(false);
    const [dataEmployeePosition, setDataEmployeePosition] = useState(null);
    const [payloadEmployee, setPayloadEmployee] = useState({
        job_position_id: '',
        no_kk: '',
        nik: '',
        no_npwp: '',
        name_user: '',
        status_user: '',
        in_date: '',
        out_date: '',
        start_date: '',
        end_date: '',
        email: '',
        phone: '',
        educational: '',
        marital: '',
        dependents: '',
        place_of_birth: '',
        date_of_birth: '',
        address: '',
        domisili_address: '',
        bank_account: '',
        bank_number: '',
        is_active: ''
    });
    const [payloadEmployeePosition, setPayloadEmployeePosition] = useState({
        job_position_id: ''
    });
    const fetchJobPosition = async () => {
        getJobPosition({ page: 1, limit: 10, search: '' })
            .then((res) => {
                setJobPosition(res.data);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const payload = {
            ...payloadEmployeePosition,
            employees_id: identify
        };
        createNewEmployeePosition({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);

                    navigate(`/employee/detail?identify=${identify}`);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdateEmployee = async (event) => {
        event.preventDefault();

        updateEmployee({ identify, form: payloadEmployee }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                setEditEmployee(false);
                navigate(`/employee/detail?identify=${identify}`);
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate(`/employee/detail?identify=${identify}`);
            }
        });
    };

    console.log(currentIndex);

    // const handleChangeEmployeePosition = async (index, event) => {
    //     const values = [...payloadEmployeePosition];
    //     values[index][event.target.name] = event.target.value;
    //     set(values);
    // };
    const handleUpdateEmployeePosition = async (event) => {
        event.preventDefault();
        setLoading(true);
        const form = {
            job_position_id: payloadEmployeePosition[currentIndex].job_position_id,
            employees_id: payloadEmployeePosition[currentIndex].employees_id,
            is_active: false
        };
        updateEmployeePosition({ identify: payloadEmployeePosition[0].id, form }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                setLoading(false);
                setEditEmployeePosition(false);
                navigate(`/employee/detail?identify=${identify}`);
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                setLoading(false);
                navigate(`/employee/detail?identify=${identify}`);
            }
        });
    };
    const fetchDetailEmployee = async (identify) => {
        getDetailEmployee({ identify }).then((res) => {
            setPayloadEmployee({
                job_position_id: res.data.job_position_id,
                no_kk: res.data.no_kk,
                nik: res.data.nik,
                no_npwp: res.data.no_npwp,
                name_user: res.data.name_user,
                status_user: res.data.status_user,
                in_date: moment(res.data.in_date).format('YYYY-MM-DD'),
                out_date: moment(res.data.out_date).format('YYYY-MM-DD'),
                start_date: moment(res.data.start_date).format('YYYY-MM-DD'),
                end_date: moment(res.data.end_date).format('YYYY-MM-DD'),
                email: res.data.email,
                phone: res.data.phone,
                educational: res.data.educational,
                marital: res.data.marital,
                dependents: res.data.dependents,
                place_of_birth: res.data.place_of_birth,
                date_of_birth: moment(res.data.date_of_birth).format('YYYY-MM-DD'),
                address: res.data.address,
                domisili_address: res.data.domisili_address,
                bank_account: res.data.bank_account,
                bank_number: res.data.bank_number,
                is_active: res.data.is_active
            });
        });
    };
    const fetchDetailEmployeePosition = async (identify) => {
        getDetailEmployeePosition({ identify }).then((res) => {
            setDataEmployeePosition(res.data);
            setPayloadEmployeePosition(res.data);
        });
    };

    useEffect(() => {
        fetchJobPosition();
        fetchDetailEmployee(identify);
        fetchDetailEmployeePosition(identify);
    }, [identify, loading]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                Detail Employee
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/skills')}
            />
            <Box
                component="form"
                autoComplete="off"
                onSubmit={(ev) =>
                    editEmployee ? handleUpdateEmployee(ev) : editEmployeePosition ? handleUpdateEmployeePosition(ev) : handleSubmit(ev)
                }
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item md={6} xs={12}>
                        <SubCard
                            title={
                                <Grid px={2} container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Chip
                                            size="small"
                                            style={{ color: 'white' }}
                                            label={payloadEmployee.is_active ? 'Active' : 'Inactive'}
                                            color={payloadEmployee.is_active ? 'success' : 'primary'}
                                        />
                                    </Grid>
                                    {editEmployee && (
                                        <Grid item>
                                            <Button type="submit" variant="contained">
                                                Save
                                            </Button>
                                        </Grid>
                                    )}
                                    {!editEmployee && (
                                        <Grid item>
                                            <Button onClick={() => setEditEmployee(true)} variant="contained">
                                                Edit
                                            </Button>
                                        </Grid>
                                    )}
                                    {/* <Grid item>
                                <Chip size="small" label="Pro" color="primary" />
                            </Grid> */}
                                </Grid>
                            }
                        >
                            {!loading ? (
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                        <TableContainer>
                                            <Table
                                                sx={{
                                                    '& td': {
                                                        borderBottom: 'none'
                                                    }
                                                }}
                                                size="small"
                                            >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell variant="head">Employee Name</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="name_user"
                                                                margin="dense"
                                                                value={payloadEmployee.name_user}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, name_user: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Name Employee"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.name_user} </TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Family Card (No KK)</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="no_kk"
                                                                margin="dense"
                                                                type="number"
                                                                value={payloadEmployee.no_kk}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, no_kk: e.target.value })
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    '& input[type=number]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    },
                                                                    '& input[type=number]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type=number]::-webkit-inner-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    }
                                                                }}
                                                                label="Family Card (No KK)"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.no_kk}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">NIK</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="nik"
                                                                margin="dense"
                                                                type="number"
                                                                value={payloadEmployee.nik}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, nik: e.target.value })
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    '& input[type=number]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    },
                                                                    '& input[type=number]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type=number]::-webkit-inner-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    }
                                                                }}
                                                                label="NIK"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.nik}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">No NPWP</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="no_npwp"
                                                                margin="dense"
                                                                type="number"
                                                                value={payloadEmployee.no_npwp}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, no_npwp: e.target.value })
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    '& input[type=number]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    },
                                                                    '& input[type=number]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type=number]::-webkit-inner-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    }
                                                                }}
                                                                label="No NPWP"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.no_npwp}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Employee Status</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Status User</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={payloadEmployee.status_user}
                                                                    label="Status User"
                                                                    onChange={(e) =>
                                                                        setPayloadEmployee({
                                                                            ...payloadEmployee,
                                                                            status_user: e.target.value
                                                                        })
                                                                    }
                                                                >
                                                                    <MenuItem value="Magang">Magang</MenuItem>
                                                                    <MenuItem value="Probation">Probation</MenuItem>
                                                                    <MenuItem value="Kontrak">Kontrak</MenuItem>
                                                                    <MenuItem value="Tetap">Tetap</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        ) : (
                                                            <TableCell>{payloadEmployee.status_user}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">In Date / Join</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                type="date"
                                                                required
                                                                name="in_date"
                                                                margin="dense"
                                                                InputLabelProps={{ shrink: true }}
                                                                value={payloadEmployee.in_date}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, in_date: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="In Date"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{moment(payloadEmployee.in_date).format('DD MMM YYYY')}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Out Date / Resign</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                type="date"
                                                                required
                                                                name="out_date"
                                                                margin="dense"
                                                                InputLabelProps={{ shrink: true }}
                                                                value={payloadEmployee.out_date}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, out_date: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Out Date"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{moment(payloadEmployee.out_date).format('DD MMM YYYY')}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Start Date Contract Probation</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                type="date"
                                                                required
                                                                name="start_date"
                                                                margin="dense"
                                                                InputLabelProps={{ shrink: true }}
                                                                value={payloadEmployee.start_date}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, start_date: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Start Date"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>
                                                                {moment(payloadEmployee.start_date).format('DD MMM YYYY')}
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">End Date Contract Probation</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                type="date"
                                                                required
                                                                name="end_date"
                                                                margin="dense"
                                                                InputLabelProps={{ shrink: true }}
                                                                value={payloadEmployee.end_date}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, end_date: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="End Date"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{moment(payloadEmployee.end_date).format('DD MMM YYYY')}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Email</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="email"
                                                                margin="dense"
                                                                type="email"
                                                                value={payloadEmployee.email}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, email: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Email"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.email}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">No Hp</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="phone"
                                                                margin="dense"
                                                                type="number"
                                                                value={payloadEmployee.phone}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, phone: e.target.value })
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    '& input[type=number]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    },
                                                                    '& input[type=number]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type=number]::-webkit-inner-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    }
                                                                }}
                                                                label="Phone"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.phone}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Education</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Education</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={payloadEmployee.educational}
                                                                    label="Educational"
                                                                    onChange={(e) =>
                                                                        setPayloadEmployee({
                                                                            ...payloadEmployee,
                                                                            educational: e.target.value
                                                                        })
                                                                    }
                                                                >
                                                                    <MenuItem value="SMA">SMA</MenuItem>
                                                                    <MenuItem value="S1">S1</MenuItem>
                                                                    <MenuItem value="S2">S2</MenuItem>
                                                                    <MenuItem value="S3">S3</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        ) : (
                                                            <TableCell>{payloadEmployee.educational}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Marital Status</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <FormControl margin="normal" fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Marital Status</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={payloadEmployee.marital}
                                                                    label="Marital Status"
                                                                    onChange={(e) =>
                                                                        setPayloadEmployee({ ...payloadEmployee, marital: e.target.value })
                                                                    }
                                                                >
                                                                    <MenuItem value="Married">Married</MenuItem>
                                                                    <MenuItem value="Not Married">Not Married</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        ) : (
                                                            <TableCell>{payloadEmployee.marital}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Dependents</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                type="number"
                                                                required
                                                                name="dependents"
                                                                margin="dense"
                                                                value={payloadEmployee.dependents}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, dependents: e.target.value })
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    '& input[type=number]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    },
                                                                    '& input[type=number]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type=number]::-webkit-inner-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    }
                                                                }}
                                                                label="Dependents"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.dependents}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Birth Place</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="place_of_birth"
                                                                margin="dense"
                                                                value={payloadEmployee.place_of_birth}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({
                                                                        ...payloadEmployee,
                                                                        place_of_birth: e.target.value
                                                                    })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Place of Birth"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.place_of_birth}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Birth Date</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="date_of_birth"
                                                                margin="dense"
                                                                type="date"
                                                                InputLabelProps={{ shrink: true }}
                                                                value={payloadEmployee.date_of_birth}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({
                                                                        ...payloadEmployee,
                                                                        date_of_birth: e.target.value
                                                                    })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Date Of Birth"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>
                                                                {moment(payloadEmployee.date_of_birth).format('DD MMM YYYY')}
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Address</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="address"
                                                                margin="dense"
                                                                value={payloadEmployee.address}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, address: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Address"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.address}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Domicile Address</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="domisili_address"
                                                                margin="dense"
                                                                value={payloadEmployee.domisili_address}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({
                                                                        ...payloadEmployee,
                                                                        domisili_address: e.target.value
                                                                    })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Domicile Address"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.domisili_address}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Bank Account</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="bank_account"
                                                                margin="dense"
                                                                value={payloadEmployee.bank_account}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, bank_account: e.target.value })
                                                                }
                                                                sx={{ width: '100%' }}
                                                                label="Bank Account"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.bank_account}</TableCell>
                                                        )}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head">Bank Number</TableCell>
                                                        <TableCell>:</TableCell>
                                                        {editEmployee ? (
                                                            <TextField
                                                                required
                                                                name="bank_number"
                                                                margin="dense"
                                                                type="number"
                                                                value={payloadEmployee.bank_number}
                                                                onChange={(e) =>
                                                                    setPayloadEmployee({ ...payloadEmployee, bank_number: e.target.value })
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    '& input[type=number]': {
                                                                        '-moz-appearance': 'textfield'
                                                                    },
                                                                    '& input[type=number]::-webkit-outer-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    },
                                                                    '& input[type=number]::-webkit-inner-spin-button': {
                                                                        '-webkit-appearance': 'none',
                                                                        margin: 0
                                                                    }
                                                                }}
                                                                label="Bank Number"
                                                                onBlur={(e) => console.log(e)}
                                                                variant="outlined"
                                                            />
                                                        ) : (
                                                            <TableCell>{payloadEmployee.bank_number}</TableCell>
                                                        )}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Box width="100%">
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box>
                            )}
                        </SubCard>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <SubCard
                            title={
                                <Grid px={2} container justifyContent="end" alignItems="center">
                                    {!editEmployeePosition && (
                                        <Grid item>
                                            <Button onClick={() => setEditEmployeePosition(true)} variant="contained">
                                                Edit
                                            </Button>
                                        </Grid>
                                    )}
                                    {/* <Grid item>
                                <Chip size="small" label="Pro" color="primary" />
                            </Grid> */}
                                </Grid>
                            }
                        >
                            {dataEmployeePosition ? (
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                        <TableContainer>
                                            <Table
                                                sx={{
                                                    '& td': {
                                                        borderBottom: 'none'
                                                    }
                                                }}
                                                size="small"
                                            >
                                                <TableBody>
                                                    {dataEmployeePosition.map((item, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell variant="head">Job Position</TableCell>
                                                            <TableCell>:</TableCell>
                                                            {editEmployeePosition ? (
                                                                <>
                                                                    <FormControl margin="normal" fullWidth>
                                                                        <InputLabel id="demo-simple-select-label">Job Position</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            name="job_position_id"
                                                                            value={payloadEmployeePosition[idx].job_position_id}
                                                                            label="Job Position"
                                                                            onChange={(e) => {
                                                                                const value = [...payloadEmployeePosition];
                                                                                value[idx][e.target.name] = e.target.value;
                                                                                setPayloadEmployeePosition(value);
                                                                                // setPayloadEmployeePosition({
                                                                                //     ...payloadEmployeePosition,
                                                                                //     index: idx
                                                                                // });
                                                                            }}
                                                                        >
                                                                            {jobPosition &&
                                                                                jobPosition.map((item, index) => (
                                                                                    <MenuItem key={index} value={item.id}>
                                                                                        {item.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <Grid container justifyContent="flex-end">
                                                                        <Grid item>
                                                                            <Button
                                                                                onClick={() => setCurrentIndex(idx)}
                                                                                display="flex-end"
                                                                                type="submit"
                                                                                variant="contained"
                                                                            >
                                                                                Save
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    {item.job_position_name}
                                                                    <Chip
                                                                        size="small"
                                                                        style={{ color: 'white' }}
                                                                        label={dataEmployeePosition[idx].is_active ? 'Active' : 'Inactive'}
                                                                        color={dataEmployeePosition[idx].is_active ? 'success' : 'primary'}
                                                                    />
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Box width="100%">
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box>
                            )}
                        </SubCard>
                        <SubCard
                            title={
                                <Grid px={2} container justifyContent="end" alignItems="center">
                                    <Grid item>
                                        <Button type="submit" variant="contained">
                                            Add
                                        </Button>
                                    </Grid>

                                    {/* <Grid item>
                                <Chip size="small" label="Pro" color="primary" />
                            </Grid> */}
                                </Grid>
                            }
                        >
                            {dataEmployeePosition ? (
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">Job Position</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="job_position_id"
                                                value={payloadEmployeePosition.job_position_id}
                                                label="Job Position"
                                                onChange={(e) => {
                                                    setPayloadEmployeePosition({
                                                        job_position_id: e.target.value
                                                    });
                                                    // setPayloadEmployeePosition({
                                                    //     ...payloadEmployeePosition,
                                                    //     index: idx
                                                    // });
                                                }}
                                            >
                                                {jobPosition &&
                                                    jobPosition.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Box width="100%">
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box>
                            )}
                        </SubCard>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default EmployeeDetail;
