import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { changePassword } from 'utils/services/account-setting';
import { SNACKBAR_OPEN } from 'store/actions';
import { useTheme } from '@mui/styles';

import AnimateButton from 'ui-component/extended/AnimateButton';
import SubCard from 'ui-component/cards/SubCard';

// ==============================|| SECURITY ||============================== //

const Security = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [payload, setPayload] = useState({
        old_password: '',
        new_password: '',
        confirm_new_password: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        changePassword({ payload })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    navigate('/');
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Failed Change Password',
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
            });
    };

    return (
        <Grid container justifyContent="center" spacing={gridSpacing}>
            <Grid item sm={6} md={8}>
                <Box component="form" autoComplete="off" onSubmit={(ev) => handleSubmit(ev)}>
                    <Grid item xs={12}>
                        <SubCard title="Change Password">
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        value={payload.old_password}
                                        onChange={(e) => setPayload({ ...payload, old_password: e.target.value })}
                                        label="Current password"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        value={payload.new_password}
                                        onChange={(e) => setPayload({ ...payload, new_password: e.target.value })}
                                        label="New Password"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        value={payload.confirm_new_password}
                                        onChange={(e) => setPayload({ ...payload, confirm_new_password: e.target.value })}
                                        label="Re-enter New Password"
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign="right">
                                    <AnimateButton>
                                        <Button variant="contained" type="submit" sx={{ boxShadow: 'none' }}>
                                            Change Password
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                </Box>
            </Grid>
            <Grid item sm={6} md={4}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <SubCard title="Deactivate Account">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        To deactivate your account, first delete its resources. If you are the only owner of any teams,
                                        either assign another owner or deactivate the team.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row">
                                        <AnimateButton>
                                            <Button
                                                sx={{
                                                    color: theme.palette.error.main,
                                                    borderColor: theme.palette.error.main,
                                                    '&:hover': {
                                                        background: theme.palette.error.light + 25,
                                                        borderColor: theme.palette.error.main
                                                    }
                                                }}
                                                variant="outlined"
                                                size="small"
                                            >
                                                Deactivate Account
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Security;
