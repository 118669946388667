// material-ui
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { OpenInNewRounded } from '@mui/icons-material';
import { SNACKBAR_OPEN } from 'store/actions';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteDivisi } from 'utils/services/divisi';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';

const DivisiList = ({ divisi = [], loading = true, refresh }) => {
    const dispatch = useDispatch();

    const [granted, allPermission] = useRootPermission(['Division@division_getpost']);

    const handleDelete = async (id) => {
        deleteDivisi({ id }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
            }
            refresh();
        });
    };
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading ? (
                        <>
                            {divisi && divisi.length > 0 ? (
                                divisi.map((row, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                {hasPermission(['Division@edit_division'], allPermission) && (
                                                    <Tooltip placement="top" title="Edit Divisi">
                                                        <Link to={`form?action=update&identify=${row.id}`}>
                                                            <IconButton color="primary" aria-label="open" size="large">
                                                                <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                )}
                                                {hasPermission(['Division@delete_division'], allPermission) && (
                                                    <Tooltip placement="top" title="Delete Divisi">
                                                        <IconButton
                                                            onClick={() => handleDelete(row.id)}
                                                            color="primary"
                                                            aria-label="delete"
                                                            size="large"
                                                        >
                                                            <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableEmpty colSpan={6} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={6} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DivisiList;
