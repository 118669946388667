/* eslint-disable no-unused-vars */
import {
    Avatar,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Select,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { IconCheckbox, IconSearch } from '@tabler/icons';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { AddCircleTwoTone, PictureAsPdfTwoTone } from '@mui/icons-material';

import MainCard from 'ui-component/cards/MainCard';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import TableReporting from './Dataview/TableReporting';
import AddReportModal from './modal/AddEditReportModal';
import getReporting from 'utils/services/reporting';
import ExportReportModal from './modal/exportReportModal';

const Reporting = () => {
    const theme = useTheme('oures');
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [lists, setLists] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [clients, setClients] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchReportingData = async () => {
        setLoading(true);
        await getReporting({ page, limit, search, filter })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    // setTotal(res.meta.total);
                    setTotal(res.data.length);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchReportingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, search, filter]);

    return (
        <>
            {open === 'addReport' && (
                <AddReportModal
                    open={open === 'addReport'}
                    action={open}
                    handleClose={() => setOpen(false)}
                    handleRefresh={fetchReportingData}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
            {open === 'exportReport' && (
                <ExportReportModal
                    open={open === 'exportReport'}
                    handleClose={() => setOpen(false)}
                    handleRefresh={fetchReportingData}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}

            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" alignItems="center" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconCheckbox color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        Reporting
                                    </Typography>
                                    <Typography variant="caption">Your list of daily activity report</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControl size="small" sx={{ justifyContent: 'center' }}>
                                        <InputLabel>Filter Client</InputLabel>
                                        <Select
                                            label="Filter Client"
                                            onChange={(e) => setFilter(e.target.value)}
                                            margin="normal"
                                            sx={{ width: '150px' }}
                                            value={filter}
                                        >
                                            <MenuItem value="">Semua</MenuItem>
                                            {clients &&
                                                clients.map((client, i) => (
                                                    <MenuItem key={`client-${i}`} value={client.id}>
                                                        {client.company_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <OutlinedInput type="date" size="small" fullWidth onChange={(e) => setFilter(e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <OutlinedInput
                                        placeholder="Search"
                                        onChange={(e) => setSearch(e.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconSearch stroke={1.5} size="1rem" />
                                            </InputAdornment>
                                        }
                                        size="small"
                                    />
                                </Grid>
                                <Grid item>
                                    <Tooltip placement="top" title="Add Report">
                                        <IconButton onClick={() => setOpen('addReport')} sx={{ padding: 0 }}>
                                            <AddCircleTwoTone />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip placement="top" title="Export Report to PDF">
                                        <IconButton onClick={() => setOpen('exportReport')} sx={{ padding: 0 }}>
                                            <PictureAsPdfTwoTone />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {/* <GridToolbarExport>
                                    csvOptions=
                                    {{
                                        fileName: 'customerDataBase',
                                        delimiter: ';',
                                        utf8WithBom: true
                                    }}
                                </GridToolbarExport> */}
                            </Grid>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <TableReporting
                    loading={loading}
                    setLoading={setLoading}
                    lists={lists}
                    handleRefresh={fetchReportingData}
                    open={open}
                    setOpen={setOpen}
                    startIndex={(page - 1) * limit + 1}
                />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="secondary"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setLimit(10);
                                        handleClose();
                                    }}
                                >
                                    {' '}
                                    10 Rows
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setLimit(25);
                                        handleClose();
                                    }}
                                >
                                    {' '}
                                    25 Rows
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setLimit(50);
                                        handleClose();
                                    }}
                                >
                                    {' '}
                                    50 Rows
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default Reporting;
