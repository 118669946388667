import { Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import dummyData from 'views/dummy-data/dummyData';

const SubCardWorkExp = () => {
    const list = dummyData.dataLoker;

    return (
        <SubCard title="Working Experience" sx={{ mt: 3 }}>
            <TableContainer>
                <Table sx={{ '& td': { borderBottom: 'none' } }} size="small">
                    <TableBody>
                        {list.slice(0, 2).map((item, index) => (
                            <TableRow key={index}>
                                <TableCell variant="head" sx={{ width: '200px', display: 'flex', justifyContent: 'flex-start' }}>
                                    <Stack direction="column">
                                        <Typography sx={{ fontWeight: 600 }}>{index === 0 ? '2021 - Present' : '2020 - 2021'}</Typography>
                                        <Typography sx={{ fontSize: '12px', color: '#364152' }}>
                                            {index === 0 ? 'Full Time' : 'Internship'}
                                        </Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack spacing={0.5}>
                                        <Typography sx={{ fontWeight: 600, color: '#121926' }}>{item.posisiPekerjaan}</Typography>
                                        <Typography sx={{ fontWeight: 600, color: '#121926', fontSize: '13px' }}>
                                            PT {item.namaPerusahaan}
                                        </Typography>
                                        <Typography sx={{ color: '#364152', fontSize: '13px', textAlign: 'justify' }}>
                                            {item.deskripsi}
                                        </Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Box>
                <Typography textAlign="center" variant="subtitle2">
                    Resource doesnt add any experience(s) yet
                </Typography>
            </Box> */}
        </SubCard>
    );
};
export default SubCardWorkExp;
