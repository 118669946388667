import Slider from '@mui/material/Slider';
import { Grid, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

const marks = [
    {
        value: 5,
        label: 'Belum Mengisi',
    },
    {
        value: 30,
        label: 'Mengisi Identitas',
    },
    {
        value: 65,
        label: 'Mengisi Kemampuan',
    },
    {
        value: 95,
        label: 'Telah Selesai Mengisi',
    },
];
function valuetext(value) {
    return `${value} Progress`;
}

const Progress = () => {
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Your Progress</Typography>
                    </Grid>
                </Grid>
            }>
            <Slider
                aria-label="Always visible"
                defaultValue={50}
                getAriaValueText={valuetext}
                step={10}
                marks={marks}
                valueLabelDisplay="auto"
            />
        </MainCard >
    );
}

export default Progress