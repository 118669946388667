import { useTheme } from '@mui/material/styles';
import { Grid, Tab, Tabs } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import EditProfileTab from './EditProfileTab';
import MyAccount from './MyAccount';
import MainCard from 'ui-component/cards/MainCard';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import TabPanelFunc from 'component/function/TabPanelFunc';
import Profile from './Profile';
import { TabStyle } from 'style/tab';

// tabs option
const tabsOption = [
    {
        label: 'Profile',
        icon: <AccountCircleTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    // {
    //     label: 'Personal Details',
    //     icon: <DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    // },
    {
        label: 'Assignment History',
        icon: <LibraryBooksTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Edit Profile',
        icon: <EditOutlined sx={{ fontSize: '1.3rem' }} />
    }
];

// ==============================|| PROFILE 1 ||============================== //

const ResourceDetail = () => {
    const theme = useTheme('oures');

    const { value, handleChange, TabPanel, a11yProps } = TabPanelFunc();

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        sx={TabStyle()}
                    >
                        {tabsOption.map((tab, index) => (
                            <Tab
                                id="tab-resource-detail"
                                key={index}
                                component={Link}
                                to="#"
                                icon={tab.icon}
                                label={tab.label}
                                {...a11yProps(index)}
                            />
                        ))}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Profile />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MyAccount />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <EditProfileTab />
                    </TabPanel>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ResourceDetail;
