import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Grid, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { AddCircleTwoTone, Assignment, AssignmentReturnTwoTone, EventBusyTwoTone } from '@mui/icons-material';
import { hasPermission } from 'helper/general';

import MainCard from 'ui-component/cards/MainCard';
import AssignList from './Dataview';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import RootPermission from '../permission';
import useRootPermission from 'hooks/useRootPermission';
import TabPanelFunc from 'component/function/TabPanelFunc';

const tabsOption = [
    {
        label: 'Interview',
        icon: <AssignmentTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'On Process',
        icon: <AssignmentIndTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Running',
        icon: <AssignmentReturnTwoTone sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Done',
        icon: <AssignmentTurnedInTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Reassign',
        icon: <EventBusyTwoTone sx={{ fontSize: '1.3rem' }} />
    }
];

const AssignmentPage = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const [granted, allPermission] = useRootPermission(['Assignment@index']);

    const { value, handleChange, TabPanel, a11yProps } = TabPanelFunc();

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" alignItems="center" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <Assignment sx={{ color: '#ffffff' }} />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        Assignment
                                    </Typography>
                                    <Typography variant="caption">End to end assignment process</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        {hasPermission(['Assignment@create'], allPermission) && (
                            <Grid item>
                                <Tooltip placement="top" title="Add New Assignment">
                                    <IconButton onClick={() => navigate('/assignments/add')}>
                                        <AddCircleTwoTone />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                }
            >
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        id="tabs-assignment-page"
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                        {tabsOption.map((tab, index) => (
                            <Tab
                                id="tab-assignment-page"
                                key={index}
                                component={Link}
                                to="#"
                                icon={tab.icon}
                                label={tab.label}
                                {...a11yProps(index)}
                            />
                        ))}
                    </Tabs>
                    {tabsOption.map((tab, index) => (
                        <TabPanel key={index} value={value} index={index}>
                            <AssignList category={tab.label.charAt(0).toLowerCase() + tab.label.slice(1).replace(/\s/g, '')} />
                        </TabPanel>
                    ))}
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default AssignmentPage;
