import {
    Chip,
    Collapse,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Event, CheckCircleOutlineTwoTone, RemoveCircleOutlineTwoTone, LinkTwoTone, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useTheme } from '@mui/styles';

import TableLoader from 'ui-component/loader/TableLoader';
import moment from 'moment';
import ConfirmationModal from 'ui-component/confirmation';
import NoteModal from '../Modal/NoteModal';
import ScheduleModal from '../Modal/ScheduleModal';
import ReassignNoteModal from '../Modal/ReassignNoteModal';
import TableEmpty from 'ui-component/loader/TableEmpty';

function TableInterview({ category, open, hasPermission, allPermission, setOpen, lists, loading, setLoading, handleRefresh, startIndex }) {
    const theme = useTheme('oures');

    const [meetingLink, setMeetingLink] = useState('');
    const [data, setData] = useState('');
    const [index, setIndex] = useState('');
    const [expandedRow, setExpandedRow] = useState(null);

    const handleExpandClick = (rowIndex) => {
        if (expandedRow === rowIndex) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowIndex);
        }
    };

    return (
        <>
            {(open === 'accept' || open === 'reject') && (
                <NoteModal
                    open={open === 'accept' || open === 'reject'}
                    row={data}
                    category={category}
                    handleClose={() => setOpen(false)}
                    action={open}
                    handleRefresh={handleRefresh}
                />
            )}
            {(open === 'schedule' || open === 'updateSchedule') && (
                <ScheduleModal
                    open={open === 'schedule' || open === 'updateSchedule'}
                    handleClose={() => setOpen(false)}
                    row={data}
                    loading={loading}
                    setLoading={setLoading}
                    handleRefresh={handleRefresh}
                    action={open}
                />
            )}
            {open === 'confirm' && (
                <ConfirmationModal
                    open={open === 'confirm'}
                    title="Confirmation"
                    description={`Are you sure you want to open the link and start an interview?<br />If you open the meeting link, the status will be set to <strong>'On Interview'</strong>`}
                    handleCancel={() => setOpen(false)}
                    handleContinue={() => setOpen(false)}
                    link={meetingLink}
                    sx={{ width: '400px' }}
                />
            )}
            {(open === 'reassigns' || open === 'cancel') && (
                <ReassignNoteModal
                    open={open === 'reassigns' || open === 'cancel'}
                    action={open}
                    row={data}
                    index={index}
                    loading={loading}
                    setLoading={setLoading}
                    handleClose={() => setOpen(false)}
                    handleRefresh={handleRefresh}
                />
            )}

            <TableContainer>
                <Table sx={{ '& td': { borderBottom: 'none' } }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 2 }}>
                                No.
                            </TableCell>
                            <TableCell>Resource Name</TableCell>
                            <TableCell>Request Title</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="center" sx={{ pr: 2 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? (
                        <>
                            {lists && lists.length > 0 ? (
                                lists.map((row, index) => (
                                    <TableBody>
                                        <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            <TableCell align="center" sx={{ pl: 2 }}>
                                                <Typography variant="h6">{startIndex + index}.</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" noWrap>
                                                    {row.interview.resource_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2" noWrap>
                                                    {row.interview.request_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography align="left" variant="subtitle1" noWrap>
                                                    {new Date(row.interview.start_date) < new Date() ? (
                                                        <Grid sx={{ display: 'flex' }} alignItems="center">
                                                            <Tooltip placement="top" title="Open Link">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setOpen('confirm');
                                                                        setMeetingLink(row.interview.meeting_link);
                                                                    }}
                                                                    color="primary"
                                                                    sx={{
                                                                        padding: 0,
                                                                        paddingRight: '5px'
                                                                    }}
                                                                >
                                                                    <LinkTwoTone sx={{ fontSize: 'large' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Typography align="left" variant="subtitle1" noWrap>
                                                                On Interview
                                                            </Typography>
                                                        </Grid>
                                                    ) : (
                                                        'Scheduled'
                                                    )}
                                                </Typography>
                                                <Typography variant="subtitle2" noWrap>
                                                    {`${moment.utc(row.interview.start_date).format('DD MMMM YYYY HH:mm')} WIB`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1" noWrap>
                                                    {row.interview.description}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 2, borderBottom: 'unset' }}>
                                                {new Date(row.interview.start_date) > new Date() ? (
                                                    <>
                                                        {hasPermission(['FormRequestAssignment@store_assignment'], allPermission) && (
                                                            <Tooltip placement="top" title="Update Interview Schedule">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setOpen('schedule');
                                                                        setData(row);
                                                                    }}
                                                                    color="primary"
                                                                    aria-label="open"
                                                                    size="large"
                                                                    id="button-assignment-schedule"
                                                                >
                                                                    <Event sx={{ fontSize: 'large' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {hasPermission(['FormRequestAssignment@put_status_assignment'], allPermission) && (
                                                            <>
                                                                {row.interview.description === 'HR Interview' ? (
                                                                    <Tooltip placement="top" title="Qualify to User Interview">
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                setOpen('updateSchedule');
                                                                                setData(row);
                                                                            }}
                                                                            color="primary"
                                                                            aria-label="open"
                                                                            size="large"
                                                                            id="button-assignment-update"
                                                                        >
                                                                            <CheckCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Tooltip placement="top" title="Qualify">
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                setOpen('accept');
                                                                                setData(row);
                                                                            }}
                                                                            color="success"
                                                                            aria-label="open"
                                                                            size="large"
                                                                            id="button-assignment-qualify"
                                                                        >
                                                                            <CheckCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                                <Tooltip placement="top" title="Cancel">
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setOpen('reject');
                                                                            setData(row);
                                                                        }}
                                                                        color="error"
                                                                        aria-label="open"
                                                                        size="large"
                                                                        id="button-assignment-cancel"
                                                                    >
                                                                        <RemoveCircleOutlineTwoTone sx={{ fontSize: 'large' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {row.history_interview &&
                                                    row.history_interview.some((historyItem) => historyItem.cancel_note) && (
                                                        <Tooltip placement="top" title="Interview History">
                                                            <IconButton
                                                                color="primary"
                                                                aria-label="expand"
                                                                size="large"
                                                                id={`button-assignment-expand-${index}`}
                                                                onClick={() => handleExpandClick(index)}
                                                                sx={{ '& > *': { borderBottom: 'unset' } }}
                                                            >
                                                                <ExpandMore
                                                                    sx={{
                                                                        fontSize: 'large',
                                                                        transform: expandedRow === index ? 'rotate(180deg)' : 'rotate(0deg)'
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1 }}>
                                                        <Typography variant="h3" gutterBottom component="div">
                                                            History
                                                        </Typography>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Resource Name</TableCell>
                                                                    <TableCell>Request Title</TableCell>
                                                                    <TableCell>Last Date of Interview</TableCell>
                                                                    <TableCell align="center">Notes</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.history_interview.map((historyItem, historyIndex) => (
                                                                    <TableRow key={historyIndex}>
                                                                        <TableCell>
                                                                            <Typography variant="h6" noWrap>
                                                                                {historyItem.resource_name}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography align="left" variant="subtitle2" noWrap>
                                                                                {historyItem.request_name}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography align="left" variant="subtitle2" noWrap>
                                                                                {moment(historyItem.last_date_interview).format(
                                                                                    'D MMMM YYYY'
                                                                                )}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {historyItem.cancel_note && (
                                                                                <Chip
                                                                                    label="Cancel Note"
                                                                                    size="small"
                                                                                    align="left"
                                                                                    onClick={() => {
                                                                                        setOpen('cancel');
                                                                                        setData(row);
                                                                                        setIndex(historyIndex);
                                                                                    }}
                                                                                    sx={{
                                                                                        background: theme.palette.primary.light + 60,
                                                                                        color: theme.palette.primary.dark
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {historyItem.reassign_note && (
                                                                                <Chip
                                                                                    label="Reassign Note"
                                                                                    size="small"
                                                                                    align="left"
                                                                                    onClick={() => {
                                                                                        setOpen('reassigns');
                                                                                        setData(row);
                                                                                        setIndex(historyIndex);
                                                                                    }}
                                                                                    sx={{
                                                                                        background: theme.palette.primary.light + 60,
                                                                                        color: theme.palette.primary.dark
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))
                            ) : (
                                <TableEmpty colSpan={7} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={7} />
                    )}
                </Table>
            </TableContainer>
        </>
    );
}

export default TableInterview;
