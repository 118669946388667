/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Chip, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Delete, OpenInNewRounded } from '@mui/icons-material';
import { hasPermission } from 'helper/general';
import { Link } from 'react-router-dom';
import { deleteTicketbyId } from 'utils/services/helpdesk';
import { useTheme } from '@mui/styles';

import ConfirmationModal from 'ui-component/confirmation';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import useRootPermission from 'hooks/useRootPermission';

const HelpdeskList = ({ startIndex, helpdesks = [], handleRefresh, loading, setLoading }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState({});
    const [granted, allPermission] = useRootPermission(['HelpDesk@index']);

    const handleDelete = async () => {
        setLoading(true);
        await deleteTicketbyId({ id })
            .then((res) => {
                if (res) {
                    setOpen(false);
                    setLoading(false);
                    handleRefresh();
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <>
            <ConfirmationModal open={open} title="Confirmation" handleCancel={() => setOpen(false)} handleContinue={() => handleDelete()} />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ pl: 3 }}>
                                No.
                            </TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>PIC</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            helpdesks && helpdesks.length > 0 ? (
                                helpdesks.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center" sx={{ pl: 3 }}>
                                            {index + startIndex}.
                                        </TableCell>
                                        <TableCell>{row.title}</TableCell>
                                        <TableCell>{row.category}</TableCell>
                                        <TableCell>{row.PIC_name}</TableCell>
                                        <TableCell align="center">
                                            {row.status === 0 && (
                                                <Chip
                                                    label="Open"
                                                    size="small"
                                                    sx={{
                                                        background: theme.palette.success.light + 60,
                                                        color: theme.palette.success.dark
                                                    }}
                                                />
                                            )}
                                            {row.status === 1 && (
                                                <Chip
                                                    label="Closed"
                                                    size="small"
                                                    sx={{
                                                        background: theme.palette.info.light + 60,
                                                        color: theme.palette.info.dark
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                {hasPermission(['HelpDesk@show'], allPermission) && (
                                                    <Tooltip placement="top" title="View Detail">
                                                        <Link to={`/helpdesk/${row.id}/detail`}>
                                                            <IconButton color="primary" aria-label="open" size="large">
                                                                <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                )}
                                                {hasPermission(['HelpDesk@delete'], allPermission) && (
                                                    <Tooltip placement="top" title="Delete">
                                                        <IconButton
                                                            size="large"
                                                            color="primary"
                                                            onClick={() => {
                                                                setId(row.id);
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            <Delete sx={{ fontSize: '1.2rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableEmpty colSpan={6} />
                            )
                        ) : (
                            <TableLoader colSpan={6} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default HelpdeskList;
