import { updateExtends } from 'utils/services/assignment-done';
import { Typography, Button, Card, CardContent, CardHeader, Grid, Modal, Stack, TextField, Chip } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { IconFileBroken } from '@tabler/icons';
import { SNACKBAR_OPEN } from 'store/actions';
import Link from '@mui/material/Link';
import moment from 'moment';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 780,
    boxShadow: 24,
    borderRadius: '12px'
};

const ExtendModal = ({ open, row, loading, setLoading, handleClose, handleRefresh }) => {
    const dispatch = useDispatch();
    const uploadInputRef = useRef(null);
    const theme = useTheme('oures');

    const [disable, setDisable] = useState(false);
    const [letterNumber, setLetterNumber] = useState('');
    const [clientName, setClientName] = useState('');
    const [date, setDate] = useState('');
    const [payload, setPayload] = useState({
        name: row.resource_name,
        number: '',
        start_date: '',
        expired_date: '',
        file: ''
    });

    const handleButtonClick = () => {
        uploadInputRef.current.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const finalNumber = `08.${letterNumber}/DIF-QUOT-${clientName}/${date}`;
        const updatedPayload = {
            ...payload,
            number: finalNumber
        };
        const formData = new FormData();
        formData.append('name', updatedPayload.name);
        formData.append('number', updatedPayload.number);
        formData.append('start_date', updatedPayload.start_date);
        formData.append('expired_date', updatedPayload.expired_date);
        formData.append('file', updatedPayload.file);

        await updateExtends({ id: row.id, payload: formData })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
            .finally(() => handleRefresh());
    };

    const onCancel = () => {
        setPayload({
            name: row.resource_name,
            number: '',
            letterNumber: '',
            clientName: '',
            date: '',
            start_date: '',
            expired_date: '',
            file: ''
        });
        if (row.quotations > 0) {
            handleClose('quotationOnProcess');
        } else {
            handleClose();
        }
    };

    useEffect(() => {
        if (
            payload.name === '' ||
            letterNumber === '' ||
            clientName === '' ||
            date === '' ||
            payload.start_date === '' ||
            payload.expired_date === '' ||
            payload.file === ''
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [payload, letterNumber, clientName, date]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Card sx={modalStyle}>
                <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Extend Contract" />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <TextField
                                label="Name"
                                value={payload.name}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <Typography variant="h5">Letter Number *</Typography>
                            <Grid
                                container
                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}
                            >
                                <Typography> 08 . </Typography>
                                <TextField
                                    required
                                    label="Number"
                                    value={letterNumber}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, '').substring(0, 3);
                                        setLetterNumber(value);
                                    }}
                                />
                                <Typography> / DIF - QUOT - </Typography>
                                <TextField
                                    required
                                    label="Client Name in Short"
                                    value={clientName}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\d/g, '').substring(0, 3).toUpperCase();
                                        setClientName(value);
                                    }}
                                />
                                <Typography> / </Typography>
                                <TextField
                                    required
                                    type="month"
                                    label="Date"
                                    sx={{ width: 'auto' }}
                                    onChange={(e) => setDate(moment(e.target.value).format('MMM.YYYY'))}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} spacing={2} direction="row">
                                <Typography variant="h5" paddingBottom={0.5}>
                                    Upload Contract Extension Files *
                                </Typography>
                                <input
                                    required
                                    ref={uploadInputRef}
                                    accept="application/pdf"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => setPayload({ ...payload, file: e.target.files[0] })}
                                />
                                <Button
                                    sx={{ width: '100%', height: '100px', display: 'flex', direction: 'column' }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleButtonClick}
                                >
                                    <>
                                        <IconFileBroken />
                                        {!payload.file ? (
                                            <Typography>Click to Add File</Typography>
                                        ) : (
                                            <Typography>{payload.file.name}</Typography>
                                        )}
                                    </>
                                </Button>
                            </Grid>
                            {payload.file && (
                                <Link href={URL.createObjectURL(payload.file)} target="_blank" rel="noopener noreferrer">
                                    <Chip
                                        label={!payload.file ? 'View Contract Extension Files' : payload.file.name}
                                        variant="outlined"
                                        sx={{
                                            background: theme.palette.primary.light + 60,
                                            color: theme.palette.primary.dark,
                                            width: '100%'
                                        }}
                                    />
                                </Link>
                            )}
                            <Stack>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type="date"
                                            label="Start Date"
                                            sx={{ width: '100%' }}
                                            value={moment(payload.start_date).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                setPayload({ ...payload, start_date: moment(e.target.value).format('YYYY-MM-DD') })
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="End Date"
                                            required
                                            type="date"
                                            sx={{ width: '100%' }}
                                            value={moment(payload.expired_date).format('YYYY-MM-DD')}
                                            onChange={(e) =>
                                                setPayload({ ...payload, expired_date: moment(e.target.value).format('YYYY-MM-DD') })
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <Button id="button-client-cancel" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    disableElevation
                                    id="button-client-submit"
                                    variant="contained"
                                    type={loading ? 'button' : 'submit'}
                                    disabled={loading || disable}
                                >
                                    {loading ? 'Loading' : 'Submit'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default ExtendModal;
