// material-ui
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { OpenInNewRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { deleteRole } from 'utils/services/role';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';

const RoleList = ({data = [], handleRefresh, loading = false}) => {
    const dispatch = useDispatch();
    const [granted, allPermission] = useRootPermission(['Role@get_role_by_id']);

    const handleDelete = async (id) => {
        deleteRole({ id })
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                }
                else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                }
                handleRefresh()
            });
    };
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Job Position</TableCell>
                        <TableCell>Division</TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading ? (
                        <>
                            {data && data.length > 0 ? (
                                data.map((row, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.job_Position}</TableCell>
                                        <TableCell>{row.division_name}</TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                {hasPermission(['Role@update_role'], allPermission) && (
                                                <Tooltip placement="top" title="Edit Role">
                                                    <Link to={`form?action=update&identify=${row.id}`}>
                                                        <IconButton color="primary" aria-label="open" size="large">
                                                            <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                                )}
                                                {hasPermission(['Role@delete_role'], allPermission) && (
                                                <Tooltip placement="top" title="Delete Role">
                                                    <IconButton color="primary" onClick={() => handleDelete(row.id)} aria-label="delete" size="large">
                                                        <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableEmpty colSpan={7} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={7} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RoleList