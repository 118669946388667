import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getCompetences = async ({ page = 1, limit = 5, search = '' }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/competences?page=${page}&limit=${limit}&search=${search}`, config);
};
export const getCompetenceList = async () => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/competences/dropdown/list`, config);
};
export const getSpeciality = async ({page = 1, limit = 10, filter = ''}) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/speciality?page=${page}&limit=${10}&filter=${filter}`, config);
};
export const createNewCompetences = async ({ payload }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/competences`,{ ...payload }, config);
    
};
export const getDetailCompetences = async ({ identify }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/competences/${identify}`, config)
    
};
export const updateCompetences = async ({ identify,form }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.put(`${process.env.REACT_APP_API_URL}/competences/${identify}`,form, config)
};
export const deleteCompetences = async ({ id }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/competences/${id}`, config)
};

    