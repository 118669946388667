import { FormattedMessage } from 'react-intl';
import { IconBrandChrome } from '@tabler/icons';

const dashboard = {
    id: 'dashboard-id',
    type: 'group',
    permissions: ['Assignment@index'],
    title: <FormattedMessage id="Dashboard" />,
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: IconBrandChrome,
            permissions: ['Assignment@index'],
            breadcrumbs: false
        }
    ]
};

export default dashboard;
