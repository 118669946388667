import { Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { TypographyLanding } from 'style/typography';

import logo from 'assets/svg/OuresLogo.svg';
import IconVerified from 'assets/images/icons/IconVerified.svg';
import IconShare from 'assets/images/icons/IconShare.svg';
import IconBookmark from 'assets/images/icons/IconBookmark.svg';
import IconPinDrop from 'assets/images/icons/IconPinDrop.svg';
import IconBagOffice from 'assets/images/icons/IconBagOffice.svg';
import IconShieldPerson from 'assets/images/icons/IconShieldPerson.svg';
import IconWallet from 'assets/images/icons/IconWallet.svg';
import IconArrowDoubleDown from 'assets/images/icons/IconArrowDoubleDown.svg';
import FadeInWhenVisible from 'views/pages/landing/Animation';
import SubCard from 'ui-component/cards/SubCard';

const SubCardJobs = ({ lists }) => {
    const currentTime = new Date();
    const [displayedItems, setDisplayedItems] = useState(3);
    const LoadMoreItems = () => {
        setDisplayedItems((prev) => prev + 3);
    };

    return (
        <SubCard sx={{ mt: 3 }} title={<Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Opening Jobs</Typography>}>
            <Grid container item xs={12} spacing={gridSpacing} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {lists.slice(0, displayedItems).map((row, index) => {
                    const inHours = Math.abs(
                        Math.ceil(Math.floor((new Date(row.order_date).getTime() - currentTime.getTime()) / (1000 * 60 * 60)))
                    );
                    const inDays = Math.ceil(inHours / 24);
                    const inWeeks = Math.ceil(inDays / 7);

                    const endDate = new Date(row.order_end_date);
                    const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December'
                    ];
                    const month = monthNames[endDate.getMonth()];
                    const day = endDate.getDate();
                    const year = endDate.getFullYear();

                    let suffix = '';
                    if (day === 1 || day === 21 || day === 31) {
                        suffix = 'st';
                    } else if (day === 2 || day === 22) {
                        suffix = 'nd';
                    } else if (day === 3 || day === 23) {
                        suffix = 'rd';
                    } else {
                        suffix = 'th';
                    }

                    return (
                        <Grid item xs={4} key={index}>
                            <FadeInWhenVisible>
                                <Card
                                    sx={{
                                        borderRadius: '12px',
                                        boxShadow: '0px 7px 19.3px 0px rgb(0 0 0 / 5%)',
                                        border: '1px solid #EEF2F6'
                                    }}
                                >
                                    <Stack direction="column" sx={{ p: 2, pb: 1 }}>
                                        <Stack direction="row" alignItems="center">
                                            <img src={logo} alt="Company Logo" width="30px" />
                                            <Stack direction="column" spacing={0} sx={{ textAlign: 'left', paddingLeft: 0.5, flexGrow: 1 }}>
                                                <Typography
                                                    sx={{
                                                        p: 0,
                                                        m: 0,
                                                        lineHeight: 1,
                                                        fontSize: '15px',
                                                        fontWeight: 700,
                                                        fontFamily: ['Figtree']
                                                    }}
                                                >
                                                    {row.job_position?.name}
                                                </Typography>
                                                <Stack direction="row" alignItems="center" spacing={0.2}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="primary"
                                                        sx={{
                                                            fontFamily: ['Figtree'],
                                                            fontWeight: 700,
                                                            p: 0,
                                                            m: 0,
                                                            lineHeight: 1
                                                        }}
                                                    >
                                                        {row.client?.company_name}
                                                    </Typography>
                                                    <img src={IconVerified} alt="Verified Company" width="12px" />
                                                </Stack>
                                            </Stack>
                                            <Stack direction="row" spacing={0.5}>
                                                <IconButton sx={{ p: 0 }}>
                                                    <img src={IconShare} alt="Share Job" width="20px" />
                                                </IconButton>
                                                <IconButton sx={{ p: 0 }}>
                                                    <img src={IconBookmark} alt="Share Job" width="22px" />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="column" spacing={1} sx={{ paddingTop: 2, paddingLeft: 1 }}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <img src={IconPinDrop} alt="Share Job" width="15px" style={{ padding: 0 }} />
                                                <Typography sx={TypographyLanding()}>{row.work_type?.name}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <img src={IconBagOffice} alt="Job Type" width="15px" style={{ padding: 0 }} />
                                                <Typography sx={TypographyLanding()}>{row.job_type?.name}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <img src={IconShieldPerson} alt="Experienced" width="15px" style={{ padding: 0 }} />
                                                <Typography sx={TypographyLanding()}>
                                                    Experience ({row.criteria[0]?.min_experience} - {row.criteria[0]?.max_experience} Years)
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <img src={IconWallet} alt="Expected Salary" width="15px" style={{ padding: 0 }} />
                                                <Typography sx={TypographyLanding()}>Rp{row.salary}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            sx={{
                                                textAlign: 'left',
                                                paddingTop: 2
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                    fontWeight: 400,
                                                    fontSize: '12px',
                                                    color: '#454348',
                                                    fontFamily: ['Figtree']
                                                }}
                                            >
                                                {row.city?.province.province}, Indonesia
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                    color: 'gray',
                                                    fontSize: '12px',
                                                    fontWeight: 700,
                                                    fontFamily: ['Figtree']
                                                }}
                                            >
                                                {inHours > 24 * 14
                                                    ? `${inWeeks} weeks ago`
                                                    : inHours > 24
                                                    ? `${inDays} days ago`
                                                    : `${Math.abs(Math.ceil(inHours))} hours ago`}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack sx={{ backgroundColor: '#8864EE' }}>
                                        <Typography
                                            sx={{
                                                mx: 2,
                                                my: 0.5,
                                                fontWeight: 600,
                                                fontSize: '12px',
                                                color: 'white',
                                                fontFamily: ['Figtree'],
                                                textAlign: 'left'
                                            }}
                                        >
                                            Apply before {`${month} ${day}${suffix}, ${year}`}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </FadeInWhenVisible>
                        </Grid>
                    );
                })}
                {lists.length > displayedItems && (
                    <Button
                        sx={{ justifyContent: 'center', paddingLeft: '24px', m: 0, color: '#8864EE' }}
                        startIcon={<img src={IconArrowDoubleDown} alt="Load More" width="10px" style={{ padding: 0 }} />}
                        onClick={LoadMoreItems}
                    >
                        Load More
                    </Button>
                )}
            </Grid>
        </SubCard>
    );
};
export default SubCardJobs;
