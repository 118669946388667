import { Box, TableRow, TableCell, Typography } from '@mui/material';

const TableEmpty = ({ colSpan = 1 }) => (
    <TableRow>
        <TableCell colSpan={colSpan}>
            <Box width="100%" textAlign="center">
                <Typography sx={{ margin: '50px 0' }}>No data found(s).</Typography>
            </Box>
        </TableCell>
    </TableRow>
);

export default TableEmpty;
