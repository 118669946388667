import { Stack } from '@mui/material';
import { deleteReport } from 'utils/services/reporting';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 4
};

export default function ConfirmDeleteModal({ open, handleCancel, handleRefresh, row, handleClose }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        await deleteReport({ id: row.id })
            .then((res) => {
                if (res && res.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    handleClose();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.message,
                        alertSeverity: 'warning',
                        variant: 'alert'
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
            .finally(() => handleRefresh());
    };

    return (
        <Modal open={open} closeAfterTransition>
            <Fade in={open}>
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Stack spacing={0.5}>
                            <Typography variant="h2" component="h2">
                                Are you sure?
                            </Typography>
                            <Typography>You are about delete your report.</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button variant="text" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button disableElevation variant="contained" type="submit" onClick={(e) => handleDelete(e)} disabled={loading}>
                                {loading ? 'Loading' : 'Continue'}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    );
}
