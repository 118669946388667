// material-ui
import { Box, Grid, TextField, Button, Stack, FormHelperText, Modal, Typography, Backdrop, Autocomplete, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SwitchComponent from 'ui-component/forms/Switch';
import ConfirmationModal, { modalStyle } from 'ui-component/confirmation';
import axios from 'axios';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { IconClipboardList } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';

const ClientForm = () => {
    const theme = useTheme('oures');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, copy] = useCopyToClipboard(); //eslint-disable-line
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get('action');
    const identify = queryParams.get('identify');
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [successModal, setSuccessModal] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [payload, setPayload] = useState({
        company_name: '',
        address: '',
        province: null,
        city: null,
        district: null,
        sub_district: null,
        postal_code: '',
        company_phone: '',
        company_mail: '',
        pic_name: '',
        pic_phone: '',
        pic_email: '',
        pic_address: '',
        pic_domisili: '',
        is_active: true
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/client`,
                {
                    ...payload,
                    province: payload.province?.value,
                    city: payload.city?.value,
                    district: payload.district?.value,
                    sub_district: payload.sub_district?.value ?? null
                },
                config
            )
            .then((res) => {
                if (res) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: res.data.message,
                        alertSeverity: 'success',
                        variant: 'alert'
                    });
                    setLoading(false);
                    navigate('/clients');
                }
            })
            .catch((err) => {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: err?.response?.data?.message,
                    alertSeverity: 'error',
                    variant: 'alert'
                });
                // navigate('/clients');
                setLoading(false);
            });
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        const form = {
            company_name: payload.company_name,
            address: payload.address,
            province: payload.province.value,
            city: payload.city.value,
            district: payload.district.value,
            sub_district: payload.sub_district.value,
            postal_code: payload.postal_code,
            company_phone: payload.company_phone,
            company_mail: payload.company_mail,
            is_active: payload.is_active
        };

        await axios.put(`${process.env.REACT_APP_API_URL}/client/${identify}`, form, config).then((res) => {
            if (res && res.status === 200 && res.data) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.data.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                navigate('/clients');
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.data.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
                navigate('/clients');
            }
        });
    };

    const fetchRegions = async (type = 'Province', id = '') => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        let addonsUrl = '';

        switch (type) {
            case 'City':
                addonsUrl = `&province_id=${id}`;
                break;
            case 'District':
                addonsUrl = `&city_id=${id}`;
                break;
            case 'Sub District':
                addonsUrl = `&district_id=${id}`;
                break;
            default:
                break;
        }

        await axios.get(`${process.env.REACT_APP_API_URL}/region?type=${type}${addonsUrl}`, config).then((res) => {
            if (res.status === 200) {
                const lists = [];
                res.data.data.forEach((x) => {
                    if (type === 'City') {
                        lists.push({ value: x.id, label: x.city });
                    } else if (type === 'District') {
                        lists.push({ value: x.id, label: x.district });
                    } else if (type === 'Sub District') {
                        lists.push({ value: x.id, label: x.sub_district });
                    } else {
                        lists.push({ value: x.id, label: x.province });
                    }
                });
                if (type === 'Province') {
                    setProvinces(lists);
                } else if (type === 'City') {
                    setCities(lists);
                } else if (type === 'District') {
                    setDistricts(lists);
                } else if (type === 'Sub District') {
                    setSubDistricts(lists);
                }
            }
        });
    };

    const fetchDetail = async () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/client/${identify}`, config).then((res) => {
            if (res.status === 200) {
                setPayload(res.data.data);
            }
        });
    };

    const resetRegions = () => {
        setCities([]);
        setDistricts([]);
        setSubDistricts([]);
    };

    const clearCitySelection = () => {
        setPayload({ ...payload, city: null });
        setDistricts([]);
        setSubDistricts([]);
    };

    const clearDistrictSelection = () => {
        setPayload({ ...payload, district: null });
        setSubDistricts([]);
    };

    const clearSubDistrictSelection = () => {
        setPayload({ ...payload, sub_district: null });
    };

    useEffect(() => {
        if (action === 'update' && identify) {
            fetchDetail();
        }
        fetchRegions('Province');
    }, [identify, action, selectedProvince]); //eslint-disable-line

    return (
        <MainCard
            title={
                <Box width="100%">
                    <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                        <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                            <IconClipboardList color="#fff" />
                        </Avatar>
                        <Stack direction="column">
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                {action === 'create' ? 'Create new client' : 'Update Client'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Please fill the form bellow
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            }
        >
            <ConfirmationModal
                open={openCancelModal}
                description="Your changes will be lost"
                handleCancel={() => setOpenCancelModal(false)}
                handleContinue={() => navigate('/clients')}
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={successModal}
                onClose={() => null}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Box sx={modalStyle}>
                    <Typography variant="h3">Register Success</Typography>
                    <Typography variant="caption">Client Login Information</Typography>
                    <Box sx={{ background: '#f1f1f1', padding: '10px', borderRadius: '10px', marginBottom: '10px', marginTop: '10px' }}>
                        <Typography variant="caption">Email</Typography>
                        <Typography variant="subtitle1">{credentials.email}</Typography>
                        <Typography variant="caption">Password</Typography>
                        <Typography variant="subtitle1">{credentials.password}</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={() => {
                            copy(`Email: ${credentials.email}  Password: ${credentials.password}`);
                            setSuccessModal(false);
                            navigate('/clients');
                        }}
                    >
                        Copy & Return
                    </Button>
                </Box>
            </Modal>
            <Box
                component="form"
                autoComplete="off"
                onSubmit={(e) => (identify && action === 'update' ? handleUpdate(e) : handleSubmit(e))}
            >
                <Grid container spacing={2}>
                    {!identify && (
                        <Grid item sx={12} md={6} spacing={2}>
                            <TextField
                                required
                                name="pic_name"
                                id="input-client-picName"
                                margin="normal"
                                value={payload.pic_name}
                                onChange={(e) => setPayload({ ...payload, pic_name: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Name"
                                onBlur={(e) => console.log(e)}
                                variant="outlined"
                            />
                            <TextField
                                required
                                margin="normal"
                                id="input-client-picEmail"
                                value={payload.pic_email}
                                onChange={(e) => setPayload({ ...payload, pic_email: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Email"
                                type="email"
                                variant="outlined"
                            />
                            <TextField
                                required
                                margin="normal"
                                id="input-client-picPhone"
                                value={payload.pic_phone}
                                onChange={(e) => setPayload({ ...payload, pic_phone: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Your Phone"
                                type="number"
                                variant="outlined"
                            />
                            <TextField
                                required
                                margin="normal"
                                id="input-client-picDomicile"
                                value={payload.pic_domisili}
                                onChange={(e) => setPayload({ ...payload, pic_domisili: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Domisili"
                                variant="outlined"
                            />
                            <Autocomplete
                                disablePortal
                                id="province"
                                value={selectedProvince}
                                options={provinces}
                                sx={{ width: '100%' }}
                                onChange={(e, v) => {
                                    setSelectedProvince(v);
                                    setPayload({ ...payload, province: v });
                                    if (v) {
                                        setPayload({ ...payload, province: v, city: null, district: null, sub_district: null });
                                        setCities([]);
                                        setDistricts([]);
                                        setSubDistricts([]);
                                        fetchRegions('City', v.value);
                                    } else {
                                        setPayload({ ...payload, province: null, city: null, district: null, sub_district: null });
                                        setCities([]);
                                        setDistricts([]);
                                        setSubDistricts([]);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} required label="Province" margin="normal" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="city"
                                value={payload.city}
                                options={cities}
                                sx={{ width: '100%' }}
                                onChange={(e, v) => {
                                    if (v) {
                                        setPayload({ ...payload, city: v });
                                        setDistricts([]);
                                        setSubDistricts([]);
                                        fetchRegions('District', v.value);
                                    } else {
                                        clearCitySelection();
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} required label="City" margin="normal" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="disctrict"
                                value={payload.district}
                                options={districts}
                                sx={{ width: '100%' }}
                                onChange={(e, v) => {
                                    setPayload({ ...payload, district: v });
                                    setSubDistricts([]);
                                    fetchRegions('Sub District', v ? v.value : '');
                                }}
                                renderInput={(params) => <TextField {...params} required label="District" margin="normal" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="sub-district"
                                value={payload.sub_district}
                                options={subDistricts}
                                onChange={(e, v) => {
                                    setPayload({ ...payload, sub_district: v });
                                }}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="Sub District" margin="normal" />}
                            />
                            <TextField
                                required
                                type="text"
                                id="input-client-picAddress"
                                margin="normal"
                                value={payload.pic_address}
                                onChange={(e) => setPayload({ ...payload, pic_address: e.target.value })}
                                sx={{ width: '100%' }}
                                label="Address"
                                variant="outlined"
                            />
                        </Grid>
                    )}
                    <Grid item sx={12} md={identify ? 12 : 6} spacing={2}>
                        <TextField
                            required
                            margin="normal"
                            id="input-client-companyName"
                            value={payload.company_name}
                            onChange={(e) => setPayload({ ...payload, company_name: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Company Name"
                            variant="outlined"
                        />
                        <TextField
                            required
                            margin="normal"
                            id="input-client-companyPhone"
                            value={payload.company_phone}
                            onChange={(e) => setPayload({ ...payload, company_phone: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Company Phone"
                            type="number"
                            variant="outlined"
                        />
                        <TextField
                            required
                            margin="normal"
                            id="input-client-companyMail"
                            value={payload.company_mail}
                            onChange={(e) => setPayload({ ...payload, company_mail: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Company Mail"
                            type="email"
                            variant="outlined"
                        />
                        <TextField
                            required
                            margin="normal"
                            id="input-client-companyAddress"
                            value={payload.address}
                            onChange={(e) => setPayload({ ...payload, address: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Company Address"
                            variant="outlined"
                        />
                        <TextField
                            required
                            margin="normal"
                            id="input-client-postCode"
                            value={payload.postal_code}
                            onChange={(e) => setPayload({ ...payload, postal_code: e.target.value })}
                            sx={{ width: '100%' }}
                            label="Postal Code"
                            type="number"
                            variant="outlined"
                        />
                        <FormHelperText>
                            Status
                            <SwitchComponent
                                label="Helper"
                                id="switch-client-statusUpdate"
                                checked={payload.is_active}
                                onChange={() => setPayload({ ...payload, is_active: !payload.is_active })}
                            />
                        </FormHelperText>
                    </Grid>
                    <Grid item sx={12} md={12} textAlign="right" alignItems="right">
                        <Stack direction="row" spacing={2} justifyContent="end">
                            <Button id="button-client-cancel" variant="outlined" onClick={() => setOpenCancelModal(true)}>
                                Cancel
                            </Button>
                            <Button id="button-client-submit" variant="contained" type={loading ? 'button' : 'submit'} disabled={loading}>
                                {loading ? 'Loading' : `${action === 'create' ? 'Register Now' : 'Update Client'}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainCard>
    );
};

export default ClientForm;
