import { useState, useEffect } from 'react';

// material ui
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Typography,
    useTheme
} from '@mui/material';

// project import
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

// assets
import { IconLicense, IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import RoleList from './RoleList';
import { getRole } from 'utils/services/role';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';
import RootPermission from '../permission';

const Role = () => {
    const [role, setRole] = useState(null);
    const theme = useTheme('oures');
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const [granted, allPermission] = useRootPermission(['Role@get_role_by_id']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const fetchRole = async () => {
        setLoading(true);
        getRole({ page, limit, search: filter })
            .then((res) => {
                setRole(res.data);
                setTotal(res.meta.total);
                handleClose();
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {});
    };
    useEffect(() => {
        fetchRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page, filter]);

    return (
        <RootPermission granted={granted}>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconLicense color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3" sx={{ mb: 0 }}>
                                        Role Management
                                    </Typography>
                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                        Manage and Control Roles of users
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                id="input-role-search"
                                placeholder="Search"
                                onChange={(e) => setFilter(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                            {hasPermission(['Role@role_add'], allPermission) && (
                                <Link id="link-role-create" to="/role/form?action=create">
                                    <IconButton>
                                        <AddCircleTwoToneIcon />
                                    </IconButton>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <RoleList data={role} loading={loading} handleRefresh={() => fetchRole()} />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="primary"
                                id="pagination-role"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                                id="button-role-rows"
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                id="dropdown-role-rows"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem id="menu-role-limit5" onClick={() => setLimit(5)}>
                                    {' '}
                                    5 Rows
                                </MenuItem>
                                <MenuItem id="menu-role-limit10" onClick={() => setLimit(10)}>
                                    {' '}
                                    10 Rows
                                </MenuItem>
                                <MenuItem id="menu-role-limit20" onClick={() => setLimit(20)}>
                                    {' '}
                                    20 Rows
                                </MenuItem>
                                <MenuItem id="menu-role-limit30" onClick={() => setLimit(30)}>
                                    {' '}
                                    30 Rows
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </RootPermission>
    );
};

export default Role;
