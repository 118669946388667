import PropTypes from 'prop-types';

// material-ui
import { Grid, Typography } from '@mui/material';

// project imports
import MainCard from './MainCard';
import { useEffect, useState } from 'react';
import { getAllCancelledRequest } from 'utils/services/dashboard/canceledRequest';

// =============================|| ICON NUMBER CARD ||============================= //

const IconNumberCard = ({ title, color, iconPrimary }) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary /> : null;
    const [cancelledRequest, setCancelledRequest] = useState(0);
    
    const fetchGetAllCancelledRequest = async () => {
        getAllCancelledRequest()
        .then((res) => {
            setCancelledRequest(res.meta.total);
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {});
    };
    useEffect(() => {
        fetchGetAllCancelledRequest();
    }, []);

    return (
        <MainCard>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle2" sx={{ color }}>
                                {primaryIcon}
                            </Typography>
                            <Typography variant="h5" color="inherit">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3">{cancelledRequest}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

IconNumberCard.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    iconPrimary: PropTypes.object
};

export default IconNumberCard;
