/* eslint-disable no-unused-vars */
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Skeleton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { TextFieldStyle1, TextFieldStyle2 } from 'style/textField';
import { IconSearch } from '@tabler/icons';
import { FormLabelStyle } from 'style/form';
import { TypographyLanding } from 'style/typography';
import { getJobType, getListCity, getListExp, getWorkType } from 'utils/services/master';
import { gridSpacing } from 'store/constant';

import MainCard from 'ui-component/cards/MainCard';
import logo from 'assets/svg/OuresLogo.svg';
import IconVerified from 'assets/images/icons/IconVerified.svg';
import IconShare from 'assets/images/icons/IconShare.svg';
import IconBookmark from 'assets/images/icons/IconBookmark.svg';
import IconPinDrop from 'assets/images/icons/IconPinDrop.svg';
import IconBagOffice from 'assets/images/icons/IconBagOffice.svg';
import IconShieldPerson from 'assets/images/icons/IconShieldPerson.svg';
import IconWallet from 'assets/images/icons/IconWallet.svg';
import IconArrowDoubleDown from 'assets/images/icons/IconArrowDoubleDown.svg';
import dummyData from 'views/dummy-data/dummyData';
import FadeInWhenVisible from './Animation';

const Feature = () => {
    const list = dummyData.dataLoker;

    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);
    // const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [works, setWorks] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [city, setCity] = useState([]);
    const [exp, setExp] = useState([]);
    const [checkWorks, setCheckWorks] = useState([]);
    const [checkJobs, setCheckJobs] = useState([]);
    // const [list, setList] = useState([]);
    const [displayedItems, setDisplayedItems] = useState(10);
    const [payload, setPayload] = useState({
        search: '',
        pengalaman: '',
        lokasi: ''
    });

    const LoadMoreItems = () => {
        setDisplayedItems((prev) => prev + 10);
    };

    const fetchWorks = async () => {
        setLoading(true);
        await getWorkType()
            .then((res) => {
                if (res && res.success) {
                    setWorks(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchJobs = async () => {
        setLoading(true);
        await getJobType()
            .then((res) => {
                if (res && res.success) {
                    setJobs(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchCity = async () => {
        setLoading(true);
        await getListCity()
            .then((res) => {
                if (res && res.success) {
                    setCity(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const fetchExp = async () => {
        setLoading(true);
        await getListExp()
            .then((res) => {
                if (res && res.success) {
                    setExp(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    // const fetchOpeningJobs = async () => {
    //     setLoading(true);
    //     await getRequests({ page, limit, search })
    //         .then((res) => {
    //             if (res && res.success) {
    //                 setList(res.data);
    //                 setLoading(false);
    //             }
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             console.log(err);
    //         });
    // };

    useEffect(() => {
        fetchWorks();
        fetchJobs();
        fetchCity();
        fetchExp();
    }, []);

    // useEffect(() => {
    //     if (auth) {
    //         fetchOpeningJobs();
    //     } else {
    //         fetchOpeningJobs();
    //     }
    // }, [page, limit, search]); //eslint-disable-line

    return (
        <MainCard
            content={false}
            sx={{
                borderTopLeftRadius: '40px',
                borderTopRightRadius: '40px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                mt: -5,
                backgroundColor: '#F5F5F5'
            }}
        >
            <CardContent sx={{ justifyContent: 'center' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={16} lg={5} md={10}>
                        <Typography variant="h3" sx={{ fontFamily: ['Figtree'], fontWeight: 600, color: '#4B4B4B', paddingTop: 1 }}>
                            Explore Jobs
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="center" spacing={gridSpacing} sx={{ textAlign: 'center' }}>
                            <Grid item xs={5} sm={4} md={3.5} lg={4} xl={3}>
                                <FadeInWhenVisible>
                                    <Card>
                                        <CardContent>
                                            <Stack direction="column" spacing={1.5}>
                                                <OutlinedInput
                                                    size="small"
                                                    type="search"
                                                    placeholder="Search Job"
                                                    sx={TextFieldStyle2}
                                                    value={payload.search}
                                                    onChange={(e) => setPayload({ ...payload, search: e.target.value.toLowerCase() })}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconSearch stroke={1.5} size="1rem" />
                                                        </InputAdornment>
                                                    }
                                                />
                                                <Autocomplete
                                                    disablePortal
                                                    size="small"
                                                    sx={{ width: '100%' }}
                                                    loading={loading}
                                                    options={exp}
                                                    getOptionLabel={(option) => option.description}
                                                    renderInput={(params) => (
                                                        <TextField {...params} sx={TextFieldStyle1} label="Experience" color="secondary" />
                                                    )}
                                                    onChange={(e, newValue) => {
                                                        setPayload({ ...payload, pengalaman: newValue?.id });
                                                    }}
                                                />
                                                <Autocomplete
                                                    disablePortal
                                                    size="small"
                                                    sx={{ width: '100%' }}
                                                    loading={loading}
                                                    options={city}
                                                    getOptionLabel={(option) => option.city}
                                                    renderInput={(params) => (
                                                        <TextField {...params} sx={TextFieldStyle1} label="Location" color="secondary" />
                                                    )}
                                                    onChange={(e, newValue) => {
                                                        setPayload({ ...payload, lokasi: newValue?.id });
                                                    }}
                                                />
                                                <FormControl component="filter" sx={{ paddingTop: '7px' }} color="secondary">
                                                    <FormLabel sx={FormLabelStyle()}>Work Type</FormLabel>
                                                    <FormGroup>
                                                        {!loading ? (
                                                            works &&
                                                            works.map((item) => (
                                                                <FormControlLabel
                                                                    key={item.id}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            color="secondary"
                                                                            sx={{ p: 0 }}
                                                                            checked={checkWorks.includes(item)}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked === true) {
                                                                                    setCheckWorks([...checkWorks, item]);
                                                                                } else {
                                                                                    const result = checkWorks.filter((data) => {
                                                                                        return data !== item;
                                                                                    });
                                                                                    setCheckWorks(result);
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                    sx={{ pl: '10px', pb: '7px' }}
                                                                    label={
                                                                        <Typography sx={{ fontFamily: ['Figtree'], pl: '10px' }}>
                                                                            {item.name}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            ))
                                                        ) : (
                                                            <>
                                                                {[1, 2, 3].map((_, index) => (
                                                                    <Stack key={index} direction="row" spacing={1} sx={{ pb: '7px' }}>
                                                                        <Checkbox size="small" color="secondary" sx={{ p: 0 }} disabled />
                                                                        <Skeleton variant="text" width={80} />
                                                                    </Stack>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FormGroup>
                                                </FormControl>
                                                <FormControl component="filter" color="secondary">
                                                    <FormLabel sx={FormLabelStyle()}>Job Type</FormLabel>
                                                    <FormGroup>
                                                        {!loading ? (
                                                            jobs &&
                                                            jobs.map((item) => (
                                                                <FormControlLabel
                                                                    key={item.id}
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            color="secondary"
                                                                            sx={{ p: 0 }}
                                                                            checked={checkJobs.includes(item)}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked === true) {
                                                                                    setCheckJobs([...checkJobs, item]);
                                                                                } else {
                                                                                    const result = checkJobs.filter((data) => {
                                                                                        return data !== item;
                                                                                    });
                                                                                    setCheckJobs(result);
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                    sx={{ pl: '10px', pb: '7px' }}
                                                                    label={
                                                                        <Typography sx={{ fontFamily: ['Figtree'], pl: '10px' }}>
                                                                            {item.name}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            ))
                                                        ) : (
                                                            <>
                                                                {[1, 2, 3].map((_, index) => (
                                                                    <Stack key={index} direction="row" spacing={1} sx={{ pb: '7px' }}>
                                                                        <Checkbox size="small" color="secondary" sx={{ p: 0 }} disabled />
                                                                        <Skeleton variant="text" width={80} />
                                                                    </Stack>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FormGroup>
                                                </FormControl>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </FadeInWhenVisible>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={6}
                                md={8}
                                spacing={gridSpacing}
                                sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
                            >
                                {list.slice(0, displayedItems).map((row, index) => (
                                    <Grid item xs={12} md={5.5} lg={6} xl={3.8} key={index}>
                                        <FadeInWhenVisible>
                                            <Card>
                                                <Stack direction="column" sx={{ p: 2, pb: 1 }}>
                                                    <Stack direction="row" alignItems="center">
                                                        <img src={logo} alt="Company Logo" width="30px" />
                                                        <Stack
                                                            direction="column"
                                                            spacing={0}
                                                            sx={{ textAlign: 'left', paddingLeft: 0.5, flexGrow: 1 }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    p: 0,
                                                                    m: 0,
                                                                    lineHeight: 1,
                                                                    fontSize: '15px',
                                                                    fontWeight: 700,
                                                                    fontFamily: ['Figtree']
                                                                }}
                                                            >
                                                                {row.posisiPekerjaan}
                                                            </Typography>
                                                            <Stack direction="row" alignItems="center" spacing={0.2}>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="primary"
                                                                    sx={{
                                                                        fontFamily: ['Figtree'],
                                                                        fontWeight: 700,
                                                                        p: 0,
                                                                        m: 0,
                                                                        lineHeight: 1
                                                                    }}
                                                                >
                                                                    {`PT ${row.namaPerusahaan}`}
                                                                </Typography>
                                                                <img src={IconVerified} alt="Verified Company" width="12px" />
                                                            </Stack>
                                                        </Stack>
                                                        <Stack direction="row" spacing={0.5}>
                                                            <IconButton sx={{ p: 0 }}>
                                                                <img src={IconShare} alt="Share Job" width="20px" />
                                                            </IconButton>
                                                            <IconButton sx={{ p: 0 }}>
                                                                <img src={IconBookmark} alt="Share Job" width="22px" />
                                                            </IconButton>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack direction="column" spacing={1} sx={{ paddingTop: 2, paddingLeft: 1 }}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <img src={IconPinDrop} alt="Share Job" width="15px" style={{ padding: 0 }} />
                                                            <Typography sx={TypographyLanding()}>{row.jenisPekerjaan}</Typography>
                                                        </Stack>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <img src={IconBagOffice} alt="Job Type" width="15px" style={{ padding: 0 }} />
                                                            <Typography sx={TypographyLanding()}>{row.jenisKerja}</Typography>
                                                        </Stack>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <img
                                                                src={IconShieldPerson}
                                                                alt="Experienced"
                                                                width="15px"
                                                                style={{ padding: 0 }}
                                                            />
                                                            <Typography sx={TypographyLanding()}>
                                                                Experience ({row.pengalaman2}-{row.pengalaman} Years)
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <img
                                                                src={IconWallet}
                                                                alt="Expected Salary"
                                                                width="15px"
                                                                style={{ padding: 0 }}
                                                            />
                                                            <Typography sx={TypographyLanding()}>Rp{row.salary}.000.000,00</Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        sx={{
                                                            textAlign: 'left',
                                                            paddingTop: 2
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                p: 0,
                                                                m: 0,
                                                                fontWeight: 400,
                                                                fontSize: '12px',
                                                                color: '#454348',
                                                                fontFamily: ['Figtree']
                                                            }}
                                                        >
                                                            {row.provinsi}, Indonesia
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                p: 0,
                                                                m: 0,
                                                                color: 'gray',
                                                                fontSize: '12px',
                                                                fontWeight: 700,
                                                                fontFamily: ['Figtree']
                                                            }}
                                                        >
                                                            {row.jam} hours ago
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack sx={{ backgroundColor: '#8864EE' }}>
                                                    <Typography
                                                        sx={{
                                                            mx: 2,
                                                            my: 0.5,
                                                            fontWeight: 600,
                                                            fontSize: '12px',
                                                            color: 'white',
                                                            fontFamily: ['Figtree'],
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        Apply before {row.date}
                                                    </Typography>
                                                </Stack>
                                            </Card>
                                        </FadeInWhenVisible>
                                    </Grid>
                                ))}
                                {list.length > displayedItems && (
                                    <Button
                                        sx={{ justifyContent: 'center', paddingLeft: '24px', m: 0, color: '#8864EE' }}
                                        startIcon={<img src={IconArrowDoubleDown} alt="Load More" width="10px" style={{ padding: 0 }} />}
                                        onClick={LoadMoreItems}
                                    >
                                        Load More
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    );
};

export default Feature;
