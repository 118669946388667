import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const changePassword = async ({ payload }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/auth/password/change`, { ...payload }, config);
};

