import { createTheme } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const landingTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: '#cdc2ec',
            main: '#8864EE',
            dark: '#7958d4',
            200: '#bca8f2',
            800: '#5835b8'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    }
});
