import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getEmployees = async ({ page = 1, limit = 5, search = '' }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/employees?page=${page}&limit=${limit}&filter=${search}`, config);
};
export const createNewEmployee = async ({ payload }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/employees`, { ...payload }, config);
};
export const updateEmployee = async ({ identify,form }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    
    return ApiService.put(`${process.env.REACT_APP_API_URL}/employees/${identify}`,  form , config)
    
};
export const deleteEmployee = async ({ id }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.delete(`${process.env.REACT_APP_API_URL}/employees/${id}`, config)
};
export const getDetailEmployee = async ({ identify }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };
    return ApiService.get(`${process.env.REACT_APP_API_URL}/employees/${identify}`, config)
    
};
