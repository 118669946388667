import { styled } from '@mui/material/styles';
import { Box, Button, Container, Grid, TextField, Link, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { TextFieldStyle1 } from 'style/textField';
import { IconBrandLinkedin } from '@tabler/icons';

import InstagramIcon from '@mui/icons-material/Instagram';
import logoDark from 'assets/svg/OuresLogoText.svg';
import ShapeIconCard from 'ui-component/cards/ShapeIconCard';

const FooterWrapper = styled('div')(({ theme }) => ({
    padding: '35px 0',
    color: '#fff',
    background: '#1E1E1E',
    minHeight: '40vh',
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}));
const FooterSubWrapper = styled('div')(({ theme }) => ({
    padding: '20px 0',
    color: '#fff',
    background: '#433161',
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}));

const FooterPage = () => {
    const list1 = ['About', 'Company', 'Platform', 'FAQ', 'Contact Us'];
    const list2 = ['Features', 'Counselling', 'Courses', 'Jobs', 'Project Control'];
    const list3 = ['Product', 'Pricing', 'Blog'];

    return (
        <>
            <FooterWrapper>
                <Container>
                    <Grid container spacing={gridSpacing} justifyContent="space-between">
                        <Grid item xs={2}>
                            <Box sx={{ display: 'block' }}>
                                <img src={logoDark} alt="Berry" width="140" />
                                <Box sx={{ display: 'flex', mt: 2 }}>
                                    <Grid item container gap={1.5}>
                                        <Link
                                            href="https://www.linkedin.com/company/pt-digital-informasi-futuristik"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <ShapeIconCard
                                                iconPrimary={<IconBrandLinkedin size="medium" />}
                                                color="lightgray"
                                                sx={{ borderRadius: '50%' }}
                                            />
                                        </Link>
                                        <Link href="https://www.instagram.com/difindonesia/" target="_blank" rel="noopener noreferrer">
                                            <ShapeIconCard iconPrimary={<InstagramIcon />} color="lightgray" sx={{ borderRadius: '50%' }} />
                                        </Link>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item container xs={5} justifyContent="space-between">
                            <Grid item>
                                <Box gap={2} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                                    {list1.map((item, index) => (
                                        <Typography
                                            key={index}
                                            sx={{ fontSize: '12px', fontFamily: ['Figtree'], '&:hover': { fontWeight: 600 } }}
                                        >
                                            {item}
                                        </Typography>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box gap={2} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                                    {list2.map((item, index) => (
                                        <Typography
                                            key={index}
                                            sx={{ fontSize: '12px', fontFamily: ['Figtree'], '&:hover': { fontWeight: 600 } }}
                                        >
                                            {item}
                                        </Typography>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box gap={2} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                                    {list3.map((item, index) => (
                                        <Typography
                                            key={index}
                                            sx={{ fontSize: '12px', fontFamily: ['Figtree'], '&:hover': { fontWeight: 600 } }}
                                        >
                                            {item}
                                        </Typography>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }} gap={1}>
                                <Typography sx={{ fontSize: '14px', fontFamily: ['Figtree'], fontWeight: 600 }}>Newsletter</Typography>
                                <Typography sx={{ fontSize: '12px', fontFamily: ['Figtree'], textAlign: 'justify', fontWeight: 300 }}>
                                    Don&apos;t miss out on the chance to supercharge your career with valuable insights and opportunities.
                                    Subscribe to <strong>Oures</strong>&apos;s newsletter now and take the next step towards a brighter
                                    professional future!
                                </Typography>
                                <Box sx={{ display: 'flex', mt: 2 }}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Your email"
                                        size="small"
                                        sx={{
                                            ...TextFieldStyle1(),
                                            color: 'white',
                                            height: '5px !important',
                                            '& input': {
                                                backgroundColor: 'rgba(89, 89, 89, 1)',
                                                borderRadius: '4px',
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                                height: '5px !important',
                                                '::placeholder': {
                                                    color: 'white',
                                                    fontFamily: ['Figtree'],
                                                    fontStyle: 'italic !important',
                                                    fontSize: '12px'
                                                }
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(89, 89, 89, 1)'
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(89, 89, 89, 1)'
                                                }
                                            }
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'rgba(136, 100, 238, 1)',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            boxShadow: 'none',
                                            height: '25px',
                                            fontFamily: ['Figtree'],
                                            fontSize: '12px',
                                            paddingX: 2,
                                            '&:hover': {
                                                backgroundColor: 'rgba(136, 100, 238, 1)'
                                            }
                                        }}
                                        size="small"
                                    >
                                        Subscribe
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </FooterWrapper>
            <FooterSubWrapper>
                <Container>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle2" sx={{ fontFamily: ['Figtree'], color: 'white' }}>
                                Copyright © 2024 Oures. All rights reserved
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }} gap={6}>
                                <Typography
                                    sx={{ fontFamily: ['Figtree'] }}
                                    variant="subtitle2"
                                    // component={Link}
                                    // href="https://dif.co.id"
                                    // target="_blank"
                                    // underline="hover"
                                >
                                    Privacy policy
                                </Typography>
                                <Typography
                                    sx={{}}
                                    variant="subtitle2"
                                    // component={Link}
                                    // href="https://dif.co.id"
                                    // target="_blank"
                                    // underline="hover"
                                >
                                    Term of Services
                                </Typography>
                                <Typography
                                    sx={{}}
                                    variant="subtitle2"
                                    // component={Link}
                                    // href="https://dif.co.id"
                                    // target="_blank"
                                    // underline="hover"
                                >
                                    Cookie Settings
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </FooterSubWrapper>
        </>
    );
};

export default FooterPage;
