import { Box, Button, Card, CardContent, Chip, Divider, Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'component/function/CapitalizedFirstLetter';

import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';
import PhonelinkRingTwoToneIcon from '@mui/icons-material/PhonelinkRingTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import IconShieldPerson from 'assets/images/icons/IconShieldPerson';
import IconFileSave from 'assets/images/icons/IconFileSave';

const SubCardUser = ({ detail }) => (
    <SubCard
        title={
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    {detail.users.avatarURL ? (
                        <Avatar alt="User 1" src={detail.users.avatarURL} />
                    ) : (
                        <div
                            style={{
                                background: '#f1f1f1',
                                fontSize: '20px',
                                padding: '10px',
                                textAlign: 'center',
                                borderRadius: '50%'
                            }}
                        >
                            {detail.users.name.substr(0, 2)}
                        </div>
                    )}
                </Grid>
                <Grid item>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={0.5}>
                        <Typography align="left" variant="subtitle1">
                            {capitalizeFirstLetter(detail.users.name)}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} gap={0.5}>
                            <Chip
                                size="small"
                                label={detail.resources.available_work === '' ? 'Available' : 'Not Available'}
                                sx={{
                                    color: 'white',
                                    width: 'fit-content',
                                    fontWeight: 400,
                                    backgroundColor:
                                        detail.resources.available_work === ''
                                            ? '#11A797'
                                            : detail.resources.available_work === 'hired'
                                            ? '#D3B9FF'
                                            : '#BEBEBE'
                                }}
                            />
                            <Chip
                                size="small"
                                label="Senior Level"
                                avatar={<IconShieldPerson color="white" />}
                                sx={{
                                    color: 'white',
                                    width: 'fit-content',
                                    fontWeight: 400,
                                    backgroundColor: '#344C7A',
                                    paddingLeft: '1px'
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        }
    >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 1 }}>
                <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                <Typography variant="subtitle1" sx={{ pl: 1, flexGrow: 1 }}>
                    Email
                </Typography>
                <Typography variant="subtitle2">{detail.users.email}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 1 }}>
                <PhonelinkRingTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                <Typography variant="subtitle1" sx={{ pl: 1, flexGrow: 1 }}>
                    Phone
                </Typography>
                <Typography variant="subtitle2">{detail.users.phoneNumber}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 1 }}>
                <PinDropTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                <Typography variant="subtitle1" sx={{ pl: 1, flexGrow: 1 }}>
                    Domicile
                </Typography>
                <Typography variant="subtitle2">Bandung</Typography>
            </Box>
            <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    sx={{
                        borderRadius: '4px',
                        boxShadow: 'none',
                        mt: 5,
                        justifyContent: 'center',
                        width: 'fit-content',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}
                    startIcon={<IconFileSave />}
                >
                    Generate CV
                </Button>
            </Box>
            <Card sx={{ backgroundColor: '#EEF2F6', mt: 5, borderRadius: '4px' }}>
                <CardContent>
                    <Typography sx={{ color: '#5E35B1', fontWeight: 600 }}>Congratulations!</Typography>
                    <Typography sx={{ color: '#364152', fontWeight: 400, fontSize: '12px', mt: 1 }}>
                        You reach highest level of programming. Are you willing to work as{' '}
                        <strong>
                            <u>Product Owner</u>
                        </strong>{' '}
                        or{' '}
                        <strong>
                            <u>Scrum Master</u>
                        </strong>
                        ?
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }} gap={1}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            sx={{
                                borderRadius: '4px',
                                boxShadow: 'none',
                                justifyContent: 'center',
                                width: 'fit-content',
                                alignItems: 'center',
                                textAlign: 'center',
                                fontSize: '12px'
                            }}
                        >
                            Change Job-seeking Settings
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            sx={{
                                borderRadius: '4px',
                                boxShadow: 'none',
                                justifyContent: 'center',
                                width: 'fit-content',
                                alignItems: 'center',
                                textAlign: 'center',
                                fontSize: '12px'
                            }}
                        >
                            Not now
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </SubCard>
);

export default SubCardUser;
