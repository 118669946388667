/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination } from '@mui/material';
import { IconSearch } from '@tabler/icons';
import { gridSpacing } from 'store/constant';
import { getAssignment } from 'utils/services/assignments';
import { hasPermission } from 'helper/general';
import { getAssignmentInterviewList } from 'utils/services/assignmentInterview';
import { getRequestDone, getUserQuotation } from 'utils/services/assignment-quotation';
import { getAssignmentCancelled } from 'utils/services/assignment-reassign';

import MainCard from 'ui-component/cards/MainCard';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import TableDone from './TableDone';
import TableReassign from './TableReassign';
import useRootPermission from 'hooks/useRootPermission';
import TableRunning from './TableRunning';
import TableOnProcess from './TableOnProcess';
import getUserRunning from 'utils/services/assignment-running';
import TableInterview from './TableInterview';

const AssignList = (props) => {
    const { category } = props;
    const theme = useTheme('oures');

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [assignList, setAssignList] = useState([]);
    const [lists, setLists] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [granted, allPermission] = useRootPermission(['Assignment@index']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchData = async () => {
        setLoading(true);
        await getAssignment({ page, limit, search, category })
            .then((res) => {
                if (res && res.success) {
                    setAssignList(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const fetchRequestStatus = async () => {
        setLoading(true);
        await getAssignmentInterviewList({ page, limit, search })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const fetchAssignmentQuotations = async () => {
        setLoading(true);
        await getUserQuotation({ page, limit, search })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const fetchAssignmentRunning = async () => {
        setLoading(true);
        await getUserRunning({ page, limit, search })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const fetchAssignmentDone = async () => {
        setLoading(true);
        await getRequestDone({ page, limit, search })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const fetchAssignmentReassign = async () => {
        setLoading(true);
        await getAssignmentCancelled({ page, limit, search })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (category === 'interview') {
            fetchRequestStatus();
        } else if (category === 'onProcess') {
            fetchAssignmentQuotations();
        } else if (category === 'running') {
            fetchAssignmentRunning();
        } else if (category === 'done') {
            fetchAssignmentDone();
        } else if (category === 'reassign') {
            fetchAssignmentReassign();
        }
    }, [category, search, limit, page]); //eslint-disable-line

    useEffect(() => {
        fetchData();
    }, [limit, page, search, category]); //eslint-disable-line

    return (
        <MainCard
            title={
                <OutlinedInput
                    id="input-assignment-search"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch stroke={1.5} size="1rem" />
                        </InputAdornment>
                    }
                    size="small"
                />
            }
            content={false}
        >
            {category === 'interview' && lists && (
                <TableInterview
                    lists={lists}
                    loading={loading}
                    setLoading={setLoading}
                    category={category}
                    hasPermission={hasPermission}
                    allPermission={allPermission}
                    setOpen={setOpen}
                    open={open}
                    handleRefresh={fetchRequestStatus}
                    startIndex={(page - 1) * limit + 1}
                />
            )}
            {category === 'onProcess' && lists && (
                <TableOnProcess
                    lists={lists}
                    open={open}
                    loading={loading}
                    setLoading={setLoading}
                    hasPermission={hasPermission}
                    allPermission={allPermission}
                    setOpen={setOpen}
                    handleRefresh={fetchAssignmentQuotations}
                    startIndex={(page - 1) * limit + 1}
                />
            )}
            {category === 'running' && lists && (
                <TableRunning
                    lists={lists}
                    open={open}
                    loading={loading}
                    setLoading={setLoading}
                    hasPermission={hasPermission}
                    allPermission={allPermission}
                    setOpen={setOpen}
                    handleRefresh={fetchAssignmentRunning}
                    startIndex={(page - 1) * limit + 1}
                />
            )}
            {category === 'done' && lists && (
                <TableDone
                    lists={lists}
                    open={open}
                    loading={loading}
                    setLoading={setLoading}
                    hasPermission={hasPermission}
                    allPermission={allPermission}
                    setOpen={setOpen}
                    handleRefresh={fetchAssignmentDone}
                    startIndex={(page - 1) * limit + 1}
                />
            )}
            {category === 'reassign' && lists && (
                <TableReassign
                    lists={lists}
                    open={open}
                    loading={loading}
                    setLoading={setLoading}
                    hasPermission={hasPermission}
                    allPermission={allPermission}
                    setOpen={setOpen}
                    handleRefresh={fetchAssignmentReassign}
                    startIndex={(page - 1) * limit + 1}
                />
            )}

            <Grid container justifyContent="space-between" spacing={gridSpacing} p={2}>
                <Grid item>
                    <Pagination
                        onChange={(e, value) => setPage(value)}
                        count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                        color="primary"
                    />
                </Grid>
                <Grid item>
                    <Button
                        size="large"
                        sx={{ color: theme.palette.grey[900] }}
                        color="secondary"
                        endIcon={<ExpandMoreRoundedIcon />}
                        onClick={handleClick}
                        id="button-assignment-rows"
                    >
                        {limit} Rows
                    </Button>
                    <Menu
                        id="dropdown-assignment-rows"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem
                            id="menu-assignment-limit5"
                            onClick={() => {
                                setLimit(5);
                                handleClose();
                            }}
                        >
                            {' '}
                            5 Rows
                        </MenuItem>
                        <MenuItem
                            id="menu-assignment-limit10"
                            onClick={() => {
                                setLimit(10);
                                handleClose();
                            }}
                        >
                            {' '}
                            10 Rows
                        </MenuItem>
                        <MenuItem
                            id="menu-assignment-limit20"
                            onClick={() => {
                                setLimit(20);
                                handleClose();
                            }}
                        >
                            {' '}
                            20 Rows
                        </MenuItem>
                        <MenuItem
                            id="menu-assignment-limit30"
                            onClick={() => {
                                setLimit(30);
                                handleClose();
                            }}
                        >
                            {' '}
                            30 Rows
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AssignList;
