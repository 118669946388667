/* eslint-disable no-unused-vars */
import { gridSpacing, storageKey } from 'store/constant';
import { IconInbox, IconSearch } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { hasPermission } from 'helper/general';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { getListClient } from 'utils/services/dashboard/client';
import { getRequests } from 'utils/services/requests';
import {
    Autocomplete,
    Avatar,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import useRootPermission from 'hooks/useRootPermission';
import AddCircleTwoTone from '@mui/icons-material/AddCircleTwoTone';
import TableRequest from './TableRequest';

const RequestListPage = () => {
    const theme = useTheme('oures');

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState([]);
    const [clients, setClients] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [granted, allPermission] = useRootPermission(['FormRequest@get_all']);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchClient = async () => {
        setLoading(true);
        await getListClient({ page, limit })
            .then((res) => {
                if (res && res.success) {
                    setClients(res.data);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    const getData = async () => {
        setLoading(true);
        await getRequests({ page, limit, search, filter })
            .then((res) => {
                if (res && res.success) {
                    setResources(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (hasPermission(['FormRequest@get_all'], allPermission) === 'FormRequest@get_all') {
            fetchClient();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPermission]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, filter, search]);

    return (
        <>
            <MainCard
                sx={{ marginTop: hasPermission(['FormRequest@create'], allPermission) ? '10px' : '' }}
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                                <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                    <IconInbox color="#fff" />
                                </Avatar>
                                <Stack direction="column">
                                    <Typography variant="h3">
                                        {hasPermission(['FormRequest@get_all'], allPermission) ? 'Client Requests' : ''}
                                    </Typography>
                                    <Typography variant="caption" sx={{ mb: 2 }}>
                                        Request of Developers from Clients
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item spacing={2}>
                            {hasPermission(['FormRequest@get_all'], allPermission) && (
                                <FormControl sx={{ margin: '0 10px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="client-dropdown"
                                        options={clients}
                                        getOptionLabel={(option) => option.company_name}
                                        sx={{ width: '200px', display: 'flex' }}
                                        onChange={(e, v) => {
                                            setFilter(v?.id);
                                            setPage(1);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Enter Client Name" />}
                                    />
                                </FormControl>
                            )}
                            <OutlinedInput
                                id="input-request-search"
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" />
                                    </InputAdornment>
                                }
                            />
                            {hasPermission(['FormRequest@create'], allPermission) &&
                                (localStorage.getItem(storageKey.USER_ROLE) === 'client' || 'super-admin') && (
                                    <Link id="link-request-add" to="/requests/form?action=create">
                                        <IconButton>
                                            <AddCircleTwoTone />
                                        </IconButton>
                                    </Link>
                                )}
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <TableRequest
                    loading={loading}
                    setLoading={setLoading}
                    resources={resources}
                    onRefresh={getData}
                    startIndex={(page - 1) * limit + 1}
                />
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                onChange={(e, value) => setPage(value)}
                                count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                                color="primary"
                                id="pagination-request"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                                id="button-request-rows"
                            >
                                {limit} Rows
                            </Button>
                            <Menu
                                id="dropdown-request-rows"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem id="menu-request-limit5" onClick={() => setLimit(10)}>
                                    {' '}
                                    10 Rows
                                </MenuItem>
                                <MenuItem id="menu-request-limit10" onClick={() => setLimit(25)}>
                                    {' '}
                                    25 Rows
                                </MenuItem>
                                <MenuItem id="menu-request-limit20" onClick={() => setLimit(50)}>
                                    {' '}
                                    50 Rows
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default RequestListPage;
