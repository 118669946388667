import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const createBatchAssignment = async ({ body }) => {
    // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.post(`${process.env.REACT_APP_API_URL}/assignment/batch`, { ...body }, config);
};

export const getAssignment = async ({ page, limit, search, category }) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(
        `${process.env.REACT_APP_API_URL}/assignment?page=${page}&limit=${limit}&search=${search}&sortBy=created_at,asc&category=${category}`,
        config
    );
};
