import { styled } from '@mui/material/styles';

import Header from './Header';
import HeaderTestimonies from './HeaderTestimonies';
import Footer from './Footer';
import AppBarCustom from 'views/pages/landing/AppBarCustom';
import Feature from './Feature';

const AppBarWrapper = styled('div')(() => ({
    paddingTop: 30,
    position: 'sticky',
    zIndex: 6
}));

const Landing = () => (
    <>
        <AppBarWrapper>
            <AppBarCustom />
        </AppBarWrapper>
        <Header />
        <Feature />
        <HeaderTestimonies />
        <Footer />
    </>
);

export default Landing;
