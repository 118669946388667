import { useParams } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';

import axios from 'axios';
import SubCardUser from './SubCardUser';
import SubcardExpectancy from './SubCardExpectancy';
import SubCardSkill from './SubCardSkill';
import SubCardProfile from './SubCardProfie';
import SubCardWorkExp from './SubCardWorkExp';
import SubCardEdu from './SubCardEdu';
import SubCardExpertise from './SubCardExpertise';

const Profile = () => {
    const { resourceId } = useParams();
    const [detail, setDetail] = React.useState({ resources: {}, users: {} });

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        };
        axios
            .get(`${process.env.REACT_APP_API_URL}/resources/${resourceId}`, config)
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.length > 0) {
                    setDetail(res.data.data[0]);
                }
            })
            .catch();
    }, [resourceId]);

    return detail && detail.resources.id ? (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={4} xs={12}>
                <SubCardUser detail={detail} />
                <SubcardExpectancy />
            </Grid>
            <Grid item lg={8} xs={12}>
                <SubCardSkill />
                <SubCardProfile detail={detail} />
                <SubCardWorkExp />
                <SubCardEdu />
                <SubCardExpertise detail={detail} />
            </Grid>
        </Grid>
    ) : (
        <p>Tidak ditemukan data.</p>
    );
};

export default Profile;
