function IconShieldPerson({ color = '#454348', width = 16, height = 17, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 {width} {height}" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask
                id="a"
                style={{
                    maskType: 'alpha'
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={width}
                height={height}
            >
                <path fill="#D9D9D9" d="M0 0.5H16V16.5H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M8 9.167c-.656 0-1.208-.225-1.658-.675-.45-.45-.675-1.003-.675-1.659 0-.655.225-1.208.675-1.658C6.792 4.725 7.344 4.5 8 4.5c.656 0 1.208.225 1.658.675.45.45.675 1.003.675 1.658 0 .656-.225 1.209-.675 1.659-.45.45-1.002.675-1.658.675zm0-1.334a.971.971 0 00.717-.283A.971.971 0 009 6.833a.971.971 0 00-.283-.716A.971.971 0 008 5.833a.971.971 0 00-.717.284.971.971 0 00-.283.716c0 .29.094.528.283.717A.971.971 0 008 7.833zm0 7.334c-1.544-.39-2.82-1.275-3.825-2.659-1.006-1.383-1.508-2.92-1.508-4.608V3.833l5.333-2 5.333 2V7.9c0 1.689-.502 3.225-1.508 4.608-1.005 1.384-2.28 2.27-3.825 2.659zM8 3.25l-4 1.5V7.9c0 .6.083 1.183.25 1.75.167.567.394 1.1.683 1.6A7.507 7.507 0 016.4 10.7a6.315 6.315 0 011.6-.2c.556 0 1.089.067 1.6.2.511.133 1 .317 1.467.55.289-.5.516-1.033.683-1.6.167-.567.25-1.15.25-1.75V4.75l-4-1.5zm0 8.583c-.4 0-.789.045-1.167.134a5.924 5.924 0 00-1.083.366 5.96 5.96 0 001.05.867c.378.245.778.433 1.2.567a4.937 4.937 0 001.2-.567 5.96 5.96 0 001.05-.867 5.924 5.924 0 00-1.083-.366A5.076 5.076 0 008 11.833z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default IconShieldPerson;
