// material-ui
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { OpenInNewRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TableLoader from 'ui-component/loader/TableLoader';
import TableEmpty from 'ui-component/loader/TableEmpty';
import { deleteUser } from 'utils/services/users';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import useRootPermission from 'hooks/useRootPermission';
import { hasPermission } from 'helper/general';

const UserList = ({ data = [], handleRefresh, loading = true }) => {
    const dispatch = useDispatch();
    const [granted, allPermission] = useRootPermission(['Users@users_get']);
    const handleDelete = async (id) => {
        deleteUser({ id }).then((res) => {
            if (res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
            }
            handleRefresh();
        });
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading ? (
                        <>
                            {data && data.length > 0 ? (
                                data.map((row, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            {hasPermission(['Users@users_getputdel'], allPermission) && (
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    <Tooltip placement="top" title="Edit Competences">
                                                        <Link to={`form?action=update&identify=${row.id}`}>
                                                            <IconButton color="primary" aria-label="open" size="large">
                                                                <OpenInNewRounded sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete Competences">
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => handleDelete(row.id)}
                                                            aria-label="delete"
                                                            size="large"
                                                        >
                                                            <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableEmpty colSpan={5} />
                            )}
                        </>
                    ) : (
                        <TableLoader colSpan={5} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserList;
