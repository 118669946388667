import { Box } from '@mui/system';
import { useState } from 'react';

import PropTypes from 'prop-types';

const TabPanelFunc = () => {
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    function TabPanel({ children, value, index, ...other }) {
        return (
            <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
                {value === index && <Box>{children}</Box>}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`
        };
    }

    return { value, handleChange, TabPanel, a11yProps };
};

export default TabPanelFunc;
