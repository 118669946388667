import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const updateAssignmentCancelled = async ({ id, payload, notes = '' }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/assignment/status/${id}?process_step=${payload}&notes=${notes}`, {}, config);
};
export const updateReassign = async ({ id, payload }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.put(`${process.env.REACT_APP_API_URL}/assignment/reassign-interview/${id}`, { ...payload }, config);
};

export const getAssignmentCancelled = async ({ page, limit, search }) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` } };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/assignment/is_cancelled?page=${page}&limit=${limit}&search=${search}`, config);
};
