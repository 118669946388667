import { storageKey } from 'store/constant';
import ApiService from '../../service';

export const getPermission = async ({ page = 1, limit = 5, search = '' }) => { // eslint-disable-line
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem(storageKey.USER_TOKEN)}` }
    };

    return ApiService.get(`${process.env.REACT_APP_API_URL}/permission`, config);
};
