import { Stack } from '@mui/material';

import SpiderChart from 'component/chart/SpiderChart';
import SubCard from 'ui-component/cards/SubCard';

const SubCardSkill = () => {
    const softSkillCategory = [
        'Communication Skill',
        'Time Management',
        'Team Work',
        'English Level',
        'Critical Thinking',
        'Problem Solving'
    ];
    const hardSkillCategory = [
        'HTML/CSS',
        'JavaScript',
        'Python',
        'Java',
        'React',
        'Node.js',
        'SQL',
        'Git',
        'Linux',
        'Algorithms',
        'Data Structures'
    ];

    const softSkillSeries = [
        {
            name: 'Soft Skill',
            data: [80, 50, 30, 40, 60, 70]
        }
    ];
    const hardSkillSeries = [
        {
            name: 'Hard Skill',
            data: [80, 50, 30, 40, 60, 70, 80, 50, 30, 40, 60]
        }
    ];

    return (
        <SubCard title="Skill Overview" sx={{ height: 285 }}>
            <Stack direction="row" alignItems="center" spacing={0}>
                <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                    <Stack direction="row" alignItems="center" spacing={0}>
                        <SpiderChart categories={softSkillCategory} series={softSkillSeries} />
                        <SpiderChart categories={hardSkillCategory} series={hardSkillSeries} />
                    </Stack>
                </div>
            </Stack>
        </SubCard>
    );
};

export default SubCardSkill;
