import { Check, Event, PersonAddAlt1TwoTone } from '@mui/icons-material';
import {
    Chip,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { changeRegistrationStatus } from 'utils/services/registration-requests';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import TableEmpty from 'ui-component/loader/TableEmpty';
import TableLoader from 'ui-component/loader/TableLoader';
import DateModal from './DateModal';
import ConfirmationModal from 'ui-component/confirmation';
import { useTheme } from '@mui/styles';

const RegistrationRequest = ({ lists, loading, setLoading, handleRefresh, startIndex }) => {
    moment.locale('en');
    const theme = useTheme();
    const dispatch = useDispatch();

    const [item, setItem] = useState({});
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSetStatus = async (id) => {
        setLoading(true);
        const payload = {
            status: 'done'
        };
        await changeRegistrationStatus({ id, payload }).then((res) => {
            if (res && res.success) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'success',
                    variant: 'alert'
                });
                setLoading(false);
                handleClose();
                handleRefresh();
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: res.message,
                    alertSeverity: 'warning',
                    variant: 'alert'
                });
            }
        });
    };

    return (
        <>
            {open === 'schedule' && (
                <DateModal open={open === 'schedule'} handleClose={handleClose} item={item} handleRefresh={handleRefresh} />
            )}
            {open === 'done' && (
                <ConfirmationModal
                    open={open === 'done'}
                    description="You will mark this request as done"
                    handleCancel={handleClose}
                    handleContinue={() => handleSetStatus(item.id)}
                    loading={loading}
                />
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Company</TableCell>
                            <TableCell align="center">Availability Date</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {lists && lists.length > 0 ? (
                                    lists.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{index + startIndex}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.company_name}</TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                                    <Typography variant="body1">
                                                        {moment.utc(row.avaliable_at).format('D MMMM YYYY, h:mm a')}
                                                    </Typography>
                                                    <Typography variant="body1">to</Typography>
                                                    <Typography variant="body1">
                                                        {moment.utc(row.avaliable_until).format('D MMMM YYYY, h:mm a')}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Chip
                                                    label={row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                                                    size="small"
                                                    sx={{
                                                        background:
                                                            row.status === 'pending'
                                                                ? theme.palette.info.light + 60
                                                                : theme.palette.success.light,
                                                        color:
                                                            row.status === 'pending' ? theme.palette.info.dark : theme.palette.success.dark
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                {row.status === 'pending' ? (
                                                    <Stack direction="row">
                                                        <Tooltip placement="top" title="Set Meeting Schedule">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setOpen('schedule');
                                                                    setItem(row);
                                                                }}
                                                                color="primary"
                                                                aria-label="open"
                                                                size="large"
                                                                id="button-registration-meeting"
                                                            >
                                                                <Event sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Mark as Done">
                                                            <IconButton
                                                                color="success"
                                                                aria-label="open"
                                                                size="large"
                                                                id="button-accept"
                                                                onClick={() => {
                                                                    setOpen('done');
                                                                    setItem(row);
                                                                }}
                                                            >
                                                                <Check sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                ) : (
                                                    <Tooltip placement="top" title="Add New Client">
                                                        <Link
                                                            id="link-registration-newClient"
                                                            to={`/registration-request/form?action=create&identify=${row.id}`}
                                                        >
                                                            <IconButton color="primary" aria-label="open" size="large">
                                                                <PersonAddAlt1TwoTone sx={{ fontSize: '1.1rem' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableEmpty colSpan={6} />
                                )}
                            </>
                        ) : (
                            <TableLoader colSpan={6} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default RegistrationRequest;
