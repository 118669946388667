import PropTypes from 'prop-types';

import { IconWrapper } from 'style/wrapper';

const ShapeIconCard = ({ iconPrimary, color, sx }) => {
    return (
        <IconWrapper sx={sx}>
            <span style={{ background: color }} />
            <span style={{ color }}>{iconPrimary}</span>
        </IconWrapper>
    );
};

ShapeIconCard.propTypes = {
    iconPrimary: PropTypes.object,
    color: PropTypes.string
};

export default ShapeIconCard;
