import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { currentUser } from 'utils/services/users';
import { Grid, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Skeleton, Typography } from '@mui/material';

import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import Avatar3 from 'assets/images/users/avatar-3.png';

// ==============================|| PROFILE ||============================== //

const Profile = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const fetchUserData = async () => {
        setLoading(true);
        await currentUser()
            .then((res) => {
                if (res) {
                    setData(res.data.user_info);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <Grid container justifyContent="center" spacing={gridSpacing}>
            <Grid item lg={4} xs={12}>
                {!loading ? (
                    <SubCard
                        title={
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar alt="User 1" src={data.avatarURL ?? Avatar3} />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography align="left" variant="subtitle1">
                                        {data.name}
                                    </Typography>
                                    <Typography align="left" variant="subtitle2">
                                        {/* {user.sub.role.name} */}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItemButton>
                                <ListItemIcon>
                                    <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="subtitle1">Email</Typography>} />
                                <ListItemSecondaryAction>
                                    <Typography variant="subtitle2" align="right">
                                        {data.email}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </List>
                    </SubCard>
                ) : (
                    <Skeleton variant="rectangular" width={210} height={118} />
                )}
            </Grid>
        </Grid>
    );
};

export default Profile;
