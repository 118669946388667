import { Backdrop, Card, CardContent, CardHeader, Box, Typography } from '@mui/material';
import { boxStyle } from './NegotiationLetterModal';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 735,
    boxShadow: 24,
    borderRadius: '12px'
};

export default function BlacklistedModal({ row, open, handleClose }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <Card sx={modalStyle}>
                    <CardHeader sx={{ borderBottom: 1, borderColor: '#d6d6d6', p: 2 }} title="Blacklisted Notes" />
                    <CardContent>
                        <Box sx={boxStyle}>
                            <Typography dangerouslySetInnerHTML={{ __html: row.blacklist_notes }} />
                        </Box>
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
}
