import { ExpandMoreRounded } from '@mui/icons-material';
import { Avatar, Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Stack, Typography } from '@mui/material';
import { IconBriefcase, IconSearch } from '@tabler/icons';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import { getRegistration } from 'utils/services/registration-requests';

import MainCard from 'ui-component/cards/MainCard';
import RegistrationRequest from './RegistrationRequest';

const RegistrationRequestPage = () => {
    const theme = useTheme('oures');

    const [filter, setFilter] = useState('');
    const [lists, setLists] = useState([]);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getData = async () => {
        setLoading(true);
        await getRegistration({ page, limit, filter })
            .then((res) => {
                if (res && res.success) {
                    setLists(res.data);
                    setTotal(res.meta.total);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, filter]);

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Stack direction="row" spacing={2} justifyContent="left" sx={{ float: 'left' }}>
                            <Avatar variant="rounded" color="inherit" sx={{ bgcolor: theme.palette.secondary.main, ml: 'auto' }}>
                                <IconBriefcase color="#fff" />
                            </Avatar>
                            <Stack direction="column">
                                <Typography variant="h3">Client Registration Requests</Typography>
                                <Typography variant="caption">Register assigned client to make the client request possible</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <OutlinedInput
                            id="input-registration"
                            placeholder="Search"
                            onChange={(e) => setFilter(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" />
                                </InputAdornment>
                            }
                            size="small"
                        />
                    </Grid>
                </Grid>
            }
        >
            <RegistrationRequest
                lists={lists}
                loading={loading}
                setLoading={setLoading}
                handleRefresh={() => getData()}
                startIndex={(page - 1) * limit + 1}
            />
            <Grid container justifyContent="space-between" spacing={gridSpacing} xs={12} sx={{ py: 3 }}>
                <Grid item>
                    <Pagination
                        onChange={(e, value) => setPage(value)}
                        count={total > 0 ? `${total / limit > 0 ? Math.ceil(total / limit) : 1}` : 0}
                        color="primary"
                    />
                </Grid>
                <Grid item>
                    <Button
                        size="large"
                        sx={{ color: theme.palette.grey[900] }}
                        color="secondary"
                        endIcon={<ExpandMoreRounded />}
                        onClick={handleClick}
                        id="button-registration-rows"
                    >
                        {limit} Rows
                    </Button>
                    <Menu
                        id="dropdown-registration-rows"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem id="menu-registration-limit5" onClick={() => setLimit(5)}>
                            {' '}
                            5 Rows
                        </MenuItem>
                        <MenuItem id="menu-registration-limit10" onClick={() => setLimit(10)}>
                            {' '}
                            10 Rows
                        </MenuItem>
                        <MenuItem id="menu-registration-limit20" onClick={() => setLimit(20)}>
                            {' '}
                            20 Rows
                        </MenuItem>
                        <MenuItem id="menu-registration-limit30" onClick={() => setLimit(30)}>
                            {' '}
                            30 Rows
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default RegistrationRequestPage;
